import React from 'react';

import CourseDesktopTabView from '@pages/Course/DesktopTabView';
import CourseMobileView from '@pages/Course/MobileView';

import NameAndPhoneNoOtp from '@components/Popups/NameAndPhoneNoOtp';
import CartAndEnrollView from '@components/Popups/CartAndEnrollView';

import './style.css';

export default (props) => (
	<>
		{props.viewportWidth > 768 ? (
			<CourseDesktopTabView {...props} />
		) : (
			<CourseMobileView {...props} />
		)}

		<NameAndPhoneNoOtp
			nameAndPhoneNoOtpOpenBtnRef={props.nameAndPhoneNoOtpOpenBtnRef}
			nameAndPhoneNoOtpStage={props.nameAndPhoneNoOtpStage}
			countryCode={props.countryCode}
			phoneNo={props.phoneNo}
			handleLeadsVerifyOTP={props.handleLeadsVerifyOTP}
			handleResendOTP={props.handleResendOTP}
			flushNameAndPhoneNoOtpStage={props.flushNameAndPhoneNoOtpStage}
			handleEnrollSendOTP={props.handleEnrollSendOTP}
			buttonType={props.buttonType}
		/>

		<CartAndEnrollView
			handleEnrollAndAddToCart={props.handleEnrollAndAddToCart}
			takeCartViewAction={props.takeCartViewAction}
			cartView={props.cartView}
			courseData={props.courseDetails}
			handleAddToCart={props.handleAddToCart}
			addToCartLoading={props.addToCartLoading}
			handleFreeCourse={props.handleFreeCourse}
		/>
	</>
);
