import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Navbar from "@components/Navbar";
import TopLoadingBar from "@components/TopLoadingBar";

import * as NavbarActions from "@redux/actions/Navbar";
import * as TopLoadingBarActions from "@redux/actions/TopLoadingBar";
import * as CheckoutServices from "@services/Checkout";
import * as TentativeOrdersService from "@services/TentativeOrders";
import * as ExamServices from "@services/Exam";

import PayView from "@pages/Pay/view";

import { isUserLogedIn } from "@library/auth";
import { decryptText, encryptText } from "@library/enc-dec";
import { loadStripe } from "@stripe/stripe-js";
import {
  convertObjectToQueryString,
  objectToQueryString,
} from "@library/helpers";

function OtherPay(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataFetchedRef = useRef(false);
  const location = useLocation();

  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [paymentLoaderNote, setPaymentLoaderNote] = useState("");

  const orderDetailsFromState = location.state?.orderDetails;
  const purchaseDetailsFromState = location.state?.purchaseDetails;
  const source = location.state?.source;

  useEffect(() => {
    window.scroll(0, 0);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    setPaymentLoaderNote("Payment Loading...");
    checkLogin();
  }, []);

  const checkLogin = async () => {
    const userLogedIn = await isUserLogedIn();
    dispatch(NavbarActions.updateLoginState(userLogedIn));
    if (!userLogedIn) {
      navigate("/login", { state: { message: "Please login first" } });
      return;
    }
    // debugger
    if (
      orderDetailsFromState &&
      Object.entries(orderDetailsFromState).length > 0 &&
      purchaseDetailsFromState &&
      Object.entries(purchaseDetailsFromState).length > 0 &&
      source &&
      Object.entries(source).length
    ) {
      if (orderDetailsFromState.paymentMethod === "stripe") {
        generateStripeOrderId();
      } else if (orderDetailsFromState.paymentMethod === "ccavenue") {
        generateCCAvenueOrderId();
      } else if (
        orderDetailsFromState.paymentMethod === "razorpay" ||
        orderDetailsFromState.paymentMethod === "eazypay" ||
        orderDetailsFromState.paymentMethod === "axis" ||
        orderDetailsFromState.paymentMethod === "idfc"
      ) {
        genrateRazorpayOrderId();
      }
    } else {
      alert("Purchase details missing");
      navigate("/");
    }
  };

  const generateStripeOrderId = async () => {
    try {
      UpdateTopLoadingBarForThisPage(30);

      let authToken = decryptText(localStorage.getItem("eMediat"));
      const paymentDetails = {
        amount: parseInt(orderDetailsFromState.grandTotal),
        currency: orderDetailsFromState.currencyCode,
      };
      const orderDetails = await CheckoutServices.createOrderForStripe(
        paymentDetails,
        authToken
      );
      if (orderDetails.success) {
        if (
          orderDetails.data &&
          orderDetails.data.id &&
          orderDetails.data.client_secret
        ) {
          addTentativeOrder(orderDetails.data);
          UpdateTopLoadingBarForThisPage(50);
        } else {
          console.log("Issue while generating order id with of strip");
          navigate("/");
        }
      } else {
        alert(orderDetails.message);
        navigate("/");
        return;
      }
    } catch (err) {
      console.log("Error coming while generateStripeOrderId()", err);
      navigate("/");
    }
  };

  const genrateRazorpayOrderId = async () => {
    try {
      UpdateTopLoadingBarForThisPage(30);
      // debugger
      const paymentDetails = {
        amount: Math.round(parseInt(orderDetailsFromState.grandTotal)),
        currency: orderDetailsFromState.currencyCode,
        paymentMethod: orderDetailsFromState.paymentMethod
      };

      let authToken = decryptText(localStorage.getItem("eMediat"));
      const orderDetails = await CheckoutServices.createOrder(
        paymentDetails,
        authToken
      );
      if (orderDetails.success) {
        addTentativeOrder(orderDetails.data);
        UpdateTopLoadingBarForThisPage(50);
      } else {
        alert(orderDetails.message);
        navigate("/");
        return;
      }
    } catch (err) {
      console.log("Error coming while genrateRazorpayOrderId()", err);
    }
  };

  const generateCCAvenueOrderId = async () => {
    try {
      const orderId = Math.floor(Math.random() * 1000000000);

      const currencyCode = decryptText(localStorage.getItem("cc"));
      let finalAmount = Math.round(
        parseInt(orderDetailsFromState.grandTotal) - orderDetailsFromState.discountInNumbers - orderDetailsFromState.gatewayDiscountInNumber
      );
      const amount = finalAmount.toString() + ".00";
      const orderDetails = {
        id: orderId,
        order_id: orderId,
        amount: amount,
        currency: currencyCode,
      };

      if (orderDetails) {
        addTentativeOrder(orderDetails);
        UpdateTopLoadingBarForThisPage(50);
      } else {
        console.log("Issue while generating order id with of strip");
        navigate("/");
      }
    } catch (err) {
      console.log("Error coming while generateCCAvenueOrderId()", err);
    }
  };

  const addTentativeOrder = async (orderDetails) => {
    try {
      // actulAmt : 200
      // oderamt = actulAmt - (actulAmt * gatWayDiscoum /100)
      // grandtotal = oderamt
      // gst = grandtotal * 0.08/100
      // netTotal = gradnToal
      // subTotal = oderamt - gst

      let installmentNumber = 0;
      let paymentStatus = "";
      let certificateCount = 0;
      let installmentOption = orderDetailsFromState.installmentOption;

      // debugger
      if (source.expenseType === "examReattempt") {
        paymentStatus = "full";
      }
      // else if (source.expenseType === "course" && source.paymentStatus === "partial") {
      else if (source.expenseType === "installment") {
        if (source.installmentNo === "payFullBalance") {
          for (let key in installmentOption) {
            installmentOption[key]["isPaid"] = true;
          }
          paymentStatus = "full";
        } else {
          // if (installmentOption[source.installmentNo]) {
          if (installmentOption[source.installmentNo-1]) {
              installmentOption[source.installmentNo-1]["isPaid"] = true;
            installmentNumber = source.installmentNo;
            paymentStatus = "partial";
          }
        }
      } else if (source.expenseType === "certificate") {
        paymentStatus = "full";
        certificateCount = source.numberOfCertificates;
      }

      const { type } = orderDetailsFromState.gatewayDetails;
      const GST =
        orderDetailsFromState.grandTotal * (type === "stripe" ? 0.09 : 0.18);

      let gatewayDetails = orderDetailsFromState.gatewayDetails;
      gatewayDetails.discount = 0;

      const order = {
        orderId: orderDetails.id,
        paymentId:
          orderDetailsFromState.paymentMethod === "stripe" ||
            orderDetailsFromState.paymentMethod === "ccavenue"
            ? orderDetails.id
            : "pay_" + orderDetails.id,
        paymentMethod: orderDetailsFromState.paymentMethod,
        orderAmount:
          orderDetailsFromState.currencySymbol +
          " " +
          orderDetailsFromState.grandTotal,
        orderAmountInNumbers: orderDetailsFromState.grandTotal,
        coursesMnemonic: orderDetailsFromState.coursesMnemonic,
        courses: orderDetailsFromState.courses,
        continent: orderDetailsFromState.continent,
        countryName: orderDetailsFromState.countryName,
        currencySymbol: orderDetailsFromState.currencySymbol,
        currencyCode: orderDetailsFromState.currencyCode,
        GST: GST,
        grandTotal: orderDetailsFromState.grandTotal,
        netTotal: orderDetailsFromState.grandTotal,
        subTotal: orderDetailsFromState.grandTotal - GST,
        discount: "0",
        discountInNumbers: 0,
        discountInPercentage: 0,
        offerDetails: null,
        gatewayDiscount: "0",
        gatewayDiscountInNumber: 0,
        gatewayDetails: gatewayDetails,
        paymentStatus: paymentStatus,
        installmentOption: installmentOption,
        installmentNumber: installmentNumber,
        // expenseType: source.expenseType === "course" && source.paymentStatus === "partial" ? "installment" : source.expenseType,
        expenseType: source.expenseType,
        parentPaymentId: orderDetailsFromState.paymentId,
        parentOrderId: orderDetailsFromState.orderId,
        parentOrderDocId: orderDetailsFromState._id,
        certificateCount: certificateCount,
      };

      const authToken = decryptText(localStorage.getItem("eMediat"));
      const queryString = objectToQueryString(order);
      const encryptedPayload = encryptText(queryString);
      const response = await TentativeOrdersService.addTentativeOrder(
        { order: encryptedPayload },
        authToken
      );

      if (response.success) {
        if (orderDetailsFromState.paymentMethod === "stripe") {
          // handleStripePayment(orderDetailsFromState);
          handleStripePayment(orderDetails);          
        } else if (orderDetailsFromState.paymentMethod === "ccavenue") {
          // handleCCAvenuePayment(orderDetailsFromState);
          handleCCAvenuePayment(orderDetails);          
        } else if (
          orderDetailsFromState.paymentMethod === "razorpay" ||
          orderDetailsFromState.paymentMethod === "eazypay" ||
          orderDetailsFromState.paymentMethod === "idfc" ||
          orderDetailsFromState.paymentMethod === "axis"
        ) {
          // handleRazorpayPayment(orderDetailsFromState);
          handleRazorpayPayment(orderDetails);          
        }
        return;
      }
      alert(response.message);
      navigate("/");
      return;
    } catch (err) {
      console.log("Error coming while adding tentative orders", err);
    }
  };

  const handleStripePayment = (orderDetails) => {
    try {
      setStripePromise(
        loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY)
      );
      setClientSecret(orderDetails.client_secret);
      setIsLoading(false);
      UpdateTopLoadingBarForThisPage(100);
    } catch (err) {
      console.log("Error coming while handleStripePayment()", err);
    }
  };

  const handleRazorpayPayment = (orderDetails) => {
    try {
      if (orderDetails) {
        const currencyCode = decryptText(localStorage.getItem("cc"));
        var options = {
          key:
            orderDetailsFromState.paymentMethod === "eazypay"
              ? process.env.REACT_APP_EAZYPAY_API_KEY
              : orderDetailsFromState.paymentMethod === "idfc"
                ? process.env.REACT_APP_IDFC_API_KEY
                : orderDetailsFromState.paymentMethod === "axis"
                  ? process.env.REACT_APP_AXIS_API_KEY
                  : process.env.REACT_APP_RAZORPAY_API_KEY,

          // Enter the Key ID generated from the Dashboard
          amount: orderDetails.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: currencyCode,
          name: "eMediSkill Services LLP",
          description: "Secure Payment with eMediSkill",
          image:
            "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/rayzorpayIcon.png",
          order_id: orderDetails.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: (response) => {
            handleRedirectionForRazorPay(response);
          },
          prefill: {
            name: `${orderDetailsFromState.firstName} ${orderDetailsFromState.lastName}`,
            email: orderDetailsFromState.email,
            contact: orderDetailsFromState.contactNumber,
          },
          theme: {
            color: "#3399cc",
          },
          modal: {
            ondismiss: () => navigate("/"),
          },
        };

        var rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          alert(response.error.description);
        });

        rzp1.open();

        UpdateTopLoadingBarForThisPage(100);
        setIsLoading(false);
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log("Error coming while handleRazorpayPayment()", err);
    }
  };

  const handleRedirectionForRazorPay = async (response) => {
    if (source.expenseType) {
      if (source.expenseType === "coursePurchase") {
        let redirectPage = "/confirm-order";
        if (window.innerWidth > 576) {
          redirectPage = "/";
        }
        navigate(redirectPage, {
          state: {
            paymentId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
          },
        });
      } else if (source.expenseType === "examReattempt") {
        if (purchaseDetailsFromState) {
          const purchaseDocId = purchaseDetailsFromState._id;
          const courseId = purchaseDetailsFromState.courseId;
          const examType = "Exam";

          let payload = {
            purchaseId: purchaseDocId,
          };
          const authToken = decryptText(localStorage.getItem("eMediat"));
          const response = await ExamServices.reAttemptExam(
            examType,
            authToken,
            payload
          );

          if (response.success) {
            navigate("/exam", {
              state: {
                courseId: courseId,
                purchaseId: purchaseDocId,
              },
            });
          } else {
            console.log("Issue from handleRedirectToEaxm() ", response);
            alert("Issue in updating re-attempt exam details");
            navigate("/");
          }
        } else {
          alert("Purchase details not found after successful payment");
          navigate("/");
        }
      } else if (
        source.expenseType === "installment" ||
        source.expenseType === "certificate"
      ) {
        if (purchaseDetailsFromState) {
          const purchaseDocId = purchaseDetailsFromState._id;
          navigate(`/timeline/${purchaseDocId}`);
        } else {
          alert("Purchase details not found after successful payment");
          navigate("/");
        }
      }
    } else {
      navigate("/");
    }
  };

  const handleCCAvenuePayment = async (orderDetails) => {
    try {
      if (orderDetails) {
        let authToken = decryptText(localStorage.getItem("eMediat"));
        const payload = {
          tid: orderDetails.id * 2,
          order_id: orderDetails.id,
          merchant_id: process.env.REACT_APP_CCAVENUE_MERCHANT_ID,
          amount: orderDetails.amount,
          language: "EN",
          currency: orderDetails.currency,
          redirect_url: process.env.REACT_APP_CCAVENUE_REDIRECT_URL,
          cancel_url: process.env.REACT_APP_CCAVENUE_CANCEL_URL,
        };

        const queryString = convertObjectToQueryString(payload);
        const encryptedPayload = encryptText(queryString);

        const formHTML = await CheckoutServices.getCCAvenuePaymentLink(
          { data: encryptedPayload },
          authToken
        );

        if (formHTML) {
          // Create a temporary div element
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = formHTML;
          document.body.appendChild(tempDiv);
          // Submit the form
          document.redirect.submit();
        } else {
          console.error("Invalid response from server");
        }
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log("Error coming while handleHdfcPayment()", err);
    }
  };

  //START: Code for setting the progressof top bar loader
  const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
    if (interval) {
      setTimeout(function () {
        dispatch(
          TopLoadingBarActions.updateLoadingBarProgress(
            props.loadingBarProgress + value
          )
        );
      }, 500);
    } else {
      dispatch(
        TopLoadingBarActions.updateLoadingBarProgress(
          props.loadingBarProgress + value
        )
      );
    }
  };
  //END: Code for setting the progressof top bar loader

  return (
    <>
      <Navbar />
      <TopLoadingBar />
      <PayView
        expenseType={source.expenseType}
        purchaseDetails={purchaseDetailsFromState}
        stripePromise={stripePromise}
        clientSecret={clientSecret}
        paymentLoaderNote={paymentLoaderNote}
        isLoading={isLoading}
        orderAmount={orderDetailsFromState.grandTotal}
        paymentMethod={orderDetailsFromState.paymentMethod}
      />
    </>
  );
}

export default OtherPay;
