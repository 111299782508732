import React from 'react';
import ReplySectionView from './view';
import { useNavigate } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { produce } from 'immer';

import { decryptText } from '@library/enc-dec';
import * as CourseContentServices from '@services/CourseContent';
import * as CourseDetailsActions from '@redux/actions/CourseDetails';

function ReplySection(props) {
	const navigate = useNavigate();

	const handleLikeReply = async (replyId) => {
		try {
			if (!localStorage.getItem('eMediat')) {
				localStorage.removeItem('eMediat');
				localStorage.removeItem('eMediatt');
				navigate('/login');
				return;
			}

			let authToken = decryptText(localStorage.getItem('eMediat'));
			const videoDocId = props.currentVideoData._id;

			let payload = {
				commentId: props.currentCommentId,
				videoDocId: videoDocId,
				replyId: replyId,
			};
			const response = await CourseContentServices.likeReply(payload, authToken);
			if (response.success) {
				const status = response.data ? true : false;
				handleReplyLikeStatus(status, props.currentCommentId, replyId);
			}
		} catch (err) {
			console.log('Error coming while handling reply like', err);
		}
	};

	const handleReplyLikeStatus = (status, commentId, replyId) => {
		let updatedVideoComments = produce(props.currentVideoComments, (draft) => {
			let comment = draft.data.comments.find((c) => c._id === commentId);
			if (comment) {
				let reply = comment.replies.find((r) => r._id === replyId);
				if (reply) {
					reply.isLiked = status;
					if (status) {
						reply.likesCount++;
					} else {
						reply.likesCount--;
					}

					if (reply.isDisliked) {
						reply.isDisliked = false;
						reply.dislikesCount--;
					}
				}
			}
		});
		// Updating Redux
		props.updateCurrentVideoComments(updatedVideoComments);
	};

	const handleDislikeReply = async (replyId) => {
		try {
			if (!localStorage.getItem('eMediat')) {
				localStorage.removeItem('eMediat');
				localStorage.removeItem('eMediatt');
				navigate('/login');
				return;
			}

			let authToken = decryptText(localStorage.getItem('eMediat'));
			const videoDocId = props.currentVideoData._id;

			let payload = {
				commentId: props.currentCommentId,
				videoDocId: videoDocId,
				replyId: replyId,
			};
			const response = await CourseContentServices.dislikeReply(
				payload,
				authToken
			);
			if (response.success) {
				const status = response.data ? true : false;
				handleReplyDislikeStatus(status, props.currentCommentId, replyId);
			}
		} catch (err) {
			console.log('Error coming while handling reply dislike', err);
		}
	};

	const handleReplyDislikeStatus = (status, commentId, replyId) => {
		let updatedVideoComments = produce(props.currentVideoComments, (draft) => {
			let comment = draft.data.comments.find((c) => c._id === commentId);
			if (comment) {
				let reply = comment.replies.find((r) => r._id === replyId);
				if (reply) {
					reply.isDisliked = status;
					if (status) {
						reply.dislikesCount++;
					} else {
						reply.dislikesCount--;
					}

					if (reply.isLiked) {
						reply.isLiked = false;
						reply.likesCount--;
					}
				}
			}
		});

		// Updating Redux
		props.updateCurrentVideoComments(updatedVideoComments);
	};

	return (
		<>
			<ReplySectionView
				currentCommentId={props.currentCommentId}
				commentRepliesData={props.commentRepliesData}
				handleCommentReply={props.handleReply}
				handleLikeReply={handleLikeReply}
				handleDislikeReply={handleDislikeReply}
			/>
		</>
	);
}
function mapStateToProps(state) {
	return {
		currentVideoData: state.courseDetails.currentVideoData,
		currentVideoComments: state.courseDetails.currentVideoComments,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateCurrentVideoComments: CourseDetailsActions.updateCurrentVideoComments,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplySection);
