import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';

import '../_style.css';
import './mobileStyle.css';

import MyLearning from '@pages/UserDashboard/MyLearning/Main';
import Evaluation from '@pages/UserDashboard/Evaluation';
import Profile from '@pages/UserDashboard/Profile/Main';
import Setting from '@pages/UserDashboard/Setting/Main';

import MyLearningIcon from '@assets/userDashboard/Icons/grayMyLearningIcon.png';
import ProfileIcon from '@assets/userDashboard/Icons/grayProfileIcon.png';
import EvaluationIcon from '@assets/userDashboard/Icons/grayEvaluationIcon.png';
import SettingIcon from '@assets/userDashboard/Icons/graySettingIcon.png';

import MyLearningIcon_blue from '@assets/userDashboard/Icons/blueMyLearningIcon.png';
import ProfileIcon_blue from '@assets/userDashboard/Icons/blueProfileIcon.png';
import EvaluationIcon_blue from '@assets/userDashboard/Icons/blueEvaluationIcon.png';
import SettingIcon_blue from '@assets/userDashboard/Icons/blueSettingIcon.png';

function MobileView(props) {
	return (
		<>
			<center>
				<div className='userDashboardMainContainer mobile_userDashboardMainContainer'>
					{props.isLoading ? (
						<></>
					) : (
						<>
							<Tab.Container
								defaultActiveKey='homeTab'
								activeKey={props.activeTab}
								onSelect={props.handleTabSelect}>
								<div className='fixed-bottom mobileNavContainer'>
									<Nav variant='tabs' className='navContainer col-12'>
										<Nav.Item className='col-3'>
											<Nav.Link eventKey='myLearningTab' onClick={props.tabNavLinkClicked}>
												{props.myLearningTab ? (
													<>
														<img
															src={MyLearningIcon_blue}
															alt='MyLearningIcon'
															className='navIcon'
														/>
													</>
												) : (
													<>
														<img
															src={MyLearningIcon}
															alt='MyLearningIcon'
															className='navIcon'
														/>
													</>
												)}
												<p className='navTitle'>My Learning</p>
											</Nav.Link>
										</Nav.Item>

										<Nav.Item className='col-3'>
											<Nav.Link eventKey='evaluationTab' onClick={props.tabNavLinkClicked}>
												{props.evaluationTab ? (
													<>
														<img
															src={EvaluationIcon_blue}
															alt='EvaluationIcon'
															className='navIcon'
														/>
													</>
												) : (
													<>
														<img
															src={EvaluationIcon}
															alt='EvaluationIcon'
															className='navIcon'
														/>
													</>
												)}
												<p className='navTitle'>Evaluation</p>
											</Nav.Link>
										</Nav.Item>

										<Nav.Item className='col-3'>
											<Nav.Link eventKey='profileTab' onClick={props.tabNavLinkClicked}>
												{props.profileTab ? (
													<>
														<img
															src={ProfileIcon_blue}
															alt='ProfileIcon'
															className='navIcon'
														/>
													</>
												) : (
													<>
														<img src={ProfileIcon} alt='ProfileIcon' className='navIcon' />
													</>
												)}
												<p className='navTitle'>Profile</p>
											</Nav.Link>
										</Nav.Item>

										<Nav.Item>
											<Nav.Link eventKey='settingTab' onClick={props.tabNavLinkClicked}>
												{props.settingTab ? (
													<>
														<img
															src={SettingIcon_blue}
															alt='SettingIcon'
															className='navIcon'
														/>
													</>
												) : (
													<>
														<img src={SettingIcon} alt='SettingIcon' className='navIcon' />
													</>
												)}
												<p className='navTitle'>Settings</p>
											</Nav.Link>
										</Nav.Item>
									</Nav>
								</div>

								<Tab.Content className='tabContainer'>
									{/* myLearningTab*/}
									<Tab.Pane eventKey='myLearningTab' className='myLearningMainContainer'>
										{props.myLearningTab && <MyLearning />}
									</Tab.Pane>

									{/* evaluationTab*/}
									<Tab.Pane eventKey='evaluationTab' className='myLearningMainContainer'>
										{props.evaluationTab && <Evaluation />}
									</Tab.Pane>

									{/* profileTab*/}
									<Tab.Pane eventKey='profileTab' className='profileMainContainer'>
										{props.profileTab && <Profile />}
									</Tab.Pane>

									{/* settingTab*/}
									<Tab.Pane eventKey='settingTab' className='profileMainContainer'>
										{props.settingTab && <Setting />}
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</>
					)}
				</div>
			</center>
		</>
	);
}

export default MobileView;
