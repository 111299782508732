export const updatePurchasedCourses = (value) => {
	return {
		type: 'UPDATE_PURCHASED_COURSES',
		payload: {
			purchasedCourses: value,
		},
	};
};

export const updateSeriesList = (value) => {
	return {
		type: 'UPDATED_SERIES_LIST',
		payload: {
			seriesList: value,
		},
	};
};


export const updateUserHistort = (value) => {
	return {
		type: 'UPDATED_USER_HISTORY',
		payload: {
			userHistory: value,
		},
	};
};


export const updateUserFav = (value) => {
	return {
		type: 'UPDATED_USER_FAV',
		payload: {
			userFav: value,
		},
	};
};

