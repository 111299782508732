import React, { useRef, useEffect, useState } from 'react';

import OfferScratchCardView from './view.js';

function OfferScratchCard(props) {
	const canvasRef = useRef(null);
	const isDrawingRef = useRef(false);
	const lastPointRef = useRef(null);
	const [scratchPercentage, setScratchPercentage] = useState(0);
	const [isCopied, setIsCopied] = useState(false);

	const getPosition = (event) => {
		let target = canvasRef.current;
		let offsetX = 0;
		let offsetY = 0;

		if (target.offsetParent !== undefined) {
			while ((target = target.offsetParent)) {
				offsetX += target.offsetLeft;
				offsetY += target.offsetTop;
			}
		}

		const x = (event.pageX || event.touches[0].clientX) - offsetX;
		const y = (event.pageY || event.touches[0].clientY) - offsetY;
		return { x, y };
	};

	const touchStart = (event) => {
		isDrawingRef.current = true;
		lastPointRef.current = getPosition(event);
		const ctx = canvasRef.current.getContext('2d');
		ctx.globalCompositeOperation = 'destination-out';
	};

	const touchMove = (event) => {
		if (!isDrawingRef.current) return;
		if (event.cancelable) {
			event.preventDefault();
		 }

		try{
		const ctx = canvasRef.current.getContext('2d');
		const a = lastPointRef.current;
		const b = getPosition(event);
		const dist = Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
		const angle = Math.atan2(b.x - a.x, b.y - a.y);
		const brush = new Image();
		brush.src = require('./brush.png');
		const offsetX = brush.width / 2;
		const offsetY = brush.height / 2;

		for (let x, y, i = 0; i < dist; i++) {
			x = a.x + Math.sin(angle) * i - offsetX;
			// We were using a.y inPlace of 10 but in mobile that creates problem as that given relative position
			// of component but in pc that gives position of mouse inside the scratchable component
			y = 10 + Math.cos(angle) * i - offsetY;
			ctx.drawImage(brush, x, y);
		}

		lastPointRef.current = b;
        
		// Calculate and update the scratch percentage
		const totalPixels = canvasRef.current.width * canvasRef.current.height;
		const scratchedPixels = ctx
			.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height)
			.data.filter((pixel, index) => 
				index % 4 === 3 && pixel === 0).length;
		

		const percentage = (scratchedPixels / totalPixels) * 100;
		
		setScratchPercentage(percentage);
	  }
	  catch(exception){
		console.log("Scratch Complete")
	  }
	};

	const touchEnd = () => {
		isDrawingRef.current = false;
	};

	const handleCopy = (event) => {
		let offercode = event.target.getAttribute('offercode');
		navigator.clipboard
			.writeText(offercode)
			.then(() => {
				setIsCopied(true);
				setTimeout(function () {
					setIsCopied(false);
				}, 2000);
			})
			.catch((error) => {
				console.error('Failed to copy code:', error);
			});
	};

	useEffect(() => {
		const canvas = canvasRef.current;

		const drawCover = () => {
			const ctx = canvas.getContext('2d');
			const cover = new Image();
			cover.src = require('./scratchCardCover.png');
			cover.onload = () => {
				ctx.drawImage(cover, 0, 0, canvas.width, canvas.height);
			};
		};

		if (canvas && canvas.parentElement) {
			canvas.width = canvas.parentElement.offsetWidth;
			canvas.height = canvas.parentElement.offsetHeight;
			drawCover();
		}

		if (canvas) {
			canvas.addEventListener('mousedown', touchStart);
			canvas.addEventListener('touchstart', touchStart);

			canvas.addEventListener('mousemove', touchMove);
			canvas.addEventListener('touchmove', touchMove);

			canvas.addEventListener('mouseup', touchEnd);
			canvas.addEventListener('touchend', touchEnd);
		}

		setScratchPercentage(0);

		const handleResize = () => {
			const canvas = canvasRef.current;
			if (canvas && canvas.parentElement) {
				canvas.width = canvas.parentElement.offsetWidth;
				canvas.height = canvas.parentElement.offsetHeight;
				drawCover();
			}
		};

		// Add the event listener when the component mounts
		window.addEventListener('resize', handleResize);

		return () => {
			canvas.removeEventListener('mousedown', touchStart);
			canvas.removeEventListener('touchstart', touchStart);
			canvas.removeEventListener('mousemove', touchMove);
			canvas.removeEventListener('touchmove', touchMove);
			canvas.removeEventListener('mouseup', touchEnd);
			canvas.removeEventListener('touchend', touchEnd);

			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			<OfferScratchCardView
				isCopied={isCopied}
				offerCode={props.offerCode}
				canvasRef={canvasRef}
				scratchPercentage={scratchPercentage}
				handleCopy={handleCopy}
			/>
		</>
	);
}

export default OfferScratchCard;

//REF : https://codesandbox.io/embed/kon73lw085?file=/src/styles.scss&codemirror=1
