import React, { useEffect, useState } from 'react';

import VideoPlaylistView from './view';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { produce } from 'immer';

import { decryptText } from '@library/enc-dec';
import * as CourseContentServices from '@services/CourseContent';
import * as SeriesDetailsActions from '@redux/actions/SeriesDetails';

function VideoPlaylist(props) {
	const {
		seriesDetails,
		onValueChange,
		resetViewCount,
		resetWatchedVideoCount,
		videoId,
	} = props;

	const [inMobileView, setInMobileView] = useState();

	useEffect(() => {
		const viewportWidth = window.innerWidth;
		if (viewportWidth < 992) {
			setInMobileView(true);
		}
		return () => {
			setInMobileView(false);
		};
	}, []);

	const handleVideoReRender = (event, value) => {
		const parts = value.split('_');

		if (parts.length > 0) {
			const reRenderData = {
				seriesIndex: parts[0],
				videoIndex: parts[1],
			};
			onValueChange(reRenderData);
			resetViewCount();
			resetWatchedVideoCount();
		}
	};

	const handleFavourite = async (event, videoDocId) => {
		try {
			let authToken = decryptText(localStorage.getItem('eMediat'));
			const response = await CourseContentServices.addToFavourite(
				videoDocId,
				authToken
			);

			if (response.success) {
				let updatedSeriesDetails = produce(props.seriesDetails, (draft) => {
					draft.data.map((series) => {
						series.videos.map((video) => {
							if (video._id === videoDocId) {
								video.isFavourite = response.data ? true : false;
							}
						});
					});
				});

				props.updateSeriesDetails(updatedSeriesDetails);
			}
		} catch (err) {
			console.log('Error coming while handling favourite video', err);
		}
	};

	//START : Fn' of sharing videos functionality
	const handleShare = (event, courseId, subSectionId) => {
		document.getElementById('sharePopupBtn').click();
		let currentUrl = window.location.href;
		currentUrl = currentUrl.split('/');
		currentUrl =
			currentUrl[0] + '//' + currentUrl[1] + currentUrl[2] + '/' + currentUrl[3];
		// let shareUrlOLD = `${currentUrl}/${courseId}/${subSectionId}`;
		let shareUrl = `${currentUrl}/v/${subSectionId}`;
		document.getElementById('shareInput').value = shareUrl;
	};
	//END : Fn' of sharing videos functionality
	return (
		<>
			{props.currentContentType === 's' && (
				<VideoPlaylistView
					//Usestates and fn's of sharing vidoes functionality
					handleShare={handleShare}
					//Usestates and fn's of sharing vidoes functionality

					inMobileView={inMobileView}
					handleFavourite={handleFavourite}
					handleVideoReRender={handleVideoReRender}
					seriesDetails={seriesDetails}
					videoId={videoId}
				/>
			)}
		</>
	);
}

function mapStateToProps(state) {
	return {
		seriesDetails: state.seriesDetails.seriesDetails,
		currentContentType: state.seriesDetails.currentContentType,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateSeriesDetails: SeriesDetailsActions.updateSeriesDetails,
			// updateFavStatus: SeriesDetailsActions.updateFavStatus,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlaylist);
