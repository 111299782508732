// current index.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import TopLoadingBar from '@components/TopLoadingBar';

import ExamView from './view';
import { decryptText } from '@library/enc-dec';
import { isUserLogedIn } from '@library/auth';

import * as ExamServices from '@services/Exam';
import * as ExamActions from "@redux/actions/Exam";

import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';
import * as NavbarActions from '@redux/actions/Navbar';

import { useDispatch, useSelector } from "react-redux";
import { produce } from "immer";


function Exam(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    // To run this page below two things are required
    // const courseId = '';
    // const purchaseId = '';

    const courseId = location.state?.courseId; // in case of examType as test courseId will hold testExamId
    const purchaseId = location.state?.purchaseId; // in case of examType as test purchaseId will hold testExamDocId
    const examType = location.state?.examType;

    const questionBank = useSelector((state) => state.exam.questionBank);
    const totalNumberOfQuestions = useSelector(
        (state) => state.exam.totalNumberOfQuestions
    );
    const allQuestions = useSelector((state) => state.exam.allQuestions);
    const [examStrike, setExamStrike] = useState(0);

    const loadingBarProgress = useSelector(
        (state) => state.topLoadingBar.loadingBarProgress
    );

    const [isNextBtnVisible, setIsNextBtnVisible] = useState(true);
    const [isPreviousBtnVisible, setIsPreviousBtnVisible] = useState(true);

    const [displayExamTimer, setDisplayExamTimer] = useState(false);

    const [isExamStarted, setIsExamStarted] = useState(false);
    const [isExamAttempted, setIsExamAttempted] = useState(false);

    const [isExamTimeRemaning, setIsExamTimeRemaning] = useState(true);

    const [daysRemainingResults, setDaysRemainingResults] = useState(false);

    const [windowBlurFlag, setWindowBlurFlag] = useState(0);

    const [pageNumber, setPageNumber] = useState(1)

    var timer;

    useEffect(() => {
        window.scroll(0, 0);
        setIsLoading(true);
        handlePage();

        return () => {
            handleWindowBlur()
            window.removeEventListener('blur', handleWindowBlur);
            clearInterval(timer)
        };
    }, []);

    const handlePage = async () => {
        try {
            const userLogedIn = await isUserLogedIn();
            dispatch(NavbarActions.updateLoginState(userLogedIn));

            if (!userLogedIn) {
                localStorage.removeItem('eMediat');
                localStorage.removeItem('eMediatt');
                navigate('/login');
                return;
            }

            if (!courseId || !purchaseId) {
                navigate('/dashboard');
                return;
            }

            // Checking exam eligibility            
            checkExamEligibility();

            window.addEventListener('blur', handleWindowBlur);

        } catch (err) {
            console.log('Error coming while handling Exam page', err);
        }
    };

    // Detecting user mouse pointer going out of window
    const handleWindowBlur = () => {
        // Updating exam strike count
        handleExamStrike();
    };

    const handleExamStrike = async () => {
        try {
            let authToken = decryptText(localStorage.getItem('eMediat'));
            const response = await ExamServices.updateExamStrike(examType, purchaseId, authToken);

            if (response.success) {
                setExamStrike(response.data.examStrike);
                setWindowBlurFlag(response.data.examStrike);

                // Checking if 4 window switches has happend
                checkWindowBlurStatus(response.data.examStrike);
            }
        } catch (err) {
            console.log('Error coming from updateExamStrike()', err);
        }
    };

    // Checking if window switch has happen >= 4
    const checkWindowBlurStatus = (examStrike) => {
        try {
            if (examStrike >= 4) {
                // Automatically submitting the exam
                handleSubmition();
            }
        } catch (err) {
            console.log('Error coming from checkWindowBlurStatus()', err);
        }
    };

    const checkExamEligibility = async () => {
        UpdateTopLoadingBarForThisPage(10);
        try {
            let authToken = decryptText(localStorage.getItem('eMediat'));
            const isEligibleForExam = await ExamServices.checkEligibilityForExam(
                examType,
                purchaseId,
                authToken
            );
            if (isEligibleForExam.success) {
                if (
                    isEligibleForExam.data.currentExamStatus === 'started' ||
                    isEligibleForExam.data.currentExamStatus === 'unlocked'
                ) {
                    UpdateTopLoadingBarForThisPage(50);
                    getQuestionBank();
                } else {
                    navigate('/dashboard');
                    // Mahdi : Show the message to the user he have already submitted the exam
                }
            }
            return;
        } catch (err) {
            console.log('Error coming form :checkEligiblityForExam()', err);
        }
    };

    const getQuestionBank = async () => {
        try {
            let authToken = decryptText(localStorage.getItem("eMediat"));
            const questionBank = await ExamServices.getQuestionBank(
                examType,
                courseId,
                authToken
            );

            if (questionBank.success) {
                dispatch(ExamActions.updateQuestionBank(questionBank.data));

                UpdateTopLoadingBarForThisPage(100);
                setIsLoading(false);
                return;
            }
        } catch (err) {
            console.log("Error coming from getQuestionBank()", err);
        }
    };

    // After updating the question bank this useEffect will get call
    useEffect(() => {
        // Load to get exam time
        checkIsExamStarted();
    }, [questionBank]);

    const checkIsExamStarted = async () => {
        try {
            let authToken = decryptText(localStorage.getItem("eMediat"));
            const purchaseCourse = await ExamServices.getExamTimings(
                examType,
                purchaseId,
                authToken
            );
            if (purchaseCourse.success) {
                if (purchaseCourse.data.examStartAt) {
                    handleStartExam(purchaseCourse.data.examEndAtMilli);

                    setWindowBlurFlag(purchaseCourse.data.examStrike);

                    // Checking if 4 window switches has happend
                    checkWindowBlurStatus(purchaseCourse.data.examStrike);
                }
            }
        } catch (err) {
            console.log("Error coming from checkIsExamStarted()", err);
        }
    };

    const handleStartExam = async (countDownTime = 0) => {
        try {
            if (questionBank && questionBank?.questionsSet.length > 0) {
                const dbQuestionSet = questionBank?.questionsSet.map((question) => ({
                    ...question,
                    selectedOption: null,
                }));

                // Shuffle the questions if inSequence is true
                if (questionBank?.inSequence) {
                    dbQuestionSet.sort(() => Math.random() - 0.5);
                }

                dispatch(
                    ExamActions.updateTotalNumberOfQuestions(dbQuestionSet.length)
                );

                dispatch(ExamActions.updateAllQuestions(dbQuestionSet));

                setIsPreviousBtnVisible(false);
                setIsNextBtnVisible(dbQuestionSet.length >= 10);
            }

            if (countDownTime > 0) {
                checkExamTime(countDownTime);
            } else if (questionBank) {
                let authToken = decryptText(localStorage.getItem("eMediat"));
                let examDuration = questionBank?.duration; // exam time in minutes

                const purchaseCourse = await ExamServices.updateExamTimings(
                    examType,
                    examDuration,
                    purchaseId,
                    authToken
                );

                if (purchaseCourse.success) {
                    checkExamTime(purchaseCourse.data.examEndAtMilli);
                }
            }
        } catch (err) {
            console.log("Error coming from handleStartExam()", err);
        }
    };

    const checkExamTime = async (countDownTime) => {
        setIsExamStarted(true);
        if (document.getElementById("examPageBgHolder")) {
            document.getElementById("examPageBgHolder").style.height = "auto";
        }

        timer = setInterval(() => {
            let now = new Date().getTime(); // current time in miliseconds
            let remaningTime = countDownTime - now;
            let hours = Math.floor(
                (remaningTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            let minutes = Math.floor((remaningTime % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((remaningTime % (1000 * 60)) / 1000);

            if (remaningTime < 0) {
                // Auto submit exam
                setDisplayExamTimer(" " + "0" + "h " + "0" + "m " + "0" + "s ");
                handleSubmition();
            } else {
                setDisplayExamTimer(
                    " " + hours + "h " + minutes + "m " + seconds + "s "
                );
                setIsExamTimeRemaning(true);
            }
        }, 1000);
    };

    const handleSelectedOption = (event, questionId) => {
        let selectedOption = event.target.value;
        const index = allQuestions.findIndex(
            (question) => question._id === questionId
        );
        if (index !== -1) {
            const updatedAllQuestions = produce(allQuestions, (draft) => {
                draft[index]["selectedOption"] = selectedOption;
            });

            dispatch(ExamActions.updateAllQuestions(updatedAllQuestions));
        }
    };

    const handleNextBtn = () => {
        if (totalNumberOfQuestions > pageNumber * 10) {
            setPageNumber(pageNumber + 1);
            setIsPreviousBtnVisible(true);
        } else {
            setIsNextBtnVisible(false);
        }

        window.scroll(0, 0);
    };

    const handlePreviousBtn = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            setIsNextBtnVisible(true);
        } else {
            setIsPreviousBtnVisible(false);
        }

        window.scroll(0, 0);
    };

    useEffect(() => {
        if (questionBank && examStrike >= 4) {
            handleSubmition();
        }
    }, [examStrike, questionBank]);


    const handleSubmition = async () => {
        try {
            // for testing exam
            // if(examStrike < 15){
            //     return
            // }
            if (questionBank) {
                let examId = questionBank?._id;

                let authToken = decryptText(localStorage.getItem("eMediat"));
                const markAsSubmitted = await ExamServices.updateExamSubmition(
                    examType,
                    purchaseId,
                    examId,
                    authToken
                );

                if (markAsSubmitted.success) {
                    // Get the total attempts from allQuestions where selectedOption is not null
                    let currentAttempts = allQuestions.filter(
                        (question) => question.selectedOption !== null
                    );

                    let payload = {
                        examId: examId,
                        purchaseId: purchaseId,
                        attempts:
                            currentAttempts.length > 0 ? currentAttempts : allQuestions,
                    };

                    if(examType === "Test"){
                        payload = {
                            testExamId: examId,
                            testExamDocId: purchaseId,
                            attempts:
                                currentAttempts.length > 0 ? currentAttempts : allQuestions,
                        };
                    }

                    const result = await ExamServices.addToResult(examType, payload, authToken);

                    if (result.success) {
                        clearInterval(timer);
                        setIsExamTimeRemaning(false);
                        if (questionBank?.declarationResultInDays === 0) {
                        navigate("/exam-result", {
                            state: {
                                purchaseId: purchaseId,
                                examType: examType
                            },
                        });
                        return;
                        }
                        setIsExamAttempted(true);
                        if (document.getElementById("examPageBgHolder")) {
                            document.getElementById("examPageBgHolder").style.height = "75vh";
                        }
                        if (questionBank?.declarationResultInDays > 0) {
                            setDaysRemainingResults(questionBank?.declarationResultInDays);
                        }
                    }
                }
            } else {
                handleExamStrike();
                console.log("Auto Submit not Happened");
            }
        } catch (err) {
            console.log("Error coming from:handleSubmition()", err);
        }
    };


    //START: Code for setting the progressof top bar loader
    const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
        if (interval) {
            setTimeout(function () {
                dispatch(
                    TopLoadingBarActions.updateLoadingBarProgress(
                        loadingBarProgress + value
                    )
                );
            }, 500);
        } else {
            dispatch(
                TopLoadingBarActions.updateLoadingBarProgress(
                    loadingBarProgress + value
                )
            );
        }
    };
    //END: Code for setting the progressof top bar loader


    const handleRichTextEditorCourseDiscription = (htmlStr, icon, value) => {
        if (htmlStr !== undefined && icon !== null) {
            let newHtmlStr = '';
            newHtmlStr = htmlStr;
            return newHtmlStr.replace(
                /<li>/g,
                '<li style="background-image: url(' +
                icon +
                '); background-repeat: no-repeat; background-size: 20px 20px;">'
            );
        }
        return htmlStr;
    };


    return (
        <>
            <Navbar />
            <TopLoadingBar />
            <ExamView
                windowBlurFlag={windowBlurFlag}
                handleStartExam={handleStartExam}
                isExamStarted={isExamStarted}
                handlePreviousBtn={handlePreviousBtn}
                handleNextBtn={handleNextBtn}
                isNextBtnVisible={isNextBtnVisible}
                isPreviousBtnVisible={isPreviousBtnVisible}
                displayExamTimer={displayExamTimer}
                allQuestions={allQuestions}
                isExamAttempted={isExamAttempted}
                isExamTimeRemaning={isExamTimeRemaning}
                isLoading={isLoading}
                handleSelectedOption={handleSelectedOption}
                questionBank={questionBank}
                daysRemainingResults={daysRemainingResults}
                handleSubmit={handleSubmition}
                handleRichTextEditorCourseDiscription={handleRichTextEditorCourseDiscription}

                pageNumber={pageNumber}
            />
            <Footer />
        </>
    );
}
export default Exam;