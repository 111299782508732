import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ProbileTabView from './view';
import { useSelector, useDispatch } from 'react-redux';

import { decryptText } from '@library/enc-dec';

import * as UserDashboardServices from '@services/UserDashboard';
import * as UserDashboardActions from '@redux/actions/UserDashboard';

function ProbileTab() {
	const dispatch = useDispatch();
	const location = useLocation();
	const user = useSelector((state) => state.dashboard.user);
	const [isLoading, setIsLoading] = useState(true)
	const [currentLocation, setCurrentLocation] = useState("")
	useEffect(() => {
		if (!user || Object.entries(user).length === 0) {
			getUserDetails();
		}
	}, []);

	useEffect(() => {
		if (user && Object.entries(user).length > 0) {
			setIsLoading(false)
			handleScrollToSection()
		}
	}, [user])


	const handleScrollToSection = () => {
		if (currentLocation === "") {
			setTimeout(function () {
				const hash = location.hash;
				if (hash) {
					setCurrentLocation(location)
					const sectionId = hash.substring(1);
					const sectionElement = document.getElementById(sectionId);
					if (sectionElement) {
						sectionElement.scrollIntoView({ behavior: 'smooth' });
					}
				}
			}, 500)
		}
	}


	const getUserDetails = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('eMediat'));
			const response = await UserDashboardServices.getUser(authToken);
			if (response.success) {
				dispatch(UserDashboardActions.updateUserDetails(response.data));
			}
		} catch (err) {
			console.log('Profile: Error coming from getUserDetails()', err);
		}
	};

	return <ProbileTabView
		isLoading={isLoading}
	/>;
}

export default ProbileTab;
