import React, { useState, useEffect, useRef } from 'react';

import SettingView from './view';

import { decryptText } from '@library/enc-dec';
import { useDispatch, useSelector } from 'react-redux';

import * as SettingServices from '@services/UserDashboard/Setting';

import * as UserDashboardServices from '@services/UserDashboard';
import * as UserDashboardActions from '@redux/actions/UserDashboard';

function Setting() {
	const transactionsDetails = useSelector((state) => state.dashboard.transactionsDetails);
    const user = useSelector((state) => state.dashboard.user);
    const dispatch = useDispatch();

    const preferencesModalOpenBtn = useRef(null);
    const preferencesModal = useRef(null);
    const preferencesModalCloseBtn = useRef(null);
    const [transactionDetailsIsLoading, setTransactionDetailsIsLoading] = useState(true)
    const [userDetailsIsLoading, setUserDetailsIsLoading] = useState(true)

    useEffect(() => {
        if (!user || Object.entries(user).length === 0) {
            getUserDetails();
        }
        else {
            getPurchasesByUserId(user)
        }

        const modalElement = preferencesModal.current;
        if (modalElement) {
            modalElement.addEventListener('hidden.bs.modal', handlePopUpCloseViaClickingOutside);
        }

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('hidden.bs.modal', handlePopUpCloseViaClickingOutside);
            }
        };

    }, []);


    useEffect(()=>{
        if(user && Object.entries(user).length > 0 ){
            setUserDetailsIsLoading(false)
        }
        if(transactionsDetails && transactionsDetails.success){
            setTransactionDetailsIsLoading(false)
			// setTimeout(function(){ // testing
			// 	setTransactionDetailsIsLoading(false)
			// },3000)
        }
    },[user, transactionsDetails])


    const getPurchasesByUserId = async (userData) => {
        if(transactionsDetails && transactionsDetails.success){
            return
        }
        if (userData && userData._id) {
            let payload = {
                userId: userData._id
            };

            try {
                const response = await SettingServices.getPurchasesByUserId(payload);
                if (response.success) {
                    if (response.data && response.data.length > 0) {
                        let transactionData = response.data
                        transactionData.forEach((transaction, transactionIndex) => {
                            let coursesMnemonic = ""
                            transaction.courseDetails.forEach((course, courseIndex) => {
                                coursesMnemonic = coursesMnemonic + " + " + course.coursesMnemonic
                            })
                            coursesMnemonic = coursesMnemonic.replace(" + ", "");
                            transactionData[transactionIndex]['coursesMnemonic'] = coursesMnemonic
                        })
                        dispatch(UserDashboardActions.updateTransactionsDetails({
                            success: response.success,
                            data: transactionData
                        }));
                    }
                    else {
                        dispatch(UserDashboardActions.updateTransactionsDetails({
                            success: response.success,
                            data: []
                        }));
                    }
                }
                else {
                    console.log('Setting tab: Error coming from getPurchasesByUserId()', response);
                }
            } catch (err) {
                console.log('Setting tab: Error coming from getPurchasesByUserId()', err);
            }
        }
    }


    const getUserDetails = async () => {
        try {
            let authToken = decryptText(localStorage.getItem('eMediat'));
            const response = await UserDashboardServices.getUser(authToken);
            if (response.success) {
                dispatch(UserDashboardActions.updateUserDetails(response.data));
                getPurchasesByUserId(response.data)
            }
        } catch (err) {
            console.log('Profile: Error coming from getUserDetails()', err);
        }
    };


    const handleSavePreference = (value) => {

    }
    const openPreferencesPopup = () => {
        if (preferencesModalOpenBtn.current) {
            preferencesModalOpenBtn.current.click();
        }
    }
    const handlePopUpCloseViaClickingOutside = () => {

    }
    return (
        <>
            <SettingView
                userData={user}
                transactionsDetails={transactionsDetails}
                preferencesModalOpenBtn={preferencesModalOpenBtn}
                preferencesModal={preferencesModal}
                preferencesModalCloseBtn={preferencesModalCloseBtn}
                openPreferencesPopup={openPreferencesPopup}
                handleSavePreference={handleSavePreference}
                transactionDetailsIsLoading={transactionDetailsIsLoading}
                userDetailsIsLoading={userDetailsIsLoading}                
            />
        </>
    );
}

export default Setting;
