import React from 'react';

import CoursesHolderMobileCart from '@components/CoursesHolderMobileCart';
import './style.css';
import RightArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';

function CartView(props) {
	return (
		<>
			<div className='cartView'>
				<div className='header'>
					<p className='head'>Cart</p>
					<button className='closeBtn' data-bs-dismiss='modal'>
						x
					</button>
				</div>

				<CoursesHolderMobileCart
					source="mobileCart"
					device={props.device ? props.device : null} // is set at components\Popups\RestOfPopupsOfCoursePage\view.js
					removeCartItem={props.removeCartItem}
					selectedCourses={props.selectedCourses}
					deleteCartItemIsLoading={props.deleteCartItemIsLoading}
				/>
				<button
					onClick={() => props.handleContinueButton()}
					className={`actionBtn ${
						props.currentCartValue <= 0 && 'disableActionBtn'
					}`}>
					{props.selectedOffer &&
					props.selectedOffer.maxDiscount &&
					props.selectedOffer.maxDiscount > 0 ? (
						<>Continue to choose courses</>
					) : (
						<>
							{props.totalCartValueInNumbers ? (
								<>Continue to pay {props.currentCartValue}</>
							) : (
								<>Cart is empty</>
							)}
						</>
					)}
					{props.totalCartValueInNumbers > 0 && (
						<img src={RightArrow} alt='RightArrow' />
					)}
				</button>
			</div>
		</>
	);
}

export default CartView;
