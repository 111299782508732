import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isUserLogedIn } from '@library/auth';
import { decryptText, encryptText } from '@library/enc-dec';
import { useNavigate, useLocation } from 'react-router-dom';
import numeral from 'numeral';

import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import TopLoadingBar from '@components/TopLoadingBar';

import DesktopView from "@pages/ConfirmOrder/DesktopView";
import MobileView from "@pages/ConfirmOrder/MobileView";
import { getPurchasesCount } from '@library/helpers';

import * as CheckoutServices from '@services/Checkout';

import * as NavbarActions from '@redux/actions/Navbar';
import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';
import * as CartActions from '@redux/actions/Cart';

import { isMobile } from 'react-device-detect';
import { jsPDF } from "jspdf";
import InvoiceMobile from '@components/InvoiceMobile';
import HdfcInvoice from '@components/Invoice/HdfcInvoice';
import StripeInvoice from '@components/Invoice/StripeInvoice';
import { renderToStaticMarkup } from "react-dom/server";
import DownloadIcon from "@assets/timeline/icons/download.png";
import LoaderGif from "@assets/course/loading.gif";
import { RobotoRegular } from "@assets/Roboto-Regular-normal.js"


function ConfirmOrder(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [confirmOrderDetails, setConfirmOrderDetails] = useState({});
	const [totalOrderValue, setTotalOrderValue] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const loadingBarProgress = useSelector(
		(state) => state.topLoadingBar.loadingBarProgress
	);

	useEffect(() => {
		if (!location.state) {
			navigate('/');
			return;
		}
		handlePage();
	}, []);

	const handlePage = async () => {
		try {
			setIsLoading(true);
			const isLogedIn = await isUserLogedIn();
			dispatch(NavbarActions.updateLoginState(isLogedIn));

			if (!isLogedIn) {
				navigate('/login', { state: { message: 'Please login first' } });
				return;
			}

			const purchasesCount = await getPurchasesCount();
			dispatch(NavbarActions.updatePurchasesCount(purchasesCount));

			getConfirmOrders();
		} catch (err) {
			console.log('Error coming from handlePage()', err);
		}
	};

	const getConfirmOrders = async () => {
		UpdateTopLoadingBarForThisPage(10);
		try {
			let authToken = decryptText(localStorage.getItem('eMediat'));
			const details = await CheckoutServices.getOrderById(
				location?.state?.orderId,
				authToken
			);

			if (details.success) {
				setConfirmOrderDetails(details);
				if (details.data && details.data.courses && details.data.courses.length > 0) {
					let value = 0;
					details.data.courses.map((course, index) => {
						//debugger
						if (course.isInOffer) {
							value = value + course.offerPriceInNumbers;
						}
						else {
							value = value + course.crossedPriceInNumbers;
						}
					});
					if (details.data.gatewayDiscountInNumber) {
						value = value - details.data.gatewayDiscountInNumber;
					}
					value = numeral(value).format('0,0.00');
					setTotalOrderValue(value);
				}
				const courseIds = details.data.courses.map((item) => item.courseId);
				clearCartItems(courseIds);
			} else {
				// Invalid Order id
				navigate('/');
			}
		} catch (err) {
			console.log(err);
		}

		if(props && props.emptyLocationSateValues){
			props.emptyLocationSateValues()
		}

		setTimeout(function () {
			UpdateTopLoadingBarForThisPage(50);
		}, 1000);

		setTimeout(function () {
			UpdateTopLoadingBarForThisPage(100);
		}, 2000);

		setIsLoading(false);
	};

	const clearCartItems = async (courseIds) => {
		try {
			dispatch(CartActions.updateCartItems({}));
			dispatch(CartActions.updateCartItemCount(0));

			// Remove the course from local storage course list
			if (localStorage.getItem('crsl')) {
				// let localDbCoursesList = decryptText(localStorage.getItem('crsl'))
				// 	? JSON.parse(decryptText(localStorage.getItem('crsl')))
				// 	: [];
				let jsonCheckDetails = {
					returnType: []
				};
				let localDbCoursesList = [];
				const encryptedData = localStorage.getItem('crsl');
				if (encryptedData) {
					const decryptedData = decryptText(encryptedData, jsonCheckDetails);
					if (decryptedData) {
						localDbCoursesList = JSON.parse(decryptedData);
					}
				}

				localDbCoursesList = localDbCoursesList.filter(
					(course) => !courseIds.includes(course._id)
				);

				localStorage.setItem('crsl', encryptText(localDbCoursesList)); //crsl
			}

			setIsLoading(false);
		} catch (err) {
			console.log('Error coming while clearCartItems()', err);
		}
	};

	const handleDownloadInvoice = (event) => {
		if(props && props.handleDownloadInvoiceFromHomePage){
			props.handleDownloadInvoiceFromHomePage(event, confirmOrderDetails)
		}
		else{
			generatePdf(event, confirmOrderDetails)

			// old code
			// if (isMobile) {
			// 	generatePdf(event, confirmOrderDetails)
			// }
			// else{
			// 	navigate('/invoice', { state: { invoice: confirmOrderDetails } });
			// }
		}
	};


	const generatePdf = async (event, invoice) => {
		try {
			event.target.src = LoaderGif

			const doc = new jsPDF("p", "pt", "a4", true);

			doc.addFileToVFS("Roboto-Regular.ttf", RobotoRegular);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.setFont("Roboto");
	  
			var InvoiceComponent = null
			if (invoice.data && invoice.data.paymentMethod && invoice.data.paymentMethod) {
				// testing : razorpay stripe
				if (invoice.data.paymentMethod === "stripe") {
				  InvoiceComponent = <StripeInvoice data={invoice.data} />
				} else if (invoice.data.paymentMethod === "ccavenue") {
				  InvoiceComponent = <HdfcInvoice data={invoice.data} />
				}
				else {
				  InvoiceComponent = <InvoiceMobile invoice={invoice} />
				}
			  }
			  else {
				alert("Issue in generating invoice go to dashboard and check again")
				event.target.src = DownloadIcon
				return
			  }

			// var InvoiceComponent = <InvoiceMobile invoice={invoice} />
			var htmlPDFPage = renderToStaticMarkup(InvoiceComponent);

			doc.internal.write(0, "Tw");
			const pdfPromise = new Promise((resolve) => {
				doc.html(htmlPDFPage, {
					callback: function () {
						resolve(doc);
					},
				});
			});

			// Wait for the promise to resolve before continuing
			const generatedDoc = await pdfPromise;
			generatedDoc.save("invoice.pdf");


			setTimeout(function () {
				event.target.src = DownloadIcon
			}, 100)

		} catch (err) {
			console.log("Error", err);
		}
	};


	//START: Code for setting the progressof top bar loader
	const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
		if (interval) {
			setTimeout(function () {
				dispatch(
					TopLoadingBarActions.updateLoadingBarProgress(loadingBarProgress + value)
				);
			}, 1000);
		} else {
			dispatch(
				TopLoadingBarActions.updateLoadingBarProgress(loadingBarProgress + value)
			);
		}
	};

	return (
		<>
			{!(props && props.fromHomePage) && (
				<>
					<Navbar />
					<TopLoadingBar />
				</>
			)}


			{(props && props.fromHomePage) || window.innerWidth < 576 ?
				(
					<>
						<MobileView
							isLoading={isLoading}
							confirmOrderDetails={confirmOrderDetails}
							totalOrderValue={totalOrderValue}
							handleDownloadInvoice={handleDownloadInvoice}
						/>
					</>
				) : (
					<>
						<DesktopView
							isLoading={isLoading}
							confirmOrderDetails={confirmOrderDetails}
							totalOrderValue={totalOrderValue}
							handleDownloadInvoice={handleDownloadInvoice}
						/>
					</>
				)
			}

			{/* <ConfirmOrderView
				isLoading={isLoading}
				confirmOrderDetails={confirmOrderDetails}
				handleDownloadInvoice={handleDownloadInvoice}
			/> */}

			{!(props && props.fromHomePage) && <Footer />}

		</>
	);
}

export default ConfirmOrder;
