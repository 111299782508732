import React from 'react';
import ContentLoader from "react-content-loader";
import './style.css';

export default () => (
    <div className='purchaseContainerLoader'>
        {/* START Desktop */}
        <ContentLoader
            className='purchaseContainerLoaderDesktop'
            backgroundColor="#ffffff"
            // foregroundColor="#a09d9d"
            viewBox="0 0 400 110">
            {/* Card 1 */}
            <rect x="0" y="0" rx="4" ry="4" width="90" height="70" />
            <rect x="1" y="74" rx="2" ry="2" width="85" height="7" />
            <rect x="1" y="84" rx="2" ry="2" width="65" height="7" />
            <rect x="1" y="94" rx="2" ry="2" width="45" height="7" />

            {/* Card 2 */}
            <rect x="100" y="0" rx="4" ry="4" width="90" height="70" />
            <rect x="100" y="74" rx="2" ry="2" width="85" height="7" />
            <rect x="100" y="84" rx="2" ry="2" width="65" height="7" />
            <rect x="100" y="94" rx="2" ry="2" width="45" height="7" />

            {/* Card 3 */}
            <rect x="200" y="0" rx="4" ry="4" width="90" height="70" />
            <rect x="200" y="74" rx="2" ry="2" width="85" height="7" />
            <rect x="200" y="84" rx="2" ry="2" width="65" height="7" />
            <rect x="200" y="94" rx="2" ry="2" width="45" height="7" />

            {/* Card 4 */}
            <rect x="300" y="0" rx="4" ry="4" width="90" height="70" />
            <rect x="300" y="74" rx="2" ry="2" width="85" height="7" />
            <rect x="300" y="84" rx="2" ry="2" width="65" height="7" />
            <rect x="300" y="94" rx="2" ry="2" width="45" height="7" />
        </ContentLoader>
        {/* END Desktop */}



        {/* START Mobile */}
        {[...Array(4)].map((_, index) => (
            <div key={index} className='purchaseContainerLoaderMobile'>
                <ContentLoader                
                    backgroundColor="#ffffff"
                    // foregroundColor="#a09d9d"
                    viewBox="0 0 100 30">
                    {/* Card 1 */}
                    <rect x="0" y="0" rx="3" ry="3" width="40" height="25" />
                    <rect x="42" y="4" rx="1" ry="1" width="57" height="3" />
                    <rect x="42" y="10" rx="1" ry="1" width="47" height="3" />
                    <rect x="42" y="16" rx="1" ry="1" width="37" height="3" />
                </ContentLoader>
            </div>
        ))}
        {/* END Mobile */}
    </div>
);
