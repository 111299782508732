export const addTentativeOrder = async (orderDetails, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/addTentativeOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(orderDetails),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from TentativeOrders Service => addTentativeOrder()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const removeTentativeOrders = async (orderId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/removeTentativeOrders`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify({
          orderId: orderId,
        }),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from tentative orders => removeTentativeOrders() services",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
