import React from 'react';

import HistoryFav from '@pages/UserDashboard/MyLearning/HistoryFav';
import Courses from '@pages/UserDashboard/MyLearning/Courses';
import Series from '@pages/UserDashboard/MyLearning/Series';
import { propTypes } from 'react-bootstrap/esm/Image';
import { useSelector } from 'react-redux';

function MyLearningView() {
	const myProfileError = useSelector((state)=>state.dashboard.myProfileError)
	return (
		<>
		    {myProfileError? <div class="alert alert-danger" role="alert">{myProfileError}</div> : null}
			<Courses />
			<Series />
			<HistoryFav />
		</>
	);
}

export default MyLearningView;
