import React from "react";
import CouserLoader from './loader';

import Exam from "./Exam/examIndex";
import Test from "./Exam/testIndex";
import "./style.css";

function EvaluationTabView(props) {
  return (
    <>
      {props.isLoading ?
        (
          <>
            <CouserLoader />
          </>
        ) : (
          <>
            <Exam examDetails={props.examDetails} />
            <Test testDetails={props.testDetails} />
          </>
        )
      }
    </>
  );
}

export default EvaluationTabView;
