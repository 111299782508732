const initialState = {
	title: '',
	description: '',
	pointers: [],
    images:[]
};

const TermsAndConditionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_TERM_AND_CONDITION':
			return {
				...state,
				title: action.payload.title,
				description: action.payload.description,
				pointers: action.payload.pointers,
				images: action.payload.images,
			};
	
		case 'RESET_TERMS_AND_CONDITIONS_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default TermsAndConditionsReducer;
