const initialState = {
	firstName: '',
	lastName: '',
	contactNumber: '',
	countryCode: '',
	countryName: '',
	email: '',
	username: '',
	password: '',
	confirmPassword: '',
};

const SignUpReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_FIRST_NAME':
			return {
				...state,
				firstName: action.payload.firstName,
			};
		case 'UPDATE_LAST_NAME':
			return {
				...state,
				lastName: action.payload.lastName,
			};
		case 'UPDATE_CONTACT_NUMBER':
			return {
				...state,
				contactNumber: action.payload.contactNumber,
			};
		case 'UPDATE_COUNTRY_CODE':
			return {
				...state,
				countryCode: action.payload.countryCode,
			};
		case 'UPDATE_COUNTRY_NAME':
			return {
				...state,
				countryName: action.payload.countryName,
			};	
		case 'UPDATE_EMAIL':
			return {
				...state,
				email: action.payload.email,
			};
		case 'UPDATE_USERNAME':
			return {
				...state,
				username: action.payload.username,
			};
		case 'UPDATE_PASSWORD':
			return {
				...state,
				password: action.payload.password,
			};
		case 'UPDATE_CONFIRM_PASSWORD':
			return {
				...state,
				confirmPassword: action.payload.confirmPassword,
			};
		case 'RESET_SIGNUP_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default SignUpReducer;
