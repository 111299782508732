import React from 'react';
import ContentLoader from "react-content-loader";

import './style.css';

function DesktopLoader(props) {
	return (
		<>
			<ContentLoader
				// backgroundColor="#9e0505"
				// foregroundColor="#ff8585"
				viewBox='0 0 400 810'>
				<rect x='0' y='10' rx='3' ry='3' width='200' height='5' />
				<rect x='0' y='20' rx='3' ry='3' width='130' height='5' />
				<rect x='0' y='30' rx='3' ry='3' width='90' height='5' />

				<circle cx='2' cy='50' r='2' />
				<rect x='7' y='49' rx='1' ry='1' width='30' height='2' />

				<circle cx='2' cy='60' r='2' />
				<rect x='7' y='59' rx='1' ry='1' width='30' height='2' />

				<circle cx='2' cy='70' r='2' />
				<rect x='7' y='69' rx='1' ry='1' width='30' height='2' />

				<circle cx='2' cy='80' r='2' />
				<rect x='7' y='79' rx='1' ry='1' width='30' height='2' />

				<rect x='0' y='95' rx='3' ry='3' width='200' height='5' />
				<rect x='0' y='105' rx='3' ry='3' width='130' height='5' />
				<rect x='0' y='115' rx='3' ry='3' width='90' height='5' />

				{/* Registration */}
				<rect x='0' y='140' rx='3' ry='3' width='90' height='5' />
				<rect x='0' y='150' rx='3' ry='3' width='200' height='14' />
				<rect x='0' y='170' rx='3' ry='3' width='20' height='14' />
				<rect x='30' y='170' rx='3' ry='3' width='170' height='14' />
				<rect x='0' y='190' rx='3' ry='3' width='200' height='14' />

				{/* Preview Video */}
				<rect x='30' y='230' rx='3' ry='3' width='340' height='220' />

				{/* Certificate */}
				<rect x='150' y='480' rx='3' ry='3' width='100' height='5' />
				<rect x='60' y='500' rx='3' ry='3' width='280' height='200' />

				{/* Related product cards */}
				<rect x='0' y='725' rx='3' ry='3' width='100' height='5' />
				<rect x='0' y='740' rx='5' ry='5' width='90' height='50' />
				<rect x='100' y='740' rx='5' ry='5' width='90' height='50' />
				<rect x='200' y='740' rx='5' ry='5' width='90' height='50' />
				<rect x='300' y='740' rx='5' ry='5' width='90' height='50' />

				{/* Product Card */}
				<rect x='270' y='10' rx='3' ry='3' width='130' height='180' />

			</ContentLoader>
		</>
	);
}

export default DesktopLoader;
