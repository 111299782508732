import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import ExamResultView from './view';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { decryptText } from '@library/enc-dec';
import * as ExamServices from '@services/Exam';

import * as NavbarActions from '@redux/actions/Navbar';
import { isUserLogedIn } from '@library/auth';

function ExamResult(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const dataFetchedRef = useRef(false);
	const [isLoading, setIsLoading] = useState(false);

	// Below purchase id is required to run this page ( Replace with dynamic purchase id )
	const purchaseId = location.state?.purchaseId;
    const examType = location.state?.examType;

	const [resultDetails, setResultDetails] = useState({});
	const [totalNumberOfQuestions, setTotalNumberOfQuestions] = useState(0);

	const [allQuestions, setAllQuestions] = useState([]);
	const [questionSet, setQuestionSet] = useState([]);

	const [minQuestionNo, setMinQuestionNo] = useState(0);
	const [maxQuestionNo, setMaxQuestionNo] = useState(0);

	const [isNextBtnVisible, setIsNextBtnVisible] = useState(true);
	const [isPreviousBtnVisible, setIsPreviousBtnVisible] = useState(true);

	useEffect(() => {
		window.scroll(0, 0);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		if (!examType || !purchaseId) {
			navigate('/dashboard');
			return;
		}
		checkLogin()
		handleResult();
	}, []);

	const checkLogin=async()=>{
		const userLogedIn = await isUserLogedIn();
		props.updateLoginState(userLogedIn);
	}

	const handleResult = async () => {
		try {
			setIsLoading(true)
			let authToken = decryptText(localStorage.getItem('eMediat'));
			const result = await ExamServices.getResult(examType, purchaseId, authToken);
			if (result.success) {
				setResultDetails(result);
				setIsLoading(false);
				return;
			}
		} catch (err) {
			console.log('Error coming from: handleResult()', err);
		}
	};

	useEffect(() => {
		setFirstSetOfQuestions();
	}, [resultDetails]);

	const setFirstSetOfQuestions = () => {
		if (
			resultDetails &&
			resultDetails.success &&
			resultDetails.data.attempts.length > 0
		) {
			let dbQuestionSet = resultDetails.data.attempts;

			let questionSetFromDb = dbQuestionSet;
			setTotalNumberOfQuestions(questionSetFromDb.length);
			setAllQuestions(questionSetFromDb);
			let min;
			let max;
			let slicedQuestionsArray;

			if (questionSetFromDb.length > 10) {
				min = 0;
				max = 10;
				setMinQuestionNo(min);
				setMaxQuestionNo(max);

				setIsPreviousBtnVisible(false);
			} else {
				min = 0;
				max = questionSetFromDb.length;
				setMinQuestionNo(min);
				setMaxQuestionNo(max);

				setIsPreviousBtnVisible(false);
				setIsNextBtnVisible(false);
			}

			slicedQuestionsArray = resultDetails.data['attempts'].slice(min, max);
			setQuestionSet(slicedQuestionsArray);
		}
	};

	const handlePreviousBtn = () => {
		let min = minQuestionNo;
		let max = maxQuestionNo;
		let slicedQuestionsArray;
		if (min - 10 > 0) {
			max = min;
			min = min - 10;

			setMinQuestionNo(min);
			setMaxQuestionNo(max);

			setIsNextBtnVisible(true);
		} else {
			max = min;
			min = 0;

			setMinQuestionNo(min);
			setMaxQuestionNo(max);

			setIsPreviousBtnVisible(false);
			setIsNextBtnVisible(true);
		}

		slicedQuestionsArray = allQuestions.slice(min, max);
		setQuestionSet(slicedQuestionsArray);

		window.scroll(0, 0);
	};

	const handleNextBtn = () => {
		let min = minQuestionNo;
		let max = maxQuestionNo;
		let slicedQuestionsArray;
		if (totalNumberOfQuestions > max + 10) {
			min = max;
			max = max + 10;

			setMinQuestionNo(min);
			setMaxQuestionNo(max);

			setIsPreviousBtnVisible(true);
		} else {
			min = max;
			max = totalNumberOfQuestions;

			setMinQuestionNo(min);
			setMaxQuestionNo(max);

			setIsNextBtnVisible(false);
			setIsPreviousBtnVisible(true);
		}

		slicedQuestionsArray = allQuestions.slice(min, max);
		setQuestionSet(slicedQuestionsArray);

		window.scroll(0, 0);
	};

	return (
		<>
			<Navbar />
			<ExamResultView
				handleNextBtn={handleNextBtn}
				handlePreviousBtn={handlePreviousBtn}
				isNextBtnVisible={isNextBtnVisible}
				isPreviousBtnVisible={isPreviousBtnVisible}
				questionSet={questionSet}
				isLoading={isLoading}
				resultDetails={resultDetails}
			/>
			<Footer />
		</>
	);
}
function mapStateToProps(state) {
	return {
		isLogedIn: state.navbar.loginState,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateLoginState: NavbarActions.updateLoginState,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ExamResult);

// export default ExamResult;
