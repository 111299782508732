import React from 'react';

import './style.css';
function TosterView(props) {
	return (
		<>
			
			{/* <div
				style={{ background: "green", color: "white", bottom: props.bottom }}
				className='tosterViewContainer'>
				<p>Test</p>
			</div> */}

			{props.isVisiable &&
				<div
					style={{ background: props.bg, color: props.c, bottom: props.bottom }}
					className='tosterViewContainer'>
					<p>{props.text}</p>
				</div>
			}
		</>
	);
}


export default TosterView;
