import React from 'react';

import BlogView from './view';
import { useSelector } from 'react-redux';

function Blog() {
	const courseDetails = useSelector((state) => state.course.courseDetails);
	return <BlogView blogDetails={courseDetails?.blogDetails} />;
}

export default Blog;
