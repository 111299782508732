import React, { useRef, useEffect } from 'react';
import ContentDescriptionView from './view';
import { useSelector } from 'react-redux';

import { handleRichTextEditorCourseDiscription } from '@pages/Course/HelperFunctions';

function ContentDescription() {
	const contentDescriptionRef = useRef(null);
	const courseDetails = useSelector((state) => state.course.courseDetails);

	useEffect(() => {
		updatecontentDescription();
	}, [courseDetails]);

	const updatecontentDescription = () => {
		if (contentDescriptionRef.current) {
			let html = handleRichTextEditorCourseDiscription(
				courseDetails?.infoParagraph?.pointer,
				courseDetails?.infoParagraph?.icon,
				'courseContentDescriptionContainer'
			);
			contentDescriptionRef.current.innerHTML = html;
		}
	};

	return (
		<ContentDescriptionView contentDescriptionRef={contentDescriptionRef} />
	);
}

export default ContentDescription;
