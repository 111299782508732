import React, { useState, useEffect } from 'react';
import Navbar from '@components/Navbar';
import Footer from '@components/Footer/index.js';
import { useNavigate } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as SignUpActions from '@redux/actions/SignUp';
import * as SignUpServices from '@services/SignUp';

import SignUpnView from './view';

import { hideShowRWIcons } from '@library/LoginUIHelper';

function SignUp(props) {

	useEffect(()=>{
		hideShowRWIcons('hide')
		window.scrollTo(0, 0);

		return ()=>{
			hideShowRWIcons('show')
		}
	},[])

	const navigate = useNavigate();
	const [displayCountryName, setDisplayCountryName] = useState('');
	const [displayCountryCode, setDisplayCountryCode] = useState('');
	const [poorPassword, setPoorPassword] = useState(false);
	const [weakPassword, setWeakPassword] = useState(false);
	const [strongPassword, setStrongPassword] = useState(false);
	const [passwordError, setPasswordErr] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	const [sendNotification, setSendNotification] = useState(false);

	const handlePaswordValidation = (value) => {
		const passwordValue = value;
		const passwordLength = passwordValue.length;
		const poorRegExp = /[a-z]/;
		const weakRegExp = /(?=.*?[0-9])/;
		const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
		const whitespaceRegExp = /^$|\s+/;
		const poorPassword = poorRegExp.test(passwordValue);
		const weakPassword = weakRegExp.test(passwordValue);
		const strongPassword = strongRegExp.test(passwordValue);
		const whiteSpace = whitespaceRegExp.test(passwordValue);

		if (passwordValue === '') {
			setPasswordErr('Password is Empty');
		} else {
			// to check whitespace
			if (whiteSpace) {
				setPasswordErr('Whitespaces are not allowed');
			}
			// to check poor password
			if (
				passwordLength <= 3 &&
				(poorPassword || weakPassword || strongPassword)
			) {
				setPoorPassword(true);
				setPasswordErr('Password is poor');
			}
			// to check weak password
			if (
				passwordLength >= 4 &&
				poorPassword &&
				(weakPassword || strongPassword)
			) {
				setWeakPassword(true);
				setPasswordErr('Password is weak');
			} else {
				setWeakPassword(false);
			}
			// to check strong Password
			if (passwordLength >= 6 && poorPassword && weakPassword && strongPassword) {
				setStrongPassword(true);
				setPasswordErr('Password is strong');
			} else {
				setStrongPassword(false);
			}
		}
	};

	const handleFirstName = (text) => {
		setErrorMessage('');
		props.updateFirstName(text);
	};

	const handleLastName = (text) => {
		setErrorMessage('');
		props.updateLastName(text);
	};

	const handleCountry = (event) => {
		document.getElementById('country').style.color = 'black';
		let countryName = event.target.value;

		let optionEle = event.target.options[event.target.selectedIndex];
		let countryCode = optionEle.getAttribute('countryCode');
		props.updateCountryCode(countryCode);
		props.updateCountryName(countryName);
		setDisplayCountryName(countryName);
		document.getElementById('countryCode').innerHTML = countryCode;
		setDisplayCountryCode(countryCode);
		//country code is in countryCode & country name is in countryName use it for redux
	};

	const handleContactNumber = (text) => {
		setErrorMessage('');
		if(text.length <=10){
			props.updateContactNumber(text);
		}
	};
	const handleEmail = (text) => {
		setErrorMessage('');
		props.updateEmail(text);
	};

	const handleUsername = (text) => {
		setErrorMessage('');
		props.updateUsername(text);
	};

	const handlePassword = (text) => {
		setErrorMessage('');
		props.updatePassword(text);
	};

	const handleConfirmPassword = (text) => {
		setErrorMessage('');
		props.updateConfirmPassword(text);
	};

	const handleSignUp = async (event) => {
		event.preventDefault();
		setIsBtnLoading(true);
		const {
			firstName,
			lastName,
			countryCode,
			countryName,
			email,
			contactNumber,
			username,
			password,
			confirmPassword,
		} = props;

		setErrorMessage('');

		const response = await SignUpServices.signUp(
			firstName,
			lastName,
			countryCode,
			countryName,
			email,
			contactNumber,
			username,
			password,
			confirmPassword,
			sendNotification
		);

		if (response.success === true) {
			props.resetSignUpReducer();
			navigate('/login');
		} else {
			setErrorMessage(response.message);
		}

		setIsBtnLoading(false);
	};

	const handleSendNotification = (event) => {
		setSendNotification(event.target.checked)
	};

	return (
		<>
			<Navbar />
			<SignUpnView
				isBtnLoading={isBtnLoading}
				handlePaswordValidation={handlePaswordValidation}
				poorPassword={poorPassword}
				weakPassword={weakPassword}
				strongPassword={strongPassword}
				passwordError={passwordError}
				handleFirstName={handleFirstName}
				handleLastName={handleLastName}
				handleEmail={handleEmail}
				handleCountry={handleCountry}
				displayCountryCode={displayCountryCode}
				displayCountryName={displayCountryName}
				handleContactNumber={handleContactNumber}
				contactNumber={props.contactNumber}
				handleUsername={handleUsername}
				handlePassword={handlePassword}
				handleConfirmPassword={handleConfirmPassword}
				handleSignUp={handleSignUp}
				errorMessage={errorMessage}
				sendNotification={sendNotification}
				handleSendNotification={handleSendNotification}
			/>
			{/* <Footer /> */}
		</>
	);
}

function mapStateToProps(state) {
	return {
		firstName: state.signUp.firstName,
		lastName: state.signUp.lastName,
		email: state.signUp.email,
		countryCode: state.signUp.countryCode,
		countryName: state.signUp.countryName,
		contactNumber: state.signUp.contactNumber,
		username: state.signUp.username,
		password: state.signUp.password,
		confirmPassword: state.signUp.confirmPassword,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateFirstName: SignUpActions.updateFirstName,
			updateLastName: SignUpActions.updateLastName,
			updateCountryCode: SignUpActions.updateCountryCode,
			updateCountryName: SignUpActions.updateCountryName,
			updateContactNumber: SignUpActions.updateContactNumber,
			updateEmail: SignUpActions.updateEmail,
			updateUsername: SignUpActions.updateUsername,
			updatePassword: SignUpActions.updatePassword,
			updateConfirmPassword: SignUpActions.updateConfirmPassword,
			resetSignUpReducer: SignUpActions.resetSignUpReducer,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
