import React from 'react';

import RightWhiteArrow from '@assets/timeline/icons/rightWhiteArrow.png';
import CertificatePlaceHolder from '@assets/timeline/icons/certificatePlaceHolder.png';

import './style.css';

function ClaimPopupView(props) {
	return (
		<>
			<div className='claimCertificateClaimPopupMainContainer'>
				<button
					className='d-none'
					ref={props.claimCertificateClaimPopupOpenBtn}
					data-bs-toggle="modal"
					data-bs-target="#claimCertificateClaimPopup">
					claimCertificateClaimPopupOpenBtn
				</button>

				<div ref={props.modalRef} className="modal fade" id="claimCertificateClaimPopup">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<button
								ref={props.claimCertificateClaimPopupCloseBtn}
								type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>

							<div className="modal-header">
								<h5 className="modal-title">
									<p className='t1'>You are eligible for </p>
									<p className='t2'>The following Certificate(s) </p>
								</h5>
							</div>

							<div className="modal-body">
								<div className='row row_x'>
									{props.certificateDetails.map((item, index) => (
										<React.Fragment key={index}>
											<div className='col-4'>
												<div
													onClick={() => { props.handleCertificateChange(index, item.price) }}
													className={`certificateContainer ${index === 0 && 'blueBorder'}`} id={`certificateContainer_${index}`}>
													<img className='w-100' src={CertificatePlaceHolder} alt='CertificatePlaceHolder' />

													<p className='title'>
														{index + 1} &nbsp;
														{index === 0 ?
															(
																<>
																	Certificate
																</>
															) : (
																<>
																	Certificates
																</>
															)
														}
													</p>

													<p className='price'>
														{index === 0 ?
															(
																<>
																	FREE
																</>
															) : (
																<>
																	INR {item.price}
																</>
															)
														}
													</p>

												</div>
											</div>
										</React.Fragment>
									))}
								</div>
							</div>

							<div className="modal-footer">
								<button
									disabled={props.claimBtnLoading}
									style={props.claimBtnLoading ? { background: '#6096e6' } : {}}
									className='w-100'
									onClick={() => { props.claimCertificate() }}>
									{props.finalAmt ?
										(
											<>
												Continue to pay {props.finalAmt}
											</>
										) : (
											<>
												{props.claimBtnLoading ? 'Loading...' : 'Claim Free Certificate'}
											</>
										)
									}
									{!props.claimBtnLoading &&
										<img src={RightWhiteArrow} alt='RightWhiteArrow' />
									}
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ClaimPopupView;
