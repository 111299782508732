import React, { useState, useEffect } from "react";

import InstallmentView from "./view";
import { useDispatch, useSelector } from "react-redux";
import { decryptText } from "@library/enc-dec";
import * as CartActions from "@redux/actions/Cart";

function Installment(props) {
  const dispatch = useDispatch();
  const currencySymbol = decryptText(localStorage.getItem("cs"));
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartTotalValue = useSelector((state) => state.cart.cartTotalValue);
  const cartTotalValueInNumbers = useSelector(
    (state) => state.cart.cartTotalValueInNumbers
  );
  const [installmentCounter, setInstallmentCounter] = useState(-1);
  const [currentView, setCurrentView] = useState("init");



  const calculateExtraCharges = (instllmentOptions) => {
    try {
      let totalPercentage = instllmentOptions.reduce(
        (acc, option) => Number(acc) + Number(option),
        0
      );
      const extraCharges = totalPercentage - 100;
      return extraCharges;
    } catch (err) {
      console.log("Error coming from calculateExtraCharges()", err);
    }
  };

  const calculateInstallmentPrice = (installmentPercentage) => {
    try {
      let actualTotalValueInNumbers = cartItems[0].isInOffer
        ? cartItems[0].offerPriceInNumbers
        : cartItems[0].crossedPriceInNumbers;

      let installmentPrice =
        (actualTotalValueInNumbers * installmentPercentage) / 100;
      return Math.round(installmentPrice);
    } catch (err) {
      console.log("Error coming from calculateInstallmentPrice()", err);
    }
  };

  const handleInstallmentChange = (index, installmentOption) => {
    // Changing the installment selection
    setInstallmentCounter(index);

    let actualTotalValueInNumbers = cartItems[0].isInOffer
      ? cartItems[0].offerPriceInNumbers
      : cartItems[0].crossedPriceInNumbers;

    if (!installmentOption) {
      const cartValue = cartItems[0].isInOffer
        ? cartItems[0].offerPrice
        : cartItems[0].crossedPrice;

      dispatch(CartActions.updateCartTotalValue(cartValue));
      dispatch(
        CartActions.updateCartTotalValueInNumbers(actualTotalValueInNumbers)
      );
      dispatch(CartActions.updateInstallmentOption(null));
      return;
    }

    // Update the total cart value based on the selected installment option
    const cartValueInNumbers = calculateInstallmentPrice(
      installmentOption?.options[0]
    );

    const cartValue = currencySymbol + " " + cartValueInNumbers;

    dispatch(CartActions.updateCartTotalValue(cartValue));
    dispatch(CartActions.updateCartTotalValueInNumbers(cartValueInNumbers));

    let updatedInstallmentOption = {};
    Object.keys(installmentOption?.options).map((option) => {
      updatedInstallmentOption[option] = {
        discount: Number(installmentOption?.options[option]),
        isPaid: option === "option1" ? true : false,
      };
    });

    //  Update selected installment option
    dispatch(CartActions.updateInstallmentOption(updatedInstallmentOption));
  };

  const handleInstallmentsOnPress = () => {
    try {
      props.handleSidebarView("installment");
    } catch (err) {
      console.log("Error coming from handleInstallmentsOnPress()", err);
    }
  };

  return (
    <InstallmentView
      currencySymbol={currencySymbol}
      installmentCounter={installmentCounter}
      courseDetails={cartItems && cartItems.length > 0 ? cartItems[0] : {}}
      handleViewDetails={() => setCurrentView("installmentDetails")}
      cartTotalValue={cartTotalValue}
      cartTotalValueInNumbers={cartTotalValueInNumbers}
      calculateExtraCharges={calculateExtraCharges}
      calculateInstallmentPrice={calculateInstallmentPrice}
      handleInstallmentChange={handleInstallmentChange}
      handleInstallmentsOnPress={handleInstallmentsOnPress}
      currentView={currentView}
      {...props}
    />
  );
}

export default Installment;
