import React from 'react';

import ExamLoader from '../Exam/ExamLoader/examLoader';

import PreviousBtn from '@assets/exams/previousBtn.svg';
import NextBtn from '@assets/exams/nextBtn.png';
import WrongAnswerIcon from '@assets/exams/wrongAnswer.png';
import RightAnswerIcon from '@assets/exams/rightAnswer.png';
import alertIcon from '@assets/userDashboard/alertIcon.png';

import './examResult.css';

function ExamResultView(props) {
	return (
		<>
			<center>
				{props.isLoading ? (
					<>	
						<div className='examPageMainContainer'>
							<ExamLoader />
						</div>
					{/* Loading will come here after api intregration */}
					</>
				) : (
					<>
						{props.resultDetails && props.resultDetails.success && (
							<>
								<div className='examPageBgHolder' 
									style={{height : props.resultDetails.success && 'auto'}}>
									<div className='examPageMainContainer'>
										<div className='examPageHeadingContainer'>
											<h3>{props.resultDetails.data.title}</h3>
											<hr style={{ margin: '15px 0px 10px 0px' }} />

											{/* START : Attempted Container */}
											<div className='resultMatrixContainer'>
												<p className='leftContent'>Attempted</p>
												<p className='rightContent' style={{ background: '#4D80CD' }}>
													{props.resultDetails.data.totalAttempts}/
													{props.resultDetails.data.outOff}
												</p>
											</div>
											{/* END : Attempted Container */}

											{/* START : Percentage Container */}
											<div className='resultMatrixContainer'>
												<p className='leftContent'>Percentage</p>
												<p className='rightContent' style={{ background: '#4D80CD' }}>
													{props.resultDetails.data.percentage}%
												</p>
											</div>
											{/* END : Percentage Container */}

											{/* START : Fail/Pass Container */}
											<div className='resultMatrixContainer'>
												<p className='leftContent'>Status</p>
												{props.resultDetails.data.result === 'passed' ? (
													<>
														<p className='rightContent' style={{ background: '#00A524' }}>
															Passed
														</p>
													</>
												) : (
													<>
														<p className='rightContent' style={{ background: '#F90000' }}>
															Failed
														</p>
													</>
												)}
											</div>
											{/* END : Fail/Pass Container */}
										</div>

										<p
											style={{
												marginTop: '20px',
												fontSize: '0.9rem',
												marginLeft: '20px',
											}}>
											Your results will expire when the certificate is delivered.
										</p>

										{props.resultDetails &&
											props.resultDetails.success &&
											props.questionSet.map((questions, questionIndex) => (
												<>
													<div
														style={{
															padding: '1rem 0rem 1rem 0.5rem',
														}}
														className='resultPageQuestionContainer'
														key={questionIndex}>
														<p
															style={{
																paddingLeft: '10px',
																marginBottom:
																	!questions.hasOwnProperty('selectedOption') ||
																	questions.selectedOption === ''
																		? '0.6rem'
																		: '',
															}}
															className='question'>
															{questions.question}
														</p>

														{(!questions.hasOwnProperty('selectedOption') ||
															questions.selectedOption === '') && (
															<div className='missingBadgeContainer'>
																<p>Missing</p>
																<img src={alertIcon} alt='alertIcon' />
															</div>
														)}

														{questions.options.map((option, optionIndex) => (
															<>
																<div
																	className={
																		questions.selectedOption === option
																			? questions.answer === questions.selectedOption
																				? 'form-check option answerIsCorrect selectedRadioBtn'
																				: 'form-check option answerIsInCorrect selectedRadioBtn'
																			: 'form-check option'
																	}
																	key={optionIndex}>
																	<input
																		disabled
																		className='form-check-input resultRadioBtn'
																		type='radio'
																		value={option}
																		name={'radio_' + questionIndex}
																		id={option + '_' + optionIndex}
																		checked={questions.selectedOption === option ? true : false}
																	/>
																	<label
																		className='form-check-label resultRadioBtnLabel'
																		htmlFor={option + '_' + optionIndex}>
																		{option}
																	</label>

																	{questions.selectedOption === option ? (
																		questions.answer === questions.selectedOption ? (
																			<img
																				className='rightAnswerIcon'
																				src={RightAnswerIcon}
																				alt='RightAnswerIcon'
																			/>
																		) : (
																			<img
																				className='wrongAnswerIcon'
																				src={WrongAnswerIcon}
																				alt='WrongAnswerIcon'
																			/>
																		)
																	) : null}
																</div>
															</>
														))}
													</div>
												</>
											))}

										<div className='pageNavigationBtn'>
											<img
												style={{
													visibility: props.isPreviousBtnVisible ? 'visible' : 'hidden',
												}}
												onClick={props.handlePreviousBtn}
												src={PreviousBtn}
												alt='PreviousBtn'
											/>

											{props.isNextBtnVisible && (
												<>
													<img onClick={props.handleNextBtn} src={NextBtn} alt='NextBtn' />
												</>
											)}
										</div>
									</div>
								</div>
							</>
						)}
					</>
				)}
			</center>
		</>
	);
}

export default ExamResultView;
