import React from 'react';

import './style.css';

function AgreePopupView(props) {
	return (
		<>
			<div className='claimCertificateAgreePopupMainContainer'>
				<button 
					className='d-none'
					ref={props.claimCertificateAgreePopupOpenBtn} 
					data-bs-toggle="modal" 
					data-bs-target="#claimCertificateAgreePopup">
					claimCertificateAgreePopupOpenBtn
				</button>

				<div className="modal fade" id="claimCertificateAgreePopup">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Notice</h5>
							</div>
							<div className="modal-body">
								{props.addressPlaceHolder}
								{props.userAddress}
							</div>
							<div className="modal-footer row">
								<button 
									onClick={()=>{props.handleAgreement()}}
									style={{color: '#007AFF'}} 
									className='col-5 actionBtns'>Agree</button>
								<button 
									ref={props.claimCertificateAgreePopupCloseBtn}
									className='col-5 actionBtns' data-bs-dismiss="modal">
										Go back
									</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AgreePopupView;
