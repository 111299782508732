import React from "react";
import ContentLoader from 'react-content-loader';

import './termsAndConditions.css';

import Pin from '@assets/termsAndCondition/pin.png';

function ViewTermsAndConditions(props) {
    return (
        <>
            <center>
                <div className="tNcMain">
                    {props.isLoading ?
                        (
                            <>
                                <ContentLoader viewBox='0 0 400 450' style={{ marginTop: '0px' }}>
                                    <rect x='0' y='0' rx='3' ry='3' width='400' height='45' />
                                    <rect x='0' y='55' rx='3' ry='3' width='400' height='45' />
                                    <rect x='0' y='110' rx='3' ry='3' width='400' height='45' />
                                    <rect x='0' y='165' rx='3' ry='3' width='400' height='45' />
                                    <rect x='0' y='220' rx='3' ry='3' width='400' height='45' />
                                    <rect x='0' y='275' rx='3' ry='3' width='400' height='45' />

                                    <rect x='0' y='330' rx='3' ry='3' width='400' height='45' />
                                    <rect x='0' y='385' rx='3' ry='3' width='400' height='45' />
                                </ContentLoader>
                            </>
                        ) : (
                            <>
                                {props.tNcData && props.tNcData.success &&
                                    <>
                                        <h3>{props.tNcData.data.title}</h3>
                                        <p
                                            className="tNcDescription"
                                            dangerouslySetInnerHTML={{ __html: props.tNcData.data.description.pointer }}
                                            style={{ fontSize: '1rem' }}>
                                            {/* todo Icon will come here */}
                                        </p>
                                        <br />

                                        {props.tNcData.data.sections
                                            && props.tNcData.data.sections.length > 0
                                            && props.tNcData.data.sections.map((section, index) => (
                                                <>
                                                    <div key={index}>
                                                        <h5
                                                            className="d-inline-block"
                                                            dangerouslySetInnerHTML={{ __html: section.title }}
                                                        >
                                                        </h5>
                                                        <img className="pinImg" src={Pin} alt='pin' />

                                                        <hr style={{ opacity: '1' }} />

                                                        <p
                                                            dangerouslySetInnerHTML={{ __html: section.paragraph }}
                                                            className="text"></p>

                                                        <ul>
                                                            {section.pointers && section.pointers.length > 0
                                                                && section.pointers.map((p, pIndex) => (
                                                                    <>
                                                                        <div className="tNcPointer">
                                                                            {p.icon &&
                                                                                <img src={p.icon} alt='pointer' />
                                                                            }
                                                                            <span dangerouslySetInnerHTML={{ __html: p.pointer }}></span>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                        </ul>
                                                        {section.imageUrl &&
                                                            <>
                                                                <center>
                                                                    <img className="lastImg" src={section.imageUrl} alt='tnc' />
                                                                    <br />
                                                                </center>

                                                                <br />
                                                            </>
                                                        }
                                                        <br />
                                                    </div>
                                                </>
                                            ))}
                                    </>
                                }
                            </>
                        )
                    }
                </div>
            </center>
        </>
    );

}

export default ViewTermsAndConditions;