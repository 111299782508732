import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';

import '../_style.css';
import './desktopTableStyle.css';

import MyLearning from '@pages/UserDashboard/MyLearning/Main';
import Evaluation from '@pages/UserDashboard/Evaluation';
import Profile from '@pages/UserDashboard/Profile/Main';
import Setting from '@pages/UserDashboard/Setting/Main';

import MyLearningIcon from '@assets/userDashboard/Icons/grayMyLearningIcon.png';
import ProfileIcon from '@assets/userDashboard/Icons/grayProfileIcon.png';
import EvaluationIcon from '@assets/userDashboard/Icons/grayEvaluationIcon.png';
import SettingIcon from '@assets/userDashboard/Icons/graySettingIcon.png';

import MyLearningIcon_blue from '@assets/userDashboard/Icons/blueMyLearningIcon.png';
import ProfileIcon_blue from '@assets/userDashboard/Icons/blueProfileIcon.png';
import EvaluationIcon_blue from '@assets/userDashboard/Icons/blueEvaluationIcon.png';
import SettingIcon_blue from '@assets/userDashboard/Icons/blueSettingIcon.png';


function DesktopTabletView(props) {
	return (
		<>
			<center>
				<div className='userDashboardMainContainer'>
					{props.isLoading ? (
						<></>
					) : (
						<>
							<Tab.Container
								defaultActiveKey='myLearningTab'
								activeKey={props.activeTab}								
								onSelect={props.handleTabSelect}>
								<Nav variant='tabs' className='navContainer'>
									<Nav.Item>
										<Nav.Link eventKey='myLearningTab' onClick={props.tabNavLinkClicked}>
											{props.myLearningTab ? (
												<>
													<img
														src={MyLearningIcon_blue}
														alt='MyLearningIcon'
														className='navIcon'
													/>
												</>
											) : (
												<>
													<img
														src={MyLearningIcon}
														alt='MyLearningIcon'
														className='navIcon'
													/>
												</>
											)}
											My Learning
										</Nav.Link>
									</Nav.Item>

									<Nav.Item>
										<Nav.Link eventKey='evaluationTab' onClick={props.tabNavLinkClicked}>
											{props.evaluationTab ? (
												<>
													<img
														src={EvaluationIcon_blue}
														alt='EvaluationIcon'
														className='navIcon'
													/>
												</>
											) : (
												<>
													<img
														src={EvaluationIcon}
														alt='EvaluationIcon'
														className='navIcon'
													/>
												</>
											)}
											Evaluation
										</Nav.Link>
									</Nav.Item>

									<Nav.Item>
										<Nav.Link eventKey='profileTab' onClick={props.tabNavLinkClicked}>
											{props.profileTab ? (
												<>
													<img
														src={ProfileIcon_blue}
														alt='ProfileIcon'
														className='navIcon'
													/>
												</>
											) : (
												<>
													<img src={ProfileIcon} alt='ProfileIcon' className='navIcon' />
												</>
											)}
											Profile
										</Nav.Link>
									</Nav.Item>

									<Nav.Item>
										<Nav.Link eventKey='settingTab' onClick={props.tabNavLinkClicked}>
											{props.settingTab ? (
												<>
													<img
														src={SettingIcon_blue}
														alt='SettingIcon'
														className='navIcon'
													/>
												</>
											) : (
												<>
													<img src={SettingIcon} alt='SettingIcon' className='navIcon' />
												</>
											)}
											Settings
										</Nav.Link>
									</Nav.Item>
								</Nav>

								<Tab.Content className='tabContainer'>
									{/* myLearningTab*/}
									<Tab.Pane eventKey='myLearningTab' className='myLearningMainContainer'>
										{props.myLearningTab && <MyLearning />}
									</Tab.Pane>

									{/* evaluationTab*/}
									<Tab.Pane eventKey='evaluationTab' className='myLearningMainContainer'>
										{props.evaluationTab && <Evaluation />}
									</Tab.Pane>

									{/* profileTab*/}
									<Tab.Pane eventKey='profileTab' className='profileMainContainer'>
										{props.profileTab && <Profile />}
									</Tab.Pane>

									{/* settingTab*/}
									<Tab.Pane eventKey='settingTab' className='settingMainContainer'>
										{props.settingTab && <Setting />}
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</>
					)}
				</div>
			</center>
		</>
	);
}

export default DesktopTabletView;
