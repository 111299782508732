import React from 'react';
import OTP from '@components/Otp';

import './style.css';
import RightArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';
import LeftArrow from '@assets/userDashboard/Icons/leftArrowForVPopup.png';

function IntrestedPopup(props) {
	return (
		<>
			<button
				className='d-none'
				ref={props.contactNoVerificationPopupOpenBtn}
				data-bs-toggle='modal'
				data-bs-target='#IntrestedPopup'>
				Launch demo modal
			</button>

			<div
				ref={props.modalRef}
				className='modal fade verificationPopup'
				id='IntrestedPopup'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						{props.verificationPopupStage &&
							props.verificationPopupStage === 'otpStage' && (
								<div className='modal-header'>
									<button
										onClick={() => {
											props.switchVerificationPopupContent('close');
										}}>
										<img src={LeftArrow} alt='LeftArrow' />
										Edit Phone Number
									</button>
									<button
										ref={props.contactNoVerificationPopupCloseBtn}
										type='button'
										className='d-none'
										data-bs-dismiss='modal'></button>
								</div>
							)}

						<div className='modal-body'>
							{props.verificationPopupStage &&
							props.verificationPopupStage === 'init' ? (
								<>
									<div className='initContainer'>
										Verify using OTP sent to {props.userData.countryCode}{' '}
										{props.userData.contactNumber}
									</div>
								</>
							) : (
								<>
									<div className='otpStageContainer'>
										<p className='heading'>Verify Phone Number</p>
										<p className='subHeading'>
											Verify using OTP sent to {props.userData.countryCode}{' '}
											{props.userData.contactNumber}
										</p>

										<OTP 
											countryCode={props.userData.countryCode}
											contactNumber={props.userData.contactNumber}
											handleSuccessOTP={props.handleSuccessOtp}
											/>
									</div>
								</>
							)}
						</div>

						{props.verificationPopupStage &&
							props.verificationPopupStage === 'init' && (
								<>
									<div className='modal-footer'>
										<div className='initContainer'>
											<button
												onClick={() => {
													props.switchVerificationPopupContent('otpStage');
												}}>
												I’m Interested
												<img src={RightArrow} alt='RightArrow' />
											</button>
										</div>
									</div>
								</>
							)}
					</div>
				</div>
			</div>
		</>
	);
}

export default IntrestedPopup;
