import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

function SeoHandler() {
	const navigate = useNavigate();

	useEffect(() => {
		handleRedirect();
	}, []);

    const handleRedirect=()=>{
        navigate('/');
    }
	return (
        <>
            {/* <h1>hg</h1> */}
        </>
    )
}
export default SeoHandler;
