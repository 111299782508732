import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import HomeView from './view';
import Footer from '@components/Footer';
import Navbar from '@components/Navbar';
import TopLoadingBar from '@components/TopLoadingBar';

import * as HomeServices from '@services/Home';

import * as NavbarActions from '@redux/actions/Navbar';
import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';
import * as HomeActions from '@redux/actions/Home';

import {
	getPrice,
	getPurchasesCount,
	updateCurrencyValue,
} from '../../library/helpers';
import { decryptText, encryptText } from '@library/enc-dec';
import { isUserLogedIn } from '@library/auth';

import OfferBackSide from '@assets/offers/offerBackSide.png';

function Home(props) {
	const navigate = useNavigate();
	const location = useLocation();

	const [latestCoursesDisplay, setLatestCoursesDisplay] = useState(true); // flag for showing 5 courses
	const dataFetchedRef = useRef(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isBannerLoaded, setIsBannerLoaded] = useState(false);

	//States for coupon
	const [coupons, setCoupons] = useState({});
	const [isOfferImageLoaded, setIsOfferImageLoaded] = useState(true);

	//States for confirm order
	const openhomePageConfirmOrderViewModalBtn= useRef(null);
	const [showInVoicePossible, setShowInVoicePossible] = useState(false)

	useEffect(() => {
		window.scrollTo(0, 0);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		setTimeout(() => {
			// debugger
			showInvoice();
			handlePurchasesCount();
			getLatestCourses();
			getLatestCoupons();
		}, 2000);
		UpdateTopLoadingBarForThisPage(10);
		setIsLoading(true);
		let viewportWidth = window.innerWidth;
		if (viewportWidth < 1000) {
			setLatestCoursesDisplay(false);
		}
	}, []);

	const handlePurchasesCount = async () => {
		try {
			const userLogedIn = await isUserLogedIn();
			props.updateLoginState(userLogedIn);

			if (userLogedIn) {
				const purchasesCount = await getPurchasesCount();
				props.updatePurchasesCount(purchasesCount);
			}
		} catch (err) {
			console.log('Error coming while fetching purchases count', err);
		}
	};

	const getLatestCourses = async () => {
		try {
			UpdateTopLoadingBarForThisPage(10);
			setIsLoading(true);

			UpdateTopLoadingBarForThisPage(40, true);
			let latestCourses = await HomeServices.getLatestCourses();

			UpdateTopLoadingBarForThisPage(70);

			if (latestCourses.success) {
				let updatedLatestCourses = {
					success: true,
					data: [],
				};
				await updateCurrencyValue();
				const latestCoursesPromises = latestCourses.data.map(async (course) => {
					const price = getPrice(course);
					course['price'] = price;

					updatedLatestCourses.data.push(course);
				});

				await Promise.all(latestCoursesPromises);
				props.updateLatestCourses(updatedLatestCourses);
				setIsLoading(false);
				UpdateTopLoadingBarForThisPage(100);
			}
		} catch (err) {
			console.log('Error coming from getLatestCourses()', err);
		}
	};

	const getLatestCoupons = async () => {
		try {
			const response = await HomeServices.getLatestCoupons();
			if (response.success) {
				setCoupons(response);
			}
		} catch (err) {
			console.log('Error coming form getLatestCoupons()', err);
		}
	};

	const handleCourse = (event, courseId) => {
		navigate(`/course/${courseId}`);
	};

	//START: Code for setting the progressof top bar loader
	const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
		if (interval) {
			setTimeout(function () {
				props.updateLoadingBarProgress(props.loadingBarProgress + value);
			}, 500);
		} else {
			props.updateLoadingBarProgress(props.loadingBarProgress + value);
		}
	};
	//END: Code for setting the progressof top bar loader

	const handleBannerImageLoading = () => {
		setTimeout(function () {
			setIsBannerLoaded(true);
		}, 900);
	};

	//START : CODE for offer card
	const handleMouseEnter = (event, index, offerDescription) => {
		let ele = event.target;
		ele.src = OfferBackSide;

		let offerDescriptionId = 'offerDetails_' + index;
		if (document.getElementById(offerDescriptionId)) {
			let offerDescriptionEle = document.getElementById(offerDescriptionId);
			offerDescriptionEle.style.display = 'block';

			const viewportWidth = window.innerWidth;
			console.log(window.innerWidth, offerDescription.length);
			let displayOfferDiscription = '';
			if (offerDescription) {
				displayOfferDiscription = offerDescription;
			}
			if (
				offerDescription &&
				offerDescription.length > 150 &&
				viewportWidth <= 1500 &&
				viewportWidth >= 1340
			) {
				let truncateVal = 140;
				if (viewportWidth < 1400) {
					truncateVal = 125;
				} else if (viewportWidth < 1380) {
					truncateVal = 115;
				}
				offerDescriptionEle.style.padding = '23px';
				displayOfferDiscription =
					displayOfferDiscription.substring(0, truncateVal) + '...';
			}
			offerDescriptionEle.innerHTML = displayOfferDiscription;
		}
	};

	const handleOfferImageLoading = () => {
		setIsOfferImageLoaded(true);
	};

	const handleMouseLeave = (event, myImg, index) => {
		let ele = event.target;
		ele.style.display = 'none';
		let offerImageId = 'offerImg_' + index;
		let offerImageIdEle = document.getElementById(offerImageId);
		offerImageIdEle.src = myImg;
	};

	const handleTouchStart = (event, myImg, index, value) => {
		if (value === 'show') {
			let ele = event.target;
			ele.src = OfferBackSide;

			let offerDescriptionId = 'offerDetailsM_' + index;
			if (document.getElementById(offerDescriptionId)) {
				let offerDescriptionEle = document.getElementById(offerDescriptionId);
				offerDescriptionEle.style.display = 'block';

				// const viewportWidth = window.innerWidth;
				// let spanTag = offerDescriptionEle.querySelector('span')
				// if(viewportWidth <= 1500 && viewportWidth >= 1300){
				// 	if(spanTag){
				// 		spanTag.style.display = 'block';
				// 		spanTag.classList.add('truncateOfferText')
				// 	}
				// }
				// else{
				// 	if(spanTag){
				// 		spanTag.style.display = 'block';
				// 	}
				// }
			}
		} else {
			let ele = event.target;
			ele.style.display = 'none';

			// const viewportWidth = window.innerWidth;
			// let spanTag = ele.querySelector('span')
			// if(viewportWidth <= 1500 && viewportWidth >= 1300){
			// 	if(spanTag){
			// 		spanTag.style.display = 'none';
			// 		spanTag.classList.remove('truncateOfferText')
			// 	}
			// }
			// else{
			// 	if(spanTag){
			// 		spanTag.style.display = 'none';
			// 	}
			// }

			let offerImageId = 'offerImgM_' + index;
			let offerImageIdEle = document.getElementById(offerImageId);
			offerImageIdEle.src = myImg;
		}
	};

	// Fn to get the review section scroll center
	let onLoadReviewInnerSection1_var = false;
	const onLoadReviewInnerSection1 = () => {
		if (!onLoadReviewInnerSection1_var) {
			setTimeout(function () {
				if (document.getElementById('reviewInnerSection1')) {
					let reviewInnerSection1Ele = document.getElementById(
						'reviewInnerSection1'
					);
					reviewInnerSection1Ele.scrollLeft =
						reviewInnerSection1Ele.getBoundingClientRect().width / 2;
				}
			}, 300);
			onLoadReviewInnerSection1_var = true;
		}
	};


	// START : Confirm order code
	const showInvoice = async () => {
		if(location && location.state && location.state.orderId && location.state.orderId !=null){
			setShowInVoicePossible(true)
		}
		if (openhomePageConfirmOrderViewModalBtn.current && location && location.state && location.state.orderId && location.state.orderId !=null) {
			openhomePageConfirmOrderViewModalBtn.current.click()
		}
	};
	// END : confirm order code




	return (
		<>
			<Navbar />
			<TopLoadingBar />
			<HomeView
				isLoading={isLoading}
				handleBannerImageLoading={handleBannerImageLoading}
				isBannerLoaded={isBannerLoaded}
				latestCourses={props.latestCourses}
				latestCoursesDisplay={latestCoursesDisplay}
				handleCourse={handleCourse}
				coupons={coupons}
				isOfferImageLoaded={isOfferImageLoaded}
				handleOfferImageLoading={handleOfferImageLoading}
				handleMouseEnter={handleMouseEnter}
				handleMouseLeave={handleMouseLeave}
				handleTouchStart={handleTouchStart}
				onLoadReviewInnerSection1={onLoadReviewInnerSection1}
				showInVoicePossible={showInVoicePossible}
				openhomePageConfirmOrderViewModalBtn={openhomePageConfirmOrderViewModalBtn}
			/>

			<Footer />
		</>
	);
}

function mapStateToProps(state) {
	return {
		latestCourses: state.home.latestCourses,
		loadingBarProgress: state.topLoadingBar.loadingBarProgress,
		isLogedIn: state.navbar.loginState,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateLatestCourses: HomeActions.updateLatestCourses,
			updateLoadingBarProgress: TopLoadingBarActions.updateLoadingBarProgress,
			updatePurchasesCount: NavbarActions.updatePurchasesCount,
			updateLoginState: NavbarActions.updateLoginState,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
