import React from 'react';

import './style.css';

import SeriesCard from '@components/SeriesCard';

export default (props) => (
	<div className='seriesContainer row seriesContainerC'>

	{props.seriesList && props.seriesList.length > 0 ? (
			<>
				{props.seriesList.map((series, index) => (
					<SeriesCard
						key={index}
						series={series}
						index={index}
						accordanceConfig={props.accordanceConfig}
					/>
				))}
			</>
		) : (
			<center>
				<img
					id='noProduct'
					src='https://stores.maxfashion.in/VendorpageTheme/Enterprise/EThemeForMax/images/product-not-found.jpg'
					alt='noProduct'
				/>
			</center>
		)}
	</div>
);
