import React from 'react';
import { Link } from 'react-router-dom';

import './cart.css';
import '@css/common.css';

import ContentLoader from 'react-content-loader';

function CartView(props) {
	return (
		<>
			{props.isLoading && (
				<>
					<div
						className='d-none d-md-block mainCart'
						style={{ background: 'transparent' }}>
						<ContentLoader viewBox='0 0 400 160'>
							<rect x='15' y='10' rx='5' ry='5' width='70' height='40' />
							<rect x='95' y='12' rx='3' ry='3' width='170' height='8' />
							<rect x='95' y='25' rx='3' ry='3' width='89' height='8' />
							<rect x='305' y='10' rx='3' ry='3' width='70' height='40' />

							<rect x='15' y='60' rx='5' ry='5' width='70' height='40' />
							<rect x='95' y='60' rx='3' ry='3' width='170' height='8' />
							<rect x='95' y='73' rx='3' ry='3' width='89' height='8' />

							<rect x='15' y='110' rx='5' ry='5' width='70' height='40' />
							<rect x='95' y='110' rx='3' ry='3' width='170' height='8' />
							<rect x='95' y='123' rx='3' ry='3' width='89' height='8' />
						</ContentLoader>

						<ContentLoader viewBox='0 0 400 25'>
							<rect x='15' y='0' rx='3' ry='3' width='250' height='5' />
							<rect x='15' y='10' rx='3' ry='3' width='150' height='5' />
							<rect x='15' y='20' rx='3' ry='3' width='90' height='5' />
						</ContentLoader>

						<ContentLoader viewBox='0 0 400 70'>
							<rect x='15' y='10' rx='5' ry='5' width='70' height='40' />
							<rect x='95' y='10' rx='5' ry='5' width='70' height='40' />
							<rect x='175' y='10' rx='5' ry='5' width='70' height='40' />
							<rect x='255' y='10' rx='5' ry='5' width='70' height='40' />
							<rect x='335' y='10' rx='5' ry='5' width='70' height='40' />
						</ContentLoader>
					</div>

					<div
						className='d-block d-md-none mainCart'
						style={{ background: 'transparent' }}>
						<ContentLoader viewBox='0 0 400 340'>
							<rect x='15' y='10' rx='3' ry='3' width='370' height='50' />

							<rect x='15' y='70' rx='5' ry='5' width='140' height='80' />
							<rect x='170' y='80' rx='3' ry='3' width='190' height='13' />
							<rect x='170' y='105' rx='3' ry='3' width='89' height='13' />

							<rect x='15' y='160' rx='5' ry='5' width='140' height='80' />
							<rect x='170' y='160' rx='3' ry='3' width='190' height='13' />
							<rect x='170' y='185' rx='3' ry='3' width='89' height='13' />

							<rect x='15' y='250' rx='5' ry='5' width='140' height='80' />
							<rect x='170' y='250' rx='3' ry='3' width='190' height='13' />
							<rect x='170' y='275' rx='3' ry='3' width='89' height='13' />
						</ContentLoader>

						<ContentLoader viewBox='0 0 400 80'>
							<rect x='15' y='10' rx='3' ry='3' width='300' height='13' />
							<rect x='15' y='33' rx='3' ry='3' width='230' height='13' />
							<rect x='15' y='56' rx='3' ry='3' width='150' height='13' />
						</ContentLoader>

						<ContentLoader viewBox='0 0 400 100'>
							<rect x='15' y='10' rx='5' ry='5' width='120' height='70' />
							<rect x='140' y='10' rx='5' ry='5' width='120' height='70' />
							<rect x='265' y='10' rx='5' ry='5' width='120' height='70' />
						</ContentLoader>
					</div>
				</>
			)}

			{!props.isLoading &&
			props.cartItems &&
			props.cartItems.success === true &&
			props.cartItems.data.totalItems > 0 ? (
				<>
					<center className='cartFiller'>
						<div className='mainCart'>
							<div className='row' style={{ justifyContent: 'space-evenly' }}>
								<div className='col-12 col-md-8 col-xl-9' id='allCartItemsContainer'>
									<div
										className='row d-none d-md-flex'
										style={{
											padding: '10px 0px 0px 0px',
											justifyContent: 'space-between',
										}}>
										<div className='col-sm-6'>
											<h4 className='heading'>Courses cart</h4>
										</div>

										<div
											className='col-sm-6'
											style={{ textAlign: 'right', paddingTop: '3px' }}>
											<h5 id='priceHeading'>Price</h5>
										</div>
									</div>

									<div className='col-12 d-block d-md-none' id='cartSubTotalContainer'>
										<p id='subTotal'>
											{props.cartItems.data.totalItems > 1 ? (
												<>Subtotal ({props.cartItems.data.totalItems} courses):</>
											) : (
												<>Subtotal ({props.cartItems.data.totalItems} course):</>
											)}

											<b id='amount'> {props.cartItems.data.totalValue} </b>
										</p>
										<button onClick={props.handleProcessToPay} className='btn w-100'>
											Proceed to Pay
										</button>
									</div>

									<hr style={{ marginTop: '1.5rem 0px' }} />

									{props.cartItems.data.itemsList.map((item, index) => (
										<>
											<div className='row cartItem' key={index.toString()}>
												<div className='col-5 col-sm-5 col-md-4 col-xl-3 courseImgContainer'>
													<div className='shineOnCourseCard'>
														<img
															className='w-100'
															src={item.thumbnailUrl}
															alt={item.title}
															onClick={(event) => {
																props.handleRedirectToCourse(event, item.courseId);
															}}
														/>
													</div>
												</div>

												<div className='col-7 col-sm-7 col-md-6 col-xl-6'>
													<h5
														className='courseHeading'
														onClick={(event) => {
															props.handleRedirectToCourse(event, item.courseId);
														}}>
														{item.title}
													</h5>
													{/* <p className='courseDescription'>{item.description}</p> */}

													<h5 className='d-block d-md-none coursePrice'>
														{item.isInOffer ? item?.offerPrice : item.crossedPrice}
													</h5>

													<div className='d-none d-sm-block'>
														<button
															className='cartItemButton'
															onClick={(e) => props.handleDeleteItem(item)}>
															Delete
														</button>
														<button
															id={item.courseId}
															onClick={(event) => props.handleShare(event)}
															className='cartItemButton'>
															Share
														</button>
													</div>
												</div>

												<div className='d-block d-sm-none cartItemButtonContainerMobile'>
													<button
														className='col-3 cartItemButton'
														style={{ marginRight: '10px' }}
														onClick={(e) => props.handleDeleteItem(item)}>
														Delete
													</button>

													<button
														id={item.courseId}
														onClick={(event) => props.handleShare(event)}
														className='col-3 cartItemButton'>
														Share
													</button>
												</div>

												<div className='d-none d-md-block col-md-2 col-xl-3 coursePriceContainer'>
													<h6 className='coursePrice'>
														{item.isInOffer ? item?.offerPrice : item.crossedPrice}
													</h6>
												</div>
											</div>
										</>
									))}

									<hr />

									<div className='d-none d-md-block' style={{ textAlign: 'right' }}>
										<p id='subTotal'>
											{props.cartItems.data.totalItems > 1 ? (
												<>Subtotal ({props.cartItems.data.totalItems} courses):</>
											) : (
												<>Subtotal ({props.cartItems.data.totalItems} course):</>
											)}

											<b id='amount'> {props.cartItems.data.totalValue} </b>
										</p>
									</div>
								</div>

								<div
									className='d-none d-md-block col-md-3 col-xl-2'
									id='cartSubTotalContainer'>
									<p id='subTotal'>
										{props.cartItems.data.totalItems > 1 ? (
											<>Subtotal ({props.cartItems.data.totalItems} courses):</>
										) : (
											<>Subtotal ({props.cartItems.data.totalItems} course):</>
										)}
										<br />
										<b id='amount'> {props.cartItems.data.totalValue} </b>
									</p>
									<button onClick={props.handleProcessToPay} className='btn w-100'>
										Proceed to Pay
									</button>
								</div>
							</div>

							<div className='col-12 col-md-8 col-xl-9' id='cartTnC'>
								<p>
									By clicking “Proceed to Pay” I agree to the{' '}
									<Link style={{ color: '#0070c0' }} to='/articles/terms-of-service'>
										Terms of Service
									</Link>{' '}
									and{' '}
									<Link style={{ color: '#0070c0' }} to='/articles/privacy-policy'>
										Privacy Policy
									</Link>
									.
								</p>
							</div>
						</div>
					</center>
				</>
			) : (
				!props.isLoading && (
					<>
						<center style={{ height: '80%' }}>
							<img
								id='emptyCart'
								src='https://shop.millenniumbooksource.com/static/images/cart1.png'
								alt='emptyCart'
							/>
						</center>
					</>
				)
			)}

			{!props.isLoading && props.relatedCourses.data && (
				<>
					{props.relatedCourses.data.length > 0 && (
						<center className='cartFiller'>
							<div id='cartPageRecentlyViewedProducts'>
								<div id='innerDiv'>
									<h4 className='heading' style={{ marginBottom: '15px' }}>
										Related Courses
									</h4>

									<div className='col-12'>
										<div className='d-flex overflow-auto'>
											{Object.values(props.relatedCourses.data).map((value, index) => (
												<div
													key={index.toString()}
													className='col-7 col-sm-4 col-lg-3 col-xl-2 viewedProduct'>
													<div
														className='shineOnCourseCard'
														onClick={(event) =>
															props.handleRedirectToCourse(event, value._id)
														}>
														<img
															src={value.thumbnailUrl}
															alt={value.title}
															className='w-100'
														/>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</center>
					)}
				</>
			)}

			{/* START: Share course modal */}
			<button
				id='sharePopupBtn'
				data-bs-toggle='modal'
				data-bs-target='#sharePopup'
				style={{ display: 'none' }}></button>

			<div className='modal fade' id='sharePopup'>
				<div className='modal-dialog modal-dialog-centered' role='document'>
					<div className='modal-content' style={{ borderRadius: '16px' }}>
						<div
							className='modal-header'
							style={{ paddingTop: '0px', paddingBottom: '0px', borderBottom: '0px' }}>
							<h5 className='modal-title' id='exampleModalLongTitle'>
								Share this product with friends
							</h5>
							<button className='btn' data-bs-dismiss='modal'>
								<span aria-hidden='true' style={{ fontSize: '40px' }}>
									&times;
								</span>
							</button>
						</div>
						<div className='modal-body' style={{ paddingTop: '0px' }}>
							<hr style={{ marginTop: '0px' }} />

							<div
								className={`textCopiedAlter alert alert-success ${
									props.copyAlterIsVisible ? 'visible' : ''
								}`}
								id='textCopiedAlter'
								style={{ padding: '7px' }}>
								Shared URL Copied
							</div>

							<div className='text-center' style={{ marginBottom: '20px' }}>
								<img
									onClick={(event) => {
										props.handleWhatAppShare(event);
									}}
									className='col-2 shareIcon'
									id='whatsApp'
									alt='whatsApp'
									src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/640px-WhatsApp_icon.png'
								/>
								<img
									onClick={(event) => {
										props.handleWhatAppShare(event);
									}}
									className='col-2 shareIcon'
									id='gmail'
									alt='gmail'
									src='https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png'
								/>
								<img
									onClick={(event) => {
										props.handleWhatAppShare(event);
									}}
									className='col-2 shareIcon'
									id='facebook'
									alt='facebook'
									src='https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-facebook_-512.png'
								/>
								<img
									onClick={(event) => {
										props.handleWhatAppShare(event);
									}}
									className='col-2 shareIcon'
									id='twitter'
									alt='facebook'
									src='https://www.freepnglogos.com/uploads/twitter-logo-png/twitter-logo-vector-png-clipart-1.png'
								/>
							</div>

							<div className='input-group mb-3'>
								<input type='text' className='form-control' id='shareInput' />
								<button
									className='btn btn-outline-secondary'
									onClick={props.handleCopy}>
									Copy
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* END: Share course modal */}
		</>
	);
}
export default CartView;
