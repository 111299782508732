import React from 'react';
import '@css/SignupLogin.css';
import { Link } from 'react-router-dom';

const countries = require('@assets/countryCode.json');

export default (props) => (
	<>
		<div className='container'>
			<div className='row'>
				<center id='centerTagConatiner' className='centerTagConatinerSingUp'>
					<form
						className='col-10 col-sm-10 col-md-8 col-lg-5'
						onSubmit={props.handleSignUp}>
						<p style={{ textAlign: 'left' }}>Sign up and start learning</p>
						{props.errorMessage ? (
							<p className='alert alert-danger'>{props.errorMessage}</p>
						) : null}

						<div className='input-group'>
							<input
								type='text'
								className='form-control signUpInputBoxes'
								placeholder='First Name'
								id='firstName'
								required
								onChange={(e) => props.handleFirstName(e.target.value)}
							/>
						</div>

						<div className='input-group'>
							<input
								type='text'
								className='form-control signUpInputBoxes'
								placeholder='Last Name'
								id='lastName'
								required
								onChange={(e) => props.handleLastName(e.target.value)}
							/>
						</div>

						{/* <div className='form-group'>
							<select
								id='country'
								style={{ color: '#6c757e' }}
								className='form-select signUpInputBoxes'
								onChange={props.handleCountry}
								required>
								<option value={''}>
									{props.displayCountryName
										? props.displayCountryName
										: `Select country`}
								</option>
								{countries.map((country, index) => (
									<option
										key={index}
										countryCode={country.countryCode}
										value={country.countryName}>
										{props.displayCountryName === country.countryName
											? country.countryName
											: country.countryName + ' ' + country.countryCode}
									</option>
								))}
							</select>
						</div> */}

						<div className='input-group'>
							<div style={{ display: ' none' }}>
								<span className='input-group-text basicFormInput' id='countryCode'>
									+
								</span>
							</div>

							<div className='form-group' id='countryCodeContainer'>
								<select
									id='country'
									style={{
										color: '#6c757e',
										width: '100px',
										borderRadius: '15px 0px 0px 15px',
									}}
									className='form-select signUpInputBoxes'
									onChange={props.handleCountry}
									required>
									<option
										value={props.displayCountryCode ? props.displayCountryCode : '+'}>
										{props.displayCountryCode ? props.displayCountryCode : `+`}
									</option>
									{countries.map((country, index) => (
										<option
											key={index}
											countryCode={country.countryCode}
											value={country.countryName}>
											{props.displayCountryCode === country.countryCode
												? country.countryCode
												: country.countryName + ' ' + country.countryCode}
										</option>
									))}
								</select>
							</div>

							<input
								type='number'
								className='form-control signUpInputBoxes'
								placeholder='Phone Number'
								name='phoneNumber'
								id='phoneNumber'
								value={props.contactNumber}
								onChange={(e) => props.handleContactNumber(e.target.value)}
								required
							/>
						</div>

						<div className='input-group'>
							<input
								type='email'
								className='form-control signUpInputBoxes'
								placeholder='Email'
								id='semail'
								required
								onChange={(e) => props.handleEmail(e.target.value)}
							/>
						</div>

						<div className='input-group'>
							<input
								type='text'
								className='form-control signUpInputBoxes'
								placeholder='Username'
								id='username'
								required
								onChange={(e) => props.handleUsername(e.target.value)}
							/>
						</div>

						<div className='input-group'>
							<input
								type='password'
								className='form-control signUpInputBoxes'
								placeholder='Password'
								id='spassword'
								onChange={(e) => {
									props.handlePaswordValidation(e.target.value);
									props.handlePassword(e.target.value);
								}}
								required
							/>
						</div>

						<div className='input-group'>
							<input
								type='password'
								className='form-control signUpInputBoxes'
								placeholder='Confirm Password'
								id='confirmPassword'
								required
								onChange={(e) => props.handleConfirmPassword(e.target.value)}
							/>
						</div>

						<ul className='list-group list-group-horizontal'>
							{props.poorPassword === true ? (
								<li
									className='list-group-item bg-danger col-4'
									style={{ padding: '1px 0px' }}></li>
							) : (
								''
							)}
							{props.weakPassword === true ? (
								<li
									className='list-group-item bg-warning col-4'
									style={{ padding: '1px 0px' }}></li>
							) : (
								''
							)}
							{props.strongPassword === true ? (
								<li
									className='list-group-item bg-success col-4'
									style={{ padding: '1px 0px' }}></li>
							) : (
								''
							)}
						</ul>
						<p style={{ textAlign: 'left' }}> {props.passwordError}</p>

						<div className='input-group row'>
							<div className='col-1'>
								<input
									checked={props.sendNotification}
									id='specialOfferesChekBox'
									type='checkbox'
									style={{ width: '18px', height: '18px' }}
									onChange={props.handleSendNotification}
								/>
							</div>
							<label
								className='col-11'
								style={{ marginBottom: '13px', textAlign: 'left' }}
								for='specialOfferesChekBox'>
								By clicking "Sign Up" I agree to eMediSkill's{' '}
								<Link style={{ color: '#0070c0' }} to='/articles/terms-of-service'>
									Terms of Service
								</Link>{' '}
								and{' '}
								<Link style={{ color: '#0070c0' }} to='/articles/privacy-policy'>
									Privacy Policy
								</Link>
								.
							</label>
						</div>

						{props.isBtnLoading ? (
							<>
								<button
									id='signupSubmit'
									className='col-12 loginSignupPageLoadingBtn'
									disabled>
									<i class='fa fa-spinner fa-spin'></i>
								</button>
							</>
						) : (
							<>
								<button type='submit' id='signupSubmit' className='col-12'>
									Signup
								</button>
							</>
						)}
					</form>
				</center>
			</div>
		</div>
	</>
);
