import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import TopLoadingBar from '@components/TopLoadingBar';
import CheckoutView from './view';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { isUserLogedIn } from '@library/auth';
import { decryptText } from '@library/enc-dec';

import * as CartServices from '@services/Cart';
import * as CheckoutServices from '@services/Checkout';
import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';
import * as NavbarActions from '@redux/actions/Navbar';

import * as CartActions from '@redux/actions/Cart';
import * as CheckoutActions from '@redux/actions/Checkout';
import moment from 'moment';

function Checkout(props) {
	const navigate = useNavigate();
	const dataFetchedRef = useRef(false);
	const [grandTotal, setGrandTotal] = useState(0);
	const [netTotal, setNetTotal] = useState(0);
	const [subTotal, setSubTotal] = useState(0);
	const [GST, setGST] = useState(0);
	const [couponValue, setCouponValue] = useState(null);

	const [grandTotalText, setGrandTotalText] = useState(null);
	const [netTotalText, setNetTotalText] = useState(null);
	const [subTotalText, setSubTotalText] = useState(0);
	const [GSTText, setGSTText] = useState(0);
	const [couponError, setCouponError] = useState('');

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		window.scroll(0, 0);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		handlePage();
	}, []);

	const handlePage = async () => {
		const isLogedIn = await isUserLogedIn();
		props.updateLoginState(isLogedIn);

		if (!isLogedIn) {
			navigate('/login', { state: { message: 'Please login first' } });
			return;
		}
		init();
	};

	const init = async () => {
		// Get the cart details
		UpdateTopLoadingBarForThisPage(0);
		let authToken = decryptText(localStorage.getItem('eMediat'));
		let countryName = decryptText(localStorage.getItem('c'));
		let currencySymbol = decryptText(localStorage.getItem('cs'));

		UpdateTopLoadingBarForThisPage(30);
		let payload = {
			countryName: countryName,
			currencySymbol: currencySymbol,
		};
		let cartItems = await CartServices.getCartItems(payload, authToken);

		cartItems = checkItemsIsInOffer(cartItems);
		UpdateTopLoadingBarForThisPage(70);

		props.updateCartItems(cartItems);

		// Do some calculations
		if (cartItems.success) {
			let grandTotal = cartItems?.data?.totalValueInNumbers;
			let GST = parseInt(grandTotal * (18 / 100));
			let subTotal = parseInt(grandTotal - GST);
			let netTotal = grandTotal;

			props.updateCheckoutDetails({
				GST: GST,
				grandTotal: grandTotal,
				netTotal: netTotal,
				subTotal: subTotal,
				discount: 0,
				discountInPercentage: 0,
			});

			setNetTotal(netTotal);
			setSubTotal(subTotal);
			setGST(GST);
			setGrandTotal(grandTotal);

			// Convert the values to text
			if (countryName === 'India') {
				setGrandTotalText(`${currencySymbol} ${grandTotal}/-`);
				setSubTotalText(`${currencySymbol} ${subTotal}/-`);
				setGSTText(`${currencySymbol} ${GST}/-`);
				setNetTotalText(`${currencySymbol} ${netTotal}/-`);
			} else {
				setGrandTotalText(`${currencySymbol} ${grandTotal}`);
				setSubTotalText(`${currencySymbol} ${subTotal}`);
				setGSTText(`${currencySymbol} ${GST}`);
				setNetTotalText(`${currencySymbol} ${netTotal}`);
			}
		}
		UpdateTopLoadingBarForThisPage(100);
		setIsLoading(false);
	};

	const checkItemsIsInOffer = (itemsInDB) => {
		let itemList = [];
		let totalValueInNumbers = 0;
		let totalValue = '';

		itemsInDB?.data?.itemsList?.map((item) => {
			item = handlePaidCourse(item);
			itemList.push(item);
			totalValueInNumbers += item.isInOffer
				? item.offerPriceInNumbers
				: item.crossedPriceInNumbers;
		});

		let countryName = decryptText(localStorage.getItem('c'));
		let currencySymbol = decryptText(localStorage.getItem('cs'));

		//check user country show sign
		if (countryName === 'India') {
			totalValue = `${currencySymbol} ${totalValueInNumbers}/-`;
		} else {
			totalValue = `${currencySymbol} ${totalValueInNumbers}`;
		}

		itemsInDB.data.itemsList = itemList;
		itemsInDB.data.totalValueInNumbers = totalValueInNumbers;
		itemsInDB.data.totalValue = totalValue;
		return itemsInDB;
	};

	const handlePaidCourse = (courseDetails) => {
		if (localStorage.getItem('crsl')) {
			let localDbCoursesList = JSON.parse(
				decryptText(localStorage.getItem('crsl'))
			);
			const isExists = localDbCoursesList.filter(
				(item) => item._id === courseDetails.courseId
			);
			if (isExists.length > 0) {
				let todayDate = moment();
				let offerEndDate = moment(isExists[0].offerEndDate);
				if (moment(todayDate).isAfter(offerEndDate)) {
					courseDetails['isInOffer'] = false;
					return courseDetails;
				} else {
					courseDetails['isInOffer'] = true;
					return courseDetails;
				}
			} else {
				courseDetails['isInOffer'] = true;
				return courseDetails;
			}
		} else {
			courseDetails['isInOffer'] = false;
			return courseDetails;
		}
	};

	const handleApplyCoupon = async () => {
		setCouponError('');
		const couponCode = document
			.getElementById('discountCouponInput')
			.value.trim();
		let authToken = decryptText(localStorage.getItem('eMediat'));
		let countryName = decryptText(localStorage.getItem('c'));
		let currencySymbol = decryptText(localStorage.getItem('cs'));
		const applyCouponRes = await CheckoutServices.applyCoupon(
			couponCode,
			authToken
		);

		if (!applyCouponRes.success) {
			setCouponError(applyCouponRes.message);
			setCouponValue(null);
			init();
			props.resetCheckoutReducer();
			return;
		}

		props.updateCouponDetails(applyCouponRes?.data);

		// After discount calculate the values
		let discount = applyCouponRes?.data?.discountOff;
		// Change the total here
		let netTotal = grandTotal - discount;
		let GST = parseInt(netTotal * (18 / 100));
		let subTotal = parseInt(netTotal - GST);
		setSubTotal(subTotal);
		setGST(GST);
		setNetTotal(netTotal);

		props.updateCheckoutDetails({
			GST: GST,
			grandTotal: grandTotal,
			netTotal: netTotal,
			subTotal: subTotal,
			discount: applyCouponRes?.data?.discountOff,
			discountInPercentage: applyCouponRes?.data?.discountPercentage,
		});

		// Convert the values to text
		if (countryName === 'India') {
			setGrandTotalText(`${currencySymbol} ${grandTotal}/-`);
			setSubTotalText(`${currencySymbol} ${subTotal}/-`);
			setGSTText(`${currencySymbol} ${GST}/-`);
			setNetTotalText(`${currencySymbol} ${netTotal}/-`);

			setCouponValue(`${currencySymbol} ${discount}/-`);
		} else {
			setGrandTotalText(`${currencySymbol} ${grandTotal}`);
			setSubTotalText(`${currencySymbol} ${subTotal}`);
			setGSTText(`${currencySymbol} ${GST}`);
			setNetTotalText(`${currencySymbol} ${netTotal}`);
			setCouponValue(`${currencySymbol} ${discount}`);
		}
	};

	const handleCheckout = () => {
		navigate('/pay');
	};

	const handleRedirect = (event, handleRedirect) => {
		if (handleRedirect != '') {
			navigate('/course/' + handleRedirect);
		}
	};
	//START: Code for setting the progressof top bar loader
	const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
		if (interval) {
			setTimeout(function () {
				props.updateLoadingBarProgress(props.loadingBarProgress + value);
			}, 500);
		} else {
			props.updateLoadingBarProgress(props.loadingBarProgress + value);
		}
	};
	//END: Code for setting the progressof top bar loader

	return (
		<>
			<Navbar />
			<TopLoadingBar />
			<CheckoutView
				cartItems={props.cartItems}
				handleApplyCoupon={handleApplyCoupon}
				couponValue={couponValue}
				couponError={couponError}
				grandTotalText={grandTotalText}
				subTotalText={subTotalText}
				GSTText={GSTText}
				netTotalText={netTotalText}
				handleRedirect={handleRedirect}
				handleCheckout={handleCheckout}
				isLoading={isLoading}
			/>
			<Footer />
		</>
	);
}

function mapStateToProps(state) {
	return {
		isLogedIn: state.navbar.loginState,
		cartItems: state.cart.cartItems,
		cartItemCount: state.cart.cartItemCount,
		loadingBarProgress: state.topLoadingBar.loadingBarProgress,
		isLogedIn: state.navbar.loginState,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateCartItems: CartActions.updateCartItems,
			updateCheckoutDetails: CheckoutActions.updateCheckoutDetails,
			updateCouponDetails: CheckoutActions.updateCouponDetails,
			resetCheckoutReducer: CheckoutActions.resetCheckoutReducer,
			updateLoadingBarProgress: TopLoadingBarActions.updateLoadingBarProgress,
			updateLoginState: NavbarActions.updateLoginState,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
