import React from 'react';
import ContentLoader from "react-content-loader";
import './style.css';

export default () => (
    <div className='profileTabLoader'>
        <div className='profileTabLoaderDesktop'>
            <ContentLoader
                backgroundColor="#ffffff"
                // foregroundColor="#a09d9d"
                viewBox="0 0 395 120">

                {/* Head and save btn */}
                <rect x='0' y='0' rx='2' ry='2' width='80' height='12' />
                <rect x='350' y='0' rx='2' ry='2' width='40' height='12' />
                {/* Head and save btn */}

                <rect x='0' y='20' rx='2' ry='2' width='40' height='8' />
                <rect x='0' y='34' rx='2' ry='2' width='240' height='8' />
                <circle cx="30" cy="80" r="25" />
            </ContentLoader>

            {[...Array(2)].map((_, index) => (
                <ContentLoader
                    key={index}
                    backgroundColor="#ffffff"
                    // foregroundColor="#a09d9d"
                    viewBox="0 0 395 70">
                    <rect x='0' y='0' rx='2' ry='2' width='120' height='10' />
                    <rect x='0' y='30' rx='2' ry='2' width='180' height='2' />

                    <rect x='200' y='0' rx='2' ry='2' width='120' height='10' />
                    <rect x='200' y='30' rx='2' ry='2' width='180' height='2' />
                </ContentLoader>
            ))}

            <ContentLoader
                backgroundColor="#ffffff"
                // foregroundColor="#a09d9d"
                viewBox="0 0 395 30">
                {/* Head and save btn */}
                <rect x='0' y='0' rx='2' ry='2' width='80' height='12' />
                <rect x='350' y='0' rx='2' ry='2' width='40' height='12' />
                {/* Head and save btn */}
            </ContentLoader>

            {[...Array(2)].map((_, index) => (
                <ContentLoader
                    key={index}
                    backgroundColor="#ffffff"
                    // foregroundColor="#a09d9d"
                    viewBox="0 0 395 80">
                    <rect x='0' y='0' rx='2' ry='2' width='120' height='10' />
                    <rect x='0' y='30' rx='2' ry='2' width='180' height='2' />

                    <rect x='200' y='0' rx='2' ry='2' width='120' height='10' />
                    <rect x='200' y='30' rx='2' ry='2' width='180' height='2' />
                </ContentLoader>
            ))}

            <ContentLoader
                backgroundColor="#ffffff"
                // foregroundColor="#a09d9d"
                viewBox="0 0 395 30">
                {/* Head and save btn */}
                <rect x='0' y='0' rx='2' ry='2' width='80' height='12' />
                <rect x='350' y='0' rx='2' ry='2' width='40' height='12' />
                {/* Head and save btn */}
            </ContentLoader>

            {[...Array(2)].map((_, index) => (
                <ContentLoader
                    key={index}
                    backgroundColor="#ffffff"
                    // foregroundColor="#a09d9d"
                    viewBox="0 0 395 50">
                    <rect x='0' y='0' rx='2' ry='2' width='120' height='10' />
                    <rect x='0' y='30' rx='2' ry='2' width='180' height='2' />

                    <rect x='200' y='0' rx='2' ry='2' width='120' height='10' />
                    <rect x='200' y='30' rx='2' ry='2' width='180' height='2' />
                </ContentLoader>
            ))}

        </div>

        {/* START Mobile */}
        <div className='profileTabLoaderMobile'>
            <ContentLoader
                backgroundColor="#ffffff"
                // foregroundColor="#a09d9d"
                viewBox="0 0 100 110">

                {/* Head and save btn */}
                <rect x='0' y='0' rx='2' ry='2' width='30' height='7' />
                <rect x='78' y='0' rx='2' ry='2' width='20' height='7' />
                {/* Head and save btn */}

                <rect x='0' y='15' rx='2' ry='2' width='40' height='5' />
                <rect x='0' y='25' rx='2' ry='2' width='90' height='5' />
                <circle cx="20" cy="50" r="16" />
                <rect x='0' y='75' rx='2' ry='2' width='40' height='5' />
                <rect x='0' y='85' rx='2' ry='2' width='90' height='5' />
            </ContentLoader>

            {[...Array(8)].map((_, index) => (
                <ContentLoader
                    key={index}
                    backgroundColor="#ffffff"
                    // foregroundColor="#a09d9d"
                    viewBox="0 0 100 60">
                    <rect x='0' y='0' rx='2' ry='2' width='60' height='6' />
                    <rect x='0' y='18' rx='2' ry='2' width='180' height='2' />

                    <rect x='0' y='28' rx='2' ry='2' width='60' height='6' />
                    <rect x='0' y='46' rx='2' ry='2' width='180' height='2' />
                </ContentLoader>
            ))}

        </div>
        {/* END Mobile */}
    </div>
);
