import React from 'react';
import { useNavigate } from "react-router-dom";

import moment from 'moment';
import BlueBgWhiteRight from '@assets/course/blueBgWhiteRightV2.png';
import PDFIcon from "@assets/timeline/icons/pdf.png";
import DownloadIcon from "@assets/timeline/icons/download.png";
import RightArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';

import './style.css';
import ContentLoader from 'react-content-loader';

function CheckoutView(props) {
    const navigate = useNavigate();
    const handleRedirectToDashboard = () => {
        navigate("/dashboard", {});
    }
    return (
        <>
            <center>
                {props.isLoading ? (
                    <>
                        <div className='mainConfirmOrderContainer d-none d-sm-block'>
                            <ContentLoader viewBox='0 0 380 250'>
                                <rect x='0' y='0' rx='2' ry='2' width='100' height='8' />

                                <rect x='0' y='15' rx='2' ry='2' width='200' height='8' />

                                {/* Transaction details */}
                                <rect x='0' y='35' rx='2' ry='2' width='380' height='1' />
                                <rect x='0' y='45' rx='2' ry='2' width='50' height='15' />
                                <rect x='100' y='45' rx='2' ry='2' width='50' height='15' />
                                <rect x='200' y='45' rx='2' ry='2' width='50' height='15' />
                                <rect x='0' y='70' rx='2' ry='2' width='380' height='1' />

                                {/* course card 1 */}
                                {/* course card */}
                                <rect x='0' y='80' rx='2' ry='2' width='90' height='50' />
                                {/* course title */}
                                <rect x='100' y='85' rx='2' ry='2' width='90' height='5' />
                                {/* course discription */}
                                <rect x='100' y='95' rx='2' ry='2' width='150' height='5' />
                                {/* course price */}
                                <rect x='350' y='85' rx='2' ry='2' width='30' height='10' />

                                {/* course card 2 */}
                                {/* course card */}
                                <rect x='0' y='140' rx='2' ry='2' width='90' height='50' />
                                {/* course title */}
                                <rect x='100' y='145' rx='2' ry='2' width='90' height='5' />
                                {/* course discription */}
                                <rect x='100' y='155' rx='2' ry='2' width='150' height='5' />
                                {/* course price */}
                                <rect x='350' y='145' rx='2' ry='2' width='30' height='10' />

                                <rect x='0' y='200' rx='2' ry='2' width='250' height='8' />
                                <rect x='0' y='215' rx='2' ry='2' width='80' height='15' />
                            </ContentLoader>
                        </div>

                        <div className='mainConfirmOrderContainer d-block d-sm-none'>
                            <ContentLoader viewBox='0 0 200 300'>
                                <rect x='0' y='0' rx='2' ry='2' width='100' height='8' />

                                <rect x='0' y='15' rx='2' ry='2' width='200' height='8' />

                                {/* Transaction details */}
                                <rect x='0' y='35' rx='2' ry='2' width='200' height='1' />

                                <rect x='0' y='45' rx='2' ry='2' width='60' height='20' />
                                <rect x='0' y='75' rx='2' ry='2' width='60' height='20' />
                                <rect x='0' y='105' rx='2' ry='2' width='60' height='20' />

                                <rect x='0' y='135' rx='2' ry='2' width='200' height='1' />

                                {/* course card 1 */}
                                {/* course card */}
                                <rect x='0' y='150' rx='2' ry='2' width='90' height='50' />
                                {/* course title */}
                                <rect x='100' y='155' rx='2' ry='2' width='80' height='5' />
                                {/* course discription */}
                                <rect x='100' y='165' rx='2' ry='2' width='90' height='5' />
                                {/* course price */}
                                <rect x='100' y='175' rx='2' ry='2' width='30' height='5' />

                                {/* course card 1 */}
                                {/* course card */}
                                <rect x='0' y='210' rx='2' ry='2' width='90' height='50' />
                                {/* course title */}
                                <rect x='100' y='215' rx='2' ry='2' width='80' height='5' />
                                {/* course discription */}
                                <rect x='100' y='225' rx='2' ry='2' width='90' height='5' />
                                {/* course price */}
                                <rect x='100' y='235' rx='2' ry='2' width='30' height='5' />

                                <rect x='0' y='270' rx='2' ry='2' width='200' height='8' />
                                <rect x='0' y='285' rx='2' ry='2' width='90' height='15' />
                            </ContentLoader>
                        </div>
                    </>
                ) : (
                    <div className='mainMobileConfirmOrderContainer'>
                        <div className='headContainer'>
                            <p className='orderConfirmedText'>Order confirmed!</p>
                            <img src={BlueBgWhiteRight} alt='blueBgWhiteRight' />
                            <br />
                            <p>{props.confirmOrderDetails?.data?.currencySymbol}{new Intl.NumberFormat('en-IN').format(props.confirmOrderDetails?.data?.netTotal)}
                            </p>
                        </div>

                        <div className="pdfHolder"
                            onClick={(event) => props.handleDownloadInvoice(event)}>
                            <div className="div_01">
                                <img className="PDFIcon" src={PDFIcon} alt="pdf" />
                                <p className="title">
                                    {props.confirmOrderDetails?.data?.invoiceId}
                                </p>
                                <p className="subTitle">
                                    <span>
                                        {moment(props.confirmOrderDetails?.data?.createdAt).format('DD MMM YYYY')}
                                    </span>
                                </p>
                            </div>
                            <img className="DownloadIcon" src={DownloadIcon} alt="download" />
                        </div>

                        <div className='transactionDetails'>
                            <p className='text'>Transaction Date</p>
                            <p className='value'>{moment(props.confirmOrderDetails?.data?.createdAt).format('DD MMM YYYY')}</p>
                        </div>
                        <div className='transactionDetails'>
                            <p className='text'>Transaction ID</p>
                            <p className='value'>{props.confirmOrderDetails?.data?.orderId}</p>
                        </div>
                        <div className='transactionDetails'>
                            <p className='text'>Payment Gateway</p>
                            <p className='value'>{props.confirmOrderDetails?.data?.paymentMethod}</p>
                        </div>

                        {window.innerWidth < 576 &&
                            <button
                                onClick={handleRedirectToDashboard}
                                type="button"
                                className="btn btn-primary continueToDashboardBtn col-12">
                                Continue to Dashboard
                                <img src={RightArrow} alt='RightArrow' />
                            </button>
                        }


                    </div>
                )}
            </center>
        </>
    );
}

export default CheckoutView;
