const initialState = {
  purchases: {},
  user: {},
  purchasedCourses: {},
  seriesList: {},
  course: {},
  myProfileError: "",
  profileError: "",
  timelineData: {},
  activeTab: "myLearningTab",
  userHistory: {},
  userFav: {},
  seriesActivation: {},
  testDetails: {},
  examDetails: {},
  transactionsDetails: {}
};

const UserDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_USER_DETAILS":
      return {
        ...state,
        user: action.payload.user,
      };
    case "UPADATE_PURCHASES":
      return {
        ...state,
        purchases: action.payload.purchases,
      };
    case "UPDATE_PURCHASED_COURSES":
      return {
        ...state,
        purchasedCourses: action.payload.purchasedCourses,
      };
    case "UPDATED_SERIES_LIST":
      return {
        ...state,
        seriesList: action.payload.seriesList,
      };
    case "UPDATED_USER_HISTORY":
      return {
        ...state,
        userHistory: action.payload.userHistory,
      };
    case "UPDATED_USER_FAV":
      return {
        ...state,
        userFav: action.payload.userFav,
      };
    case "UPDATE_PURCHASED_COURSE":
      return {
        ...state,
        course: action.payload.course,
      };
    case "UPDATE_MY_PROFILE_ERROR":
      return {
        ...state,
        myProfileError: action.payload.myProfileError,
      };
    case "UPDATE_PROFILE_ERROR":
      return {
        ...state,
        profileError: action.payload.profileError,
      };
    case "UPDATE_TIMELINE_DATA":
      return {
        ...state,
        timelineData: action.payload.timelineData,
      };
    case "UPDATE_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    case "UPDATE_SERIES_ACTIVATION":
      return {
        ...state,
        seriesActivation: action.payload.seriesActivation,
      };
    case "UPDATED_TEST_DETAILS":
      return {
        ...state,
        testDetails: action.payload.testDetails,
      };
    case "UPDATED_EXAM_DETAILS":
      return {
        ...state,
        examDetails: action.payload.examDetails,
      };
    case "UPDATED_TRANSACTIONS_DETAILS":
      return {
        ...state,
        transactionsDetails: action.payload.transactionsDetails,
      };
    default:
      return state;
  }
};

export default UserDashboardReducer;
