import React, { useState } from 'react';

import { decryptText } from '@library/enc-dec';
import RegistrationView from './view';

function Registration(props) {
	const [fullName, setFullName] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
	const [countryCode, setCountryCode] = useState('');
	const [tnC, setTnC] = useState(true);

	const [validationError, setValidationError] = useState('');
	const [fullNameError, setFullNameError] = useState(false);
	const [tnCError, setTnCError] = useState(false);
	const seoUrlSlug = window.location.href.split('/').pop();



	const getterSetter = (action, value) => {
		updateAllErrorsToFalse();
		if (action === 'phoneNo') {
			setPhoneNo(value);
		} else if (action === 'countryCode') {
			setCountryCode(value);
		}
	};

	const handleFullName = (event) => {
		updateAllErrorsToFalse();
		setFullName(event.target.value);
		if (props.fullNameSetter) {
			props.fullNameSetter(event.target.value);
		}
	};

	const updateAllErrorsToFalse = () => {
		setTnCError(false);
		setFullNameError(false);
		setTnCError(false);
		setValidationError('');
	};

	const handleIamIntrested = () => {
		if(props.userLogedIn && props.createLead){
			const authToken = decryptText(localStorage.getItem('eMediat'));
			props.createLead(seoUrlSlug,authToken);
			setFullName('');
			getterSetter('phoneNo','')
			let CountryCodeFlagPhoneNoComponentContactNoElement = document.getElementById("CountryCodeFlagPhoneNoComponentContactNoElement")
			if(CountryCodeFlagPhoneNoComponentContactNoElement){
				setPhoneNo('')
				CountryCodeFlagPhoneNoComponentContactNoElement.value = "";
			}
			return;
		}

		let error = false;

		if (props.fullNameSetter && fullName === '') {
			setFullNameError(true);
			error = true;
		} else if (countryCode === '') {
			setValidationError('Invalid country code');
			error = true;
		} else if (phoneNo.length <= 9 || phoneNo.length >= 11) {
			setValidationError('Invalid phone number');
			error = true;
		} else if (!tnC) {
			setTnCError(true);
			error = true;
		}

		if (!error) {
			// This fn is defined at src\pages\Course\Main\index.js
			props.openOtpPopUp(countryCode, phoneNo, 'otpStage', props.buttonType);
		}
	};

	const handleTnC = (event) => {
		setTnCError(false);
		setTnC(event.target.checked);
	};

	return (
		<>
			<RegistrationView
				handleFullName={handleFullName}
				fullNameError={fullNameError}
				getterSetter={getterSetter}
				handleIamIntrested={handleIamIntrested}
				validationError={validationError}
				handleTnC={handleTnC}
				tnCError={tnCError}
				fullName={fullName}
				{...props}
			/>
		</>
	);
}

export default Registration;
