import React from 'react';
import './style.css';

export default (props) => (
	<div className='col-12 videoDiv '>
		<div className='videoContainer'>
			<iframe
				src={props.previewVideoLink}
				className='previewVideoIframe'
				title='YouTube video player'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen'
				allowFullScreen></iframe>
		</div>
	</div>
);
