import React, { useState, useEffect } from 'react';

import AgreePopupView from './view';

function AgreePopup(props) {
	const [userAddress, setUserAddress] = useState("");

	useEffect(()=>{
		let addressPlaceHolder = "Please confirm whether your delivery address is correct and claim your certificate.";
		let address = addressPlaceHolder + " address address address addressaddress address address address address address address";
		setUserAddress(address);
	},[])

	return (
		<>
			<AgreePopupView 
				{...props}
				userAddress={userAddress} />
		</>
	);
}

export default AgreePopup;
