import React from 'react';
import CourseCardView from './view';

import { useSelector } from 'react-redux';

function CourseCard() {
	const timelineData = useSelector((state) => state.dashboard.timelineData);
	return <CourseCardView timelineData={timelineData}/>;
}

export default CourseCard;
