import React from 'react';

import './style.css';

export default (props) => (
	<div className='blogView'>
		{props.blogDetails &&
			props.blogDetails?.sections.map((item, index) => (
				<div key={index} className='blogViewInnerContainer'>
					<h2
						className='title'
						dangerouslySetInnerHTML={{ __html: item.title }}></h2>
					<p
						className='description'
						dangerouslySetInnerHTML={{ __html: item.description }}></p>
					{item.thumbnailUrl !== null && (
						<img className='thumbnail' src={item.thumbnailUrl} alt='thumbnailUrl' />
					)}
				</div>
			))}
	</div>
);
