export const getConversionRate = async (currentCode) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getConversionRate/${currentCode}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Conversion Rate => getConversionRate services',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
