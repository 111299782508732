import { countryCurrency } from "./countryCurrency";
import { countries } from "./countries";
import * as DashboardServices from "@services/UserDashboard";
import * as ConversionRateServices from "@services/ConversionRate";
import * as CheckoutServices from "@services/Checkout";
import moment from "moment";

import { encryptText, decryptText } from "@library/enc-dec";

export const getUserLocation = async () => {
  try {
    if (
      localStorage.getItem("c") &&
      localStorage.getItem("cc") &&
      localStorage.getItem("cs") &&
      localStorage.getItem("cv") &&
      localStorage.getItem("cont")
    ) {
      return;
    }

    // Get IP Adress
    const response = await fetch("https://api.ipify.org/?format=json");
    const ipAddress = await response.json();
    // GET country details
    const response2 = await fetch(
      `${process.env.REACT_APP_GEO_LOCATION_BASE_URL}${ipAddress.ip}`
    );

    const locationData = await response2.json();
    let country = locationData.country_name;

    getTheContinent(country);
    let countryDetails = countryCurrency.filter(
      (item) => item.name.toLowerCase() === country.toLowerCase()
    )[0];

    let currencyCode = countryDetails.currency.code;
    let currencySymbol = countryDetails.currency.symbol;
    localStorage.setItem("cc", encryptText(currencyCode));
    localStorage.setItem("cs", encryptText(currencySymbol));

    const conversionResponse = await ConversionRateServices.getConversionRate(
      currencyCode
    );
    if (conversionResponse.success) {
      localStorage.setItem("cv", encryptText(conversionResponse.data));
    } else {
      localStorage.setItem("cv", encryptText(1));
    }

    localStorage.setItem("c", encryptText(country));
  } catch (err) {
    console.log("Error coming from getUserLocation()", err);
  }
};

function getTheContinent(country) {
  Object.keys(countries).map((item) => {
    countries[item].map((subItem) => {
      if (subItem === country) {
        localStorage.setItem("cont", encryptText(item));
      }
    });
  });
}

export const calculateOfferEndDate = (days, hours, minutes) => {
  const endDate = moment()
    .add(days, "day")
    .add(hours, "hour")
    .add(minutes, "minute")
    .toISOString();
  return endDate;
};

export const updateCurrencyValue = async () => {
  try {
    let currencyCode = localStorage.getItem("cc")
      ? decryptText(localStorage.getItem("cc"))
      : "INR";

    const conversionResponse = await ConversionRateServices.getConversionRate(
      currencyCode
    );

    if (conversionResponse.success) {
      localStorage.setItem("cv", encryptText(conversionResponse.data));
    } else {
      localStorage.setItem("cv", encryptText(1));
    }
  } catch (err) {
    console.log("Error coming from updateCurrenyValue()", err);
  }
};

// Here noOfPrices repesenting two different values offerPrice and crossedPrice
export const getPrice = (course, noOfPrices = 1) => {
  try {
    if (course) {
      let continent = localStorage.getItem("cont")
        ? decryptText(localStorage.getItem("cont"))
        : "DEVELOPING_ASIA";

      let prices = course?.pricingDetails?.prices.filter(
        (item) => item.continent === continent
      )[0];

      if (prices) {
        let offerPrice = prices["offerPrice"];

        let currencyValue = localStorage.getItem("cv")
          ? Number(decryptText(localStorage.getItem("cv")))
          : 1;
        let currencySymbol = localStorage.getItem("cs")
          ? decryptText(localStorage.getItem("cs"))
          : "₹";
        let country = localStorage.getItem("c")
          ? decryptText(localStorage.getItem("c"))
          : "India";

        let price = currencyValue * offerPrice;

        if (country === "India") {
          price = `${currencySymbol} ${Math.floor(price).toString()}/-`;
          return noOfPrices === 4
            ? {
              offerPrice: price,
              crossedPrice: `${currencySymbol} ${Math.floor(
                currencyValue * prices["crossedPrice"]
              ).toString()}/-`,
              offerPriceInNumbers: parseInt(
                prices["offerPrice"] * currencyValue
              ),
              crossedPriceInNumbers: parseInt(
                prices["crossedPrice"] * currencyValue
              ),
            }
            : price;
        } else {
          price = `${currencySymbol} ${Math.floor(price).toString()}`;
          return noOfPrices === 4
            ? {
              offerPrice: price,
              crossedPrice: `${currencySymbol} ${Math.floor(
                currencyValue * prices["crossedPrice"]
              ).toString()}`,
              offerPriceInNumbers: parseInt(
                prices["offerPrice"] * currencyValue
              ),
              crossedPriceInNumbers: parseInt(
                prices["crossedPrice"] * currencyValue
              ),
            }
            : price;
        }
      }
    }
  } catch (err) {
    console.log("Unable to fetch the price: getPrice()", err);
  }
};

export const splitCamelCase = (str) => {
  // Split camelCase string and join words with spaces
  const splitString = str.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize the first letter of each word
  const capitalizedString = splitString
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedString;
};

export const getPurchasesCount = async () => {
  try {
    let authToken = decryptText(localStorage.getItem("eMediat"));
    const response = await DashboardServices.getPurchasesCount(authToken);
    if (response.success) {
      return response.data;
    }
    return 0;
  } catch (err) {
    console.log("Error coming while fetching purchases count", err);
  }
};

export const checkFileExtension = (fileInputEvent) => {
  let validFile = false;
  try {
    if (
      fileInputEvent.target &&
      fileInputEvent.target.files &&
      fileInputEvent.target.files.length > 0
    ) {
      let file = fileInputEvent.target.files[0];
      let fileNameParts = file.name.split(".");
      let fileExtension = fileNameParts[fileNameParts.length - 1];
      fileExtension = fileExtension.toLowerCase();
      if (
        fileExtension === "png" ||
        fileExtension === "jpg" ||
        fileExtension === "jpeg"
      ) {
        validFile = true;
      }
    }
    return validFile;
  } catch (err) {
    console.log(
      "Error coming from libray->helper->index.js->checkFileExtension()",
      err
    );
  }
  return validFile;
};

export const getOrderDetailsByOrderId = async (orderId) => {
  try {
    let authToken = decryptText(localStorage.getItem("eMediat"));
    const orderDetails = await CheckoutServices.getOrderById(
      orderId,
      authToken
    );
    if (orderDetails.success) {
      return orderDetails;
    } else {
      alert(
        "Issue in getting order details, try again after refreshing the page"
      );
      return {};
    }
  } catch (err) {
    alert(
      "Issue in getting order details, try again after refreshing the page"
    );
    console.log("error from getOrderDetails()", err);
    return {};
  }
};

export const convertObjectToQueryString = (obj) => {
  const queryString = Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryString;
};

export const objectToQueryString = (obj, prefix = "") => {
  const params = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const paramKey = prefix
        ? `${prefix}[${encodeURIComponent(key)}]`
        : encodeURIComponent(key);
      if (value instanceof Array) {
        value.forEach((item, index) => {
          if (typeof item === "object") {
            params.push(objectToQueryString(item, `${paramKey}[${index}]`));
          } else {
            params.push(`${paramKey}[${index}]=${encodeURIComponent(item)}`);
          }
        });
      } else if (typeof value === "object") {
        params.push(objectToQueryString(value, paramKey));
      } else {
        params.push(`${paramKey}=${encodeURIComponent(value)}`);
      }
    }
  }
  return params.join("&");
};



export const getOfferDetailsFromUpdatedCourseDetails = (updatedCourseDetails) => {
  let offerDetails = {
    offerStartAt: '',
    offerEndAt: '',
  }
  const { days, hours, minutes } =
    updatedCourseDetails?.pricingDetails?.offerExpiry;
  offerDetails["offerStartAt"] = moment().toISOString();
  offerDetails["offerEndAt"] = calculateOfferEndDate(
    days,
    hours,
    minutes
  );
  return offerDetails;
}


export const calculateOfferEndDateWithStartDateAsInput = (updatedCourseDetails, offerStartAt) => {
  const { days, hours, minutes } = updatedCourseDetails?.pricingDetails?.offerExpiry;
  const endDate = moment(offerStartAt)
    .add(days, "day")
    .add(hours, "hour")
    .add(minutes, "minute")
    .toISOString();
  return endDate;
};

export const calculateOfferEndDateWithStartDateAsInputForUserSpecificOffer = (userSpecificOffer, offerStartAt) => {
  const { days, hours, minutes } = userSpecificOffer?.offerExpiry;
  const endDate = moment(offerStartAt)
    .add(days, "day")
    .add(hours, "hour")
    .add(minutes, "minute")
    .toISOString();
  return endDate;
};

export const compareObjects = (obj1, obj2) => {
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      return true;
    }
  }
  return false;
};

export const checkIfOfferHasChanged = (objFromLocalStorage, objFromDb, updatedCourseDetails) => {
  let updatedOfferDetails = {
    offerHasChanged: false,
    changedOfferStartAt: '',
    changedOfferEndAt: ''
  };
  if (compareObjects(objFromLocalStorage, objFromDb)) {
    const { offerStartAt, offerEndAt } = getOfferDetailsFromUpdatedCourseDetails(updatedCourseDetails);
    updatedOfferDetails = {
      offerHasChanged: true,
      changedOfferStartAt: offerStartAt,
      changedOfferEndAt: offerEndAt
    }
  }
  return updatedOfferDetails;
}


const getUserSpecificAllOffersAsPerCourse = (userSpecificOfferDetails, courseDetails) => {
  let userSpecificAllOffersAsPerCourse = {}
  for (let i = 0; i < userSpecificOfferDetails.length; i++) {
    if (userSpecificOfferDetails[i].courseId === courseDetails._id) {
      userSpecificAllOffersAsPerCourse = userSpecificOfferDetails[i]
      break
    }
  }
  return userSpecificAllOffersAsPerCourse
}

export const isFileUploadAllowedAsPerSize = (file) => {
  let isFileUploadAllowedAsPerSize = true
  if (file.size && file.size > 1 * 1024 * 1024) { // 1 MB in bytes
    isFileUploadAllowedAsPerSize = false
  }    
  return isFileUploadAllowedAsPerSize
}


export const handlePaidCourse = async (userSpecificOfferDetails, courseDetails, preFetchLocalDbCoursesList = []) => {
  try {
    // debugger
    const useThisUserSpecificOfferDetails = getUserSpecificAllOffersAsPerCourse(userSpecificOfferDetails, courseDetails)
    let updatedCourseDetails = { ...courseDetails };

    let jsonCheckDetails = {
      returnType: [],
    };
    let localDbCoursesList = preFetchLocalDbCoursesList;
    if (localDbCoursesList.length === 0) {
      const encryptedData = localStorage.getItem("crsl");
      if (encryptedData) {
        const decryptedData = decryptText(encryptedData, jsonCheckDetails);
        if (decryptedData && typeof decryptedData === "string") {
          try {
            localDbCoursesList = JSON.parse(decryptedData);
          } catch (error) {
            localStorage.removeItem("crsl");
            window.location.reload();
            // console.error("Parsing error:", error);
          }
        }
      }
    }

    const isExists = localDbCoursesList.filter(
      (item) =>
        item?._id?.toString() === updatedCourseDetails?._id?.toString()
    );


    if (isExists.length > 0) {
      let { isPurchasedCourse } = updatedCourseDetails;
      // updatedCourseDetails = isExists[0];

      let price = getPrice(updatedCourseDetails, 4);
      updatedCourseDetails["crossedPrice"] = price["crossedPrice"];
      updatedCourseDetails["crossedPriceInNumbers"] = price["crossedPriceInNumbers"];


      
      let { offerStartAt, offerEndAt, userSpecificOfferStartAt, userSpecificOfferId } = isExists[0];
      if (Object.entries(useThisUserSpecificOfferDetails).length > 0) {
        updatedCourseDetails["offerPrice"] = useThisUserSpecificOfferDetails["offerPrice"];
        updatedCourseDetails["offerPriceInNumbers"] = useThisUserSpecificOfferDetails["offerPriceInNumbers"];

        if(userSpecificOfferId !== useThisUserSpecificOfferDetails._id || userSpecificOfferStartAt === undefined){
          for (let i = 0; i <= localDbCoursesList.length; i++) {
            if (localDbCoursesList[i]._id === updatedCourseDetails._id) {
              localDbCoursesList[i].userSpecificOfferStartAt = useThisUserSpecificOfferDetails.offerStartAt
              localDbCoursesList[i].userSpecificOfferId = useThisUserSpecificOfferDetails._id
              localStorage.setItem(
                "crsl",
                encryptText(JSON.stringify(localDbCoursesList)) 
              );
              break
            }
          }
          userSpecificOfferStartAt = useThisUserSpecificOfferDetails.offerStartAt
        }


        offerEndAt = calculateOfferEndDateWithStartDateAsInputForUserSpecificOffer(useThisUserSpecificOfferDetails, userSpecificOfferStartAt)
        updatedCourseDetails['offerStartAt'] = userSpecificOfferStartAt;
        updatedCourseDetails['offerEndAt'] = offerEndAt;
      }
      else {
        updatedCourseDetails["offerPrice"] = price["offerPrice"];
        updatedCourseDetails["offerPriceInNumbers"] = price["offerPriceInNumbers"];
        offerEndAt = calculateOfferEndDateWithStartDateAsInput(updatedCourseDetails, offerStartAt)
        updatedCourseDetails['offerStartAt'] = offerStartAt;
        updatedCourseDetails['offerEndAt'] = offerEndAt;
      }

      updatedCourseDetails["isPurchasedCourse"] = isPurchasedCourse;


      const todayDate = moment().toISOString();
      // debugger
      if (moment(todayDate).isAfter(offerEndAt)) {
        if (isExists[0].pricingDetails && isExists[0].pricingDetails.offerExpiry && updatedCourseDetails.pricingDetails && updatedCourseDetails.pricingDetails.offerExpiry) {
          const objFromLocalStorage = isExists[0].pricingDetails.offerExpiry
          const objFromDb = updatedCourseDetails.pricingDetails.offerExpiry
          const { offerHasChanged, changedOfferStartAt, changedOfferEndAt } = checkIfOfferHasChanged(objFromLocalStorage, objFromDb, updatedCourseDetails)
          if (offerHasChanged) {
            updatedCourseDetails["offerStartAt"] = offerStartAt;
            updatedCourseDetails["offerEndAt"] = offerEndAt;
            updatedCourseDetails["isInOffer"] = true;
            for (let i = 0; i <= localDbCoursesList.length; i++) {
              if (localDbCoursesList[i]._id === updatedCourseDetails._id) {
                localDbCoursesList[i].offerStartAt = changedOfferStartAt
                localDbCoursesList[i].offerEndAt = changedOfferEndAt
                localDbCoursesList[i].pricingDetails.offerExpiry = objFromDb
                localStorage.setItem(
                  "crsl",
                  encryptText(JSON.stringify(localDbCoursesList)) 
                );
                break
              }
            }
            return updatedCourseDetails;
          }
        }

        updatedCourseDetails["isInOffer"] = false;
        return updatedCourseDetails;
      } else {
        updatedCourseDetails["isInOffer"] = true;
        return updatedCourseDetails;
      }
    } else {
      updatedCourseDetails["isInOffer"] = true;

      let price = getPrice(updatedCourseDetails, 4);
      updatedCourseDetails["crossedPrice"] = price["crossedPrice"];
      updatedCourseDetails["crossedPriceInNumbers"] = price["crossedPriceInNumbers"];

      if (Object.entries(useThisUserSpecificOfferDetails).length > 0) {
        updatedCourseDetails["offerPrice"] = useThisUserSpecificOfferDetails["offerPrice"];
        updatedCourseDetails["offerPriceInNumbers"] = useThisUserSpecificOfferDetails["offerPriceInNumbers"];
        updatedCourseDetails['offerStartAt'] = useThisUserSpecificOfferDetails['offerStartAt'];
        updatedCourseDetails['offerEndAt'] = useThisUserSpecificOfferDetails['offerEndAt'];
      } else {
        updatedCourseDetails["offerPrice"] = price["offerPrice"];
        updatedCourseDetails["offerPriceInNumbers"] = price["offerPriceInNumbers"];
        let offerDetailsFromUpdatedCourseDetails = getOfferDetailsFromUpdatedCourseDetails(updatedCourseDetails)
        updatedCourseDetails['offerStartAt'] = offerDetailsFromUpdatedCourseDetails['offerStartAt'];
        updatedCourseDetails['offerEndAt'] = offerDetailsFromUpdatedCourseDetails['offerEndAt'];
      }


      const todayDate = moment().toISOString();
      if (moment(todayDate).isAfter(updatedCourseDetails["offerEndAt"])) {
        updatedCourseDetails["isInOffer"] = false;
      }
      else {
        updatedCourseDetails["isInOffer"] = true;
      }
      // debugger

      localDbCoursesList.push(updatedCourseDetails);

      localStorage.setItem(
        "crsl",
        encryptText(JSON.stringify(localDbCoursesList)) //crsl
      );

      return updatedCourseDetails;
    }
  } catch (err) {
    console.log("Error coming from handlePaidCourse()", err);
  }
};


