import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
	blueBar1: {
		backgroundColor: "#293d68",
		height: "76px",
		width: "14px",
		marginTop: -30,
		marginLeft: -60,
		// transform: "skewY(-45deg)",
		transformOrigin: "left bottom",
	},

	redBar1: {
		backgroundColor: "#e21e23",
		height: "60px",
		width: "14px",
		marginLeft: -60,
		// transform: "skewY(-45deg)",
		transformOrigin: "left bottom",

	},

	blueBar2: {
		backgroundColor: "#293d68",
		height: "707px",
		width: "14px",
		marginTop: -2,
		marginLeft: -60,
		// transform: "skewY(-45deg)",
		transformOrigin: "right top",
	},
});
  
const Invoice = () => (
	<>
		<View style={styles.blueBar1} />
		<View style={styles.redBar1} />
		<View style={styles.blueBar2} />
	</>
);

export default Invoice;
