import moment from 'moment';

const initialState = {
	displayPhoto: null,
	username: '',
	firstName: '',
	lastName: '',
	dob: null,
	gender: null,
	email: '',
	country: '',
	countryCode: '',
	contactNumber: '',
	whatsAppNumber: null,
	whatsAppCountry: null,
	whatsAppCountryCode: null,
	isEmailVerified: false,
	isPersonalInfoCompleted: false,
	flatHouseNumber: null,
	buildingName: null,
	colonyName: null,
	areaName: null,
	landmark: null,
	city: null,
	userState: null,
	pincode: null,
	isAddressInfoCompleted: false,
	fullName: null,
	addharOrIdProof: null,
	marksheetOrDegreeCert: null,
	qualification: null,
	passportPhoto: null,
	fullNameStatus: 'unVerified',
	addharStatus: 'unVerified',
	marksheetStatus: 'unVerified',
	qualificationStatus: 'unVerified',
	passportPhotoStatus: 'unVerified',
	isIdentityInfoCompleted: false,
};

const BasicFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_DISPLAY_PHOTO':
			return {
				...state,
				displayPhoto: action.payload.displayPhoto,
			};
		case 'UPDATE_FIRST_NAME':
			return {
				...state,
				firstName: action.payload.firstName,
			};
		case 'UPDATE_LAST_NAME':
			return {
				...state,
				lastName: action.payload.lastName,
			};
		case 'UPDATE_DOB':
			return {
				...state,
				dob: moment(action.payload.dob).format('YYYY-MM-DD'),
			};
		case 'UPDATE_GENDER':
			return {
				...state,
				gender: action.payload.gender,
			};
		case 'UPDATE_EMAIL':
			return {
				...state,
				email: action.payload.email,
			};
		case 'UPDATE_COUNTRY':
			return {
				...state,
				country: action.payload.country,
			};
		case 'UPDATE_COUNTRY_CODE':
			return {
				...state,
				countryCode: action.payload.countryCode,
			};
		case 'UPDATE_CONTACT_NUMBER':
			return {
				...state,
				contactNumber: action.payload.contactNumber,
			};
		case 'UPDATE_WHATSAPP_NUMBER':
			return {
				...state,
				whatsAppNumber: action.payload.whatsAppNumber,
			};
		case 'UPDATE_WHATSAPP_COUNTRY':
			return {
				...state,
				whatsAppCountry: action.payload.whatsAppCountry,
			};
		case 'UPDATE_WHATSAPP_COUNTRY_CODE':
			return {
				...state,
				whatsAppCountryCode: action.payload.whatsAppCountryCode,
			};
		case 'UPDATE_FLAT_HOUSE_NUMBER':
			return {
				...state,
				flatHouseNumber: action.payload.flatHouseNumber,
			};
		case 'UPDATE_BUILDING_NAME':
			return {
				...state,
				buildingName: action.payload.buildingName,
			};
		case 'UPDATE_COLONY_NAME':
			return {
				...state,
				colonyName: action.payload.colonyName,
			};
		case 'UPDATE_AREA_NAME':
			return {
				...state,
				areaName: action.payload.areaName,
			};
		case 'UPDATE_LANDMARK':
			return {
				...state,
				landmark: action.payload.landmark,
			};
		case 'UPDATE_CITY':
			return {
				...state,
				city: action.payload.city,
			};
		case 'UPDATE_USER_STATE':
			return {
				...state,
				userState: action.payload.userState,
			};
		case 'UPDATE_PINCODE':
			return {
				...state,
				pincode: action.payload.pincode,
			};
		case 'UPDATE_FULL_NAME':
			return {
				...state,
				fullName: action.payload.fullName,
			};
		case 'UPDATE_MARKSHEET_OR_DEGREE_CERT':
			return {
				...state,
				marksheetOrDegreeCert: action.payload.marksheetOrDegreeCert,
			};
		case 'UPDATE_ADDHAR_OR_ID_PROOF':
			return {
				...state,
				addharOrIdProof: action.payload.addharOrIdProof,
			};
		case 'UPDATE_QUALIFICATION':
			return {
				...state,
				qualification: action.payload.qualification,
			};
		case 'UPDATE_PASSPORT_PHOTO':
			return {
				...state,
				passportPhoto: action.payload.passportPhoto,
			};
		case 'UPDATE_DETAILS':
			return {
				...state,
				displayPhoto: action.payload.details.displayPhoto,
				username: action.payload.details.username,
				firstName: action.payload.details.firstName,
				lastName: action.payload.details.lastName,
				dob: moment(action.payload.details.dob).format('YYYY-MM-DD'),
				gender: action.payload.details.gender,
				email: action.payload.details.email,
				country: action.payload.details.country,
				countryCode: action.payload.details.countryCode,
				contactNumber: action.payload.details.contactNumber,
				whatsAppNumber: action.payload.details.whatsAppNumber,
				whatsAppCountry: action.payload.details.whatsAppCountry,
				whatsAppCountryCode: action.payload.details.whatsAppCountryCode,
				isEmailVerified: action.payload.details.isEmailVerified,
				isPersonalInfoCompleted: action.payload.details.isPersonalInfoCompleted,
				isAddressInfoCompleted: action.payload.details.isAddressInfoCompleted,
				flatHouseNumber: action.payload.details.flatHouseNumber,
				buildingName: action.payload.details.buildingName,
				colonyName: action.payload.details.colonyName,
				areaName: action.payload.details.areaName,
				landmark: action.payload.details.landmark,
				city: action.payload.details.city,
				userState: action.payload.details.userState,
				pincode: action.payload.details.pincode,
				fullName: action.payload.details.fullName,
				addharOrIdProof: action.payload.details.addharOrIdProof,
				marksheetOrDegreeCert: action.payload.details.marksheetOrDegreeCert,
				qualification: action.payload.details.qualification,
				passportPhoto: action.payload.details.passportPhoto,
				fullNameStatus: action.payload.details.fullNameStatus,
				addharStatus: action.payload.details.addharStatus,
				marksheetStatus: action.payload.details.marksheetStatus,
				qualificationStatus: action.payload.details.qualificationStatus,
				passportPhotoStatus: action.payload.details.passportPhotoStatus,
				isIdentityInfoCompleted: action.payload.details.isIdentityInfoCompleted,
			};

		case 'RESET_BASIC_FORM_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default BasicFormReducer;
