import React from 'react';
import moment from 'moment';

import './style.css';

import Clock from '@assets/userDashboard/clock.png';
import BlueFilledHeart from '@assets/userDashboard/Icons/blueFilledHeart.png';

export default (props) => {
	const watchedVideosCount = Object.values(props.series.watchedVideos).filter(
		Boolean
	).length;

	const numberOfVideos = Object.values(props.series.watchedVideos).length;
	return (
		<div
			onClick={props.handleSeries}
			className={`seriesCard ${
				props.index % 2 !== 0 ? 'seriesCardOddMargin' : 'seriesCardEvenMargin'
			}`}>
			<div className='shineOnCourseCard col-4 col-sm-4 col-md-4 col-lg-3'>
				<img className='w-100' src={props.series.thumbnailUrl} alt='series' />
			</div>

			<div className='seriesInnerContainer col-8 col-sm-8 col-md-8 col-lg-9'>
				<p className='seriesTitle'>{props.series.title}</p>

				<p className='timerContainer'>
					<img src={BlueFilledHeart} alt='BlueFilledHeart' />
					{props.series.remainingDays} days remaining...
				</p>

				<p className='timerContainer'>
					<img src={Clock} alt='clock' />
					{props.series.seriesCompletedAt ? (
						<>
							<span>
								Completed on{' '}
								<b>{moment(props.series.seriesCompletedAt).format('Do MMMM YYYY')}</b>
							</span>
						</>
					) : props.series.seriesStatus === 'enrolled' ? (
						<>
							<b>Click/Tap</b> &nbsp;here to Start Series
						</>
					) : (
						<>
							Ends on &nbsp;
							<b>
								{moment(props.series.seriesEndAt)
									.add(props.series.remainingDays, 'days')
									.format('Do MMMM YYYY')}
							</b>
						</>
					)}
				</p>

				<div className='seriesProgressBar'>
					<div className='childX progressX'></div>
					<div
						style={{
							width: 100 - (watchedVideosCount / numberOfVideos) * 100 + '%',
						}}
						className='childX shrinkerX timelapseX'></div>
				</div>

				<div className='progressStatus'>
					<p
						className={
							(watchedVideosCount / numberOfVideos) * 100 <= 20
								? 'd-none progressStatus0'
								: 'd-inline-block progressStatus0'
						}>
						0%
					</p>

					<p
						className='d-inline-block'
						style={{
							color: '#028C34',
							marginLeft:
								(watchedVideosCount / numberOfVideos) * 100 - 10 > 0
									? (watchedVideosCount / numberOfVideos) * 100 - 7 + '%'
									: '0%',
						}}>
						{Math.round((watchedVideosCount / numberOfVideos) * 100)}%
					</p>

					<p
						style={{marginTop:'4px'}}
						className={
							(watchedVideosCount / numberOfVideos) * 100 >= 85
								? 'd-none progressStatus100 float-end'
								: 'd-inline-block progressStatus100 float-end'
						}>
						100%
					</p>
				</div>
			</div>
		</div>
	);
};
