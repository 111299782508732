export const applyCoupon = async (couponCode, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/applyCoupon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        couponCode: couponCode,
      }),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Checkout => applyCoupon services",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

// this function is not in use removed as webhook is introducted
export const stripePaymentCaptured = async (
  confirmPaymentDetails,
  authToken
) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/stripe/paymentCaptured`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(confirmPaymentDetails),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Checkout => stripePaymentCaptured fn",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const createOrderForStripe = async (paymentIntent, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(paymentIntent),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Checkout => createOrder services, createOrderForStripe fn",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const createOrder = async (paymentDetails, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/razorpay/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        amount: paymentDetails.amount,
        currency: paymentDetails.currency,
        paymentMethod: paymentDetails.paymentMethod,
      }),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Checkout => createOrder services",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const verifyPayment = async (paymentDetails, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/razorpay/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        razorpayOrderId: paymentDetails.razorpayOrderId,
        razorpayPaymentId: paymentDetails.razorpayPaymentId,
        razorpaySignature: paymentDetails.razorpaySignature,
      }),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Checkout => verifyPayment services",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const addFreeOrder = async (orderDetails, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/addFreeOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(orderDetails),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Checkout => addOrder services", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const addToPurchased = async (courseIds, orderId, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/addToPurchases`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        courseIds: courseIds,
        orderId: orderId,
      }),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Checkout => addOrder services", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getOrderById = async (orderId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getOrderById/${orderId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Checkout => getOrderById()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getCCAvenuePaymentLink = async (payload, authToken) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ccAvenuePaymentGateway`,
      {
        method: "POST",
        headers: {
          ContentType: "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    );

    if (response.ok) {
      return await response.text(); // Return the response as text
    } else {
      throw new Error("Failed to fetch payment link");
    }
  } catch (error) {
    console.error(
      "Coming from Checkout => getHdfcPaymentLink()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const ccAvenueResponseHandler = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/CCAvenueResponse`,
      {
        method: "POST",
        headers: {
          ContentType: "application/json",
        },
      }
    );

    if (response.ok) {
      return await response.text(); // Return the response as text
    } else {
      throw new Error("Failed to fetch payment link");
    }
  } catch (error) {
    console.error(
      "Coming from Checkout => getHdfcPaymentLink()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
