import React from 'react';
import Typewriter from 'typewriter-effect';
import Content from '@content/Home';

const TypewriterX = () => {
  return (
      <h1>
        How should I learn
        <Typewriter
          options={{
            strings: Content.section2.titles,
            autoStart: true,
            loop: true,
            cursor: '|',
          }}
        />
      </h1>
  );
};

export default TypewriterX;
