import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrderDetailsByOrderId } from "@library/helpers";
import ClaimPopupView from "./view";

import { decryptText } from "@library/enc-dec";
import * as TimelineServices from "@services/UserDashboard/Timeline";

import CertificatePlaceHolder from "@assets/timeline/icons/certificatePlaceHolder.png";

function ClaimPopup(props) {
  const navigate = useNavigate();
  const [finalAmt, setFinalAmt] = useState(0);
  const [numberOfCertificates, setNumberOfCertificates] = useState(0);
  const [certificateDetails, setCertificateDetails] = useState([]);
  const modalRef = useRef(null);
  const totalNoOfCertificate = 3;
  const timelineData = useSelector((state) => state.dashboard.timelineData);
  const [claimBtnLoading, setClaimBtnLoading] = useState(false);

  useEffect(() => {
    updateCertificateDetails();
    listenerForModalClosing();
  }, []);

  const listenerForModalClosing = () => {
    const modalElement = modalRef.current;
    modalElement.addEventListener("hidden.bs.modal", resetAllValues);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", resetAllValues);
    };
  };

  const resetAllValues = () => {
    handleCertificateChange(0, 0);
    setFinalAmt(0);
    setNumberOfCertificates(0);
  };

  const updateCertificateDetails = () => {
    let certificateImg = CertificatePlaceHolder;
    let certificatePrice = 1500;

    let dummyCertificateDetails = [];
    for (let i = 0; i < totalNoOfCertificate; i++) {
      let x = {
        img: certificateImg,
        price: certificatePrice * i,
      };
      dummyCertificateDetails.push(x);
    }
    setCertificateDetails(dummyCertificateDetails);
  };

  const handleCertificateChange = (index, price) => {
    setFinalAmt(price);
    setNumberOfCertificates(index);
    let defaultId = "certificateContainer_";
    let currentId = defaultId + index;
    for (let i = 0; i < totalNoOfCertificate; i++) {
      let eleId = "certificateContainer_" + i;
      let ele = document.getElementById(eleId);
      if (ele) {
        if (eleId === currentId) {
          ele.classList.add("blueBorder");
        } else {
          ele.classList.remove("blueBorder");
        }
      }
    }
  };

  const claimCertificate = async () => {
    try {
      if (numberOfCertificates === 0) {
        if (timelineData && timelineData._id) {
          setClaimBtnLoading(true);
          const purchaseId = timelineData._id;
          const authToken = decryptText(localStorage.getItem("eMediat"));
          const updateCertificateResponse =
            await TimelineServices.updateCertificateDetailsAsPurchaseId(
              purchaseId,
              1,
              authToken
            );
          if (updateCertificateResponse.success) {
            window.location.reload();
          } else {
            alert(
              "Issue in updating certificate details, try refreshing the page."
            );
          }
        }
      } else {
        if (
          props.claimCertificateClaimPopupCloseBtn &&
          props.claimCertificateClaimPopupCloseBtn.current
        ) {
          props.claimCertificateClaimPopupCloseBtn.current.click();
        }
        const orderId = timelineData.orderId;
        let orderDetails = await getOrderDetailsByOrderId(orderId);
        if (Object.entries(orderDetails).length > 0) {
          orderDetails.data.grandTotal = finalAmt;
          navigate("/otherPay", {
            state: {
              source: {
                expenseType: "certificate",
                numberOfCertificates: numberOfCertificates + 1,
              },
              purchaseDetails: timelineData,
              orderDetails: orderDetails.data,
            },
          });
        } else {
          alert(
            "Issue in getting order details, try again after refreshing the page"
          );
        }
      }
    } catch (err) {
      console.log("Error coming from claimCertificate", err);
    }
  };

  return (
    <>
      <ClaimPopupView
        claimCertificateClaimPopupCloseBtn={
          props.claimCertificateClaimPopupCloseBtn
        }
        modalRef={modalRef}
        certificateDetails={certificateDetails}
        claimCertificate={claimCertificate}
        handleCertificateChange={handleCertificateChange}
        finalAmt={finalAmt}
        claimBtnLoading={claimBtnLoading}
        {...props}
      />
    </>
  );
}

export default ClaimPopup;
