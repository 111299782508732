import React, { useState, useEffect } from 'react';

import SupportView from './view';

function Support() {
	const staticData = [
		{
			heading: 'Contact Support',
			text: 'Problems? Get needed solutions',
			link: '/request-a-call',
		},
		{
			heading: 'Terms and Conditions',
			text: 'View our terms of service..',
			link: '/articles',
		},
	];
	return (
		<>
			<SupportView staticData={staticData} />
		</>
	);
}

export default Support;
