import React from 'react';

import './style.css';
import CourseCard from '@pages/UserDashboard/MyLearning/Timeline/CourseCard';
import Support from '@pages/UserDashboard/MyLearning/Timeline/Support';
import DesktopLoader from '@pages/UserDashboard/MyLearning/Timeline/TimelineContentLoaders/desktopLoader.js';
import MobileLoader from '@pages/UserDashboard/MyLearning/Timeline/TimelineContentLoaders/mobileLoader.js';

import SectionAccordance from '@pages/UserDashboard/MyLearning/Timeline/SectionAccordance';
import timelineHeadingLeftArrow from '@assets/timeline/icons/timelineHeadingLeftArrow.png';

import { useSelector } from 'react-redux';

function Timeline(props) {
	const timeLineData = useSelector((state) => state.dashboard.timelineData);
	const course = useSelector((state) => state.dashboard.course);

	return (
		<>
			{props.isLoading ? (
				<>
					{window.innerWidth > 768 ? (
						<DesktopLoader />
					) : (
						<MobileLoader />
					)}
				</>
				
			) : (
				<div className='timelineMaincontainer'>
					<div className='dtopHeadingContainer'>
						<div className='itopHeadingContainer'>
							<img src={timelineHeadingLeftArrow} alt='timelineHeadingLeftArrow' onClick={props.handleNavigate} />
							<p>Timeline</p>
							<img
								style={{ visibility: ' hidden' }}
								src={timelineHeadingLeftArrow}
								alt='timelineHeadingLeftArrow'
							/>
						</div>
					</div>

					<div className='timelineContentContainer timelineSpaceContainer'>
						<div className='courseTabMobileContainer col-12 d-flex d-md-none tcard'>
							<CourseCard />
						</div>

						<div className='contentMainContainer row'>
							<div className='contentMainContainer_o1'>
								<div
									id='allDetailsAccordionFlush'
									className='allDetailsAccordionFlushContainer'>
									{/* enrolled component */}
									{timeLineData && (
										<SectionAccordance
											topRightCusBorderRadius='true'
											bottomLeftCusBorderRadius='false'
											topLeftCusBorderRadius='true'
											bottomRightCusBorderRadius='false'
											lineHelper='true'
											lastEle='false'
											tabName='enrolled'
											accordanceConfig={{
												"success": timeLineData ? true : false,
												"paymentMethod": timeLineData.paymentMethod,
												"createdAt": timeLineData.createdAt
											}}
										/>
									)}
									{/* enrolled component */}

									{/* completeProfile component */}
									{timeLineData && (
										<SectionAccordance
											topRightCusBorderRadius='false'
											bottomLeftCusBorderRadius='false'
											topLeftCusBorderRadius='false'
											bottomRightCusBorderRadius='false'
											lineHelper='false'
											lastEle='false'
											tabName='completeProfile'
											accordanceConfig={{
												submittedOn: course?.verificationSubmittedAt,
												verifiedOn: course?.verifiedAt,
												rejected: course?.profileActionRequired,
											}}
										/>
									)}
									{/* completeProfile component */}

									{/* watchContent component */}
									{course && (
										<SectionAccordance
											topRightCusBorderRadius='false'
											bottomLeftCusBorderRadius='false'
											topLeftCusBorderRadius='false'
											bottomRightCusBorderRadius='false'
											lineHelper='false'
											lastEle='false'
											tabName='watchContent'
											accordanceConfig={{
												superLock: ((timeLineData?.userDetails?.isBasicDetailsRequired ? timeLineData?.userDetails?.isPersonalInfoCompleted : true)
													&& (timeLineData?.userDetails?.isIdentityVerificationRequired ? timeLineData?.userDetails?.isIdentityInfoCompleted : true)
													&& (timeLineData?.userDetails?.isDeliveryAddressRequired ? timeLineData?.userDetails?.isAddressInfoCompleted : true)
													&& (timeLineData?.userDetails?.isLegalInfoRequired ? timeLineData?.userDetails?.isLegalInfoCompleted : true)
													&& (timeLineData?.userDetails?.isBSSDetailsRequired ? timeLineData?.bssFormDetails?.isBssInfoCompleted : true)) ? false : true,
												lock: false,
												completedOn: timeLineData?.courseCompletedAt,
												exemptedOn: timeLineData?.courseEndAt,
											}}
										/>
									)}
									{/* watchContent component */}

									{/* finalExam component */}
									{course && (
										<SectionAccordance
											topRightCusBorderRadius='false'
											bottomLeftCusBorderRadius='false'
											topLeftCusBorderRadius='false'
											bottomRightCusBorderRadius='false'
											lineHelper='false'
											lastEle='false'
											tabName='finalExam'
											accordanceConfig={{
												superLock: ((timeLineData?.userDetails?.isBasicDetailsRequired ? timeLineData?.userDetails?.isPersonalInfoCompleted : true)
													&& (timeLineData?.userDetails?.isIdentityVerificationRequired ? timeLineData?.userDetails?.isIdentityInfoCompleted : true)
													&& (timeLineData?.userDetails?.isDeliveryAddressRequired ? timeLineData?.userDetails?.isAddressInfoCompleted : true)
													&& (timeLineData?.userDetails?.isLegalInfoRequired ? timeLineData?.userDetails?.isLegalInfoCompleted : true)
													&& (timeLineData?.userDetails?.isBSSDetailsRequired ? timeLineData?.bssFormDetails?.isBssInfoCompleted : true)) ? false : true,
												lock: timeLineData?.examStatus === 'locked' ? true : false,
												underReview: false,
												ineligible: timeLineData?.eligibleExams?.includes(timeLineData?.userDetails?.underGraduateDegree ? timeLineData?.userDetails?.underGraduateDegree : timeLineData?.userDetails?.qualification) ? false : true,
												completedOn: timeLineData?.examSubmittedAt ? timeLineData?.examSubmittedAt : '',
												exemptedOn: course?.courseEndAt,
											}}
										/>
									)}
									{/* finalExam component */}

									{/* claimCertification component */}
									{timeLineData && (
										<SectionAccordance
											topRightCusBorderRadius='false'
											bottomLeftCusBorderRadius='true'
											topLeftCusBorderRadius='false'
											bottomRightCusBorderRadius='true'
											lineHelper='false'
											lastEle='true'
											tabName='claimCertification'
											inView={props.inView}
											handleInview={props.handleInview}
											accordanceConfig={{
												"success": true,
												"superLock": ((timeLineData?.userDetails?.isBasicDetailsRequired ? timeLineData?.userDetails?.isPersonalInfoCompleted : true)
													&& (timeLineData?.userDetails?.isIdentityVerificationRequired ? timeLineData?.userDetails?.isIdentityInfoCompleted : true)
													&& (timeLineData?.userDetails?.isDeliveryAddressRequired ? timeLineData?.userDetails?.isAddressInfoCompleted : true)
													&& (timeLineData?.userDetails?.isLegalInfoRequired ? timeLineData?.userDetails?.isLegalInfoCompleted : true)
													&& (timeLineData?.userDetails?.isBSSDetailsRequired ? timeLineData?.bssFormDetails?.isBssInfoCompleted : true)) ? false : true,
												"lock": timeLineData?.certificateStatus === null,
												"underReview": timeLineData?.certificateStatus === 'underReview',
												"ineligible": timeLineData?.eligibleCertificates?.includes(timeLineData?.userDetails?.underGraduateDegree ? timeLineData?.userDetails?.underGraduateDegree : timeLineData?.userDetails?.qualification) ? false : true,
												"claimedOn": timeLineData?.certificateClaimedAt ? timeLineData.certificateClaimedAt : '',
												"lockHeading": "Lockeds",
												"lockText": "Lockedx",
											}}
										/>
									)}

									{/* 
	1. inElligible => is Profession is in elligibleCertificateList -done
	2. superLock => if WatchContent is superlocked then finalExam also superlocked -
		Locked => if WatchContent is locked then finalExam also locked
	But if the SeriesWatch percentage is not crossed then mark as super locked
	or exmptedDays not Cross then mark as superlock

	3. Claimedon => ClaimedAt  ( in purchase Collection ) 
*/}
									{/* claimCertification component */}
								</div>
							</div>

							<div className='contentMainContainer_o2 d-none d-md-block'>
								<div className='tcard'>
									<CourseCard courseData={props.courseData} />
								</div>
								<div className='contentMainContainer_o3' id="supportCardInTimeLineMainContainer">
									<Support />
								</div>
							</div>
						</div>

						<div className='contentFooterContainer col-12 d-flex d-md-none'>
							<Support />
						</div>
					</div>
				</div>
			)}
		</>



		// 		<div className='timelineMaincontainer'>
		// 			<div className='dtopHeadingContainer'>
		// 				<div className='itopHeadingContainer'>
		// 					<img src={timelineHeadingLeftArrow} alt='timelineHeadingLeftArrow' onClick={props.handleNavigate} />
		// 					<p>Timeline</p>
		// 					<img
		// 						style={{ visibility: ' hidden' }}
		// 						src={timelineHeadingLeftArrow}
		// 						alt='timelineHeadingLeftArrow'
		// 					/>
		// 				</div>
		// 			</div>

		// 			<div className='timelineContentContainer timelineSpaceContainer'>
		// 				<div className='courseTabMobileContainer col-12 d-flex d-md-none tcard'>
		// 					<CourseCard />
		// 				</div>

		// 				<div className='contentMainContainer row'>
		// 					<div className='contentMainContainer_o1'>
		// 						<div
		// 							id='allDetailsAccordionFlush'
		// 							className='allDetailsAccordionFlushContainer'>
		// 							{/* enrolled component */}
		// 							{timeLineData && (
		// 								<SectionAccordance
		// 									topRightCusBorderRadius='true'
		// 									bottomLeftCusBorderRadius='false'
		// 									topLeftCusBorderRadius='true'
		// 									bottomRightCusBorderRadius='false'
		// 									lineHelper='true'
		// 									lastEle='false'
		// 									tabName='enrolled'
		// 									accordanceConfig={{
		// 										"success": timeLineData ? true : false,
		// 										"paymentMethod": timeLineData.paymentMethod,
		// 										"createdAt": timeLineData.createdAt
		// 									}}
		// 								/>
		// 							)}
		// 							{/* enrolled component */}

		// 							{/* completeProfile component */}
		// 							{timeLineData && (
		// 								<SectionAccordance
		// 									topRightCusBorderRadius='false'
		// 									bottomLeftCusBorderRadius='false'
		// 									topLeftCusBorderRadius='false'
		// 									bottomRightCusBorderRadius='false'
		// 									lineHelper='false'
		// 									lastEle='false'
		// 									tabName='completeProfile'
		// 									accordanceConfig={{
		// 										submittedOn: course?.verificationSubmittedAt,
		// 										verifiedOn: course?.verifiedAt,
		// 										rejected: course?.profileActionRequired,
		// 									}}
		// 								/>
		// 							)}
		// 							{/* completeProfile component */}

		// 							{/* watchContent component */}
		// 							{course && (
		// 								<SectionAccordance
		// 									topRightCusBorderRadius='false'
		// 									bottomLeftCusBorderRadius='false'
		// 									topLeftCusBorderRadius='false'
		// 									bottomRightCusBorderRadius='false'
		// 									lineHelper='false'
		// 									lastEle='false'
		// 									tabName='watchContent'
		// 									accordanceConfig={{
		// 										superLock: ((timeLineData?.userDetails?.isBasicDetailsRequired ? timeLineData?.userDetails?.isPersonalInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isIdentityVerificationRequired ? timeLineData?.userDetails?.isIdentityInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isDeliveryAddressRequired ? timeLineData?.userDetails?.isAddressInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isLegalInfoRequired ? timeLineData?.userDetails?.isLegalInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isBSSDetailsRequired ? timeLineData?.bssFormDetails?.isBssInfoCompleted : true)) ? false : true,
		// 										lock: false,
		// 										completedOn: timeLineData?.courseCompletedAt,
		// 										exemptedOn: timeLineData?.courseEndAt,
		// 									}}
		// 								/>
		// 							)}
		// 							{/* watchContent component */}

		// 							{/* finalExam component */}
		// 							{course && (
		// 								<SectionAccordance
		// 									topRightCusBorderRadius='false'
		// 									bottomLeftCusBorderRadius='false'
		// 									topLeftCusBorderRadius='false'
		// 									bottomRightCusBorderRadius='false'
		// 									lineHelper='false'
		// 									lastEle='false'
		// 									tabName='finalExam'
		// 									accordanceConfig={{
		// 										superLock: ((timeLineData?.userDetails?.isBasicDetailsRequired ? timeLineData?.userDetails?.isPersonalInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isIdentityVerificationRequired ? timeLineData?.userDetails?.isIdentityInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isDeliveryAddressRequired ? timeLineData?.userDetails?.isAddressInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isLegalInfoRequired ? timeLineData?.userDetails?.isLegalInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isBSSDetailsRequired ? timeLineData?.bssFormDetails?.isBssInfoCompleted : true)) ? false : true,
		// 										lock: timeLineData?.examStatus === 'locked' ? true : false,
		// 										underReview: false,
		// 										ineligible: timeLineData?.eligibleExams?.includes(timeLineData?.userDetails?.underGraduateDegree ? timeLineData?.userDetails?.underGraduateDegree : timeLineData?.userDetails?.qualification) ? false : true,
		// 										completedOn: timeLineData?.examSubmittedAt ? timeLineData?.examSubmittedAt : '',
		// 										exemptedOn: course?.courseEndAt,
		// 									}}
		// 								/>
		// 							)}
		// 							{/* finalExam component */}

		// 							{/* claimCertification component */}
		// 							{timeLineData && (
		// 								<SectionAccordance
		// 									topRightCusBorderRadius='false'
		// 									bottomLeftCusBorderRadius='true'
		// 									topLeftCusBorderRadius='false'
		// 									bottomRightCusBorderRadius='true'
		// 									lineHelper='false'
		// 									lastEle='true'
		// 									tabName='claimCertification'
		// 									inView={props.inView}
		// 									handleInview={props.handleInview}
		// 									accordanceConfig={{
		// 										"success": true,
		// 										"superLock": ((timeLineData?.userDetails?.isBasicDetailsRequired ? timeLineData?.userDetails?.isPersonalInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isIdentityVerificationRequired ? timeLineData?.userDetails?.isIdentityInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isDeliveryAddressRequired ? timeLineData?.userDetails?.isAddressInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isLegalInfoRequired ? timeLineData?.userDetails?.isLegalInfoCompleted : true)
		// 											&& (timeLineData?.userDetails?.isBSSDetailsRequired ? timeLineData?.bssFormDetails?.isBssInfoCompleted : true)) ? false : true,
		// 										"lock": timeLineData?.certificateStatus === null,
		// 										"underReview": timeLineData?.certificateStatus === 'underReview',
		// 										"ineligible": timeLineData?.eligibleCertificates?.includes(timeLineData?.userDetails?.underGraduateDegree ? timeLineData?.userDetails?.underGraduateDegree : timeLineData?.userDetails?.qualification) ? false : true,
		// 										"claimedOn": timeLineData?.certificateClaimedAt ? timeLineData.certificateClaimedAt : '',
		// 										"lockHeading": "Locked",
		// 										"lockText": "Locked",
		// 									}}
		// 								/>
		// 							)}

		// 							{/* 
		// 	1. inElligible => is Profession is in elligibleCertificateList -done
		// 	2. superLock => if WatchContent is superlocked then finalExam also superlocked -
		// 		Locked => if WatchContent is locked then finalExam also locked
		// 	But if the SeriesWatch percentage is not crossed then mark as super locked
		// 	or exmptedDays not Cross then mark as superlock

		// 	3. Claimedon => ClaimedAt  ( in purchase Collection ) 
		// */}
		// 							{/* claimCertification component */}
		// 						</div>
		// 					</div>

		// 					<div className='contentMainContainer_o2 d-none d-md-block'>
		// 						<div className='tcard'>
		// 							<CourseCard courseData={props.courseData} />
		// 						</div>
		// 						<div className='contentMainContainer_o3'>
		// 							<Support />
		// 						</div>
		// 					</div>
		// 				</div>

		// 				<div className='contentFooterContainer col-12 d-flex d-md-none'>
		// 					<Support />
		// 				</div>
		// 			</div>
		// 		</div>
	);
}

export default Timeline;
