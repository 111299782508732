import { configureStore } from '@reduxjs/toolkit';

import LoginReducer from '@redux/reducers/Login';
import SignUpReducer from '@redux/reducers/SignUp';

import Navbar from '@redux/reducers/Navbar';
import OTPReducer from '@redux/reducers/OTP';

import TopLoadingBar from '@redux/reducers/TopLoadingBar';

import HomeReducer from '@redux/reducers/Home';
import CourseReducer from '@redux/reducers/Course';
import ExploreReducer from '@redux/reducers/Explore';

import UserDashboardReducer from '@redux/reducers/UserDashboard';
import BasicFormReducer from '@redux/reducers/BasicForm';

import CartReducer from '@redux/reducers/Cart';
import CheckoutReducer from '../reducers/Checkout';
import ConfirmOrder from '@redux/reducers/ConfirmOrder';
import CourseDetailsReducer from '../reducers/CourseDetails';
import SeriesDetailsReducer from '../reducers/SeriesDetails';
import TermsAndConditionsReducer from '../reducers/TermsAndConditions';
import PayReducer from '../reducers/Pay';
import ExamReducer from "@redux/reducers/Exam";
import BackUpCartReducer from '@redux/reducers/BackUpCart';
import QualificationsReducer from '@redux/reducers/Qualifications';
import PageViewReducer from '@redux/reducers/PageView';
import TimelineRedirectReducer from '@redux/reducers/TimelineRedirect';
import UserSpecificOfferReducers from '@redux/reducers/UserSpecificOffer';


const store = configureStore({
	reducer: {
		login: LoginReducer,
		signUp: SignUpReducer,
		topLoadingBar: TopLoadingBar,
		navbar: Navbar,
		otp: OTPReducer,
		home: HomeReducer,
		course: CourseReducer,
		explore: ExploreReducer,
		basicForm: BasicFormReducer,
		dashboard: UserDashboardReducer,
		cart: CartReducer,
		backUpCart:BackUpCartReducer,
		qualifications:QualificationsReducer,
		userSpecificOffer:UserSpecificOfferReducers,
		pageView:PageViewReducer,
		timelineRedirect:TimelineRedirectReducer,
		confirmOrder: ConfirmOrder,
		exam: ExamReducer,
		checkout: CheckoutReducer,
		courseDetails: CourseDetailsReducer,
		seriesDetails: SeriesDetailsReducer,
		termsAndConditions: TermsAndConditionsReducer,
		pay: PayReducer,
	},
});

export default store;
