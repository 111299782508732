import React from 'react';
import AgreePopup from '../PopUps/AgreePopup'
import ClaimPopup from '../PopUps/ClaimPopup'
import './style.css';

function ClaimCertificateView(props) {

	// for testing claim certificate put this in any condition
	// content = (
	// 	<>
	// 		<p className='heading headingPink'>Congratulations! 🎉</p>
	// 		<p className='subHeading'>You have successfully completed the course and unlocked the certificate. Please confirm whether your delivery address is correct and claim your certificate!</p>
	// 		<button 
	// 			onClick={()=>{props.handleClaimCertificate()}}
	// 			className='certificateBtn certificateBtnBlueColor'>
	// 			Claim your certificate
	// 		</button>
	// 	</>
	// );

	let content = null;
	if (props.status && props.status.success) {
		if (props.status.superLock) {
			content = (
				<>
					<p className='heading headingRed'>Awaiting exam! 🤔</p>
					<p className='subHeading'>Complete the final examination to unlock certificate </p>
					<button className='certificateBtn certificateBtnGrayColor'>
						Claim your certificate
					</button>
				</>
			);
		} else if (props.status.lock) {
			content = (
				<>
					<p className='heading headingRed'>Awaiting exam! 🤔</p>
					<p className='subHeading'>Complete the final examination to unlock certificate </p>
					{/* <p className='heading headingRed'>{props.status.lockHeading}</p>
					<p className='subHeading'>{props.status.lockText}</p> */}
					<button className='certificateBtn certificateBtnGrayColor'>
						Claim your certificate
					</button>
				</>
			);
		} else if (props.status.underReview) {
			content = (
				<>
					<p className='heading headingNavyBlue'>Your profile is under review</p>
					<button className='certificateBtn certificateBtnGrayColor'>
						Claim your certificate
					</button>
				</>
			);
		} else if (props.status.ineligible) {
			content = (
				<>
					<p className='heading headingRed'>You are not eligible to claim certificate</p>
				</>
			);
		} else if (props.status.claimedOn !== "") {
			content = (
				<>
					<p className='heading headingGreen'>Certificate Claimed! ✅</p>
				</>
			);
		} else {
			content = (
				<>
					<p className='heading headingPink'>Congratulations! 🎉</p>
					<p className='subHeading'>You have successfully completed the course and unlocked the certificate. Please confirm whether your delivery address is correct and claim your certificate!</p>
					<button 
						onClick={()=>{props.handleClaimCertificate()}}
						className='certificateBtn certificateBtnBlueColor'>
						Claim your certificate
					</button>
				</>
			);
		}
	}

	return (
		<>
			<div className='claimCertificateMainContainer pb-4 mb-2'>
				{content !== null &&
					content
				}
			</div>

			<AgreePopup 
				handleAgreement={props.handleAgreement}
				claimCertificateAgreePopupOpenBtn={props.claimCertificateAgreePopupOpenBtn}
				claimCertificateAgreePopupCloseBtn={props.claimCertificateAgreePopupCloseBtn}
				/>

			<ClaimPopup 
				claimCertificateClaimPopupCloseBtn={props.claimCertificateClaimPopupCloseBtn}
				claimCertificateClaimPopupOpenBtn={props.claimCertificateClaimPopupOpenBtn}
				/>
			
		</>
	);
}

export default ClaimCertificateView;
