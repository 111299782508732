import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import Vimeo from '@vimeo/player';

import VideoPlayerView from './view';

import PlayIcon from '@assets/videoPlayer/playIcon.png';
import PauseIcon from '@assets/videoPlayer/pauseIcon.png';
import centerPlayIcon from '@assets/videoPlayer/centerPlayIcon.png';
import centerPauseIcon from '@assets/videoPlayer/centerPauseIcon.png';
import VolumeIcon from '@assets/videoPlayer/volumeIcon.png';
import MuteIcon from '@assets/videoPlayer/muteIcon.png';
import CcOnIcon from '@assets/videoPlayer/ccOnIcon.png';
import CcOffIcon from '@assets/videoPlayer/ccOffIcon.png';
import Speed_025 from '@assets/videoPlayer/speed_025.png';
import Speed_05 from '@assets/videoPlayer/speed_05.png';
import Speed_075 from '@assets/videoPlayer/speed_075.png';
import Speed_1 from '@assets/videoPlayer/speed_1.png';
import Speed_125 from '@assets/videoPlayer/speed_125.png';
import Speed_15 from '@assets/videoPlayer/speed_15.png';
import Speed_175 from '@assets/videoPlayer/speed_175.png';
import Speed_2 from '@assets/videoPlayer/speed_2.png';

import { useDoubleTap } from 'use-double-tap';

// import { handleMobileThreedots } from './mobileViewFunctions.js';

import {
	handleResize,
	handleScroll,
	adjustPlayListMainContainer,
} from '@library/adjustUIOfVideoPlayerList';

let volumeStore = null;
let previousVolume = 0;
let ccFlag = false; // CC is off
let previousSelectedSpeed = 1;
let previousSelectedQuality = 'auto';
let getNewVideo = 0;

function VideoPlayer(props) {
	const {
		videoId,
		IsVideoPlayerRenderend,
		sendVideoDuration,
		handleVideoIdChanged,
		handleNextVideo,
	} = props;

	const { identifier, id } = useParams();

	const playerRef = useRef(null);
	const videoPlayerRef = useRef(null);

	//START : States for playpause
	const [playPauseIcon, setPlayPauseIcon] = useState(null);
	// Flag variable for view count

	//END : States for playpause

	//START : States to handle video time fns
	const timeBarRef = useRef(null);
	const [timeBarChangePer, setTimeBarChangePer] = useState('0%');
	const [currentVideoTime, setCurrentVideoTime] = useState('00:00');
	const [videoDuration, setVideoDuration] = useState('00:00');
	const [currentVideoDurationInSeconds, setCurrentVideoDurationInSeconds] =
		useState(0);
	let maxVideoDuration = 0;
	//END : States to handle video time fns

	//START : States to handle volume of the video
	const [volumeBarChangePer, setVolumeBarChangePer] = useState('0%');
	const [volumeBarChangedValue, setVolumeBarChangedValue] = useState(1);
	const [volumeIcon, setVolumeIcon] = useState('');
	//END : States to handle volume of the video

	const [isFullScreen, setIsFullScreen] = useState(false);

	//START : States to handle CC
	const [isCaption, setIsCaption] = useState('none');
	const [isHasCaptions, setIsHasCaptions] = useState(false);
	const [ccIcon, setCCIcon] = useState(CcOffIcon);
	//START : States to handle CC

	//START : States to handle speed
	const speedContainerRef = useRef(null);
	const [is_SpeedContainerRef_Visible, set_Is_SpeedContainerRef_Visible] =
		useState(false);
	const [speedContainerRef_Position, set_SpeedContainerRef_Position] =
		useState(0);
	const [speedValue, setSpeedValue] = useState(1.25);
	const [speedIcon, setSpeedIcon] = useState('');
	//END : States to handle speed

	//START : States to qty speed
	const qtyContainerRef = useRef(null);
	const [is_QtyContainerRef_Visible, set_Is_QtyContainerRef_Visible] =
		useState(false);
	const [qtyContainerRef_Position, set_QtyContainerRef_Position] = useState(0);
	const [qtyValue, setQtyValue] = useState('auto');
	//END : States to qty speed

	// START : States to handle visibility of controls
	const [mouseInOut, setMouseInOut] = useState(false);
	// START : States to handle visibility of controls

	const [videoTimeBarChangedValue, setVideoTimeBarChangedValue] = useState(0);

	//--------------------------------------------------
	//--------------- START : States for mobile view -------------------
	//--------------------------------------------------

	//START : States for enabling mobile view
	const [inMobileView, setInMobileView] = useState('no');
	let localInMobileView = 'no';
	//States for fr & br icons
	const [isVisibleBackwardIcon, setIsVisibleBackwardIcon] = useState(false);
	const [isVisibleForwardIcon, setIsVisibleForwardIcon] = useState(false);

	//States for fr & br icons
	const [
		isVisibleVidePlayerInnerContainer,
		setIsVisibleVidePlayerInnerContainer,
	] = useState(false);

	//--------------------------------------------------
	//--------------- END : States for mobile view -------------------
	//--------------------------------------------------
	const [isLandscape, setIsLandscape] = useState(
		window.matchMedia('(orientation: landscape)').matches
	  );
	
	const [isManualFullScreen, setIsManualFullScreen] = useState(false);

	useEffect(() => {
		const handleOrientationChange = (event) => {
			setIsLandscape(event.matches);
		};

		const mediaQuery = window.matchMedia('(orientation: landscape)');
		mediaQuery.addListener(handleOrientationChange);

		return () => {
			mediaQuery.removeListener(handleOrientationChange);
		};
	}, []);

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullscreenChange);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange);
		};
	}, []);

	useEffect(() => {
		handleVideoIdChanged(true);
		handleScrollUpWithAdjustPlaylist();

		const options = {
			id: videoId,
			loop: true,
			controls: false,
			autoplay: true,
			responsive: true,
			texttrack: '(en-US)',
			playsinline: true,
		};

		let getUrlParameters = getUrlParametersFn();
		let typeOfThisPage;
		if (getUrlParameters && getUrlParameters.success && getUrlParameters.type) {
			typeOfThisPage = getUrlParameters.type;
		}

		var modalElement;
		if (document.getElementById('videoPlayerX')) {
			const videoPlayerX = document.getElementById('videoPlayerX');
			const videoPlayerMain = document.getElementById('videoPlayer');
			playerRef.current = new Vimeo(videoPlayerX, options);
			videoPlayerRef.current = videoPlayerMain;

			//START : Init player
			playerRef.current
				.ready()
				.then(() => {
					getNewVideo = 0;
					adjustPlayListMainContainer(typeOfThisPage);
					IsVideoPlayerRenderend(true);

					window.addEventListener('resize', handleResize);
					window.addEventListener('scroll', handleScroll);

					//Fn to update video duration
					updateVideoCompleteDuration();

					//Fn to manage video time fns
					playerRef.current.on('timeupdate', (data) => {
						handleTimeline(data);
					});

					//Set default volume
					setInitVolume();

					//Set captions
					checkForCaptions();

					//Set default speed
					handleSpeed(null, previousSelectedSpeed);

					//Set default speed
					handleQuality(null, previousSelectedQuality);

					//Set init video
					handleInitPlayPauseIcon();

					// Fn to handle seekbar when screen loads
					handleInitSeekbar();

					// START : Events to handle play and pause when we click on video player on desktop
					if (document.getElementById('div1_d')) {
						document
							.getElementById('div1_d')
							.addEventListener('click', handleClickPlayAndPause);
					}
					if (document.getElementById('div0_1')) {
						document
							.getElementById('div0_1')
							.addEventListener('click', handleClickPlayAndPause);
					}
					// END : Events to handle play and pause when we click on video player on desktop

					// START : Events to handle play and pause when we click on video player on desktop
					if (document.getElementById('div1_d')) {
						document
							.getElementById('div1_d')
							.addEventListener('click', handleClickPlayAndPause);
					}
					if (document.getElementById('div0_1')) {
						document
							.getElementById('div0_1')
							.addEventListener('click', handleClickPlayAndPause);
					}
					// END : Events to handle play and pause when we click on video player on desktop

					//Set event to close popups
					document.addEventListener('mousedown', handleOutsideClick);

					//Set event to lsn keyboard events
					document.addEventListener('keydown', handleKeyPress);

					//Set event for comment video duration
					document.addEventListener('click', handleCommentVideoDurationTagClick);

					//Fn to handle init player icons
					const viewportWidth = window.innerWidth;
					if (viewportWidth < 530) {
						setInitPlayerStatesForMobile();
					}
				})
				.catch((err) => {
					alert('Requested Video Not Found: Connect With eMediskill Team');
				});

			//Fn to handle init player icons
			const viewportWidth = window.innerWidth;
			if (viewportWidth < 530) {
				setInitPlayerStatesForMobile();
			}

			adjustVideoPlayerUIForMovile();

			if (document.getElementById('MobileQtySpeedCCUiContainer')) {
				modalElement = document.getElementById('MobileQtySpeedCCUiContainer');
				modalElement.addEventListener('hidden.bs.modal', handleModalClosed);
			}
		}

		// //Init tooltip
		// const elements = document.querySelectorAll('[data-toggle="tooltip"]');
		// elements.forEach((element) => {
		// 	new window.bootstrap.Tooltip(element);
		// });

		// document.addEventListener('fullscreenchange', handleExpandShrink);
		// document.addEventListener('webkitfullscreenchange', handleExpandShrink);
		// document.addEventListener('mozfullscreenchange', handleExpandShrink);
		// document.addEventListener('MSFullscreenChange', handleExpandShrink);

		return () => {
			if (playerRef.current) {
				playerRef.current.destroy();
				if (modalElement) {
					modalElement.removeEventListener('hidden.bs.modal', handleModalClosed);
				}
				getNewVideo = 0;
			}

			if (document.getElementById('div1_d')) {
				document
					.getElementById('div1_d')
					.removeEventListener('click', handleClickPlayAndPause);
			}
			if (document.getElementById('div0_1')) {
				document
					.getElementById('div0_1')
					.removeEventListener('click', handleClickPlayAndPause);
			}

			// adjustPlayListMainContainer(typeOfThisPage);
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('scroll', handleScroll);

			document.removeEventListener('keydown', handleKeyPress);
			document.removeEventListener('mousedown', handleOutsideClick);

			//Code to remove event for comment video duration
			document.removeEventListener('click', handleCommentVideoDurationTagClick);

			// document.removeEventListener('fullscreenchange', handleExpandShrink);
			// document.removeEventListener('webkitfullscreenchange', handleExpandShrink);
			// document.removeEventListener('mozfullscreenchange', handleExpandShrink);
			// document.removeEventListener('MSFullscreenChange', handleExpandShrink);
		};
	}, [videoId]);

	// Fn to handle seekbar when screen loads
	const handleInitSeekbar = () => {
		if (timeBarRef && timeBarRef.current) {
			let changedValue = 0;
			timeBarRef.current.value = changedValue;
		}
	};

	// Fn to handle play and pause when we click on video player on desktop
	const handleClickPlayAndPause = () => {
		const viewportWidth = window.innerWidth;
		if (viewportWidth > 530 && localInMobileView === 'no') {
			handlePlayPause(null);
		}
	};

	//Fn to handle hiding of popup's when clicked outside the respective container
	const handleOutsideClick = (event) => {
		if (
			speedContainerRef.current &&
			!speedContainerRef.current.contains(event.target)
		) {
			set_Is_SpeedContainerRef_Visible(false);
		}
		if (
			qtyContainerRef.current &&
			!qtyContainerRef.current.contains(event.target)
		) {
			set_Is_QtyContainerRef_Visible(false);
		}
	};

	// START : Fn to handle key events
	const handleKeyPress = (event) => {
		if (
			(event.key === ' ' || event.key === 'K' || event.key === 'k') 
			&& document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			event.preventDefault();
			window.scroll(0, 0);
			handlePlayPause(null);
		} else if (
			(event.key === 'ArrowLeft' || event.key === 'J' || event.key === 'j') &&
			(event.key === 'ArrowLeft' || event.key === 'J' || event.key === 'j') &&
			document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			handleBackward10();
		} else if (
			(event.key === 'ArrowRight' || event.key === 'L' || event.key === 'l') &&
			(event.key === 'ArrowRight' || event.key === 'L' || event.key === 'l') &&
			document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			handleForward10();
		} else if (
			event.key === 'ArrowUp' &&
			document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			event.preventDefault();
			updateVolume('ArrowUp');
		} else if (
			event.key === 'ArrowDown' &&
			document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			event.preventDefault();
			updateVolume('ArrowDown');
		} else if (
			(event.key === 'm' || event.key === 'M') 
			&& document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			event.preventDefault();
			updateVolume('mute');
		} else if (
			(event.key === 'f' || event.key === 'F') &&
			document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			event.preventDefault();
			isFullScreen ? exitFullscreen() : enterFullscreen();
			// handleExpandShrink();
		} else if (
			event.shiftKey &&
			event.code === 'Comma' &&
			document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			event.preventDefault();
			updateSpeed('decreaseSpeed');
		} else if (
			event.shiftKey &&
			event.code === 'Period' &&
			document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			isFullScreen ? exitFullscreen() : enterFullscreen();
			// handleExpandShrink();
		} else if (
			event.shiftKey &&
			event.code === 'Comma' &&
			document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			event.preventDefault();
			updateSpeed('decreaseSpeed');
		} else if (
			event.shiftKey &&
			event.code === 'Period' &&
			document.activeElement.tagName !== 'INPUT'
			&& document.activeElement.tagName !== 'TEXTAREA'
		) {
			event.preventDefault();
			updateSpeed('increaseSpeed');
		}
	};

	//START : Fn to handle up and down arrow keys
	const updateVolume = (value) => {
		playerRef.current.getVolume().then((volume) => {
			if (value === 'ArrowUp') {
				volume = volume + 0.2;
				if (volume >= 1) {
					volume = 1;
				} else {
					volume = volume;
				}
			} else if (value === 'ArrowDown') {
				volume = volume - 0.2;
				if (volume <= 0) {
					volume = 0;
				} else {
					volume = volume;
				}
			} else if (value === 'mute') {
				if (volume === 0) {
					volume = previousVolume;
				} else {
					previousVolume = volume;
					volume = 0;
				}
			}
			handleVolumeChange(null, volume);
		});
	};
	//END : Fn to handle up and down arrow keys

	const updateSpeed = (value) => {
		playerRef.current.getPlaybackRate().then((playbackRate) => {
			if (value === 'decreaseSpeed') {
				if (playbackRate > 0.25) {
					playbackRate = playbackRate - 0.25;
				}
			} else if (value === 'increaseSpeed') {
				if (playbackRate < 2) {
					playbackRate = playbackRate + 0.25;
				}
			}
			handleSpeed(null, playbackRate);
		});
	};

	// END : Fn to handle key events

	//Fn to handleScrollUpWithAdjustPlaylist
	const handleScrollUpWithAdjustPlaylist = () => {
		window.scroll(0, 0);
		if (
			document.getElementById('playListMainContainer') &&
			document.getElementById('playListMainContainerFIXED')
		) {
			const playListMainContainer = document.getElementById(
				'playListMainContainer'
			);
			const playListMainContainerFIXED = document.getElementById(
				'playListMainContainerFIXED'
			);
			playListMainContainer.style.visibility = 'visible';
			playListMainContainerFIXED.style.visibility = 'hidden';
		}
	};

	//Fn to get the url parameters
	const getUrlParametersFn = () => {
		let isVideoId, isCourseId;
		if (identifier === 'v' && id) {
			isVideoId = identifier === 'v' && id;
			return {
				success: true,
				type: 'v',
				id: isVideoId,
			};
		} else if (identifier === 's' && id) {
			isCourseId = identifier === 's' && id;
			return {
				success: true,
				type: 's',
				id: isCourseId,
			};
		} else {
			return {
				success: false,
				message: 'No id found',
			};
		}
	};

	//START : Fns to manage video time fns
	const updateVideoCompleteDuration = () => {
		if (playerRef.current) {
			// setTimeout(function () {
			// 	if (playerRef.current) {
			// 		playerRef.current.getPaused().then((paused) => {
			// 			if (paused) {
			// 				handlePlayPauseIcon('play');
			// 			} else {
			// 				handlePlayPauseIcon('paused');
			// 			}
			// 		});
			// 	}
			// }, 1000);

			playerRef.current
				.getDuration()
				.then(function (duration) {
					maxVideoDuration = duration;
					timeBarRef.current.max = duration;
					setVideoDuration(formatTime(duration));
					sendVideoDuration(duration);
				})
				.catch(function (error) {
					console.log('getDuration : ', error);
				});
		}
	};

	const handleTimeline = (data) => {
		// For ref : const { seconds, duration, percent } = data;
		const { seconds, duration } = data;
		setCurrentVideoDurationInSeconds(duration);
		setCurrentVideoTime(formatTime(seconds));
		timeBarRef.current.value = seconds;

		const progress =
			((timeBarRef.current.value - 0) / (maxVideoDuration - 0)) * 100;
		let bgPercentage = progress + '%';
		setTimeBarChangePer(bgPercentage);

		//Visahl : here you would get when video passes 2 mins
		if (seconds > 120) {
			props.updateVideoViewCount();
		}

		//Visahl : here you would get when video ended
		if (seconds + 20 > duration) {
			props.updateWatchedVideoCount();
		}

		// Fn  to play next video
		if (seconds > Math.floor(duration) - 0.5) {
			if (getNewVideo === 0) {
				if (playerRef.current) {
					playerRef.current.pause();
					setTimeout(function () {
						getNewVideo++;

						//Code to play next video
						if (timeBarRef.current) {
							timeBarRef.current.value = 0;
							setTimeBarChangePer('0%');
						}
						handleNextVideo(videoId);
						//Code to play next video
					}, 3000);
				}
			}
		}
	};

	const handleTimeBarChange = (event) => {
		let changedValue = event.target.value;

		timeBarRef.current.classList.add('custom-range');

		playerRef.current.setCurrentTime(changedValue).then(() => {
			timeBarRef.current.classList.remove('custom-range');

			timeBarRef.current.value = changedValue;
			const percent =
				((timeBarRef.current.value - 0) / (maxVideoDuration - 0)) * 100;
			let bgPercentage = percent + '%';
			setTimeBarChangePer(bgPercentage);
		});
	};

	const formatTime = (duration) => {
		const hours = Math.floor(duration / 3600);
		const minutes = Math.floor((duration % 3600) / 60);
		const seconds = Math.floor(duration % 60);

		const formattedHours = hours.toString().padStart(2, '0');
		const formattedMinutes = minutes.toString().padStart(2, '0');
		const formattedSeconds = seconds.toString().padStart(2, '0');

		if (hours > 0) {
			return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		} else {
			return `${formattedMinutes}:${formattedSeconds}`;
		}
	};
	//END : Fn to manage video time fns

	//START : Fn to handle play and pause
	const handlePlayPause = (event) => {
		//var tooltip = new window.bootstrap.Tooltip(playPauseButton);
		// tooltip.hide();
		playerRef.current.getPaused().then((paused) => {
			if (paused) {
				handlePlayPauseIcon('paused');
				playerRef.current.play();
			} else {
				handlePlayPauseIcon('play');
				playerRef.current.pause();
			}
		});
	};

	const handlePlayPauseIcon = (status) => {
		if (status === 'play') {
			// setPlayPauseIcon(centerPlayIcon); R
			setIsVisibleVidePlayerInnerContainer(true);
			setPlayPauseIcon(PlayIcon);

			// document.getElementById('playPauseButton').setAttribute('title', 'Play');
			// document
			// 	.getElementById('playPauseButton')
			// 	.setAttribute('data-bs-original-title', 'Play');
		} else {
			// setPlayPauseIcon(centerPauseIcon); R

			setIsVisibleVidePlayerInnerContainer(false);
			setPlayPauseIcon(PauseIcon);

			// document.getElementById('playPauseButton').setAttribute('title', 'Pause');
			// document
			// 	.getElementById('playPauseButton')
			// 	.setAttribute('data-bs-original-title', 'Pause');
		}
	};
	//END : Fn to handle play and pause

	//START : fn : backward and forward
	const handleBackward10 = () => {
		playerRef.current
			.getCurrentTime()
			.then((response) => {
				const currentTime = response;
				if (currentTime > 12) {
					playerRef.current.setCurrentTime(currentTime - 10);

					// START : Code to show and hide center div forward icon
					setIsVisibleBackwardIcon(true);
					setTimeout(function () {
						setIsVisibleBackwardIcon(false);
					}, 3000);
					// END : Code to show and hide center div forward icon
				}
			})
			.catch((error) => {
				console.log('backward10 : ', error);
			});
	};

	const handleForward10 = () => {
		playerRef.current
			.getCurrentTime()
			.then((response) => {
				const currentTime = response;
				if (currentTime + 12 < currentVideoDurationInSeconds - 8) {
					playerRef.current.setCurrentTime(currentTime + 10);
				} else {
					// START : Code to play next video
					if (timeBarRef.current) {
						timeBarRef.current.value = 0;
						setTimeBarChangePer('0%');
					}
					handleNextVideo(videoId);
					//END : Code to play next video
				}

				// START : Code to show and hide center div forward icon
				setIsVisibleForwardIcon(true);
				setTimeout(function () {
					setIsVisibleForwardIcon(false);
				}, 3000);
				// END : Code to show and hide center div forward icon
			})
			.catch((error) => {
				console.log('forward10 : ', error);
			});
	};
	//END : fn : backward and forward

	//START : fns for volume
	const setInitVolume = () => {
		if (volumeStore === null) {
			handleVolumeChange(null, 1);
		} else {
			handleVolumeChange(null, volumeStore);
		}
	};

	const handleVolumeChange = (event, initVal) => {
		let volume;
		if (event !== null) {
			volume = parseFloat(event.target.value);
		} else {
			volume = parseFloat(initVal);
		}

		if (volume === 0) {
			setVolumeIcon(MuteIcon);
		} else {
			setVolumeIcon(VolumeIcon);
		}

		const percent = ((volume - 0) / (1 - 0)) * 100;
		let bgPercentage = percent + '%';
		setVolumeBarChangePer(bgPercentage);
		setVolumeBarChangedValue(volume);
		playerRef.current.setVolume(volume);
		volumeStore = volume;
	};

	const handleVolumeBtn = (event) => {
		playerRef.current.getVolume().then((volume) => {
			if (volume !== 0) {
				previousVolume = volume;
				handleVolumeChange(null, 0);
			} else {
				handleVolumeChange(null, previousVolume);
			}
		});
	};
	//END : fns for volume

	const handleTimeBarRefChange = (event) => {
		let time;
		time = parseFloat(event.target.value);

		const percent =
			((event.target.value - 0) / (timeBarRef.current.max - 0)) * 100;
		let bgPercentage = percent + '%';
		playerRef.current.setCurrentTime(time);
		setTimeBarChangePer(bgPercentage);
	};

	const enterFullscreen = () => {
		const divElement = videoPlayerRef.current;
        // let requestFullscreen = undefined;
		// if(requestFullscreen === undefined){

		//    setIsManualFullScreen(!isManualFullScreen)
		//    divElement.setAttribute("class", "fullScreenMiddleDiv")

		// 	return
		// }
		if (divElement && divElement.requestFullscreen) {
			divElement.requestFullscreen();
		} else {
			const iPhoneDivEle = playerRef.current;
			if (iPhoneDivEle && iPhoneDivEle.requestFullscreen) {
				iPhoneDivEle.requestFullscreen();
			}
		}
	};

	const exitFullscreen = () => {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	};
	const handleFullscreenChange = () => {
		setIsFullScreen(!!document.fullscreenElement);
	};

	//END : fn's to handle full screen

	//START : fn's to handle CC

	// let isHasCaptions = false;
	const checkForCaptions = () => {
		playerRef.current
			.getTextTracks()
			.then(function (tracks) {
				if (tracks.length > 0) {
					setIsCaption('inline');
					setIsHasCaptions(true);
					if (ccFlag) {
						enableCC();
					}
				}
			})
			.catch(function (error) {
				console.log('checkForCaptions : ', error);
			});
	};

	const handleCC = () => {
		if (isHasCaptions) {
			if (!ccFlag) {
				enableCC();
			} else {
				disableCC();
			}
		}
	};

	const enableCC = async () => {
		try {
			const textTracks = await playerRef.current.getTextTracks();
			const captionsTrack = textTracks.find((track) => track.kind === 'captions');

			if (captionsTrack) {
				playerRef.current.enableTextTrack(captionsTrack.language);
				ccFlag = true; // CC is on
				setCCIcon(CcOnIcon);
			}
		} catch (error) {
			console.error('Error enabling captions:', error);
		}
	};

	const disableCC = () => {
		try {
			playerRef.current.disableTextTrack();
			ccFlag = false; // CC is off
			setCCIcon(CcOffIcon);
		} catch (e) {
			console.log('disableCC : ', e);
		}
	};
	//END : fn's to handle CC

	//START : fn's to handle speed
	const handleSpeedContainerVisibility = () => {
		set_Is_SpeedContainerRef_Visible(true);
		if (
			document.getElementById('playPauseButton') &&
			document.getElementById('backward10')
		) {
			const playPauseButton = document.getElementById('playPauseButton');
			const backward10 = document.getElementById('backward10');

			let marginLeft =
				playPauseButton.getBoundingClientRect().width +
				backward10.getBoundingClientRect().width -
				13 +
				'px';
			set_SpeedContainerRef_Position(marginLeft);
		}
	};

	const handleSpeed = (event, changeSpeed) => {
		setSpeedValue(changeSpeed);
		playerRef.current.setPlaybackRate(changeSpeed);
		previousSelectedSpeed = changeSpeed;

		if (changeSpeed === 0.25) {
			setSpeedIcon(Speed_025);
		} else if (changeSpeed === 0.5) {
			setSpeedIcon(Speed_05);
		} else if (changeSpeed === 0.75) {
			setSpeedIcon(Speed_075);
		} else if (changeSpeed === 1) {
			setSpeedIcon(Speed_1);
		} else if (changeSpeed === 1.25) {
			setSpeedIcon(Speed_125);
		} else if (changeSpeed === 1.5) {
			setSpeedIcon(Speed_15);
		} else if (changeSpeed === 1.75) {
			setSpeedIcon(Speed_175);
		} else if (changeSpeed === 2) {
			setSpeedIcon(Speed_2);
		}

		set_Is_SpeedContainerRef_Visible(false);
	};
	//END : fn's to handle speed

	//START : fn's to handle qty
	const handleQualityVisibility = () => {
		set_Is_QtyContainerRef_Visible(true);

		if (
			document.getElementById('videoPlayer') &&
			document.getElementById('expandShrinkButton') &&
			document.getElementById('ccButton') &&
			document.getElementById('qualityIcon')
		) {
			const videoPlayer = document.getElementById('videoPlayer');
			const expandShrinkButton = document.getElementById('expandShrinkButton');
			const ccButton = document.getElementById('ccButton');
			const qualityIcon = document.getElementById('qualityIcon');

			let marginLeft =
				videoPlayer.getBoundingClientRect().width -
				30 -
				expandShrinkButton.getBoundingClientRect().width -
				ccButton.getBoundingClientRect().width -
				qualityIcon.getBoundingClientRect().width +
				'px';
			set_QtyContainerRef_Position(marginLeft);
		}
	};

	const handleQuality = (event, changeQty) => {
		setQtyValue(changeQty);
		playerRef.current.setQuality(changeQty);
		previousSelectedQuality = changeQty;
		set_Is_QtyContainerRef_Visible(false);
	};
	//END : fn's to handle qty

	const handleInitPlayPauseIcon = () => {
		if (playerRef.current) {
			playerRef.current.on('play', () => {
				handlePlayPauseIcon('paused');
			});

			playerRef.current.on('pause', () => {
				handlePlayPauseIcon('play');
			});
		}
	};

	const handleMouseEnter = () => {
		setMouseInOut(true);
	};
	const handleMouseLeave = () => {
		setMouseInOut(false);
	};

	//--------------------------------------------------
	//--------------- START : for mobile view -------------------
	//--------------------------------------------------

	const adjustVideoPlayerUIForMovile = () => {
		const viewportWidth = window.innerWidth;
		if (viewportWidth < 530) {
			setInMobileView('yes');
			setIsVisibleVidePlayerInnerContainer(false);
			localInMobileView = 'yes';
		}
	};

	// START : Fn to handle double tap
	const handleDoubleTap = (event) => {
		const viewportWidth = window.innerWidth;
		if (viewportWidth < 530) {
			const { clientX, clientY } = event;
			if (
				document.getElementById('playPauseIcon_div0_1') &&
				document.getElementById('div2')
			) {
				let playPauseIcon_div0_1 = document.getElementById('playPauseIcon_div0_1');
				let div2 = document.getElementById('div2');
				if (
					clientX < playPauseIcon_div0_1.getBoundingClientRect().left - 20 &&
					clientY < div2.getBoundingClientRect().top - 20
				) {
					handleBackward10();
				} else if (
					clientX > playPauseIcon_div0_1.getBoundingClientRect().right + 20 &&
					clientY < div2.getBoundingClientRect().top - 20
				) {
					handleForward10();
				}
			}
		}
	};

	const doubleTapHandlers = useDoubleTap(handleDoubleTap);
	// END : Fn to handle double tap

	//START : Fn to handle single tap
	const handleVideoPlayerSingleClick = (event, t) => {
		const viewportWidth = window.innerWidth;
		if (viewportWidth < 530 || inMobileView === 'yes') {
			setTimeout(function () {
				setIsVisibleVidePlayerInnerContainer(true);
				setMouseInOut(false);
				setTimeout(function () {
					if (playerRef.current) {
						playerRef.current.getPaused().then((paused) => {
							if (!paused) {
								setIsVisibleVidePlayerInnerContainer(false);
							}
						});
					}
				}, t);
			}, 200);
		}
	};
	//END : Fn to handle single tap

	//START : Fn to handle mobile, qty, speed, cc popup
	const handleMobileThreedots = () => {
		if (document.getElementById('MobileQtySpeedCCUiContainer_OpenBtn')) {
			document.getElementById('MobileQtySpeedCCUiContainer_OpenBtn').click();
			playerRef.current.pause();
			handlePlayPauseIcon('play');
		}
	};

	const handlOk = (data) => {
		if (data.qty !== '') {
			handleQuality(null, data.qty);
		} else {
			handleQuality(null, 'auto');
		}
		if (data.speed !== '') {
			handleSpeed(null, parseFloat(data.speed));
		} else {
			handleSpeed(null, 1);
		}
		if (data.caption !== '') {
			if (data.caption === 'on') {
				enableCC();
			} else {
				disableCC();
			}
		}
	};

	const handleModalClosed = () => {
		playerRef.current.play();
		handlePlayPauseIcon('paused');
		handleVideoPlayerSingleClick(null, 2000);
	};
	//END : Fn to handle mobile, qty, speed, cc popup

	//START : Fn to handle init player icons
	const setInitPlayerStatesForMobile = () => {
		playerRef.current.on('play', () => {
			playerRef.current.getPaused().then((paused) => {
				if (paused) {
					// setPlayPauseIcon(centerPauseIcon)
					setPlayPauseIcon(centerPlayIcon);
					setIsVisibleVidePlayerInnerContainer(true);
					handleVideoPlayerSingleClick(null, 2000);
				} else {
					// setPlayPauseIcon(centerPlayIcon)
					setPlayPauseIcon(centerPauseIcon);
					setIsVisibleVidePlayerInnerContainer(false);
					handleVideoPlayerSingleClick(null, 2000);
				}
			});
		});
	};
	//END : Fn to handle init player icons

	//--------------------------------------------------
	//--------------- END : for movile view -------------------
	//--------------------------------------------------

	//--------------------------------------------------
	//--------------- START : Fn commentvideoduration events -------------------
	//--------------------------------------------------
	const handleCommentVideoDurationTagClick = (event) => {
		if (event.target.tagName === 'COMMENTVIDEODURATION') {
			if (event.target.getAttribute('href')) {
				const newVideoDuration = parseInt(event.target.getAttribute('href'));

				if (playerRef.current) {
					playerRef.current.setCurrentTime(newVideoDuration);
					playerRef.current.play();
					handlePlayPauseIcon('paused');
				}
				window.scroll(0, 0);
			}
		}
	};
	//--------------------------------------------------
	//--------------- END : Fn commentvideoduration events  -------------------
	//--------------------------------------------------

	return (
		<VideoPlayerView
			//START : States for playpause
			playPauseIcon={playPauseIcon}
			//END : States for playpause

			//START : States to handle speed
			handleSpeedContainerVisibility={handleSpeedContainerVisibility}
			is_SpeedContainerRef_Visible={is_SpeedContainerRef_Visible}
			speedContainerRef={speedContainerRef}
			speedContainerRef_Position={speedContainerRef_Position}
			handleSpeed={handleSpeed}
			speedValue={speedValue}
			speedIcon={speedIcon}
			//END : States to handle speed

			//START : States to handle qty
			handleQualityVisibility={handleQualityVisibility}
			qtyContainerRef={qtyContainerRef}
			is_QtyContainerRef_Visible={is_QtyContainerRef_Visible}
			qtyContainerRef_Position={qtyContainerRef_Position}
			handleQuality={handleQuality}
			qtyValue={qtyValue}
			//END : States to handle qty

			//START : States to handle caption
			isCaption={isCaption}
			handleCC={handleCC}
			ccIcon={ccIcon}
			//END : States to handle caption

			//START : States for volume
			handleVolumeChange={handleVolumeChange}
			volumeBarChangePer={volumeBarChangePer}
			volumeBarChangedValue={volumeBarChangedValue}
			volumeIcon={volumeIcon}
			handleVolumeBtn={handleVolumeBtn}
			//END : States for volume

			//START : States for handling video time
			timeBarRef={timeBarRef}
			handleTimeBarChange={handleTimeBarChange}
			timeBarChangePer={timeBarChangePer}
			currentVideoTime={currentVideoTime}
			videoDuration={videoDuration}
			//END : States for handling video time

			handlePlayPause={handlePlayPause}
			handleBackward10={handleBackward10}
			handleForward10={handleForward10}
			playerRef={playerRef}
			videoPlayerRef={videoPlayerRef}
			//START : States for handling mobile view
			inMobileView={inMobileView}
			doubleTapHandlers={doubleTapHandlers}
			isVisibleBackwardIcon={isVisibleBackwardIcon}
			isVisibleForwardIcon={isVisibleForwardIcon}
			handleVideoPlayerSingleClick={handleVideoPlayerSingleClick}
			isVisibleVidePlayerInnerContainer={isVisibleVidePlayerInnerContainer}
			handleMobileThreedots={handleMobileThreedots}
			handlOk={handlOk}
			isHasCaptions={isHasCaptions}
			ccFlag={ccFlag}
			//END : States for handling mobile view

			handleMouseEnter={handleMouseEnter}
			handleMouseLeave={handleMouseLeave}
			mouseInOut={mouseInOut}

			isFullScreen = {isFullScreen}
			enterFullscreen ={()=>{enterFullscreen()}}
			exitFullscreen = {()=>{exitFullscreen()}}
			videoTimeBarChangedValue = {videoTimeBarChangedValue}
			handleTimeBarRefChange = {handleTimeBarRefChange}

			isLandscape ={isLandscape}
		/>
	);
}

export default VideoPlayer;
