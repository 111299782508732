import React from "react";
import ContentLoader from 'react-content-loader';


function ExamLoader() {
    return (
        <>
            <br />
            <ContentLoader viewBox='0 0 395 620' >
                <rect x='0' y='10' rx='3' ry='3' width='395' height='50' />
                <rect x='0' y='70' rx='3' ry='3' width='395' height='90' />

                {/* Start : Question Cards */}

                {/* START : Question 1 */}
                <rect x='0' y='180' rx='2' ry='2' width='150' height='10' />
                <rect x='0' y='200' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='220' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='240' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='260' rx='2' ry='2' width='100' height='10' />
                {/* END : Question 1 */}

                {/* START : Question 2 */}
                <rect x='0' y='295' rx='2' ry='2' width='150' height='10' />
                <rect x='0' y='315' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='335' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='355' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='375' rx='2' ry='2' width='100' height='10' />
                {/* END : Question 2 */}

                {/* START : Question 3 */}
                <rect x='0' y='405' rx='2' ry='2' width='150' height='10' />
                <rect x='0' y='425' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='445' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='465' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='485' rx='2' ry='2' width='100' height='10' />
                {/* END : Question 3 */}

                {/* START : Question 4 */}
                <rect x='0' y='515' rx='2' ry='2' width='150' height='10' />
                <rect x='0' y='535' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='555' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='575' rx='2' ry='2' width='100' height='10' />
                <rect x='0' y='595' rx='2' ry='2' width='100' height='10' />
                {/* END : Question 4 */}

                {/* END : Question Cards */}

            </ContentLoader>
        </>
    )
}

export default ExamLoader;