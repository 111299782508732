import React, { useEffect } from 'react';

const MyPage = () => {
    const title = "My Awesome Page";
    const description = "This is a description of my awesome page.";
    const image = "https://emediskill-prod.s3.ap-south-1.amazonaws.com/courseRectangleThumbnails/cgo.svg";
    const url = "https://emediskill.com/t";

    useEffect(() => {
        document.title = title;

        // Create or update meta tags
        const metaTags = {
            'og:title': title,
            'og:description': description,
            'og:image': image,
            'og:url': url,
            'og:type': 'website',
            'twitter:card': 'summary_large_image',
            'twitter:title': title,
            'twitter:description': description,
            'twitter:image': image,
        };

        Object.entries(metaTags).forEach(([property, content]) => {
            let meta = document.querySelector(`meta[property='${property}']`);
            if (!meta) {
                meta = document.createElement('meta');
                meta.setAttribute('property', property);
                document.head.appendChild(meta);
            }
            meta.setAttribute('content', content);
        });

        return () => {
            // Clean up meta tags
            Object.keys(metaTags).forEach((property) => {
                const meta = document.querySelector(`meta[property='${property}']`);
                if (meta) {
                    document.head.removeChild(meta);
                }
            });
        };
    }, [title, description, image, url]);

    return (
        <div>
            <h1>{title}</h1>
            <p>{description}</p>
            <img src={image} alt="Open Graph Image" />
        </div>
    );
};

export default MyPage;
