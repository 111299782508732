import React from "react";
import ContentLoader from "react-content-loader";

import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "@pages/Pay/StripeCheckoutForm";
import "./pay.css";

export default (props) => {
  return (
    <>
      <center>
        {props.isLoading ? (
          <>
            <div
              className="mainCart d-none d-md-block"
              style={{ background: "transparent" }}
            >
              <h3 style={{ textAlign: "center", color: "#d4d4d7" }}>
                {props.paymentLoaderNote}
              </h3>
              <ContentLoader viewBox="0 0 380 250">
                <rect x="100" y="0" rx="2" ry="2" width="180" height="30" />

                <rect x="100" y="40" rx="2" ry="2" width="80" height="6" />
                <rect x="100" y="55" rx="2" ry="2" width="180" height="18" />

                <rect x="100" y="85" rx="2" ry="2" width="80" height="6" />
                <rect x="100" y="100" rx="2" ry="2" width="180" height="18" />

                <rect x="100" y="130" rx="2" ry="2" width="80" height="6" />
                <rect x="100" y="145" rx="2" ry="2" width="180" height="18" />

                <rect x="100" y="180" rx="2" ry="2" width="80" height="6" />
                <rect x="100" y="195" rx="2" ry="2" width="180" height="18" />

                <rect x="205" y="230" rx="2" ry="2" width="75" height="15" />
              </ContentLoader>
            </div>

            <div
              className="mainCart d-block d-md-none"
              style={{ background: "transparent" }}
            >
              <h4 style={{ textAlign: "center", color: "#d4d4d7" }}>
                {props.paymentLoaderNote}
              </h4>
              <ContentLoader viewBox="0 0 150 250">
                <rect x="0" y="0" rx="2" ry="2" width="150" height="30" />

                <rect x="0" y="40" rx="2" ry="2" width="80" height="6" />
                <rect x="0" y="55" rx="2" ry="2" width="150" height="18" />

                <rect x="0" y="85" rx="2" ry="2" width="80" height="6" />
                <rect x="0" y="100" rx="2" ry="2" width="150" height="18" />

                <rect x="0" y="130" rx="2" ry="2" width="80" height="6" />
                <rect x="0" y="145" rx="2" ry="2" width="150" height="18" />

                <rect x="0" y="180" rx="2" ry="2" width="80" height="6" />
                <rect x="0" y="195" rx="2" ry="2" width="150" height="18" />

                <rect x="75" y="230" rx="2" ry="2" width="75" height="15" />
              </ContentLoader>
            </div>
          </>
        ) : (
          <>
            {props.paymentMethod === "stripe" && (
              <Elements
                stripe={props.stripePromise}
                options={{ clientSecret: props.clientSecret }}
              >
                <StripeCheckoutForm
                  purchaseDetails={
                    props.purchaseDetails ? props.purchaseDetails : null
                  }
                  expenseType={props.expenseType}
                />
              </Elements>
            )}
          </>
        )}
      </center>
    </>
  );
};
