export const updateUserDetails = async (userDetails, authToken) => {
	try {
		const formData = new FormData();
		for (let key in userDetails) {
			formData.append(key, userDetails[key]);
		}

		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateUserDetails`, {
			method: 'POST',
			headers: {
				Authorization: authToken,
			},
			body: formData,
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Basic Form services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getUser = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getUser`, {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Basic Form services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
