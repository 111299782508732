import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import Invoice from '@components/Invoice';

export default function InvoicePDFView(props) {
	return (
		<center>
			<PDFViewer width='100%' height='1000' className='app'>
				<Invoice invoice={props.invoice} />
			</PDFViewer>
		</center>
	);
}
