import { produce } from 'immer';

const initialState = {
	courseDetails: {},
	currentVideoData: {},
	currentVideoComments: {},
	currentContentType: null,
	userDetails: {},
};

const CourseDetailsReducer = (state = initialState, action) => {
	const { courseDetails } = state;
	const updatedCourseDetails = { ...courseDetails };

	switch (action.type) {
		case 'UPDATE_COURSE_DETAILS':
			return {
				...state,
				courseDetails: action.payload.courseDetails,
			};
		case 'UPDATE_CURRENT_CONTENT_TYPE':
			return {
				...state,
				currentContentType: action.payload.currentContentType,
			};
		case 'UPDATE_CURRENT_VIDEO_DATA':
			return {
				...state,
				currentVideoData: action.payload.currentVideoData,
			};
		case 'UPDATE_CURRENT_VIDEO_COMMENTS':
			return {
				...state,
				currentVideoComments: action.payload.currentVideoComments,
			};
		case 'UPDATE_COMMENT_REPLY':
			let reply = action.payload.reply;
			let commentId = action.payload.commentId;
			let updatedVideoComments = produce(state.currentVideoComments, (draft) => {
				let comment = draft.data.comments.find((c) => c._id === commentId);

				if (comment) {
					if (!comment.replies) {
						comment.replies = [];
					}
					comment.replies.push(reply);
				}
			});

			return {
				...state,
				currentVideoComments: updatedVideoComments,
			};
		case 'UPDATE_FAV_STATUS':
			return {
				...state,
				courseDetails: updatedCourseDetails,
			};
		case 'UPDATE_USER_DETAILS':
			return {
				...state,
				userDetails: action.payload.userDetails,
			};
		case 'RESET_COURSE_DETAILS_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default CourseDetailsReducer;
