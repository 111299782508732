const initialState = {
	courseDetails: {},
};

const CourseReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_COURSE_DETAILS':
			return {
				...state,
				courseDetails: action.payload.courseDetails,
			};
		case 'RESET_COURSE_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default CourseReducer;
