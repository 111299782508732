import React from "react";

import './videoSharePopup.css';

function VideoSharePopupView(props) {
    return (
        <>
            {/* START : Share popup */}
            <button
                id='sharePopupBtn'
                data-bs-toggle='modal'
                data-bs-target='#sharePopup'
                style={{ display: 'none' }}></button>

            <div className='modal fade' id='sharePopup'>
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content' style={{ borderRadius: '16px' }}>
                        <div
                            className='modal-header'
                            style={{
                                paddingTop: '0px',
                                paddingBottom: '0px',
                                borderBottom: '0px',
                            }}>
                            <h5 className='modal-title' id='exampleModalLongTitle'>
                                Share this product with friends
                            </h5>
                            <button className='btn' data-bs-dismiss='modal'>
                                <span aria-hidden='true' style={{ fontSize: '40px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className='modal-body' style={{ paddingTop: '0px' }}>
                            <hr style={{ marginTop: '0px' }} />

                            <div
                                className={`textCopiedAlter alert alert-success ${props.copyAlterIsVisible ? 'visible' : ''
                                    }`}
                                id='textCopiedAlter'
                                style={{ padding: '7px' }}>
                                Shared URL Copied
                            </div>

                            <div className='text-center' style={{ marginBottom: '20px' }}>
                                <img
                                    onClick={(event) => {
                                        props.handleSocailAppShare(event, 'whatsApp');
                                    }}
                                    className='col-2 shareIcon'
                                    alt='whatsApp'
                                    src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/640px-WhatsApp_icon.png'
                                />
                                <img
                                    onClick={(event) => {
                                        props.handleSocailAppShare(event, 'gmail');
                                    }}
                                    className='col-2 shareIcon'
                                    alt='gmail'
                                    src='https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png'
                                />
                                <img
                                    onClick={(event) => {
                                        props.handleSocailAppShare(event, 'facebook');
                                    }}
                                    className='col-2 shareIcon'
                                    alt='facebook'
                                    src='https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-facebook_-512.png'
                                />
                                <img
                                    onClick={(event) => {
                                        props.handleSocailAppShare(event, 'twitter');
                                    }}
                                    className='col-2 shareIcon'
                                    alt='facebook'
                                    src='https://www.freepnglogos.com/uploads/twitter-logo-png/twitter-logo-vector-png-clipart-1.png'
                                />
                            </div>

                            <div className='input-group mb-3'>
                                <input type='text' className='form-control' id='shareInput' />
                                <button
                                    className='btn btn-outline-secondary'
                                    onClick={props.handleCopy}>
                                    Copy
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* END : Share popup */}

        </>
    )
}

export default VideoSharePopupView;


