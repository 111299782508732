import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import SectionAccordanceView from './view';

import redLock from '@assets/timeline/icons/redLock.svg';
import yellowLock from '@assets/timeline/icons/yellowLock.svg';
import grayLock from '@assets/timeline/icons/grayLock.svg';


import WhiteGrayTickFilled from '@assets/timeline/icons/whiteGrayTickFilled.png';
import InputError from '@assets/userDashboard/Icons/inputError.png';

import Blue_enrolled from '@assets/timeline/icons/blue/enrolled.png';
import Blue_completeProfile from '@assets/timeline/icons/blue/completeProfile.png';
import Blue_watchContent from '@assets/timeline/icons/blue/watchContent.png';
import Blue_finalExam from '@assets/timeline/icons/blue/finalExam.png';
import Blue_claimCertificate from '@assets/timeline/icons/blue/claimCertificate.png';

import Gray_enrolled from '@assets/timeline/icons/gray/enrolled.png';
import Gray_completeProfile from '@assets/timeline/icons/gray/completeProfile.png';
import Gray_watchContent from '@assets/timeline/icons/gray/watchContent.png';
import Gray_finalExam from '@assets/timeline/icons/gray/finalExam.png';
import Gray_claimCertificate from '@assets/timeline/icons/gray/claimCertificate.png';

import Red_enrolled from '@assets/timeline/icons/red/enrolled.png';
import Red_completeProfile from '@assets/timeline/icons/red/completeProfile.png';
import Red_watchContent from '@assets/timeline/icons/red/watchContent.png';
import Red_finalExam from '@assets/timeline/icons/red/finalExam.png';
import Red_claimCertificate from '@assets/timeline/icons/red/claimCertificate.png';

import Brown_enrolled from '@assets/timeline/icons/brown/enrolled.png';
import Brown_completeProfile from '@assets/timeline/icons/brown/completeProfile.png';
import Brown_watchContent from '@assets/timeline/icons/brown/watchContent.png';
import Brown_finalExam from '@assets/timeline/icons/brown/finalExam.png';
import Brown_claimCertificate from '@assets/timeline/icons/brown/claimCertificate.png';

function SectionAccordance(props) {
	const viewportWidth = window.innerWidth;
	const [status, setStatus] = useState({});
	const [identifyIcon, setIdentifyIcon] = useState(null);
	const [lastEleHeight, setLastEleHeight] = useState(10);

	// const [activeTabName, setActiveTabName] = useState(''); // remove it in next iteration

	const timelineRedirectDetails = useSelector((state) => state.timelineRedirect.timelineRedirectDetails);
	const [openAccordance, setOpenAccordance] = useState(false);

	const grayColor = {
		bg: '#F3F3F3',
		c: '#5E5E5E',
		bc: '#E8E8E8',
	};
	const redColor = {
		bg: '#FFEDED',
		c: '#EA2D2D',
		bc: '#EA2D2D',
	};
	const brownColor = {
		bg: '#FDF2DC',
		c: '#845201',
		bc: '#FFBC2C',
	};

	useEffect(() => {
		handleStatsStatus();
	}, [props.accordanceConfig]);

	useEffect(() => {
		openCompleteProfileTab()
	}, [timelineRedirectDetails])

	const openCompleteProfileTab = () => {
		if (props.tabName === 'completeProfile' && timelineRedirectDetails && timelineRedirectDetails.purchaseId && timelineRedirectDetails.purchaseId !== "") {
			setOpenAccordance(true)
			if(timelineRedirectDetails.yCoordinate){
				const move = timelineRedirectDetails.yCoordinate + 40
				window.scroll(0, move)
			}
			else {
				window.scroll(0, 0)
			}
		}
	}

	const handleStatsStatus = () => {
		if (props.accordanceConfig && props.tabName) {
			// dev note : as per rohit don't try to match the height of the support conatier in timeline... still son't rmeove this code
			// if (viewportWidth > 768) {
			// 	setTimeout(()=>{
			// 		handleLastEleHeight();
			// 	},300)
			// }

			if (props.tabName === 'enrolled') {
				handleEnrolledStatus(props.accordanceConfig);
			} else if (props.tabName === 'completeProfile') {
				handleCompleteProfileStatus(props.accordanceConfig);
			} else if (props.tabName === 'watchContent') {
				handleWatchContentStatus(props.accordanceConfig);
			} else if (props.tabName === 'finalExam') {
				handleFinalExamStatus(props.accordanceConfig);
			} else if (props.tabName === 'claimCertification') {
				handleClaimCertificationStatus(props.accordanceConfig);
			}
		}
	};

	const handleEnrolledStatus = (d) => {
		let s = {
			text: '',
			superLock: false,
		};

		setIdentifyIcon(Blue_enrolled);

		if (d.paymentMethod && d.paymentMethod === 'free') {
			s.text = 'Access given on ';
		} else {
			s.text = 'Enrolled on ';
			if(window.innerWidth < 568){
				s.text = 'Enrolled ';
			}
		}
		s.text = s.text + moment(d.createdAt).format('Do, MMMM YYYY');
		s.icon = WhiteGrayTickFilled;
		s = { ...s, ...grayColor };
		setStatus(s);
	};

	const handleCompleteProfileStatus = (d) => {
		let show = false;
		let s = {
			text: '',
			superLock: false,
		};

		setIdentifyIcon(Blue_completeProfile);

		if (d.hasOwnProperty('rejected') && d.rejected) {
			setIdentifyIcon(Red_claimCertificate);
			s.classN = 'accordion-item-theme-red';
			s.text = 'Action required';
			s.icon = InputError;
			s = { ...s, ...redColor };
			show = true;
		} else if (d.hasOwnProperty('submittedOn') && d.submittedOn !== '') {
			s.text = 'Submitted on ' + moment(d.submittedOn).format('Do, MMMM YYYY');
			if(window.innerWidth < 568){
				s.text = 'Submitted ' + moment(d.submittedOn).format('Do, MMMM YYYY');
			}
			s.icon = WhiteGrayTickFilled;
			s = { ...s, ...grayColor };
			show = true;
		} else if (d.hasOwnProperty('verifiedOn') && d.verifiedOn !== '') {
			s.text = 'Verified on ' + moment(d.verifiedOn).format('Do, MMMM YYYY');
			if(window.innerWidth < 568){
				s.text = 'Verified ' + moment(d.verifiedOn).format('Do, MMMM YYYY');
			}
			s.icon = WhiteGrayTickFilled;
			s = { ...s, ...grayColor };
			show = true;
		}

		if (show) {
			setStatus(s);
		}
	};

	const handleWatchContentStatus = (d) => {
		let show = false;
		let s = {
			text: '',
			superLock: false,
		};

		setIdentifyIcon(Blue_watchContent);

		if (d.hasOwnProperty('superLock') && d.superLock) {
			setIdentifyIcon(Gray_watchContent);
			s.classN = 'accordion-item-theme-gray';
			s.text = 'SuperLock';
			s.icon = grayLock;
			s.superLock = true;
			s = { ...s, ...grayColor };
			show = true;
		} else if (d.lock) {
			setIdentifyIcon(Brown_watchContent);
			s.classN = 'accordion-item-theme-brown';
			s.text = 'Locked';
			s.icon = yellowLock;
			s = { ...s, ...brownColor };
			show = true;
		} else if (d.hasOwnProperty('exemptedOn') && d.exemptedOn) {
			s.text = 'Exempted on ' + moment(d.exemptedOn).format('Do, MMMM YYYY');
			if(window.innerWidth < 568){
				s.text = 'Exempted ' + moment(d.exemptedOn).format('Do, MMMM YYYY');
			}
			s.icon = WhiteGrayTickFilled;
			s = { ...s, ...grayColor };
			show = true;
		} else if (d.hasOwnProperty('completedOn') && d.completedOn) {
			s.text = 'Completed on ' + moment(d.completedOn).format('Do, MMMM YYYY');
			if(window.innerWidth < 568){
				s.text = 'Completed ' + moment(d.completedOn).format('Do, MMMM YYYY');
			}
			s.icon = WhiteGrayTickFilled;
			s = { ...s, ...grayColor };
			show = true;
		}

		if (show) {
			setStatus(s);
		}
	};

	const handleFinalExamStatus = (d) => {
		let show = false;
		let s = {
			text: '',
			superLock: false,
		};

		setIdentifyIcon(Blue_finalExam);

		if (d.hasOwnProperty('superLock') && d.superLock) {
			setIdentifyIcon(Gray_finalExam);
			s.classN = 'accordion-item-theme-gray';
			s.text = 'SuperLock';
			s.icon = grayLock;
			s.superLock = true;
			s = { ...s, ...grayColor };
			show = true;
		} else if (d.lock) {
			setIdentifyIcon(Brown_finalExam);
			s.classN = 'accordion-item-theme-brown';
			s.text = 'Locked';
			s.icon = yellowLock;
			s = { ...s, ...brownColor };
			show = true;
		} else if (d.underReview) {
			setIdentifyIcon(Brown_finalExam);
			s.classN = 'accordion-item-theme-brown';
			s.text = 'Under Review';
			s.icon = yellowLock;
			s = { ...s, ...brownColor };
			show = true;
		} else if (d.ineligible) {
			setIdentifyIcon(Red_finalExam);
			s.classN = 'accordion-item-theme-red';
			s.text = 'Ineligible';
			s.icon = redLock;
			s = { ...s, ...redColor };
			show = true;
		} else if (d.hasOwnProperty('exemptedOn') && d.exemptedOn) {
			s.text = 'Exempted on ' + moment(d.exemptedOn).format('Do, MMMM YYYY');
			if(window.innerWidth < 568){
				s.text = 'Exempted ' + moment(d.exemptedOn).format('Do, MMMM YYYY');
			}
			s.icon = WhiteGrayTickFilled;
			s = { ...s, ...grayColor };
			show = true;
		} else if (d.hasOwnProperty('completedOn') && d.completedOn) {
			s.text = 'Completed on ' + moment(d.completedOn).format('Do, MMMM YYYY');
			if(window.innerWidth < 568){
				s.text = 'Completed ' + moment(d.completedOn).format('Do, MMMM YYYY');
			}
			s.icon = WhiteGrayTickFilled;
			s = { ...s, ...grayColor };
			show = true;
		}
		else {
			setIdentifyIcon(Blue_finalExam);
			s = {}
			show = true;
		}

		if (show) {
			setStatus(s);
		}
	};

	const handleClaimCertificationStatus = (d) => {
		let show = false;
		let s = d

		setIdentifyIcon(Blue_claimCertificate);
		if (d.hasOwnProperty('superLock') && d.superLock) {
			setIdentifyIcon(Gray_claimCertificate);
			s.classN = 'accordion-item-theme-gray';
			s.text = 'SuperLock';
			s.icon = grayLock;
			s.superLock = true;
			s = { ...s, ...grayColor };
			show = true;
		} else if (d.lock) {
			setIdentifyIcon(Brown_claimCertificate);
			s.classN = 'accordion-item-theme-brown';
			s.text = 'Locked';
			s.icon = yellowLock;
			s.lock = true
			s = { ...s, ...brownColor };
			show = true;
		} else if (d.underReview) {
			setIdentifyIcon(Brown_claimCertificate);
			s.classN = 'accordion-item-theme-brown';
			s.text = 'Under Review';
			s.icon = yellowLock;
			s.underReview = true
			s = { ...s, ...brownColor };
			show = true;
		} else if (d.ineligible) {
			setIdentifyIcon(Red_claimCertificate);
			s.classN = 'accordion-item-theme-red';
			s.text = 'Ineligible';
			s.icon = redLock;
			s.ineligible = true
			s = { ...s, ...redColor };
			show = true;
		} else if (d.hasOwnProperty('claimedOn') && d.claimedOn !== '') {
			s.text = 'Claimed on ' + moment(d.claimedOn).format('Do, MMMM YYYY');
			if(window.innerWidth < 568){
				s.text = 'Claimed ' + moment(d.claimedOn).format('Do, MMMM YYYY');
			}
			s.icon = WhiteGrayTickFilled;
			s = { ...s, ...grayColor };
			show = true;
		}

		s.success = true
		setStatus(s);
	};

	function camelCaseToWords(camelCaseString) {
		return camelCaseString
			.replace(/([A-Z])/g, ' $1')
			.replace(/^./, function (str) {
				return str.toUpperCase();
			});
	}

	function handleLastEleHeight() {
		// return
		if (props.lastEle && props.lastEle === 'true') {
			// debugger
			// let accordionItem = document.getElementsByClassName('accordion-item');
			// const supportCardInTimeLineMainContainer = document.getElementById("supportCardInTimeLineMainContainer")
			// const allDetailsAccordionFlush = document.getElementById("allDetailsAccordionFlush")
			// if(accordionItem.length && supportCardInTimeLineMainContainer && allDetailsAccordionFlush){
			// 	accordionItem = accordionItem[accordionItem.length - 1];
			// 	const accordionHeader = accordionItem.querySelector('.accordion-header')
			// 	if(accordionHeader){
			// 		const accordionHeaderBottom = accordionHeader.getBoundingClientRect().bottom;
			// 		const accordionHeaderHeight = accordionHeader.getBoundingClientRect().height;
			// 		const supportCardBottom = supportCardInTimeLineMainContainer.getBoundingClientRect().bottom
			// 		const allDetailsAccordionFlushHeight = allDetailsAccordionFlush.getBoundingClientRect().height
			// 		let lastEleHeight = supportCardBottom - accordionHeaderBottom
			// 		// lastEleHeight = allDetailsAccordionFlushHeight + lastEleHeight
			// 		lastEleHeight = accordionHeaderHeight + lastEleHeight
			// 		setLastEleHeight(lastEleHeight);
			// 	}
			// }


			let accordionItem = document.getElementsByClassName('accordion-item');
			let footer = document.getElementById('footer');
			if (accordionItem.length && footer) {
				accordionItem = accordionItem[accordionItem.length - 1];
				let accordionItemBottom = accordionItem.getBoundingClientRect().bottom;
				let footerTop = footer.getBoundingClientRect().top;
				let lastEleHeight = footerTop - accordionItemBottom;
				lastEleHeight = lastEleHeight + 170;
				setLastEleHeight(lastEleHeight);
			}
		}
	}

	// remove it in next iteration
	// const toggleAccordance = (currentTabName) => {
	// 	try {
	// 		debugger
	// 		if (currentTabName !== activeTabName) {
	// 			setActiveTabName(currentTabName);
	// 			return;
	// 		}
	// 		setActiveTabName('');
	// 	} catch (err) {
	// 		console.log('Error coming from toggleAccordance()');
	// 	}
	// };

	const accordanceItemClick = (event)=>{
		const p1 = event.target.parentNode
		const p2 = p1.parentNode
	}

	return (
		<SectionAccordanceView
			status={status}
			identifyIcon={identifyIcon}
			camelCaseToWords={camelCaseToWords}
			// activeTabName={activeTabName} // remove it in next iteration
			// toggleAccordance={toggleAccordance} // remove it in next iteration
			lastEleHeight={lastEleHeight}
			openAccordance={openAccordance}
			accordanceItemClick={accordanceItemClick}
			{...props}
		/>
	);
}

export default SectionAccordance;
