import React from 'react';
import { Link } from 'react-router-dom';

import ProfileEditPlaceholder from '@assets/userDashboard/Icons/profileEditPlaceholder2.png';
import './style.css';

function DisplayDetailsView(props) {
    return (
        <>
            <div className='displayDetailsView'> 

                <div className='sTop'>
                    <div className='fHeadingContainer' style={{ marginBottom: '12px' }}>
                        <div className='fHeadingContainer_1'>
                            <h3 className='fHeading'>DISPLAY DETAILS</h3>
                        </div>
                    </div>

                    <div className='formContainer'>
                        {/* --------------- */}
                        <div className='row displayPicContainer'>
                            <div className='d1 col-9 col-sm-10'>
                                <p className='heading'>{props.userData?.displayName}</p>
                                <p className='subHeading'>
                                    This will be visible publicly, in comments, or by visiting your profile,
                                    etc. You can keep it to your liking as long as it follows our{' '}
                                    <Link to='/'>Terms of Service</Link>
                                </p>
                            </div>

                            <img
                                className='d2 col-3 col-sm-2'
                                style={{
                                    borderRadius: `${props.userData?.displayPhoto ? '50%' : '0px'}`,
                                }}
                                src={
                                    props.userData?.displayPhoto
                                        ? props.userData.displayPhoto
                                        : ProfileEditPlaceholder
                                }
                                alt='ProfileEditPlaceholder'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DisplayDetailsView;
