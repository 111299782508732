import React, { useState } from 'react';
import VideoDiscriptionSectionView from './view';
import { useNavigate } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { produce } from 'immer';

import { decryptText } from '@library/enc-dec';
import * as CourseContentServices from '@services/CourseContent';
import * as CourseDetailsActions from '@redux/actions/CourseDetails';

var reportRadioBtnVal = '';
var reportText = '';

function VideoDiscriptionSection(props) {
	const navigate = useNavigate();
	const [reportNextBtnDisable, setReportNextBtnDisable] = useState(true);
	const [copyAlterIsVisible, setCopyAlterIsVisible] = useState(false);
	const [reportSuccessMessage, setReportSuccessMessage] = useState('');

	const { currentVideoData } = props;

	//START : Functionality of reporting
	const handleReport = (event) => {
		if (document.getElementById('reportPopupBtn')) {
			document.getElementById('reportPopupBtn').click();
		}
	};

	const handleReportRadioBtn = (event) => {
		reportRadioBtnVal = event.target.value;
		controlNextBtnOfReport();
	};

	const handleReportTextarea = (event) => {
		reportText = event.target.value;
		controlNextBtnOfReport();
	};

	const controlNextBtnOfReport = () => {
		if (reportText.length > 0 && reportRadioBtnVal !== '') {
			setReportNextBtnDisable(false);
		} else {
			setReportNextBtnDisable(true);
		}
	};

	const handleReportNextBtn = async (event) => {
		try {
			const videoDocId = props.currentVideoData._id;
			let payload = {
				title: reportRadioBtnVal,
				description: reportText,
				videoDocId: videoDocId,
				location: 'Video',
			};

			let response = null;

			if (localStorage.getItem('eMediat')) {
				let authToken = decryptText(localStorage.getItem('eMediat'));
				response = await CourseContentServices.reportVideoQuery(payload, authToken);
			} else {
				response = await CourseContentServices.reportPublicVideoQuery(payload);
			}

			if (response.success) {
				handleReportSuccessUI(response);
				document.getElementById('reportPopupCloseBtn').click();
				unSetReportValues();
			}
		} catch (err) {
			console.log('Error coming while handling the reports', err);
		}
	};

	const handleReportSuccessUI = (response) => {
		setReportSuccessMessage(response.message);
		if (document.getElementById('reportModalCloseBtn')) {
			document.getElementById('reportModalCloseBtn').click();
		}
		if (document.getElementById('reportToast')) {
			let reportToastEle = document.getElementById('reportToast');
			reportToastEle.style.opacity = 1;
			
			setTimeout(function () {
				reportToastEle.style.opacity = 0;
			}, 5000);
		}
	};

	const unSetReportValues = () => {
		setReportNextBtnDisable(true);
		document.getElementById('reportTextArea').value = '';
		const radioButtons = document.querySelectorAll(
			'input[type="radio"][name="reportRadioBtn"]'
		);
		// Uncheck each radio button
		radioButtons.forEach((radio) => {
			radio.checked = false;
		});
	};

	const handleCloseReportPopup = () => {
		unSetReportValues();
	};

	//END : Functionality of reporting

	//START : Fn's of sharing videos functionality
	const handleShare = (event, subSectionId) => {
		document.getElementById('sharePopupBtn').click();
		let currentUrl = window.location.href;
		currentUrl = currentUrl.split('/');
		currentUrl =
			currentUrl[0] + '//' + currentUrl[1] + currentUrl[2] + '/' + currentUrl[3];
		// let shareUrlOLD = `${currentUrl}/${courseId}/${subSectionId}`;
		let shareUrl = `${currentUrl}/v/${subSectionId}`;
		document.getElementById('shareInput').value = shareUrl;
	};

	const handleCopy = () => {
		let copyText = document.getElementById('shareInput').value;
		navigator.clipboard.writeText(copyText);
		setCopyAlterIsVisible(true);
		setTimeout(function () {
			setCopyAlterIsVisible(false);
		}, 2000);
	};

	const handleSocailAppShare = (event, socialMediaPlatform) => {
		let shareUrl = document.getElementById('shareInput').value;
		if (shareUrl != '') {
			if (socialMediaPlatform === 'whatsApp') {
				const whatsappUrl = `https://web.whatsapp.com/send/?text=${shareUrl}`;
				window.open(whatsappUrl, '_blank');
			}

			if (socialMediaPlatform === 'gmail') {
				const email = 'recipient@example.com';
				const subject = 'Check out this link';
				const body = `Hey, check out this link: ${shareUrl}`;
				const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
					subject
				)}&body=${encodeURIComponent(body)}`;
				window.location.href = mailtoUrl;
			}

			if (socialMediaPlatform == 'facebook') {
				const facebook = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
				window.open(facebook, '_blank');
			}

			if (socialMediaPlatform == 'twitter') {
				const tshareUrl = 'https://twitter.com/intent/tweet?url=<URL>&text=<TEXT>';
				const url = encodeURIComponent(shareUrl);
				const text = encodeURIComponent('Check out eMedi');
				window.open(
					tshareUrl.replace('<URL>', url).replace('<TEXT>', text),
					'_blank'
				);
			}
		}
	};
	//END : Fn' of sharing videos functionality

	const handleLike = async () => {
		try {
			if (!localStorage.getItem('eMediat')) {
				localStorage.removeItem('eMediat');
				localStorage.removeItem('eMediatt');
				navigate('/login');
				return;
			}

			let authToken = decryptText(localStorage.getItem('eMediat'));
			const videoDocId = props.currentVideoData._id;
			const response = await CourseContentServices.likeVideo(
				videoDocId,
				authToken
			);

			if (response.success) {
				handleLikeStatus(response.data ? true : false);
			}
		} catch (err) {
			console.log('Error coming while pressing video like', err);
		}
	};

	const handleLikeStatus = (status) => {
		let updatedVideoData = produce(props.currentVideoData, (draft) => {
			draft.isLiked = status;
			if (status) {
				draft.likesCount++;
			} else {
				draft.likesCount--;
			}

			if (draft.isDisliked) {
				draft.isDisliked = false;
				draft.dislikesCount--;
			}
		});

		props.updateCurrentVideoData(updatedVideoData);
	};

	const handleDislike = async () => {
		try {
			if (!localStorage.getItem('eMediat')) {
				localStorage.removeItem('eMediat');
				localStorage.removeItem('eMediatt');
				navigate('/login');
				return;
			}

			let authToken = decryptText(localStorage.getItem('eMediat'));
			const videoDocId = props.currentVideoData._id;
			const response = await CourseContentServices.dislikeVideo(
				videoDocId,
				authToken
			);

			if (response.success) {
				handleDislikeStatus(response.data ? true : false);
			}
		} catch (err) {
			console.log('Error coming while pressing video dislike', err);
		}
	};

	const handleDislikeStatus = (status) => {
		let updatedVideoData = produce(props.currentVideoData, (draft) => {
			draft.isDisliked = status;

			if (status) {
				draft.dislikesCount++;
			} else {
				draft.dislikesCount--;
			}

			if (draft.isLiked) {
				draft.isLiked = false;
				draft.likesCount--;
			}
		});

		props.updateCurrentVideoData(updatedVideoData);
	};

	return (
		<>
			<VideoDiscriptionSectionView
				//Usestate & fn for functionality of reporting
				handleReportNextBtn={handleReportNextBtn}
				reportNextBtnDisable={reportNextBtnDisable}
				handleReportRadioBtn={handleReportRadioBtn}
				handleReportTextarea={handleReportTextarea}
				handleReport={handleReport}
				handleCloseReportPopup={handleCloseReportPopup}
				//Usestate & fn for functionality of reporting

				//Usestates and fn's of sharing vidoes functionality
				handleSocailAppShare={handleSocailAppShare}
				copyAlterIsVisible={copyAlterIsVisible}
				handleCopy={handleCopy}
				handleShare={handleShare}
				//Usestates and fn's of sharing vidoes functionality

				currentVideoData={currentVideoData}
				handleLike={handleLike}
				handleDislike={handleDislike}
				reportSuccessMessage={reportSuccessMessage}
			/>
		</>
	);
}

function mapStateToProps(state) {
	return {
		...state,
		currentVideoData: state.courseDetails.currentVideoData,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateCurrentVideoData: CourseDetailsActions.updateCurrentVideoData,
		},
		dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VideoDiscriptionSection);
