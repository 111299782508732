import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import CCAvenueResponseView from "./view";
import Navbar from "@components/Navbar";

import TopLoadingBar from "@components/TopLoadingBar";

import { useNavigate } from "react-router-dom";
import { decryptText } from "@library/enc-dec";

import * as TopLoadingBarActions from "@redux/actions/TopLoadingBar";

// import * as NavbarActions from "@redux/actions/Navbar";
// import { isUserLogedIn } from "@library/auth";

function CCAvenueResponse(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dataFetchedRef = useRef(false);

  const [isLoading, setIsLoading] = useState(true);
  const [paymentLoaderNote, setPaymentLoaderNote] = useState("");

  const [orderId, setOrderId] = useState("");
  const [amount, setAmount] = useState("");
  const [paymenyMode, setPaymenyMode] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [trackingDate, setTrackingDate] = useState("");
  const [bankRefNo, setBankRefNo] = useState("");

  useEffect(() => {
    window.scroll(0, 0);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setPaymentLoaderNote("Wating for payment response...");
    //checkLogin();
    handleCCAvenueResponse();
  }, []);

  const checkLogin = async () => {
    // const userLogedIn = await isUserLogedIn();
    // dispatch(NavbarActions.updateLoginState(userLogedIn));
    // if (!userLogedIn) {
    //   navigate("/login", { state: { message: "Please login first" } });
    //   return;
    // }
    handleCCAvenueResponse();
  };

  const handleCCAvenueResponse = async () => {
    try {
      UpdateTopLoadingBarForThisPage(100);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const order = urlParams.get("order");
      const replacedString = order.replace(/ /g, "+");
      const decryptOrderDetails = decryptText(replacedString);

      const orderObj = strToJsonOfCCAvenueResponse(decryptOrderDetails);
      const currencySymbol = decryptText(localStorage.getItem("cs"));
      // debugger
      if (
        orderObj &&
        orderObj.order_status &&
        orderObj.order_status === "Success"
      ) {

        let redirectPage = "/confirm-order";
        if (window.innerWidth > 576) {
          redirectPage = "/";
        }
        navigate(redirectPage, {
          state: {
            paymentId: orderObj.tracking_id,
            orderId: orderObj.order_id,
          },
        });

        // CODE FOR AUDIT PURPOSE
        // setOrderId(orderObj.order_id);
        // setAmount(currencySymbol + orderObj.amount);
        // setPaymenyMode(orderObj.payment_mode);
        // setTrackingId(orderObj.tracking_id);
        // setTrackingDate(orderObj.trans_date);
        // setBankRefNo(orderObj.bank_ref_no);
      }

      // CODE FOR AUDIT PURPOSE
      // setIsLoading(false);
      // setTimeout(function () {
      //   navigate("/", {});
      // }, 4000)
    } catch (err) {
      console.log("Error coming while handleHdfcPayment()", err);
    }
  };

  const strToJsonOfCCAvenueResponse = (queryString) => {
    // Split the query string by '&'
    let pairs = queryString.split("&");

    let result = {};

    pairs.forEach((pair) => {
      let keyValue = pair.split("=");
      let key = decodeURIComponent(keyValue[0]);
      let value = decodeURIComponent(keyValue[1] || "");

      // Handle nested objects or array notation if needed
      if (key.includes("[")) {
        const keys = key.split(/\[(.*?)\]/).filter(Boolean);
        let obj = result;
        for (let i = 0; i < keys.length - 1; i++) {
          if (!obj[keys[i]]) {
            obj[keys[i]] = {};
          }
          obj = obj[keys[i]];
        }
        obj[keys[keys.length - 1]] = value;
      } else {
        result[key] = value;
      }
    });

    return result;
  };

  //START: Code for setting the progressof top bar loader
  const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
    if (interval) {
      setTimeout(function () {
        dispatch(
          TopLoadingBarActions.updateLoadingBarProgress(
            props.loadingBarProgress + value
          )
        );
      }, 500);
    } else {
      dispatch(
        TopLoadingBarActions.updateLoadingBarProgress(
          props.loadingBarProgress + value
        )
      );
    }
  };
  //END: Code for setting the progressof top bar loader

  return (
    <>
      <Navbar />
      <TopLoadingBar />
      
      {/* CODE FOR AUDIT PURPOSE */}
      <CCAvenueResponseView
        paymentLoaderNote={paymentLoaderNote}
        isLoading={isLoading}
        orderId={orderId}
        amount={amount}
        paymenyMode={paymenyMode}
        trackingId={trackingId}
        trackingDate={trackingDate}
        bankRefNo={bankRefNo}
      />
    </>
  );
}

export default CCAvenueResponse;
