import React, { useEffect, useState } from "react";
import PaymentGatewayView from "./view";

import { useDispatch, useSelector } from "react-redux";

import PaymentGateways from "@library/paymentGateways";
import { decryptText } from "@library/enc-dec";

import * as CartActions from "@redux/actions/Cart";

import stripeIcon from "@assets/paymentGateway/stripe.png";
import idfcIcon from "@assets/paymentGateway/idfc.png";
import eazyPayIcon from "@assets/paymentGateway/eazypay.png";
import ccavIcon from "@assets/paymentGateway/ccav.png";
import razorpayIcon from "@assets/paymentGateway/razorpay.png";


function PaymentGateway(props) {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);


  const cartTotalValue = useSelector((state) => state.cart.cartTotalValue);
  const discountPercentageFromCart = useSelector((state) => state.cart.discountPercentage);
  const cartTotalValueInNumbers = useSelector(
    (state) => state.cart.cartTotalValueInNumbers
  );
  const selectedPaymentGateway = useSelector(
    (state) => state.cart.selectedPaymentGateway
  );
  const [currentGatewayIndex, setCurrentGatewayIndex] = useState(-1);
  const [gatewayDetails, setGatewayDetails] = useState([]);

  const [error, setError] = useState("");
  const [tnC, setTnC] = useState(true);

  const currencySymbolGlobal = decryptText(localStorage.getItem("cs"));

  const [tempCartTotalValue, setTempCartTotalValue] = useState('')
  const [tempCartTotalValueInNumbers, setTempCartTotalValueInNumbers] = useState(0)

  useEffect(() => {
    handlePaymentGateways();
  }, []);

  useEffect(() => {
    setTempCartTotalValue(`${currencySymbolGlobal} ${calculateDiscountedValueAsPerCartDiscount()}`)
  }, [cartTotalValue])
  useEffect(() => {
    setTempCartTotalValueInNumbers(calculateDiscountedValueAsPerCartDiscount())
  }, [cartTotalValueInNumbers])

  const calculateDiscountedValueAsPerCartDiscount = (cartValue=null)=>{

    let discountedValue = cartTotalValueInNumbers
    if(cartValue !== null){
      discountedValue = cartValue
    }
    if(typeof(discountedValue) === "string"){
			discountedValue = parseInt(discountedValue.split(' ')[1]);
    }
    if(discountPercentageFromCart > 0 ){
      discountedValue = discountedValue - (discountedValue * discountPercentageFromCart/100 )
    }
    // return discountedValue
    return Math.round(discountedValue)
  }

  const handlePaymentGateways = () => {
    try {
      let updatedPaymentGateways = updatePaymentGateway()
      const gateways = cartItems[0]?.paymentGateways;
      const currencySymbol = decryptText(localStorage.getItem("cs"));
      if (gateways.length > 0) {
        let allGatewayDetails = [];
        for (var gatewayType of updatedPaymentGateways) {
          const currentGateway = gateways.filter(
            (item) => item.paymentGateway === gatewayType.type
          );
          if (currentGateway.length > 0) {
            allGatewayDetails.push({
              ...gatewayType,
              discount:
                currentGateway.length > 0 ? currentGateway[0]?.discount : 0,
            });
          }
        }        

        const stripeIndex = allGatewayDetails.findIndex(item => item.type === 'stripe');
        if (stripeIndex !== -1) {
            const [stripeItem] = allGatewayDetails.splice(stripeIndex, 1);
            allGatewayDetails.unshift(stripeItem);
        }      

        setGatewayDetails(allGatewayDetails);
      } 
    } catch (err) {
      console.log("Error coming from handlePaymentGateways()", err);
    }
  };


  const updatePaymentGateway = ()=>{
    let updatedGateways = []
    PaymentGateways.forEach((gateway, index)=>{
      let updatedGateway = gateway
      if(updatedGateway.type === "eazypay"){
        updatedGateway.icon = eazyPayIcon
      }
      else if(updatedGateway.type === "razorpay"){
        updatedGateway.icon = razorpayIcon
      }
      else if(updatedGateway.type === "stripe"){
        updatedGateway.icon = stripeIcon
      }
      else if(updatedGateway.type === "ccavenue"){
        updatedGateway.icon = ccavIcon
      }
      else if(updatedGateway.type === "idfc"){
        updatedGateway.icon = idfcIcon
      }
      updatedGateways.push(gateway)
    })
    return updatedGateways
  }

  const handleTermsNCondition = (event) => {
    setError("");
    setTnC(event.target.checked);
  };

  const handlePaymentMethodChange = (index, gateway) => {
    setCurrentGatewayIndex(index);
    if (gateway.discount && gateway.discount > 0) {
      let cartValueAfterGatewayDiscount = Math.round(cartTotalValueInNumbers - cartTotalValueInNumbers * gateway.discount / 100);
      // setTempCartTotalValue(`${currencySymbolGlobal} ${cartValueAfterGatewayDiscount}`)
      // setTempCartTotalValueInNumbers(cartValueAfterGatewayDiscount)
      setTempCartTotalValue(`${currencySymbolGlobal} ${calculateDiscountedValueAsPerCartDiscount(cartValueAfterGatewayDiscount)}`)
      setTempCartTotalValueInNumbers(calculateDiscountedValueAsPerCartDiscount(cartValueAfterGatewayDiscount))
    }
    else {
      // setTempCartTotalValue(cartTotalValue)
      // setTempCartTotalValueInNumbers(cartTotalValueInNumbers)
      setTempCartTotalValue(`${currencySymbolGlobal} ${calculateDiscountedValueAsPerCartDiscount(cartTotalValue)}`)
      setTempCartTotalValueInNumbers(calculateDiscountedValueAsPerCartDiscount(cartTotalValueInNumbers))
    }
    dispatch(CartActions.updateSelectedPaymentGateway(gateway));
    setError("");
  };

  const handleSubmitPayment = () => {
    try {
      if (tnC && selectedPaymentGateway && Object.entries(selectedPaymentGateway).length > 0) {
        
        let gatewayDiscount = 0;
        if (selectedPaymentGateway.discount && selectedPaymentGateway.discount !== null) {
          gatewayDiscount = Math.round(cartTotalValueInNumbers * selectedPaymentGateway.discount / 100);
        }
        dispatch(
          CartActions.updateGatewayDiscountValue({
            gatewayDiscountValue: currencySymbolGlobal + " " + gatewayDiscount,
            gatewayDiscountValueInNumbers: gatewayDiscount,
          })
        );

        dispatch(CartActions.updateCartTotalValue(tempCartTotalValue));
        dispatch(CartActions.updateCartTotalValueInNumbers(tempCartTotalValueInNumbers));

        props.handleSidebarView("paymentGateway", {
          currentPaymentGateway: selectedPaymentGateway,
        });
      } else {
        setError(
          "Please select a payment method and agree to the terms and conditions."
        );
      }
    } catch (err) {
      console.log("Error coming from handleSubmitPayment()", err);
    }
  };

  return (
    <PaymentGatewayView
      error={error}
      currentGatewayIndex={currentGatewayIndex}
      paymentGateways={gatewayDetails}
      cartTotalValue={cartTotalValue}
      tempCartTotalValue={tempCartTotalValue}
      handlePaymentMethodChange={handlePaymentMethodChange}
      handleTermsNCondition={handleTermsNCondition}
      handleSubmitPayment={handleSubmitPayment}
      {...props}
    />
  );
}

export default PaymentGateway;