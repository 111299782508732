import React from 'react';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';
import AlertIcon from '@assets/userDashboard/Icons/alertIcon.png';
import TickIcon from '@assets/userDashboard/Icons/tickGrey.png';
import InputCheckbox from '@components/UserDashboard/Profile/InputCheckbox';
import InputFile from '@components/UserDashboard/Profile/InputFile';
import InputText from '@components/UserDashboard/Profile/InputText';
import Dropdown from '@components/UserDashboard/Profile/Dropdown';

function IdentityVerificationView(props) {
	return (
		<>
			<div className='sTop'>
				<div className='fHeadingContainer'>
					<div className='fHeadingContainer_1  justify-content-start gap-2'>
						<h3 className='fHeading'>IDENTITY VERIFICATION</h3>
						{(props.userData.isIdentityVerificationRequired && !props.userData.isIdentityInfoCompleted) && (
							<Tags span_font_size='13px'
								img_margin='5px'
								bg='#FFE9E9'
								c='#BA131A'
								bc='#F7A8AD'
								text={'Pending'}
								imgs={AlertIcon}
							/>)}
						{props.userData.isIdentityInfoCompleted &&
							<Tags span_font_size='13px'
								img_margin='5px'
								bg='#F9F9F9'
								c='#626262'
								bc='#626262'
								text={'Submitted'}
								imgs={TickIcon}
							/>
						}
						<button
							disabled={props.savingLoading}
							className='ms-auto savingLoading'
							onClick={() => {
								props.handleSave();
							}}>
							{props.savingLoading ? "Loading..." : "Save"}
						</button>
					</div>
					<p className='fPara'>
						<b>Certain certificates</b> are reserved <b>exclusively for doctors,</b>{' '}
						and we may require them for <b>identity verification</b> purposes. If your
						course does not require doctor verification, kindly upload the highest
						qualification documents you possess.
					</p>
				</div>

				<div className='formContainer'>
					{/* --------------- */}
					<Dropdown
						showError='true'
						iserror={props.iserror}
						text='Have you finished your undergraduate degree?'
						isReq='true'
						onChangeAction='isUnderGraduate'
						dropdownType="strick"
						value={props.userData.isUnderGraduate}
						listToLoop={props.undergraduateYesNo}
						onSelect={props.handleInputChange}
					/>
					{props.userData.isUnderGraduate !== null &&
						props.userData.isUnderGraduate === true ? (
						<>
							<Dropdown
								showError='true'
								iserror={props.iserror}
								text='Select Under-graduate Degree'
								isReq='true'
								onChangeAction='underGraduateDegree'
								value={props.userData.underGraduateDegree}
								listToLoop={props.allQualifications}
								onSelect={props.handleInputChange}
							/>
						</>
					) : (
						<>
							<Dropdown
								showError='true'
								iserror={props.iserror}
								text='Select Latest Qualification'
								isReq='true'
								onChangeAction='qualification'
								value={props.userData.qualification}
								listToLoop={props.allQualifications}
								onSelect={props.handleInputChange}
							/>
						</>
					)}
					{/* --------------- */}

					<InputFile
						showError='true'
						iserror={props.iserror}
						value={props.userData.marksheetOrDegreeCert}
						text='Marksheet/Degree Certificate'
						isReq='true'
						buttonId='marksheetOrDegreeCert_uploadBtn'
						inputFileId='marksheetOrDegreeCert'
						docStatus={props.userData.marksheetStatus}
						{...props}
					/>
					{/* --------------- */}

					{/* --------------- */}

					<div className='row'>
						<InputText
							showError='true'
							iserror={props.iserror}
							fullWidth="true"
							text='Name as on Marksheet/Degree Certificate'
							isReq='true'
							value={props.userData.fullName}
							onChangeAction='fullName'
							verified={props.userData.fullNameStatus === 'Verified' ? true : false}
							{...props}
						/>
					</div>
					{/* --------------- */}

					{/* --------------- */}
					<p className='fPara'>
						Enter your name exactly as it appears on the document. DO NOT include
						prefixes like Dr or Mr or Mrs. It will be later checked by our team for
						verification. Check for full stops, spacing, etc.
					</p>
					<InputFile
						showError='true'
						iserror={props.iserror}
						value={props.userData.addharOrIdProof}
						text='ID Proof'
						isReq='true'
						buttonId='addharOrIdProof_uploadBtn'
						inputFileId='addharOrIdProof'
						docStatus={props.userData.addharStatus}
						{...props}
					/>
					{/* --------------- */}

					{/* --------------- */}
					<InputFile
						showError='true'
						iserror={props.iserror}
						value={props.userData.passportPhoto}
						text='Legal Photo'
						isReq='true'
						buttonId='passportPhoto_uploadBtn'
						inputFileId='passportPhoto'
						docStatus={props.userData.passportPhotoStatus}
						{...props}
					/>
					{/* --------------- */}

					{/* --------------- */}
					<InputCheckbox
						showError='true'
						iserror={props.iserror}
						value={props.userData.identityAcknowledged}
						isReq='true'
						inputId='identityVerificationCheckBox'
						text='I acknowledge that I have provided correct information and documents.'
						{...props}
					/>
					{/* --------------- */}
				</div>
			</div>
		</>
	);
}

export default IdentityVerificationView;
