import React from 'react';
import ContentLoader from "react-content-loader";

function MobileLoader(props) {
	return (
		<>
			<ContentLoader
				viewBox='0 0 200 390'>
				
				<rect x='0' y='0' rx='0' ry='0' width='200' height='20' />

				<rect x='5' y='36' rx='2' ry='2' width='70' height='8' />
				<rect x='5' y='50' rx='2' ry='2' width='100' height='60' />
				<rect x='110' y='60' rx='2' ry='2' width='80' height='7' />
				<rect x='110' y='72' rx='2' ry='2' width='60' height='7' />
				<rect x='110' y='85' rx='2' ry='2' width='40' height='7' />

				<rect x='5' y='125' rx='2' ry='2' width='190' height='25' />
				<rect x='5' y='160' rx='2' ry='2' width='190' height='25' />
				<rect x='5' y='195' rx='2' ry='2' width='190' height='25' />
				<rect x='5' y='230' rx='2' ry='2' width='190' height='25' />
				<rect x='5' y='265' rx='2' ry='2' width='190' height='25' />
				<rect x='5' y='300' rx='2' ry='2' width='190' height='25' />
				<rect x='5' y='335' rx='2' ry='2' width='190' height='25' />

			</ContentLoader>
		</>
	);
}

export default MobileLoader;
