import React, { useEffect, useState } from "react";

import ViewTermsAndConditions from "./view";
import Navbar from "@components/Navbar";
import Footer from "@components/Footer";

import * as TermsAndConditionsServices from "@services/TermsAndConditions";

function TermsAndConditions() {
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    initilizeTermsAndConditions();
  }, []);

  const initilizeTermsAndConditions = async () => {
    try {
      let pathUrl = window.location.href.split("/").pop();
      let termAndCondition;

      if (pathUrl !== "" && pathUrl !== "airticles") {
        termAndCondition = await TermsAndConditionsServices.getTermAndCondition(
          pathUrl
        );
      } else {
        termAndCondition = await TermsAndConditionsServices.getTermAndCondition(
          ""
        );
      }
      if (termAndCondition.success) {
        setDetails(termAndCondition);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Navbar />
      <ViewTermsAndConditions isLoading={isLoading} tNcData={details} />
      <Footer />
    </>
  );
}

export default TermsAndConditions;
