import React from 'react';
import { useSelector } from 'react-redux';
import MobileView from './view';
import BgLines from '@assets/course/bgLines.png';
import Bgc1 from '@assets/course/bgc1.png';

function Mobile(props) {

	const courseDetails = useSelector((state) => state.course.courseDetails);

	const courseMobileTop = () => {
		const courseMobileTopBg = document.getElementById('courseMobileTopBg');
		const navBar = document.getElementById('navBar');
		if (courseMobileTopBg) {
			let useThisBgColor = "#6f0031";
			if(props.courseDetails.cardBackgroundColor){
				useThisBgColor = props.courseDetails.cardBackgroundColor;
			}
			courseMobileTopBg.style.background = 'linear-gradient(0deg, rgba(0,0,0,1) -1%, ' + useThisBgColor + ' 130%)';
			let height = courseMobileTopBg.getBoundingClientRect().height;
			if(navBar){
				height = height + navBar.getBoundingClientRect().height
			}
			else{
				height = height + 70;
			}
			setBgLineOverLap(height);
		}
	}
	const setBgLineOverLap = (topBgOverlapHeight) =>{
		const bgLinesOverlapMobile = document.getElementById('bgLinesOverlapMobile');
		if(bgLinesOverlapMobile){
			bgLinesOverlapMobile.style.backgroundImage = `url(${BgLines})`;
			bgLinesOverlapMobile.style.top = topBgOverlapHeight + 'px';
			let height = topBgOverlapHeight + bgLinesOverlapMobile.getBoundingClientRect().height;
			setBgBc1Overlap(height);
		}
	}
	const setBgBc1Overlap = (bgLinesOverlapMobileHeight) =>{
		const bgBc1OverlapMobile = document.getElementById('bgBc1OverlapMobile');
		if(bgBc1OverlapMobile){
			bgBc1OverlapMobile.style.backgroundImage = `url(${Bgc1})`;
			bgBc1OverlapMobile.style.top = bgLinesOverlapMobileHeight + 'px';
		}
	}


	return <MobileView
		courseDetails={courseDetails}
		courseMobileTop={courseMobileTop}
		{...props} />;
}

export default Mobile;
