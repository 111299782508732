import React from "react";

import "./style.css";
import moment from "moment";

import PDFIcon from "@assets/timeline/icons/pdf.png";
import DownloadIcon from "@assets/timeline/icons/download.png";

export default (props) => (
  <div className="enrolledTabMainContainer">
    {/* Start Summary Section */}
    <p className="heading">SUMMARY</p>
    <div className="e_row">
      <span style={{ color: "#108A50" }}>Amount Paid</span>
      <span style={{ color: "#108A50" }}>
        {props.enrolledDetails?.orderAmount}
      </span>
    </div>
    <div className="e_row">
      <span>Minimum Amount Payable</span>
      <span>{props.enrolledDetails?.orderAmount}</span>
    </div>
    {/* End Summary Section */}

    <hr style={{ marginTop: "11px" }} />

    {/* Start Invoice Section */}
    <p className="heading">INVOICES</p>
    <div className="e_row">
      <span>
        {moment(props.enrolledDetails?.createdAt).format("DD MMM, YYYY")}
      </span>
      <span>{props.enrolledDetails?.orderAmount}</span>
    </div>

    <div className="pdfHolder" onClick={(event) => props.handleInvoiceRedirect(null, "fistPayment", event)}>
      <img className="PDFIcon" src={PDFIcon} alt="pdf" />
      <div className="div_01">
        <p className="title">{props.enrolledDetails?.invoiceId}</p>
        <p className="subTitle">
          <span>
            {moment(props.enrolledDetails?.createdAt).format("DD MMM, YYYY")}
          </span>
        </p>
      </div>
      <img className="DownloadIcon" src={DownloadIcon} alt="download" />
    </div>
    {/* Start Invoice Section */}

    {/* Start Installment Section */}
    {props.enrolledDetails?.paymentStatus === "partial" && (
      <>
        {props.showPayBalanceUIStatus !== "hidePayBalanceUI" &&
          <>
            <hr style={{ marginTop: "11px" }} />

            <p className="heading">
              PAY BALANCE {props.showPayBalanceUIStatus === "paymentDone" && 'INVOICE'}
            </p>

            <div className="e_row">
              {props.showPayBalanceUIStatus === "paymentPending" ?
                <span>Pay full balance</span>
                :
                <div className="installmentC">
                  <span>
                    {moment(props.filterAndGetInstallmentOptionDetails(-1, 'createdAt')).format("DD MMM, YYYY")}
                  </span>
                  <div className="pdfHolder" onClick={(event) => props.handleInvoiceRedirect(props.filterAndGetInstallmentOptionDetails(-1, 'orderId'), "laterInstallments", event)}>
                    <img className="PDFIcon" src={PDFIcon} alt="pdf" />
                    <div className="div_01">
                      <p className="title">{props.filterAndGetInstallmentOptionDetails(-1, 'invoiceId')}</p>
                      <p className="subTitle">
                        <span>
                          {moment(props.filterAndGetInstallmentOptionDetails(-1, 'createdAt')).format("DD MMM, YYYY")}
                        </span>
                      </p>
                    </div>
                    <img className="DownloadIcon" src={DownloadIcon} alt="download" />
                  </div>
                </div>
              }
              <div className="payBtnContainer">
                <span className="amt">
                  {props.enrolledDetails.currencySymbol}
                  {props.getBalanceAmount(props.enrolledDetails)}
                </span>
                {props.showPayBalanceUIStatus === "paymentPending" &&

                  <button
                    onClick={() =>
                      props.handlePay(
                        "payFullBalance", props.getBalanceAmount(props.enrolledDetails))
                    }>
                    Pay
                  </button>
                }
              </div>
            </div>
          </>
        }

        {Object.keys(props.enrolledDetails.installmentOption)?.map(
          (key, index) => {
            let installmentOptionAll = props.enrolledDetails.installmentOption
            let item = props.enrolledDetails.installmentOption[key];
            let disabledOtp = true
            if (installmentOptionAll[index - 1] && installmentOptionAll[index - 1].isPaid === true) {
              disabledOtp = false
            }
            if (Object.values(props.enrolledDetails.installmentOption).some(payment => !payment.isPaid) || props.filterAndGetInstallmentOptionDetails(index, 'createdAt') !== null) {

              if (index === 0) {
                return;
              }
              return (
                <React.Fragment key={index}>
                  {index === 1 && <hr style={{ marginTop: "11px" }} />}
                  <div className="e_row" key={index}>

                    <div className="installmentC">
                      <span style={{ marginBottom: "4px" }}>
                        {props.installmentNoConverter(index)} Installment {item.isPaid && 'Invoice'}
                      </span>
                      {item.isPaid ?
                        (
                          <>
                            <span>
                              {moment(props.filterAndGetInstallmentOptionDetails(index, 'createdAt')).format("DD MMM, YYYY")}
                            </span>

                            <div className="pdfHolder"
                              onClick={(event) => props.handleInvoiceRedirect(props.filterAndGetInstallmentOptionDetails(index, 'orderId'), 'laterInstallments', event)}
                              style={{ marginBottom: '20px' }}>
                              <img className="PDFIcon" src={PDFIcon} alt="pdf" />
                              <div className="div_01">
                                <p className="title">{props.filterAndGetInstallmentOptionDetails(index, 'invoiceId')}</p>
                                <p className="subTitle">
                                  <span>
                                    {moment(props.filterAndGetInstallmentOptionDetails(index, 'createdAt')).format("DD MMM, YYYY")}
                                  </span>
                                </p>
                              </div>
                              <img className="DownloadIcon" src={DownloadIcon} alt="download" />
                            </div>

                          </>
                        ) : (
                          <>
                            <span className="charges">
                              +{item.extraCharge}% Charges{" "}
                            </span>
                          </>
                        )
                      }
                    </div>

                    <div className="payBtnContainer">
                      <span className="amt">
                        {props.enrolledDetails.currencySymbol}
                        {props.enrolledDetails?.courses[0].isInOffer
                          ? (props.enrolledDetails?.courses[0]
                            .offerPriceInNumbers *
                            item.discount) /
                          100
                          : (props.enrolledDetails?.courses[0]
                            .crossedPriceInNumbers *
                            item.discount) /
                          100}
                      </span>
                      {item.isPaid ? (
                        ""
                      ) : (
                        <button
                          disabled={disabledOtp}
                          onClick={() =>
                            props.handlePay(
                              index,
                              props.enrolledDetails?.courses[0].isInOffer
                                ? (props.enrolledDetails?.courses[0]
                                  .offerPriceInNumbers *
                                  item.discount) /
                                100
                                : (props.enrolledDetails?.courses[0]
                                  .crossedPriceInNumbers *
                                  item.discount) /
                                100
                            )
                          }
                        >
                          Pay
                        </button>
                      )}
                    </div>
                  </div>

                  {
                    index < props.enrolledDetails?.installment?.length - 1 && (
                      <hr style={{ marginTop: "11px" }} />
                    )
                  }
                </React.Fragment>
              );
            }

          }
        )}
      </>
    )}
    {/* Start Installment Section */}
  </div >
);
