import React from 'react';
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import './checkout.css';
import '@css/common.css';

import EIcon from '@assets/checkout/eIcon.png';
import adIcon from '@assets/checkout/adIcon.png';
import adIconTM from '@assets/checkout/adIconTM.png';
import Razorpay from '@assets/checkout/razorpay.png';

function CheckoutView(props) {
	return (
		<>
			<center>
				{props.isLoading ?
					(
						<>
							<div className='mainCheckoutContainer d-none d-sm-block'>
								<ContentLoader viewBox='0 0 400 45'>
									<rect x='0' y='10' rx='3' ry='3' width='400' height='35' />
								</ContentLoader>
							</div>

							<div className='mainCheckoutContainer d-block d-sm-none' style={{ marginTop: '30px' }}>
								<ContentLoader viewBox='0 0 400 300'>
									<rect x='0' y='10' rx='3' ry='3' width='400' height='295' />
								</ContentLoader>
							</div>

							<div className='mainCheckoutContainer'>
								<div className='innerMainCheckoutContainer'>
									<ContentLoader viewBox='0 0 300 270'>
										<rect x='0' y='0' rx='3' ry='3' width='100' height='8' />
										<rect x='0' y='20' rx='3' ry='3' width='115' height='8' />
										<rect x='0' y='40' rx='3' ry='3' width='300' height='18' />
										<rect x='0' y='70' rx='3' ry='3' width='180' height='18' />
										<rect x='235' y='70' rx='3' ry='3' width='65' height='18' />

										{/* Course cards */}
										<rect x='0' y='100' rx='3' ry='3' width='90' height='50' />
										<rect x='100' y='100' rx='3' ry='3' width='90' height='50' />
										<rect x='200' y='100' rx='3' ry='3' width='90' height='50' />

										{/* Price Left hand side*/}
										<rect x='0' y='165' rx='3' ry='3' width='50' height='8' />
										<rect x='0' y='180' rx='3' ry='3' width='50' height='8' />
										<rect x='0' y='195' rx='3' ry='3' width='50' height='8' />
										<rect x='0' y='210' rx='3' ry='3' width='50' height='8' />

										{/* Price Right hand side*/}
										<rect x='250' y='165' rx='3' ry='3' width='50' height='8' />
										<rect x='250' y='180' rx='3' ry='3' width='50' height='8' />
										<rect x='250' y='195' rx='3' ry='3' width='50' height='8' />
										<rect x='250' y='210' rx='3' ry='3' width='50' height='8' />
										<rect x='0' y='230' rx='3' ry='3' width='300' height='2' />
										<rect x='230' y='240' rx='3' ry='3' width='70' height='12' />
									</ContentLoader>
								</div>
							</div>
						</>
					) : (
						<>

							<div className='mainCheckoutContainer'>
								<div className='adContainer'>
									<div className='row container1' style={{ maxWidth: '100%' }}>
										<img
											src={EIcon}
											alt='eIcon'
											style={{ width: '80px', height: '50px' }}
											className='col-1 d-none d-sm-inline-block'
										/>
										<div className='adDescriptionContainer col-12 col-sm-5 col-xl-4'>
											<p className='p1'>Upto 50% Off</p>
											<p className='p2'>eMediSkill: Elevate Your Medical Education. Enroll Now!</p>
										</div>
										<div className='adPriceContainer text-center col-12 col-sm-3'>
											<p>Start at 6499/-</p>
											<button>
												<Link
													style={{
														color: 'unset',
														textDecoration: 'none'
													}}
													to='/offers'>
													View Offers
												</Link>
											</button>
										</div>
										<img
											src={adIconTM}
											alt='adIconTM'
											className='col-12 d-inline-block  d-sm-none'
										/>
										<img
											src={adIcon}
											alt='adIcon'
											style={{ paddingTop: '5px' }}
											className='col-2 d-none d-sm-inline-block'
										/>
									</div>
								</div>

								<div className='innerMainCheckoutContainer'>
									<div className='paymentGatway'>
										<h5 style={{ fontSize: '1.25rem' }}>Payment options</h5>
										<p>All transactions are secure and encrypted</p>

										<div>
											<input
												class='form-check-input'
												type='radio'
												name='flexRadioDefault'
												id='flexRadioDefault2'
												checked
											/>
											<label class='form-check-label' for='flexRadioDefault2'>
												<img style={{ width: '100px' }} src={Razorpay} alt='razorpay' />
											</label>
										</div>
									</div>

									<hr style={{ margin: '25px 0px' }} />

									<div className='discountCouponContainer'>
										{props.couponError && (
											<p style={{ color: 'red' }}>{props.couponError}</p>
										)}
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<input
												class='col-6 col-sm-8'
												type='text'
												placeholder='Coupon code'
												name='discountCoupon'
												id='discountCouponInput'
											/>
											<button
												class='col-5 col-sm-3 btn btn-outline-secondary'
												onClick={props.handleApplyCoupon}>
												APPLY CODE
											</button>
										</div>

										<div className='col-12' style={{ marginTop: '20px' }}>
											<div className='d-flex overflow-auto'>
												{props.cartItems &&
													props.cartItems.data?.itemsList.map((course, index) => (
														<>
															<div
																onClick={(event) => { props.handleRedirect(event, course.courseId) }}
																key={index}
																className='col-7 col-sm-4 col-lg-3 col-xl-3 couponImg shineOnCourseCard'>
																<img
																	src={course.thumbnailUrl}
																	alt={course.title}
																	className='w-100'
																/>
															</div>
														</>
													))}
											</div>
										</div>
									</div>

									<hr style={{ margin: '25px 0px' }} />
									{props.subTotal !== null && (
										<div className='amountContainer'>
											<div className='row' style={{ justifyContent: 'space-between' }}>
												<div className='col-4'>
													<p>Grand Total</p>
													<p>Promo Code</p>
													<p>GST</p>
													<p>Subtotal</p>
												</div>

												<div className='col-4' style={{ textAlign: 'right' }}>
													<p>{props.grandTotalText}</p>
													{props.couponValue !== null ? (
														<p>-{props.couponValue}</p>
													) : (
														<p>Not applied</p>
													)}
													<p>{props.GSTText}</p>
													<p>{props.subTotalText}</p>
												</div>
											</div>
											<hr style={{ margin: '22px 0px 13px 0px' }} />
											<p style={{ textAlign: 'right' }}>{props.netTotalText}</p>
											<p style={{ fontWeight: '500' }}>
												By clicking start subscription you agree to our
												<Link to='#' style={{ color: '#252525', margin: '0px 5px' }}>
													Terms
												</Link>{' '}
												and authorize this recurring change.
											</p>

											<div style={{ textAlign: 'right' }}>
												<button id='checkOutBtn' onClick={props.handleCheckout}>
													Check Out
												</button>
											</div>

											<hr />
											<p style={{ fontWeight: '500' }}>
												Payment once received will not be refunded.
											</p>
										</div>
									)}
								</div>
							</div>
						</>
					)
				}
			</center>
		</>
	);
}

export default CheckoutView;
