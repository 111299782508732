import React from 'react';

import './style.css';

export default (props) => (
	<div className='dobMainContainer'>
		{props.showError !== '' && <p className='error'>{props.showError}</p>}

		<div className='row'>
			<div className='inputConatiner col-4'>
				<input
					onChange={(event) => props.handleOnChange('day', event)}
					type='number'
					className='input'
					id='dayInput'
					placeholder=' '
				/>
				<label className='inputLabel'>Day</label>
			</div>

			<div className='inputConatiner col-4'>
				<select
					id='monthInput'
					className='input'
					onChange={(event) => props.handleOnChange('month', event)}>
					<option value=''>Month</option>
					{props.months.map((mon, index) => (
						<option value={mon} key={index}>
							{mon}
						</option>
					))}
				</select>
				<label id='monthLabel' className='inputLabel'>
					Month
				</label>
			</div>

			<div className='inputConatiner col-4'>
				<input
					onChange={(event) => props.handleOnChange('year', event)}
					type='number'
					className='input'
					id='yearInput'
					placeholder=' '
				/>
				<label className='inputLabel'>Year</label>
			</div>
		</div>

		<br />

		<div className='inputConatiner'>
			<select
				id='genderInput'
				className='input'
				onChange={(event) => props.handleOnChange('gender', event)}>
				<option value=''>Gender</option>
				<option value='male'>Male</option>
				<option value='female'>Female</option>
				<option value='other'>Other</option>
			</select>
			<label id='genderLabel' className='inputLabel'>
				Gender
			</label>
		</div>

		<button className='nextBtn' onClick={props.handleDOB}>
			Next
		</button>
	</div>
);
