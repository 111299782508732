import React, { useState } from 'react';

import CommentReplyInputView from './view';

import * as CourseContentServices from '@services/CourseContent';
// import { useNavigate } from 'react-router-dom';

import { decryptText } from '@library/enc-dec';
import { formatComment } from '@library/commentVideoTimeDuration';

function CommentReplyInput(props) {
	// const navigate = useNavigate();
	const { replyContainerId, ProfileImgPlaceholder, videoDuration } = props;
	const [userComment, setUserComment] = useState('');

	const handleUserCommentChange = (event) => {
		setUserComment(event.target.value);
	};

	const handleUserCommentCancleBtn = (event, replyContainerId) => {
		setUserComment('');

		if (document.getElementById('replyContainerId_' + replyContainerId)) {
			let replyContainer = document.getElementById(
				'replyContainerId_' + replyContainerId
			);
			replyContainer.style.display = 'none';
		}
	};

	const handleCommentReplyToDb = async (event, commentId) => {
		try {
			let formattedComment = formatComment(userComment, videoDuration);

			const replyDetails = {
				reply: formattedComment,
				commentId: commentId,
			};

			if (!localStorage.getItem('eMediat')) {
				localStorage.removeItem('eMediat');
				localStorage.removeItem('eMediatt');
				props.navigateToLogin();
				return;
			}

			let authToken = decryptText(localStorage.getItem('eMediat'));
			const response = await CourseContentServices.addReply(
				replyDetails,
				authToken
			);
			if (response.success) {
				// For this function checkout the videoComments component handleAddNewReply
				props.handleAddNewReply(response.data, commentId);
				handleUserCommentCancleBtn(event, props.replyContainerId);
			}
		} catch (err) {
			console.log('Error coming while adding repy on comment:', err);
		}
	};

	return (
		<CommentReplyInputView
			ProfileImgPlaceholder={ProfileImgPlaceholder}
			replyContainerId={replyContainerId}
			handleUserCommentChange={handleUserCommentChange}
			userComment={userComment}
			handleUserCommentCancleBtn={handleUserCommentCancleBtn}
			handleCommentReplyToDb={handleCommentReplyToDb}
		/>
	);
}

export default CommentReplyInput;
