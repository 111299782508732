import React from 'react';

import SeriesView from './view';
import { useSelector } from 'react-redux';

function Series() {
	const courseDetails = useSelector((state) => state.course.courseDetails);
	return <SeriesView seriesDetails={courseDetails?.series} />;
}

export default Series;
