const initialState = {
	orderDetails: {},
};

const OrderDetailsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CONFIRM_ORDER_DETAILS':
			return {
				...state,
				orderDetails: action.payload.orderDetails,
			};
		default:
			return state;
	}
};

export default OrderDetailsReducer;
