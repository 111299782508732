import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';

import InterRegular from '@assets/invoice/Inter-Regular.ttf';
import InterMedium from '@assets/invoice/Inter-Medium.ttf';
import InterSemiBold from '@assets/invoice/Inter-SemiBold.ttf';
import InterBold from '@assets/invoice/Inter-Bold.ttf';

Font.register({ family: 'InterRegular', src: InterRegular });
Font.register({ family: 'InterMedium', src: InterMedium });
Font.register({ family: 'InterSemiBold', src: InterSemiBold });
Font.register({ family: 'InterBold', src: InterBold });

const styles = StyleSheet.create({
	footer: {
		position: 'absolute',
		bottom: 50, // Adjust the bottom position as needed
		left: 60, // Adjust the left position as needed
		width: '100%',
		textAlign: 'center',
	},

	bottomHr: {
		padding: 0,
		borderBottom: '1pt solid #dddedd',
		width: '525px',
	},
	footerCompanyName: {
		marginTop: 5,
		fontSize: 10.5,
	},

	blueBar3: {
		backgroundColor: '#293d68',
		height: '15px',
		width: '84px',
		// transform: 'skewX(-45deg)',
		position: 'absolute',
		bottom: -53,
		right: -70,
	},

	redBar2: {
		backgroundColor: '#e21e23',
		height: '15px',
		width: '200px',
		// transform: 'skewX(-45deg)',
		position: 'absolute',
		bottom: -53,
		right: 6,
	},
});

const InvoiceFooter = ({ invoice }) => (
	<>
		<View style={styles.footer}>
			<View style={styles.bottomHr} />
			<Text style={styles.footerCompanyName}>eMediSkill Services LLP</Text>

			<View style={styles.redBar2} />
			<View style={styles.blueBar3} />
		</View>
	</>
);

export default InvoiceFooter;
