// REF : https://www.npmjs.com/package/react-image-crop#example

import React, { useEffect, useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import testSrc from "@assets/userDashboard/course/cgoCourse.png";

import "./style.css";

function DisplayImage(props) {
  const modalRef = useRef(null);
  const popupOpenBtn = useRef(null);
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const imgRef = useRef(null);

  useEffect(() => {
    if (popupOpenBtn.current) {
      popupOpenBtn.current.click();
      handleHeightOfTheModal()
    }

    const modalElement = modalRef.current;
    modalElement.addEventListener(
      "hidden.bs.modal",
      props.handleProfilePicPoupupClose
    );

    return () => {
      modalElement.removeEventListener(
        "hidden.bs.modal",
        props.handleProfilePicPoupupClose
      );
    };
  }, []);

  const handleHeightOfTheModal = () =>{
    const modalElement = modalRef.current;
    if(modalElement){
      const cropPreview = modalElement.querySelector(".cropPreview");
      if(cropPreview){
        cropPreview.style.maxHeight = window.innerHeight - 220 + "px";
      }
    }
  }

  const getCroppedImage = () => {
    return new Promise((resolve) => {
      if (imgRef.current && crop.width && crop.height) {
        const canvas = document.createElement("canvas");
        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
          imgRef.current,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        // Convert the canvas to a data URL
        const croppedImageUrl = canvas.toDataURL("image/png");

        // Resolve the promise with the cropped image data
        resolve({
          file: dataURItoBlob(croppedImageUrl),
          dataUrl: croppedImageUrl,
        });
      } else {
        resolve(null);
      }
    });
  };

  // Helper function to convert data URL to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleSave = async () => {
    const croppedImage = await getCroppedImage();
    if (croppedImage) {
      props.handleDisplayImgSave({ croppedImage: croppedImage, success: true });
    } else if (imgRef.current && imgRef.current.src) {
      const fileObjFromBase64 = {
        file: dataURItoBlob(imgRef.current.src),
        dataUrl: imgRef.current.src,
      }
      props.handleDisplayImgSave({ croppedImage: fileObjFromBase64, success: true });
    }
    else {
      props.handleDisplayImgSave({
        croppedImage: croppedImage,
        success: false,
      });
    }

    if (document.getElementById("disaplyImgModalCloseBtn")) {
      document.getElementById("disaplyImgModalCloseBtn").click();
    }
  };

  return (
    <>
      <button
        className="d-none"
        ref={popupOpenBtn}
        data-bs-toggle="modal"
        data-bs-target="#disaplyImgModal"
      >
        Launch demo modal
      </button>

      <div className="modal fade" id="disaplyImgModal" ref={modalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Display Picture</h5>
            </div>

            <div className="modal-body">
              <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
                {/* <img src={testSrc} alt='x' ref={imgRef} /> */}

                <img
                  className="cropPreview"
                  src={props.displayPhotoBinary}
                  alt="Crop Preview"
                  ref={imgRef}
                />
              </ReactCrop>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => {
                  props.handleProfilePicPoupupClose();
                }}
                id="disaplyImgModalCloseBtn"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button onClick={handleSave} className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DisplayImage;
