import React, { useState, useEffect } from 'react';

import TagsView from './view';

function Tags(props) {
    return (
        <>
            <TagsView 
                {...props} />
        </>
    );
}

export default Tags;
