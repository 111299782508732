import React from 'react';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';
import AlertIcon from '@assets/userDashboard/Icons/alertIcon.png';
import TickIcon from '@assets/userDashboard/Icons/tickGrey.png';

import InputText from '@components/UserDashboard/Profile/InputText';
import InputCheckbox from '@components/UserDashboard/Profile/InputCheckbox';

function LegalView(props) {
	return (
		<>
			<div className='sTop' id="legalInformation">
				<div className='fHeadingContainer'>
					<div className='fHeadingContainer_1 justify-content-start gap-2'>
						<h3 className='fHeading'>Legal Information</h3>
						{(props.userData.isLegalInfoRequired && !props.userData.isLegalInfoCompleted) && (
							<Tags span_font_size='13px'
								img_margin='5px'
								bg='#FFE9E9'
								c='#BA131A'
								bc='#F7A8AD'
								text={'Pending'}
								imgs={AlertIcon}
							/>)}
						{props.userData.isLegalInfoCompleted &&
							<Tags span_font_size='13px'
								img_margin='5px'
								bg='#F9F9F9'
								c='#626262'
								bc='#626262'
								text={'Submitted'}
								imgs={TickIcon}
							/>
						}
						<button
							disabled={props.savingLoading}
							className='ms-auto savingLoading'
							onClick={() => {
								props.handleSave();
							}}>
							{props.savingLoading ? "Loading..." : "Save"}
						</button>
					</div>
					<p>
						Enter the name <b>exactly</b> as it appears on government documents. The
						invoices will be generated based on this information.
					</p>
				</div>

				<div className='formContainer'>
					{/* --------------- */}
					<div className='row'>
						<InputText
							showError='true'
							iserror={props.iserror}
							text='Legal First name'
							isReq='true'
							value={props.userData.firstName}
							onChangeAction='firstName'
							{...props}
						/>

						<InputText
							showError='true'
							iserror={props.iserror}
							text='Legal Middle name'
							isReq='false'
							value={props.userData.middleName}
							onChangeAction='middleName'
							{...props}
						/>
					</div>
					{/* --------------- */}

					{/* --------------- */}
					<div className='row'>
						<InputText
							showError='true'
							iserror={props.iserror}
							text='Legal Last name'
							isReq='true'
							value={props.userData.lastName}
							onChangeAction='lastName'
							{...props}
						/>
					</div>
					{/* --------------- */}

					{/* --------------- */}
					<InputCheckbox
						showError='true'
						iserror={props.iserror}
						value={props.userData.legalInfoAcknowledged}
						isReq='true'
						inputId='legalCheckBox'
						text='I have entered the name correctly abiding by legal requirements'
						{...props}
					/>
					{/* --------------- */}
				</div>
			</div>
		</>
	);
}

export default LegalView;
