import React from 'react';
import moment from 'moment';

import CouserLoader from './loader';
import './style.css';

import Clock from '@assets/userDashboard/clock.png';

export default (props) => (
	<div className='purchaseContainer'>
		{props.isLoading ?
			(
				<>
					<h3 className='cHeading'>Courses</h3>
					<CouserLoader />
				</>
			) : (
				<>
					<div className='row'>
						<h3 className='cHeading'>Courses</h3>
						{props.courses && props.courses.data && props.courses.data.length > 0 ? (
							<>
								{props.courses.data.map((course, index) => (
									<div
										onClick={(event) => props.handleCourse(course._id)}
										key={index}
										className='col-12 col-sm-6 col-md-4 col-lg-3 courseCard'>
										<div className='shineOnCourseCard col-4 col-sm-12 col-md-12 col-lg-12'>
											<img className='w-100' src={course.thumbnailUrl} alt='exam' />
										</div>
										<div className='purchaseInnerContainer'>
											<p className='courseTitle'>{course.title}</p>
											<hr className='d-none d-sm-block' />
											<p className='timerContainer'>
												<img src={Clock} alt='clock' />
												{course.courseEndAt}
												{course.courseEndAt ? (
													<>
														<span>
															Completed on{' '}
															<b>{moment(course.courseEndAt).format('Do MMMM YYYY')}</b>
														</span>
													</>
												) : course.courseStatus === 'enrolled' ? (
													<>
														<b>Click/Tap</b> &nbsp;here to Start Course
													</>
												) : (
													<>
														Enrolled on &nbsp;
														<b>
															{moment(course.courseStartAt).format('Do MMMM YYYY')}
														</b>
													</>
												)}
											</p>
										</div>
									</div>
								))}
							</>
						) : (
							<center>
								<img
									id='noProduct'
									src='https://stores.maxfashion.in/VendorpageTheme/Enterprise/EThemeForMax/images/product-not-found.jpg'
									alt='noProduct'
								/>
							</center>
						)}
					</div>
				</>
			)}
	</div>
);
