const initialState = {
	backUpCartDetails: [],
	mobileEnrollOrAddToCartState : ""
};

const BackUpCartReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_BACKUP_CART_DETAILS':
			return {
				...state,
				backUpCartDetails: action.payload,
			};
		case 'UPDATE_MOBILE_ENROLL_OR_ADD_TO_CART_STATE':		
			return {
				...state,
				mobileEnrollOrAddToCartState: action.payload,
			};
		case 'RESET_BACKUP_CART_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default BackUpCartReducer;
