import { PaymentElement } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import "./style.css";
import * as ExamServices from '@services/Exam';

import { decryptText } from "@library/enc-dec";


export default function StripeCheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const currencySymbol = useSelector((state) => state.pay.currencySymbol);
  const grandTotal = useSelector((state) => state.pay.grandTotal);
  const discountInNumbers = useSelector((state) => state.pay.discountInNumbers);
  const gatewayDiscountInNumber = useSelector((state) => state.pay.gatewayDiscountInNumber);

  const handleStripeSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!stripe || !elements) {
        return;
      }

      setIsProcessing(true);
      setMessage("");
      const confirmPaymentStatus = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (
        confirmPaymentStatus.paymentIntent &&
        confirmPaymentStatus.paymentIntent.id &&
        confirmPaymentStatus.paymentIntent.status === "succeeded"
      ) {
        setTimeout(async () => {
          setIsProcessing(false);
          handleRedirectionForStripe(confirmPaymentStatus)
        }, 5000);
      }
      else{
        if(confirmPaymentStatus.error ){
          alert(confirmPaymentStatus.error.message);
          navigate("/");
        }
      }
    } catch (err) {
      alert("Error coming from handleStripeSubmit()", err);
      navigate("/");
    }
  };


  const handleRedirectionForStripe = async (confirmPaymentStatus) => {
    if (props.expenseType) {
      if (props.expenseType === "coursePurchase") {
        let redirectPage = "/confirm-order"
        if (window.innerWidth > 576) {
          redirectPage = "/";
        }
        navigate(redirectPage, {
          state: {
            paymentId: confirmPaymentStatus.paymentIntent.id,
            orderId: confirmPaymentStatus.paymentIntent.id,
          },
        });
      }
      else if (props.expenseType === "examReattempt") {
        if (props.purchaseDetails) {
          const purchaseDocId = props.purchaseDetails._id;
          const courseId = props.purchaseDetails.courseId;
          const examType = "Exam";

          let payload = {
            purchaseId: purchaseDocId
          }
          const authToken = decryptText(localStorage.getItem('eMediat'));
          const response = await ExamServices.reAttemptExam(examType, authToken, payload);

          if (response.success) {
            navigate('/exam', {
              state: {
                courseId: courseId,
                purchaseId: purchaseDocId,
              },
            });
          }
          else {
            console.log('Issue from handleRedirectToEaxm() ', response)
            alert('Issue in updating re-attempt exam details')
            navigate("/");
          }
        }
        else {
          alert('Purchase details not found after successful payment')
          navigate("/");
        }
      }
      else if (props.expenseType === "installment" || props.expenseType === "certificate") {
        if (props.purchaseDetails) {
          const purchaseDocId = props.purchaseDetails._id;
          navigate(`/timeline/${purchaseDocId}`);
        } else {
          alert('Purchase details not found after successful payment')
          navigate("/");
        }
      }
    }
    else {
      navigate("/");
    }
  }


  const formLoaded = () => {

  };

  return (
    <div className="stripeCheckoutForm">
      <form id="payment-form" onSubmit={handleStripeSubmit} onLoad={formLoaded}>
        <p className="orderAmount">Pay  {currencySymbol}{Math.round(parseInt(grandTotal) - discountInNumbers - gatewayDiscountInNumber)}</p>
        {message !== "" && <p class="paymentErrMessage">{message}</p>}
        <PaymentElement id="payment-element" />
        <button disabled={isProcessing || !stripe || !elements} id="submit">
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>
      </form>
    </div>
  );
}
