import React from 'react';

import { Link } from 'react-router-dom';
import ShareIcon from '@assets/video/share.png';
import ReportIcon from '@assets/video/report.png';

import './videoDiscriptionSection.css';

function VideoDiscriptionSectionView(props) {
	return (
		<>
			<div className='videoInnerContainer'>
				<p className='videoTitle'>{props.currentVideoData.title}</p>
				<div className='videoBtnContainer'>
					<button
						className='additionalVideoIconBtn likeBtn'
						videoid={props.currentVideoData._id}
						onClick={props.handleLike}>
						{props.currentVideoData.isLiked ? (
							<>
								<i
									className='fa-solid fa-thumbs-up'
									videoid={props.currentVideoData._id}></i>
							</>
						) : (
							<>
								<i
									className='fa-regular fa-thumbs-up'
									videoid={props.currentVideoData._id}></i>
							</>
						)}
						{props.currentVideoData.likesCount}
					</button>

					<button
						className='additionalVideoIconBtn dislikeBtn'
						style={{ marginRight: '12px' }}
						videoid={props.currentVideoData._id}
						onClick={props.handleDislike}>
						{props.currentVideoData.isDisliked ? (
							<>
								<i
									className='fa-solid fa-thumbs-down fa-flip-horizontal'
									videoid={props.currentVideoData._id}></i>
							</>
						) : (
							<>
								<i
									className='fa-regular fa-thumbs-down fa-flip-horizontal'
									videoid={props.currentVideoData._id}></i>
							</>
						)}
					</button>

					<button
						className='additionalVideoIconBtn'
						style={{ marginRight: '12px' }}
						onClick={(event) => {
							props.handleShare(event, props.currentVideoData._id);
						}}>
						<img
							src={ShareIcon}
							alt='share'
							style={{ width: '16px', marginRight: '5px' }}
						/>
						Share
					</button>

					<button
						className='additionalVideoIconBtn'
						onClick={(event) => {
							props.handleReport(event);
						}}>
						<img
							src={ReportIcon}
							alt='report'
							style={{ width: '16px', marginTop: '-3px', marginRight: '5px' }}
						/>
						Report
					</button>
				</div>
			</div>

			{/* START : View and discription Section */}
			<div className='videoInfo'>
				<div id='videoInfoAccordion'>
					<button
						className='accordion-button collapsed'
						data-bs-toggle='collapse'
						data-bs-target='#videoInfoCollapse'>
						{props.currentVideoData.viewsCount} views
					</button>
					<div
						id='videoInfoCollapse'
						className='collapse'
						data-bs-parent='#videoInfoAccordion'>
						<div className='accordion-body' style={{ paddingTop: '0px' }}>
							<p style={{ marginBottom: '0px' }}>
								{props.currentVideoData.description}
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* END : View and discription Section */}

			{/* START : Report popup */}
			<button
				id='reportPopupBtn'
				data-bs-toggle='modal'
				data-bs-target='#reportPopup'
				style={{ display: 'none' }}></button>

			<div className='modal fade' id='reportPopup'>
				<div className='modal-dialog modal-dialog-centered' role='document'>
					<div className='modal-content' style={{ borderRadius: '16px' }}>
						<div
							className='modal-header'
							style={{
								paddingTop: '0px',
								paddingBottom: '0px',
								borderBottom: '0px',
							}}>
							<h5 className='modal-title' id='exampleModalLongTitle'>
								Report video
							</h5>
							<button
								onClick={props.handleCloseReportPopup}
								id='reportPopupCloseBtn'
								className='btn'
								data-bs-dismiss='modal'>
								<span aria-hidden='true' style={{ fontSize: '40px' }}>
									&times;
								</span>
							</button>
						</div>
						<div
							className='modal-body'
							style={{ paddingTop: '0px', textAlign: 'left' }}>
							<div className='form-check'>
								<input
									onChange={(event) => {
										props.handleReportRadioBtn(event);
									}}
									className='form-check-input'
									value='misinformation'
									type='radio'
									name='reportRadioBtn'
									id='misinformationRadioBtn'
								/>
								<label className='form-check-label' htmlFor='misinformationRadioBtn'>
									Misinformation
								</label>
							</div>
							<div className='form-check'>
								<input
									onChange={(event) => {
										props.handleReportRadioBtn(event);
									}}
									className='form-check-input'
									value='missingPresentation'
									type='radio'
									name='reportRadioBtn'
									id='missingPresentationRadioBtn'
								/>
								<label
									className='form-check-label'
									htmlFor='missingPresentationRadioBtn'>
									Missing Presentation
								</label>
							</div>

							<div className='form-check'>
								<input
									onChange={(event) => {
										props.handleReportRadioBtn(event);
									}}
									className='form-check-input'
									value='other'
									type='radio'
									name='reportRadioBtn'
									id='otherRadioBtn'
								/>
								<label className='form-check-label' htmlFor='otherRadioBtn'>
									Other
								</label>
							</div>

							<div className='form-group' style={{ marginTop: '10px' }}>
								<textarea
									id='reportTextArea'
									onChange={(event) => {
										props.handleReportTextarea(event);
									}}
									style={{ borderRadius: '10px' }}
									className='form-control'
									rows='3'></textarea>
							</div>

							<p
								style={{
									marginTop: '15px',
									marginBottom: '0px',
									fontSize: '0.9rem',
								}}>
								Multiple reports can be sent for the same video in case of more than one
								error. In case of technical or editing errors, video gets updated as
								soon as possible. eMediSkill Team takes cases of misinformation very
								seriously.
							</p>
							<Link style={{ fontSize: '0.9rem', textDecoration: 'none' }} to='#'>
								View our Terms of Service
							</Link>

							<div style={{ marginTop: '15px', textAlign: 'right' }}>
								<button
									onClick={props.handleCloseReportPopup}
									data-bs-dismiss='modal'
									className='courseDetailsReportCancleBtn'>
									Cancel
								</button>
								<button
									onClick={props.handleReportNextBtn}
									id='courseDetailsReportNextBtn'
									disabled={props.reportNextBtnDisable}
									className={`courseDetailsReportNextBtn ${
										!props.reportNextBtnDisable && 'courseDetailsReportNextBtnAddOn'
									}`}>
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* END : Report popup */}

			<div id='reportToast' className='toast bg-success'>
				{props.reportSuccessMessage}
			</div>
		</>
	);
}

export default VideoDiscriptionSectionView;
