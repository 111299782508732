import React from 'react';

import ConfirmOrder from '@pages/ConfirmOrder';
import RightArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';
import './style.css';

function ConfirmOrderView(props) {
	return (
		<>
			<div className='homePageConfirmOrderView'>

				<button
					ref={props.openhomePageConfirmOrderViewModalBtn}
					type="button"
					className="d-none"
					data-bs-toggle="modal"
					data-bs-target="#homePageConfirmOrderViewModal">
					Launch demo modal
				</button>


				<div className="modal fade" id="homePageConfirmOrderViewModal" ref={props.modalRef}>
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								{props.showInVoicePossible &&
									<ConfirmOrder
										emptyLocationSateValues={props.emptyLocationSateValues}
										handleDownloadInvoiceFromHomePage={props.handleDownloadInvoiceFromHomePage}
										fromHomePage={true}
									/>
								}
							</div>

							<button
								data-bs-dismiss="modal"
								ref={props.closehomePageConfirmOrderViewModalBtn}
								onClick={props.handleRedirectToDashboard}
								type="button"
								className="btn btn-primary continueToDashboardBtn">
								Continue to Dashboard
								<img src={RightArrow} alt='RightArrow' />
							</button>
						</div>
					</div>
				</div>

			</div>
		</>
	);
}

export default ConfirmOrderView;
