const initialState = {
	pageViewDocId : ""
};

const PageViewReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_PAGE_VIEW_DOC_ID':		
			return {
				...state,
				pageViewDocId: action.payload,
			};
		case 'RESET_PAGE_VIEW_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default PageViewReducer;
