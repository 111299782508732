export const verifyLeadsOTP = async (payload, OTPToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/verifyLeadsOTP`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: OTPToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Verify OTP services of Login :', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addToLead = async (seoUrlSlug, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addToLead`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify({
				seoUrlSlug: seoUrlSlug,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Verify OTP services of Login :', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
