import React from 'react';

import CoursesHolderMobileCartView from './view';

function CoursesHolderMobileCart(props) {
	const constNumOfCoursePlaceholder = 5;

	return (
		<>
			<CoursesHolderMobileCartView
				constNumOfCoursePlaceholder={constNumOfCoursePlaceholder}
				handleRemoveCartItem={props.removeCartItem} // this fn removeCartItem is defined in @components\Popups\CoursePagePopupsContent\Cart\index.js
				{...props}
			/>
		</>
	);
}

export default CoursesHolderMobileCart;
