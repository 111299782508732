import React from 'react';

import DisplayDetailsView from './view';

function DisplayDetails(props) {

    return (
        <>
            <DisplayDetailsView 
                userData={props.userData}
                />
        </>
    );
}

export default DisplayDetails;
