import React from 'react';

import ProfileImgPlaceholder from '@assets/video/profileImgPlaceholder.png';
import './replySection.css';

import numeral from 'numeral';
import moment from 'moment';

import { formatLikesFn } from '@library/formatLikes';

function ReplySectionView(props) {
	return (
		<>
			{props.commentRepliesData && props.commentRepliesData.length > 0 && (
				<>
					<div
						className='accordion commentsAccordion'
						id={'commentsReplyAccordion_' + props.currentCommentId}>
						<button
							className='accordion-button collapsed'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target={'#commentRepliesCollapse_' + props.currentCommentId}>
							{props.commentRepliesData.length}{' '}
							{props.commentRepliesData.length > 1 ? 'replies' : 'reply'}
						</button>

						<div
							id={'commentRepliesCollapse_' + props.currentCommentId}
							className='accordion-collapse collapse'
							data-bs-parent={'#commentsReplyAccordion_' + props.currentCommentId}>
							<div className='row accordion-body'>
								{props.commentRepliesData.map((replyData, replyIndex) => (
									<>
										<div style={{ display: 'flex' }}>
											<div className='col-profileImgPlaceholder'>
												<img
													src={
														replyData.displayPhoto
															? replyData.displayPhoto
															: ProfileImgPlaceholder
													}
													alt='Profile Placeholder'
													style={{ width: '30px' }}
												/>
											</div>

											<div
												className='col-commentContainer'
												style={{ paddingLeft: '10px' }}>
												<p className='commenterName'>
												{replyData.displayName? replyData.displayName: replyData.username}
													<span className='commentTime'>
														{moment(replyData.createdAt).fromNow()}
													</span>
												</p>

												<p className='comment'>
												<div dangerouslySetInnerHTML={{ __html: replyData.reply }} />
												</p>

												<div className='commentInteractionContainer'>
													
													<p
														className='commentLikeContainer'
														onClick={(event) => props.handleLikeReply(replyData._id)}>
														{replyData.isLiked ? (
															<i className='fa-solid fa-thumbs-up'></i>
														) : (
															<i className='fa-regular fa-thumbs-up'></i>
														)}
														<span>
															{formatLikesFn(replyData.likesCount)}
														</span>
													</p>

													<p
														className='commentDislikeContainer'
														onClick={(event) => props.handleDislikeReply(replyData._id)}>
														{replyData.isDisliked ? (
															<i className='fa-solid fa-thumbs-down fa-flip-horizontal'></i>
														) : (
															<i className='fa-regular fa-thumbs-down fa-flip-horizontal'></i>
														)}
													</p>

													<button
														className='commentReplyBtn'
														data-reply_container_id={replyData._id}
														data-has_reply_container='false'
														onClick={(event) => {
															props.handleCommentReply(event, props.currentCommentId);
														}}>
														Reply
													</button>
												</div>
											</div>
										</div>
									</>
								))}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default ReplySectionView;
