import React from "react";
import { Link } from "react-router-dom";

import Tags from "@components/Tags";
import Toster from "@components/Toster";

import InputError from "@assets/userDashboard/Icons/inputError.png";
import AlertIcon from "@assets/userDashboard/Icons/alertIcon.png";
import TickIcon from "@assets/userDashboard/Icons/tickGrey.png";

import InputText from "@components/UserDashboard/Profile/InputText";
import InputDate from "@components/UserDashboard/Profile/InputDate";
import InputContactNo from "@components/UserDashboard/Profile/InputContactNo";
import VerificationPopup from "@components/UserDashboard/Profile/VerificationPopUp";
import DisplayImage from "@components/UserDashboard/Profile/DisplayImage";

import ProfileEditPlaceholder from "@assets/userDashboard/Icons/profileEditPlaceholder.png";
import VerifiedTick from "@assets/userDashboard/Icons/verifiedTick.png";
import { useSelector } from "react-redux";

function BasicInfoView(props) {
  const profileError = useSelector((state) => state.dashboard.profileError);
  return (
    <>
      <div className="sTop" id="personalInformation">
        {profileError ? (
          <div class="alert alert-danger" role="alert">
            {profileError}
          </div>
        ) : null}
        <div className="fHeadingContainer" style={{ marginBottom: "12px" }}>
          <div className="fHeadingContainer_1 justify-content-start gap-2">
            <h3 className="fHeading ">BASIC DETAILS</h3>
            {props.userData.isBasicDetailsRequired &&
              !props.userData.isPersonalInfoCompleted && (
                <Tags
                  span_font_size="13px"
                  img_margin="5px"
                  bg="#FFE9E9"
                  c="#BA131A"
                  bc="#F7A8AD"
                  text={"Pending"}
                  imgs={AlertIcon}
                />
              )}
            {props.userData.isPersonalInfoCompleted && (
              <Tags
                span_font_size="13px"
                img_margin="5px"
                bg="#F9F9F9"
                c="#626262"
                bc="#626262"
                text={"Submitted"}
                imgs={TickIcon}
              />
            )}
            <button
              disabled={props.savingLoading}
              className='ms-auto savingLoading'
              onClick={() => {
                props.handleSave();
              }}
            >
              {props.savingLoading ? "Loading..." : "Save"}
            </button>
          </div>
        </div>

        <div className="formContainer">
          {/* --------------- */}
          <div className="row displayPicContainer">
            <div className="d1 col-7 col-sm-9">
              <p className="heading">Display picture</p>
              <p className="subHeading">
                This will be visible publicly, in comments, or by visiting your
                profile, etc. You can keep it to your liking as long as it
                follows our <Link to="/">Terms of Service</Link>
              </p>
            </div>

            <img
              onClick={(e) =>
                props.handleProfilePicPoupup("displayPicFileInput")
              }
              className={`d2 col-3 col-sm-2 ${props.userData.displayPhoto ? 'rounded-circle' : ''}`}
              style={{ width: "120px", height: "100px" }}
              src={
                props.userData.displayPhoto
                  ? props.userData.displayPhoto
                  : ProfileEditPlaceholder
              }
              alt="ProfileEditPlaceholder"
            />

            <input
              id="displayPicFileInput"
              className="d-none"
              type="file"
              accept="image/jpeg, image/png, image/jpg"
            />
            {props.displayPhotoBinary && props.displayPhotoBinary !== null && (
              <DisplayImage {...props} />
            )}
          </div>

          {/* --------------- */}

          {/* --------------- */}
          <div className="row">
            <InputText
              showError="true"
              iserror={props.iserror}
              text="Display Name"
              isReq="true"
              value={props.userData.displayName}
              onChangeAction="displayName"
              extraParaText="You can keep the display name to whatever you like as long as it follows our "
              anchorText="Terms of Service"
              anchorLink="/articles"
              {...props}
            />

            <InputText
              showError="true"
              iserror={props.iserror}
              text="Username"
              isReq="true"
              value={props.userData.username}
              onChangeAction="username"
              {...props}
            // disabled={true}
            />
          </div>
          {/* --------------- */}

          {/* --------------- */}
          <div className="row">
            <div className="inputContainer_x inputContainerSupportVerify col-12 col-sm-6">
              <p className="inputLable d-flex">
                <span style={{ marginRight: '7px' }}>
                  Email<b className="asterisk">*</b>
                </span>

                {(props.iserror || props.emailError !== "") && (
                  <Tags
                    span_font_size="10px"
                    img_margin="5px"
                    bg="#FFE9E9"
                    c="#962020"
                    bc="#A62323"
                    text={
                      props.emailError && props.emailError !== ""
                        ? props.emailError
                        : "Input Required"
                    }
                    imgs={InputError}
                  />
                )}


              </p>
              <div>

                <input
                  type="text"
                  value={props.userData.email}
                  onChange={(event) => {
                    props.handleInputChange(event, "email");
                  }}
                  readOnly={props.userData.isEmailVerified}
                />

                {props.userData.isEmailVerified ? (
                  <>
                    <span className="rightEle verifiedContainer">
                      <img src={VerifiedTick} alt="VerifiedTick" />
                      <span className="d-inline d-sm-none d-md-inline">
                        Verified
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    <button
                      disabled={props.emailSentVerificationLoading}
                      onClick={() => {
                        props.handleEmailVerification(
                          props.userData.email,
                          "first"
                        );
                      }}
                      className="rightEle"
                    >
                      {props.emailSentVerificationLoading ? "Loading..." : "Verify"}
                    </button>
                  </>
                )}

                {props.reSendEmail && (
                  <div className="extraParaText">
                    <span>Click on verification link on email ID!&nbsp;</span>
                    <button
                      disabled={props.emailSentVerificationLoading}
                      onClick={() => {
                        props.handleEmailVerification(
                          props.userData.email,
                          "notfirst"
                        );
                      }}
                      className="reSendBtn"
                    >
                      {props.emailSentVerificationLoading ? "Loading..." : "Resend"}
                    </button>
                    {/* {props.reSendEmailNote && <span> Email sent</span>} */}
                  </div>
                )}
              </div>
            </div>

            <InputDate
              showError="true"
              iserror={props.iserror}
              text="Birthday"
              isReq="true"
              value={props.userData.dob}
              onChangeAction="dob"
              {...props}
            />
          </div>
          {/* --------------- */}

          {/* --------------- */}
          <div className="row">
            <InputContactNo
              showError="true"
              iserror={props.iserror}
              currentError={props.contactNoError}
              isReq="true"
              text="Contact Number"
              onCountryCodeChange="countryCode"
              onNumberChange="contactNumber"
              verificationProcess="true"
              countryCode={props.userData.countryCode}
              contactNumber={props.userData.contactNumber}
              isContactNumberVerified={props.userData.isContactNumberVerified}
              isContactChanged={props.isContactChanged}
              {...props}
            />


            <InputContactNo
              showError="true"
              iserror={props.iserror}
              currentError=""
              isReq="true"
              text="WhatsApp Number"
              onCountryCodeChange="whatsAppCountryCode"
              onNumberChange="whatsAppNumber"
              verificationProcess="false"
              countryName={props.userData.whatsAppCountry}
              countryCode={props.userData.whatsAppCountryCode}
              contactNumber={props.userData.whatsAppNumber}
              {...props}
            />
          </div>
          {/* --------------- */}
        </div>
      </div>

      {/* --------------- */}
      <VerificationPopup {...props} />
      {/* --------------- */}



      <Toster
        isVisiable={props.tosterIsVisiable}
        bg={props.tosterBg}
        c={props.tosterC}
        text={props.tosterText}
      />
    </>
  );
}

export default BasicInfoView;
