import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as DashboardServices from "@services/UserDashboard";

import * as NavbarActions from "@redux/actions/Navbar";
import * as BasicFormActions from "@redux/actions/BasicForm";
import { isUserLogedIn } from "@library/auth";

function VerifyEamil(props) {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    window.scroll(0, 0);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    checkLogin();
    handlePage();
  }, []);

  const checkLogin = async () => {
    const userLogedIn = await isUserLogedIn();
    props.updateLoginState(userLogedIn);
  };

  const handlePage = async () => {
    handleEmailVerification();
  };

  const handleEmailVerification = async () => {
    try {
      const emailToken = window.location.href.split("?token=").pop();
      const emailVerifcation = await DashboardServices.verifyEmail(emailToken);
      if (emailVerifcation.success) {
        props.updateDetails(emailVerifcation.data);
        alert(emailVerifcation.message);
        navigate("/");
        return;
      }
      // Mahdi : Show flash message to user on dashboard screen insted of alert
      alert("Invalid email Token");
    } catch (err) {
      console.log("Error coming from : handleEmailVerification()", err);
    }
  };
  return (
    <div>
      <p>Wait for moment we are verifying your email</p>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isLogedIn: state.navbar.loginState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateLoginState: NavbarActions.updateLoginState,
      updateDetails: BasicFormActions.updateDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEamil);
