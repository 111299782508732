import React, { useState, useEffect } from 'react';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';

import VerifiedTick from '@assets/userDashboard/Icons/verifiedTick.png';

function InputContactNo(props) {
	const allCountries = require('@assets/countryCode.json');
	// debugger;
	return (
		<>
			<div className='inputContainer_x col-12 col-sm-6'>
				<p className='inputLable d-flex'>
					<span style={{ marginRight: '7px' }}>
						{props.text}
						{props.isReq === 'true' && <b className='asterisk'>*</b>}
					</span>

					{props.isReq === 'true' &&
						props.showError &&
						props.showError === 'true' &&
						(props.iserror || props.currentError !== "") &&
						(!props.contactNumber ||
							props.contactNumber === '' ||
							props.contactNumber === null) && (
							<Tags
								span_font_size='10px'
								img_margin='5px'
								bg='#FFE9E9'
								c='#962020'
								bc='#A62323'
								text={props.currentError && props.currentError !== "" ? props.currentError : 'Input Required'}
								imgs={InputError}
							/>
						)}


				</p>

				<div className='contactNumberComContainer inputContainerSupportVerify d-flex align-items-baseline'>
					<select
						value={props.countryName}
						style={{ width: '60px' }}
						onChange={(event) => {
							props.handleInputChange(event, props.onCountryCodeChange);
						}}
						disabled={props.isContactNumberVerified}
					>
						{!props.countryCode &&
							<option value=""></option>
						}

						{allCountries.map((item, index) => (
							<option
								countrycode={item.countryCode}
								key={index}
								value={item.countryName}>
								{props.countryCode === item.countryCode
									? `${item.countryCode}`
									: `${item.countryName} ${item.countryCode}`}
							</option>
						))}
					</select>


					<input
						onChange={(event) => {
							props.handleInputChange(event, props.onNumberChange);
						}}
						value={props.contactNumber}
						type='number'
						readOnly={props.isContactNumberVerified}
					/>

					{props.verificationProcess && props.verificationProcess === 'true' && (
						<>
							{props.isContactNumberVerified ? (
								<>
									<span style={{ right: '0px' }} className='rightEle verifiedContainer'>
										<img src={VerifiedTick} alt='VerifiedTick' />
										<span className='d-inline d-sm-none d-md-inline'>Verified</span>
									</span>
								</>
							) : props.isContactChanged === false ? (
								<>
									<button
										onClick={() => {
											props.handleContactVerification(props.contactNumber);
										}}
										style={{ right: '0px' }}
										className='rightEle'>
										Verify
									</button>
								</>
							) : ''}
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default InputContactNo;
