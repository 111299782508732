import React, { useState, useEffect } from 'react';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';
import TickIcon from '@assets/userDashboard/Icons/verifiedTick.png';
import AlertIcon from '@assets/userDashboard/Icons/alertIcon.png';

function InputSelect(props) {

	return (
		<>
			<div className='col-12 col-sm-6 inputContainer_x' style={{ position: 'relative' }}>
				<p className='inputLable d-flex'>
					{props.text}

					{props.isReq === 'true' &&
						props.showError &&
						props.showError === 'true' &&
						props.iserror &&
						(!props.value || props.value === '' || props.value === null) && (
							<Tags
								span_font_size='10px'
								img_margin='5px'
								bg='#FFE9E9'
								c='#962020'
								bc='#A62323'
								text='Input Required'
								imgs={InputError}
							/>
						)}
				</p>

				{props.verified &&
					<div style={{ position: 'absolute', right: 0 }}><Tags span_font_size='13px'
						img_margin='5px'
						bg='#EEFFE4'
						c='#139528'
						bc='#139528'
						text={'Verified'}
						imgs={TickIcon}
					/></div>
				}
				{props.rejected &&
					<div style={{ position: 'absolute', right: 0 }}><Tags span_font_size='13px'
						img_margin='5px'
						bg='#FFE9E9'
						c='#BA131A'
						bc='#F7A8AD'
						text={'Rejected'}
						imgs={AlertIcon}
					/></div>
				}

				<select
					value={props.value}
					disabled={props.disabled}
					onChange={(event) => {
						props.handleInputChange(event, props.onChangeAction);
					}}>
					{!props.value &&
						<option value="">Please select {props.text}</option>
					}
					{props.listToloop.map((item, index) => (
						<option
							key={index} value={item}>
							{item}
						</option>
					))}
				</select>
			</div>
		</>
	);
}

export default InputSelect;
