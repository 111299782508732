import React from 'react';

import moment from 'moment';
import './style.css';
import ContentLoader from 'react-content-loader';

function CheckoutView(props) {
	return (
		<>
			<center>
				{props.isLoading ? (
					<>
						<div className='mainConfirmOrderContainer d-none d-sm-block'>
							<ContentLoader viewBox='0 0 380 250'>
								<rect x='0' y='0' rx='2' ry='2' width='100' height='8' />

								<rect x='0' y='15' rx='2' ry='2' width='200' height='8' />

								{/* Transaction details */}
								<rect x='0' y='35' rx='2' ry='2' width='380' height='1' />
								<rect x='0' y='45' rx='2' ry='2' width='50' height='15' />
								<rect x='100' y='45' rx='2' ry='2' width='50' height='15' />
								<rect x='200' y='45' rx='2' ry='2' width='50' height='15' />
								<rect x='0' y='70' rx='2' ry='2' width='380' height='1' />

								{/* course card 1 */}
								{/* course card */}
								<rect x='0' y='80' rx='2' ry='2' width='90' height='50' />
								{/* course title */}
								<rect x='100' y='85' rx='2' ry='2' width='90' height='5' />
								{/* course discription */}
								<rect x='100' y='95' rx='2' ry='2' width='150' height='5' />
								{/* course price */}
								<rect x='350' y='85' rx='2' ry='2' width='30' height='10' />

								{/* course card 2 */}
								{/* course card */}
								<rect x='0' y='140' rx='2' ry='2' width='90' height='50' />
								{/* course title */}
								<rect x='100' y='145' rx='2' ry='2' width='90' height='5' />
								{/* course discription */}
								<rect x='100' y='155' rx='2' ry='2' width='150' height='5' />
								{/* course price */}
								<rect x='350' y='145' rx='2' ry='2' width='30' height='10' />

								<rect x='0' y='200' rx='2' ry='2' width='250' height='8' />
								<rect x='0' y='215' rx='2' ry='2' width='80' height='15' />
							</ContentLoader>
						</div>

						<div className='mainConfirmOrderContainer d-block d-sm-none'>
							<ContentLoader viewBox='0 0 200 300'>
								<rect x='0' y='0' rx='2' ry='2' width='100' height='8' />

								<rect x='0' y='15' rx='2' ry='2' width='200' height='8' />

								{/* Transaction details */}
								<rect x='0' y='35' rx='2' ry='2' width='200' height='1' />

								<rect x='0' y='45' rx='2' ry='2' width='60' height='20' />
								<rect x='0' y='75' rx='2' ry='2' width='60' height='20' />
								<rect x='0' y='105' rx='2' ry='2' width='60' height='20' />

								<rect x='0' y='135' rx='2' ry='2' width='200' height='1' />

								{/* course card 1 */}
								{/* course card */}
								<rect x='0' y='150' rx='2' ry='2' width='90' height='50' />
								{/* course title */}
								<rect x='100' y='155' rx='2' ry='2' width='80' height='5' />
								{/* course discription */}
								<rect x='100' y='165' rx='2' ry='2' width='90' height='5' />
								{/* course price */}
								<rect x='100' y='175' rx='2' ry='2' width='30' height='5' />

								{/* course card 1 */}
								{/* course card */}
								<rect x='0' y='210' rx='2' ry='2' width='90' height='50' />
								{/* course title */}
								<rect x='100' y='215' rx='2' ry='2' width='80' height='5' />
								{/* course discription */}
								<rect x='100' y='225' rx='2' ry='2' width='90' height='5' />
								{/* course price */}
								<rect x='100' y='235' rx='2' ry='2' width='30' height='5' />

								<rect x='0' y='270' rx='2' ry='2' width='200' height='8' />
								<rect x='0' y='285' rx='2' ry='2' width='90' height='15' />
							</ContentLoader>
						</div>
					</>
				) : (
					<>
						<div className='mainConfirmOrderContainer'>
							<h3 style={{ marginBottom: '30px', color: '#038B00' }}>
								Your order is Confirmed!
							</h3>
							<p style={{ marginBottom: '5px' }}>
								Hello {props.confirmOrderDetails?.data?.firstName},
							</p>


							<hr />

							<div className='row confirmOrderInfoContainer'>
								<div className='col-12 col-sm-4 col-md-3'>
									<p className='heading'>Date</p>
									<p className='value'>
										{moment(props.confirmOrderDetails?.data?.createdAt).format(
											'DD/MM/YYYY'
										)}
									</p>
								</div>

								<div className='col-12 col-sm-5 col-md-3'>
									<p className='heading'>Transaction Id.</p>
									<p className='value'>{props.confirmOrderDetails?.data?.orderId}</p>
								</div>

								<div className='col-12 col-sm-4 col-md-3'>
									<p className='heading'>Payment mode</p>
									<p className='value'>
										{props.confirmOrderDetails?.data?.paymentMethod.toUpperCase()}
									</p>
								</div>
							</div>

							<hr />

							<div className='row confirmOrderDetailsContainer'>
								{props.confirmOrderDetails !== null &&
									props.confirmOrderDetails?.data?.courses?.map((item, index) => (
										<>
											<div className='row' key={index} style={{ marginBottom: '25px' }}>
												<div className='col-6 col-sm-5 col-md-4 col-xl-3 imgContainer'>
													<img
														className='w-100'
														src={item.rectangleThumbnailUrl}
														alt={`Coupon ${index}`}
													/>
												</div>

												<div className='col-6 col-sm-7 col-md-5 col-xl-6 textContainer'>
													<p className='heading'>{item.title}</p>
													<p className='description'>{item.description}</p>
													<h4 className='d-block d-md-none priceTM'>
														{item.isInOffer ? item.offerPrice : item.crossedPrice}
													</h4>
												</div>

												<div className='d-none d-md-block col-md-3 col-xl-3 priceContainer'>
													<h4>{item.isInOffer ? item.offerPrice : item.crossedPrice}</h4>
												</div>
											</div>
										</>
									))}
							</div>

							<hr />

							<p>Download your payment recepit from below</p>

							<p style={{ fontWeight: '700', marginBottom: '0px' }}>
								Thank you for joining us
							</p>
							<p>emediskill Team</p>
							<button
								id='downloadPaymentRecepit'
								onClick={(event) => props.handleDownloadInvoice(event)}>
								Download payment recepit
							</button>
						</div>
					</>
				)}
			</center>
		</>
	);
}

export default CheckoutView;
