export const updateBackUpCartDetails = (value) => {
	return {
		type: 'UPDATE_BACKUP_CART_DETAILS',
		payload: value,
	};
};

export const updateMobileEnrollOrAddToCartState = (value) => {
	return {
		type: 'UPDATE_MOBILE_ENROLL_OR_ADD_TO_CART_STATE',
		payload: value,
	};
};


export const resetBackUpCartReducers = () => {
	return {
		type: 'RESET_BACKUP_CART_REDUCER',
	};
};
