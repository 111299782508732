import { decryptText } from '@library/enc-dec';
import * as LoginServices from '@services/Login';

export const isUserLogedIn = async () => {
	try {
		if (localStorage.getItem('eMediat') && localStorage.getItem('eMediatt')) {
			let eMediatt = decryptText(localStorage.getItem('eMediatt'));
			let currentTime =  Date.now();
			let thresholdFromTheRecentlyLogin = 30000; // value in milliseconds
			if(currentTime - Number(eMediatt) < thresholdFromTheRecentlyLogin){
				return true;
			}
			let localAuth = decryptText(localStorage.getItem('eMediat'));
			let loginAuth = await LoginServices.getLoginAuth(localAuth);
			if (loginAuth.success) {
				if (loginAuth.data === localAuth.split(' ')[1]) {
					let geteMediatt = decryptText(localStorage.getItem('eMediatt'));
					let checkLoginExpiry = Date.now() - geteMediatt;
					checkLoginExpiry = checkLoginExpiry / (1000 * 60 * 60 * 24);

					if (checkLoginExpiry < 8) {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
	} catch (e) {
		console.error('Unable to access localStorage');
		return false;
	}
};
