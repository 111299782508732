import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { produce } from "immer";

import moment from "moment";

import BasicInfoView from "./view";

import { encryptText } from "@library/enc-dec";

import * as UserDashboardServices from "@services/UserDashboard";
import * as UserDashboardActions from "@redux/actions/UserDashboard";
import * as TimelineRedirectActions from '@redux/actions/TimelineRedirect';

import * as LoginServices from "@services/Login";

import { decryptText } from "@library/enc-dec";

function BasicInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iserror, setIserror] = useState(false);
  const [contactNoError, setContactNoError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [reSendEmail, setReSendEmail] = useState(false);

  const [verificationPopupStage, setVerificationPopupStage] = useState("init");
  const [otpToken, setOTPToken] = useState(null);
  const contactNoVerificationPopupOpenBtn = useRef(null);
  const contactNoVerificationPopupCloseBtn = useRef(null);
  const modalRef = useRef(null);

  const user = useSelector((state) => state.dashboard.user);
  const timelineRedirectDetails = useSelector((state) => state.timelineRedirect.timelineRedirectDetails);

  const [displayPhotoBinary, setDisplayPhotoBinary] = useState(null);
  const [displayPhotoFile, setDisplayPhotoFile] = useState(null);
  const [isContactChanged, setIsContactChanged] = useState(false);

  const [emailSentVerificationLoading, setEmailSentVerificationLoading] = useState(false)
  const [tosterIsVisiable, setTosterIsVisiable] = useState(false)
  const [tosterText, setTosterText] = useState("")
  const [tosterBg, setTosterBg] = useState("")
  const [tosterC, setTosterC] = useState("")

  const [savingLoading, setSavingLoading] = useState(false);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.addEventListener(
        "hidden.bs.modal",
        clickRefBtn(contactNoVerificationPopupCloseBtn)
      );
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener(
          "hidden.bs.modal",
          clickRefBtn(contactNoVerificationPopupCloseBtn)
        );
      }
    };
  }, []);

  const handleUserUpdate = (key, value) => {
    try {
      setIserror(false);
      let updatedUser = produce(user, (draft) => {
        draft[key] = value;
      });

      dispatch(UserDashboardActions.updateUserDetails(updatedUser));
    } catch (err) {
      console.log("Basic Form Info : Error coming from handleTextInput()", err);
    }
  };

  const handleProfilePicPoupup = (id) => {
    try {
      let fileInptId = id;
      let fileElement = document.getElementById(fileInptId);
      fileElement.value = "";
      document.getElementById(fileInptId).click();

      document
        .getElementById(fileInptId)
        .addEventListener("change", function (event) {
          // let fileName = this.value.replace(/C:\\fakepath\\/i, '');
          // const file = event.target.files[0];
          if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
              setDisplayPhotoBinary(reader.result);
              // dispatch(
              //   UserDashboardActions.updateUserDetails({
              //     ...user,
              //     displayPhoto: reader.result,
              //   })
              // );
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleProfilePicPoupupClose = () => {
    setDisplayPhotoBinary(null);
  };

  const handleDisplayImgSave = (response) => {
    if (response.success) {
      response = response.croppedImage;
      let dataUrl = response.dataUrl;
      let file = response.file;

      handleUserUpdate("displayPhoto", dataUrl);
      setDisplayPhotoFile(file);
    }
  };

  const handleInputChange = (event, inputAction) => {
    setContactNoError("");
    setEmailError("");
    let eventType = event.target.type;
    let inputValue = event.target.value;
    if (eventType === "checkbox") {
      inputValue = event.target.checked;
    }

    switch (inputAction) {
      case "dob":
        handleDOB(inputValue);
        break;
      case "countryCode":
        handleCountryCode(event, inputAction);
        break;
      case "whatsAppCountryCode":
        handleCountryCode(event, inputAction);
        break;
      case "contactNumber":
        setIsContactChanged(true);
      default:
        handleUserUpdate(inputAction, inputValue);
        break;
    }
  };

  const handleDOB = (text) => {
    try {
      setIserror(false);
      const ISODate = moment(text).toISOString();
      let updatedUser = produce(user, (draft) => {
        draft.dob = ISODate;
      });
      dispatch(UserDashboardActions.updateUserDetails(updatedUser));
    } catch (err) {
      console.log("Basic Form Info : Error coming from handleTextInput()", err);
    }
  };

  const handleCountryCode = (event, action) => {
    let countryName = event.target.value;
    let optionEle = event.target.options[event.target.selectedIndex];
    let countryCode = optionEle.getAttribute("countrycode");

    console.log(countryName, countryCode, action);
    // Saving country name as well
    setIserror(false);
    var updatedUser;
    if (action === "countryCode") {
      updatedUser = produce(user, (draft) => {
        draft["country"] = countryName;
        draft["countryCode"] = countryCode;
      });

    } else {
      updatedUser = produce(user, (draft) => {
        draft["whatsAppCountry"] = countryName;
        draft["whatsAppCountryCode"] = countryCode;
      });
    }

    dispatch(UserDashboardActions.updateUserDetails(updatedUser));
  };

  function validateEmail(email) {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleEmailVerification = async (email, action) => {
    if (typeof email !== "undefined" && email !== "" && validateEmail(email)) {
      setEmailSentVerificationLoading(true)
      if (action === "notfirst") {
        // setReSendEmailNote(true);
        // setTimeout(function () {
        //   setReSendEmailNote(false);
        // }, 2000);
      }
      setReSendEmail(true);

      let authToken = decryptText(localStorage.getItem("eMediat"));
      let redirectionUrl = window.location.href.split("/dashboard")[0];
      const isEmailSend = await UserDashboardServices.sendVerificationEmail(
        redirectionUrl,
        authToken
      );
      if (
        isEmailSend &&
        isEmailSend.data &&
        isEmailSend.data.length > 0 &&
        isEmailSend.data[0].status
      ) {
        handleTosterDetails("Email sent successfully", "#5ec35e", "white", 3000)
        // console.log("Email status : ", isEmailSend?.data[0]?.status)
      }
      else{
        handleTosterDetails("Issue in sending email", "red", "white", 5000)
      }
      setEmailSentVerificationLoading(false)
    } else {
      setEmailError("Invalid email address");
    }
  };


  const handleTosterDetails = (text, bg, c, timeOut) =>{
    setTosterText(text)
    setTosterIsVisiable(true)
    setTosterBg(bg)
    setTosterC(c)
    setTimeout(function () {
      setTosterText("")
      setTosterBg("")
      setTosterC("")
      setTosterIsVisiable(false)
    }, timeOut)
  }

  // START : Verification popup Fns
  const handleContactVerification = (contactNo) => {
    if (contactNo && contactNo.toString().length >= 10) {
      clickRefBtn(contactNoVerificationPopupOpenBtn);
    } else {
      setContactNoError("Invalid Contact No");
    }
  };

  const clickRefBtn = (r) => {
    if (r.current) {
      r.current.click();
      setVerificationPopupStage("init");
    }
  };

  const switchVerificationPopupContent = async (action) => {
    if (action === "close") {
      clickRefBtn(contactNoVerificationPopupCloseBtn);
    } else {
      setVerificationPopupStage("otpStage");
      // sent otp request
      const response = await LoginServices.login(user.contactNumber); //

      if (response.success) {
        if (response.message === "User logged in successfully") {
          // contact  automatically no need of otp
          return;
        } else {
          setOTPToken(response.data.token); //where to set this one
          // dispatch(LoginActions.updateCurrentLoginStage('otp'));
          // dispatch(LoginActions.updateLoginSubHeader('Enter OTP'));
          return;
        }
      }
    }
  };

  // TODO : move to prfiletab index.js
  // END : Verification popup Fns

  // START : Display pic crop Fn's

  const handleSuccessOtp = async (OTPNumber) => {
    const data = await LoginServices.verifyOTP(
      OTPNumber,
      otpToken,
      user.contactNumber,
      user.countryCode
    );
    if (data.success === true) {
      window.localStorage.setItem("eMediat", encryptText(data.data.authToken));
      //set status to verified
      const newUserInfo = produce(user, (draft) => {
        draft.isContactNumberVerified = true;
      });
      dispatch(UserDashboardActions.updateUserDetails(newUserInfo));
      dispatch(UserDashboardActions.updateProfileError(""));
    } else {
      dispatch(UserDashboardActions.updateProfileError(data.message));
    }
    setVerificationPopupStage("init");
  };

  const handleSave = async () => {
    try {
      // Updating the users personal info
      let paylaod = {
        displayName: user.displayName,
        email: user.email,
        dob: user.dob,
        contactNumber: user.contactNumber,
        countryCode: user.countryCode,
        country: user.country,
        whatsAppCountry: user.whatsAppCountry,
        whatsAppCountryCode: user.whatsAppCountryCode,
        whatsAppNumber: user.whatsAppNumber,
        displayPhoto:
          displayPhotoFile === null ? user.displayPhoto : displayPhotoFile,
      };

      const isValidPayload = validateBasicInfo(paylaod);

      if (isValidPayload.success) {
        setSavingLoading(true)
        let authToken = decryptText(localStorage.getItem("eMediat"));
        let response = await UserDashboardServices.updateBasicInfo(
          paylaod,
          authToken
        );

        if (response.success) {
          // setIsDisablePersonalInfoEdit(true);
          // response.data['userState'] = personalInfo.data.state;
          alert(response.message);
          dispatch(UserDashboardActions.updateProfileError(""));
          dispatch(
            UserDashboardActions.updateUserDetails({
              ...response.data,
              bssFormDetails: user.bssFormDetails,
            })
          );
          setSavingLoading(false)
          setIsContactChanged(false);
          if (timelineRedirectDetails && timelineRedirectDetails.purchaseId && timelineRedirectDetails.purchaseId !== "" && timelineRedirectDetails.section === "personalInformation") {
            navigate(`/timeline/${timelineRedirectDetails.purchaseId}`);
          }
          else {
            dispatch(TimelineRedirectActions.resetTimelineRedirectReducers())
          }
          return;
        }
        setSavingLoading(false)
        // Note dont remove this handling mobile file uplading error below
        if (response.success === false) {
          if (response.message.includes(user.email)) {
            dispatch(
              UserDashboardActions.updateProfileError(
                "Email already used by other user"
              )
            );
          }
          if (response.message.includes(user.contactNumber)) {
            dispatch(
              UserDashboardActions.updateProfileError(
                "Contact number already used by other user"
              )
            );
          }
          return;
        }

        // Handling all possible error of file upload
        if (response === "Load failed") {
          dispatch(
            UserDashboardActions.updateProfileError(
              "Make sure you are uploading files below 500kb"
            )
          );
          return;
        }

        if (response === "Failed to fetch") {
          dispatch(
            UserDashboardActions.updateProfileError(
              "Make sure you are uploading files below 500kb"
            )
          );
          return;
        }
        alert(response);
      } else {
        if (isValidPayload.message === "Invalid Date of Birth") {
          dispatch(
            UserDashboardActions.updateProfileError(isValidPayload.message)
          );
        }
        setIserror(true);
      }
    } catch (err) {
      console.log("Basic Info :Error coming from handleSave()", err);
    }
  };

  const validateBasicInfo = (payload) => {
    const {
      displayName,
      email,
      contactNumber,
      whatsAppNumber,
      dob,
      whatsAppCountryCode,
      countryCode,
    } = payload;

    const validateEmail = () => {
      if (!email) {
        return { success: false, message: "Email is required" };
      }

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValidEmail = emailRegex.test(email);

      return isValidEmail
        ? { success: true, message: "Valid email" }
        : { success: false, message: "Invalid email" };
    };

    const validateContactNumber = (number, code, field) => {
      const contactNumberRegex = /^\d{10}$/;
      const isValidNumber = contactNumberRegex.test(number);

      if (!isValidNumber || !code || code === "null") {
        return {
          success: false,
          message: `Invalid ${field} / Country code missing`,
        };
      }

      return { success: true, message: `Valid ${field}` };
    };

    const validateDOB = () => {
      if (dob) {
        const year = dob.split("-")[0];
        const minYear = new Date().getFullYear() - 80;
        const currentYear = new Date().getFullYear() - 16;

        if (
          year.length === 4 &&
          Number(year) > minYear &&
          Number(year) < currentYear
        ) {
          return { success: true, message: "Valid Date of Birth" };
        }

        return { success: false, message: "Invalid Date of Birth" };
      }

      return { success: true, message: "Valid payload" };
    };

    const emailValidationResult = validateEmail();
    if (!displayName || !emailValidationResult.success) {
      return { success: false, message: "Invalid input data" };
    }

    const contactNumberValidationResult = validateContactNumber(
      contactNumber,
      countryCode,
      "Contact Number"
    );
    if (!contactNumberValidationResult.success) {
      return contactNumberValidationResult;
    }

    const whatsAppNumberValidationResult = validateContactNumber(
      whatsAppNumber,
      whatsAppCountryCode,
      "WhatsApp Number"
    );
    if (!whatsAppNumberValidationResult.success) {
      return whatsAppNumberValidationResult;
    }

    return validateDOB();
  };

  return (
    <>
      {user && (
        <BasicInfoView
          contactNoVerificationPopupOpenBtn={contactNoVerificationPopupOpenBtn}
          verificationPopupStage={verificationPopupStage}
          switchVerificationPopupContent={switchVerificationPopupContent}
          contactNoVerificationPopupCloseBtn={
            contactNoVerificationPopupCloseBtn
          }
          reSendEmail={reSendEmail}
          handleEmailVerification={handleEmailVerification}
          handleContactVerification={handleContactVerification}
          handleSuccessOtp={handleSuccessOtp}
          // ----- //
          modalRef={modalRef}
          iserror={iserror}
          contactNoError={contactNoError}
          emailError={emailError}
          userData={user}
          handleProfilePicPoupup={handleProfilePicPoupup}
          handleProfilePicPoupupClose={handleProfilePicPoupupClose}
          handleDisplayImgSave={handleDisplayImgSave}
          handleInputChange={handleInputChange}
          displayPhotoBinary={displayPhotoBinary}
          handleSave={handleSave}
          isContactChanged={isContactChanged}
          tosterIsVisiable={tosterIsVisiable}
          tosterText={tosterText}
          tosterBg={tosterBg}
          tosterC={tosterC}
          emailSentVerificationLoading={emailSentVerificationLoading}
          savingLoading={savingLoading}
        />
      )}
    </>
  );
}

export default BasicInfo;
