import { useEffect, useState, useRef } from 'react';
import LoginView from './view';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { encryptText, decryptText } from '@library/enc-dec';
import { isUserLogedIn } from '@library/auth';
import { getPurchasesCount } from '@library/helpers';

import Navbar from '@components/Navbar';

import * as LoginServices from '@services/Login';
import * as NavbarActions from '@redux/actions/Navbar';
import * as LoginActions from '@redux/actions/Login';
import { hideShowRWIcons } from '@library/LoginUIHelper';

function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const dataFetchedRef = useRef(false);
	const [validationError, setValidationError] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
	const [countryCode, setCountryCode] = useState('');
	const [OTPToken, setOTPToken] = useState('');
	const [getOtpIsLoading, setGetOtpIsLoading] = useState(false)

	const currentLoginStage = useSelector(
		(state) => state.login.currentLoginStage
	);
	const loginSubHeader = useSelector((state) => state.login.loginSubHeader);

	useEffect(() => {
		hideShowRWIcons('hide')
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		// Checking user is already logedin or not
		// if user already logedin then navigate to specific screen
		checkUserLoginState();

		return () => {
			hideShowRWIcons('show');
		  };	  
	}, []);

	const checkUserLoginState = async () => {
		try {
			const userLogedIn = await isUserLogedIn();
			dispatch(NavbarActions.updateLoginState(userLogedIn));
			if (userLogedIn) {
				// Get user details
				let authToken = decryptText(localStorage.getItem('eMediat'));
				const response = await LoginServices.getLogedInUser(authToken);
				if (response.success) {
					handleSuccessLogin('login', response.data);
					return;
				}
			}
		} catch (err) {
			console.log('Error coming from checkUserLoginState()', err);
		}
	};

	// START : Fns for phoneNo & otp Stage
	const getterSetterPhoneNo = (action, value) => {
		setValidationError('');
		if (action === 'phoneNo') {
			setPhoneNo(value);
		} else if (action === 'countryCode') {
			setCountryCode(value);
		}
	};

	const handleLogin = async () => {
		try {
			if (!countryCode) {
				setValidationError('Invalid country code');
				return;
			}

			if (phoneNo.length <= 9 || phoneNo.length >= 11) {
				setValidationError('Invalid phone number');
				return;
			}
			setGetOtpIsLoading(true)
			// Send OTP and validate the user
			const response = await LoginServices.login(phoneNo);

			if (response.success) {
				if(response.message === "User logged in successfully"){
					actionAfterSuccessfulLogin(response);
					return;	
				}
				else{
					setOTPToken(response.data.token);
					dispatch(LoginActions.updateCurrentLoginStage('otp'));
					dispatch(LoginActions.updateLoginSubHeader('Enter OTP'));
					return;	
				}
			}
			setGetOtpIsLoading(false)
			alert(response.message);
		} catch (err) {
			console.error('Error coming from handleLogin()', err);
		}
	};

	const handleResendOTP = async () => {
		try {
			// Resend the OTP
			const response = await LoginServices.login(phoneNo);
			if (response.success) {
				setOTPToken(response.data.token);
			}
		} catch (err) {
			console.log('Error coming from handleResendOTP()', err);
		}
	};

	const handleSuccessOTP = async (OTP) => {
		try {
			// Handle OTP verification and check is user already exists or not if not then which required fields are pending to fill
			const response = await LoginServices.verifyOTP(OTP, OTPToken, phoneNo, countryCode);

			if (response.success) {
				// Save auth token to the localstorage
				// TODO: After Success login update the header values
				actionAfterSuccessfulLogin(response);
				return;
			}
			alert(response.message);
		} catch (err) {
			console.log('Error coming from handleSuccessOTP()', err);
		}
	};

	const saveToken = (token) => {
		try {
			window.localStorage.setItem('eMediat', encryptText(token));
			window.localStorage.setItem('eMediatt', encryptText(Date.now()));
		} catch (err) {
			console.log('Error coming from  saveToken()', err);
		}
	};

	const actionAfterSuccessfulLogin = (response)=>{
		saveToken(response.data?.authToken);

		setTimeout(async () => {
			const purchasesCount = await getPurchasesCount();
			dispatch(NavbarActions.updatePurchasesCount(purchasesCount));
			dispatch(NavbarActions.updateLoginState(true));
			handleSuccessLogin('login', response.data);
		}, 2000);
	}

	// START : Util Fns
	const updateSubHeader = (text) => {
		let subHeader = document.getElementById('subHeader');
		if (subHeader) {
			subHeader.innerHTML = text;
		}
	};

	useEffect(() => {
		updateSubHeader(loginSubHeader);
	}, [loginSubHeader]);

	const handleSuccessLogin = (lastView, userDetails) => {
		try {
			if (lastView === 'login') {
				if (userDetails.displayName === null) {
					dispatch(LoginActions.updateCurrentLoginStage('displayName'));

					dispatch(
						LoginActions.updateLoginSubHeader('Enter your name to identify yourself')
					);

					return;
				} else if (userDetails.dob === null) {
					dispatch(LoginActions.updateCurrentLoginStage('dob'));

					dispatch(
						LoginActions.updateLoginSubHeader('Enter your Birthday and Gender')
					);
					return;
				} else if (userDetails.password === null) {
					dispatch(LoginActions.updateCurrentLoginStage('password'));
					dispatch(
						LoginActions.updateLoginSubHeader(
							'Create a strong password that is easy to remember of minimum 8 characters'
						)
					);
					return;
				}
			} else if (lastView === 'displayName') {
				if (userDetails.dob === null) {
					dispatch(LoginActions.updateCurrentLoginStage('dob'));

					dispatch(
						LoginActions.updateLoginSubHeader('Enter your Birthday and Gender')
					);
					return;
				} else if (userDetails.password === null) {
					dispatch(LoginActions.updateCurrentLoginStage('password'));

					dispatch(
						LoginActions.updateLoginSubHeader(
							'Create a strong password that is easy to remember of minimum 8 characters'
						)
					);
					return;
				}
			} else if (lastView === 'dob') {
				if (userDetails.password === null) {
					dispatch(LoginActions.updateCurrentLoginStage('password'));

					dispatch(
						LoginActions.updateLoginSubHeader(
							'Create a strong password that is easy to remember of minimum 8 characters'
						)
					);

					return;
				}
			} else if (lastView === 'password') {

			}

			navigate('/');
		} catch (err) {
			console.log('Error coming from handleSuccessLogin()', err);
		}
	};

	return (
		<>
			<Navbar />
			<LoginView
				currentStage={currentLoginStage}
				getterSetterPhoneNo={getterSetterPhoneNo}
				validationError={validationError}
				phoneNo={phoneNo}
				countryCode={countryCode}
				handleLogin={handleLogin}
				getOtpIsLoading={getOtpIsLoading}
				handleResendOTP={handleResendOTP}
				handleSuccessOTP={handleSuccessOTP}
				handleSuccessLogin={handleSuccessLogin}
			/>
		</>
	);
}

export default Login;
