const initialState = {
	latestCourses: {},
};

const HomeReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_LATEST_COURSES':
			return {
				...state,
				latestCourses: action.payload.latestCourses,
			};
		case 'RESET_HOME_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default HomeReducer;
