export const updateUserSpecificOfferDetails = (value) => {
	return {
		type: 'UPDATE_USER_SPECIFIC_OFFER_DETAILS',
		payload: value,
	};
};


export const resetUserSpecificOfferReducers = () => {
	return {
		type: 'RESET_USER_SPECIFIC_OFFER_REDUCER',
	};
};
