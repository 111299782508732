import React from "react";
import moment from "moment-timezone";

import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

import InterRegular from "@assets/invoice/Inter-Regular.ttf";
import InterMedium from "@assets/invoice/Inter-Medium.ttf";
import InterSemiBold from "@assets/invoice/Inter-SemiBold.ttf";
import InterBold from "@assets/invoice/Inter-Bold.ttf";

Font.register({ family: "InterRegular", src: InterRegular });
Font.register({ family: "InterMedium", src: InterMedium });
Font.register({ family: "InterSemiBold", src: InterSemiBold });
Font.register({ family: "InterBold", src: InterBold });

const styles = StyleSheet.create({
  billingSection: {
    marginTop: 5,
    marginLeft: -25,
    padding: 0,
  },
  billingToText: {
    color: "#7f7f7f",
    fontSize: 12,
    fontFamily: "InterBold",
  },
  lightGrayFColor: {
    color: "#7f7f7f",
  },
  userName: {
    fontSize: 12,
  },
  hr: {
    borderBottom: "0.5pt solid #dddedd",
    marginTop: 2,
    marginBottom: 6,
    width: "525px",
  },
  userContactNumber: {
    marginBottom: 5,
  },

  rightHandSideContainer: {
    marginLeft: 330,
    marginTop: -20,
  },

  userEmail: {
    marginTop: -30,
  },
  transactionDate: {
    marginBottom: 5,
  },
});

const InvoiceBilling = ({ invoice }) => {
  var timeZoneName = moment.tz.guess();
  return (
    <>
      <View style={styles.billingSection}>
        <Text style={styles.billingToText}>BILLING TO</Text>
        <Text
          style={styles.userName}
        >{`${invoice.data.firstName} ${invoice.data.lastName}`}</Text>
        <View style={styles.hr} />

        <Text
          style={styles.userContactNumber}
        >{`${invoice.data.countryCode} ${invoice.data.contactNumber}`}</Text>

        <View style={styles.rightHandSideContainer}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lightGrayFColor}>Payment Gateway:</Text>
            <Text style={{ marginLeft: 10 }}>{invoice.data.paymentMethod}</Text>
          </View>

          <View style={{ flexDirection: "row", marginTop: 3 }}>
            <Text style={styles.lightGrayFColor}>Payment Mode:</Text>
            <Text style={{ marginLeft: 23 }}>Online</Text>
          </View>

          <View style={{ flexDirection: "row", marginTop: 3 }}>
            <Text style={styles.lightGrayFColor}>Transaction Time:</Text>
            <Text style={{ marginLeft: 12 }}>
              {`${moment(invoice.data.createdAt)
                .tz(timeZoneName)
                .format("LT (z)")}`}
            </Text>
          </View>
        </View>

        <Text style={styles.userEmail}>{invoice.data.email}</Text>

        <View style={{ flexDirection: "row", marginTop: 7 }}>
          <Text style={styles.lightGrayFColor}>Transaction Date:</Text>
          <Text style={{ marginLeft: 16 }}>
            {moment(invoice.data.createdAt).format("Do MMM YYYY")}
          </Text>
        </View>

        <View style={{ flexDirection: "row", marginTop: 4 }}>
          <Text style={styles.lightGrayFColor}>Transaction ID:</Text>
          <Text style={{ marginLeft: 27 }}>{invoice.data.paymentId}</Text>
        </View>
      </View>
    </>
  );
};

export default InvoiceBilling;
