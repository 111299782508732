import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import ClaimCertificateView from './view';

function ClaimCertificate(props) {
	const timeLineData = useSelector((state) => state.dashboard.timelineData);

	const claimCertificateAgreePopupOpenBtn = useRef(null);
	const claimCertificateAgreePopupCloseBtn = useRef(null);
	const claimCertificateClaimPopupOpenBtn = useRef(null);
	const claimCertificateClaimPopupCloseBtn = useRef(null);

	const handleClaimCertificate = ()=>{
        if (claimCertificateAgreePopupOpenBtn.current) {
            claimCertificateAgreePopupOpenBtn.current.click();
        }
	}

	const handleAgreement = () =>{
        if (claimCertificateAgreePopupCloseBtn.current) {
            claimCertificateAgreePopupCloseBtn.current.click();
        }
        if (claimCertificateClaimPopupOpenBtn.current) {
            claimCertificateClaimPopupOpenBtn.current.click();
        }
	}

	return (
		<>
			<ClaimCertificateView 
				claimCertificateAgreePopupOpenBtn={claimCertificateAgreePopupOpenBtn}
				claimCertificateAgreePopupCloseBtn={claimCertificateAgreePopupCloseBtn}
				claimCertificateClaimPopupOpenBtn={claimCertificateClaimPopupOpenBtn}
				claimCertificateClaimPopupCloseBtn={claimCertificateClaimPopupCloseBtn}
				handleAgreement={handleAgreement}
				handleClaimCertificate={handleClaimCertificate}
				statusData = {props.status}
				{...props} />
		</>
	);
}

export default ClaimCertificate;
