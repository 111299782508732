import React, { useState, useEffect } from "react";

import "./style.css";
function TagsView(props) {
    return (
        <>
            <p className="tagsComponent"
                style={{ letterSpacing: 'normal', backgroundColor: props.bg, borderColor: props.bc }}>
                {props.imgs && <img style={{ margin: props.img_margin ? props.img_margin : '7px' }} src={props.imgs} alt={props.text} />}
                {props.text &&
                    <span style={{
                        fontSize: props.span_font_size ? props.span_font_size : '11px',
                        marginLeft: !props.imgs ? props.img_margin : '',
                        marginRight: !props.imgs ? props.img_margin : '',
                        color: props.c
                    }}>{props.text}</span>
                }
            </p>
        </>
    );
}

export default TagsView;

