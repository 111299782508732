import React, { useState, useEffect } from "react";

import RoadMapView from "./view";

import RoadmapRegister from "@assets/course/roadmapRegister.png";
import RoadMapEnroll from "@assets/course/roadMapEnroll.png";
import RoadMapVerification from "@assets/course/roadMapVerification.png";
import RoadMapCertificateDelivery from "@assets/course/roadMapCertificateDelivery.png";
import RoadMapExamination from "@assets/course/roadMapExamination.png";
import RoadMapOnDemandPractical from "@assets/course/roadMapOnDemandPractical.png";
import RoadMapWatchContent from "@assets/course/roadMapWatchContent.png";

import RoadmapNumOne from "@assets/course/roadmapNumOne.png";
import RoadmapNumTwo from "@assets/course/roadmapNumTwo.png";
import RoadmapNumThree from "@assets/course/roadmapNumThree.png";
import RoadmapNumFour from "@assets/course/roadmapNumFour.png";
import RoadmapNumFive from "@assets/course/roadmapNumFive.png";
import RoadmapNumSix from "@assets/course/roadmapNumSix.png";
import RoadmapNumSeven from "@assets/course/roadmapNumSeven.png";

// import RoadMapNumOneLine from '@assets/course/roadMapNumOneLine.png';
// import RoadMapNumTwoLine from '@assets/course/roadMapNumTwoLine.png';
// import RoadMapNumThreeLine from '@assets/course/roadMapNumThreeLine.png';
// import RoadMapNumFourLine from '@assets/course/roadMapNumFourLine.png';
// import RoadMapNumFiveLine from '@assets/course/roadMapNumFiveLine.png';
// import RoadMapNumSixLine from '@assets/course/roadMapNumSixLine.png';

import RoadMapNumOneLine from "@assets/course/lines/roadMapNumOneLine.png";
import RoadMapNumTwoLine from "@assets/course/lines/roadMapNumTwoLine.png";
import RoadMapNumThreeLine from "@assets/course/lines/roadMapNumThreeLine.png";
import RoadMapNumFourLine from "@assets/course/lines/roadMapNumFourLine.png";
import RoadMapNumFiveLine from "@assets/course/lines/roadMapNumFiveLine.png";
import RoadMapNumSixLine from "@assets/course/lines/roadMapNumSixLine.png";

import { useSelector } from "react-redux";

function RoadMap(props) {
  const courseDetails = useSelector((state) => state.course.courseDetails);

  let caseSixExamination = {
    lhsHeading: null,
    lhsContent: null,
    lhsImg: RoadMapExamination,
    num: RoadmapNumFive,
    line: RoadMapNumFiveLine,
    rhsImg: null,
    rhsHeading: "Examination",
    rhsContent:
      "Complete an online multiple-choice​ questions (MCQ) assessment exam​ to qualify for the certificate.​",
  };

  let caseSixCertificateDelivery = {
    lhsHeading: "Certificate Delivery",
    lhsContent:
      "Upon successfully clearing the final exam, the certificate will be delivered at your delivery address.",
    lhsImg: null,
    num: RoadmapNumSix,
    line: null,
    rhsImg: RoadMapCertificateDelivery,
    rhsHeading: null,
    rhsContent: null,
  };

  let caseSevenOnDemandPractical = {
    lhsHeading: null,
    lhsContent: null,
    lhsImg: RoadMapOnDemandPractical,
    num: RoadmapNumFive,
    line: RoadMapNumFiveLine,
    rhsImg: null,
    rhsHeading: "On-demand Practical",
    rhsContent:
      "(Pune-exclusive) on-demand practical training follows our schedule and syllabus for specialized skill development.",
  };

  let caseSevenExamination = {
    lhsHeading: "Examination",
    lhsContent:
      "Complete an online multiple-chojce questions (MCQ) assessment exam to qualify tor the certificate.",
    lhsImg: null,
    num: RoadmapNumSix,
    line: RoadMapNumSixLine,
    rhsImg: RoadMapExamination,
    rhsHeading: null,
    rhsContent: null,
  };

  let caseSevenCertificateDelivery = {
    lhsHeading: null,
    lhsContent: null,
    lhsImg: RoadMapCertificateDelivery,
    num: RoadmapNumSeven,
    line: null,
    rhsImg: null,
    rhsHeading: "Certificate Delivery",
    rhsContent:
      "Upon successfully clearing the final exam, the certificate will be delivered at your delivery address.",
  };

  const [viewRoadMapData, setViewRoadMapData] = useState([
    {
      lhsHeading: null,
      lhsContent: null,
      lhsImg: RoadmapRegister,
      num: RoadmapNumOne,
      line: RoadMapNumOneLine,
      rhsImg: null,
      rhsHeading: "Register",
      rhsContent:
        "Provide necessary personal information, contact details and create login credentials.",
    },
    {
      lhsHeading: "Enroll",
      lhsContent:
        "Explore courses, review syllabus and enroll in the desired medical program by completing payment.",
      lhsImg: null,
      num: RoadmapNumTwo,
      line: RoadMapNumTwoLine,
      rhsImg: RoadMapEnroll,
      rhsHeading: null,
      rhsContent: null,
    },

    {
      lhsHeading: null,
      lhsContent: null,
      lhsImg: RoadMapVerification,
      num: RoadmapNumThree,
      line: RoadMapNumThreeLine,
      rhsImg: null,
      rhsHeading: "Verification",
      rhsContent:
        "Verify your identity and qualification by uploading your latest marksheet & ID Proof.",
    },
    {
      lhsHeading: "Watch Content",
      lhsContent:
        "Access course materials a lectures with 24x7 availability for a flexible and immersive teaming experience.",
      lhsImg: null,
      num: RoadmapNumFour,
      line: RoadMapNumFourLine,
      rhsImg: RoadMapWatchContent,
      rhsHeading: null,
      rhsContent: null,
    },
  ]);

  useEffect(() => {
    if (
      courseDetails?.roadmapType &&
      courseDetails?.roadmapType === "onlinePractical"
    ) {
      setViewRoadMapData((prevData) => {
        return [
          ...prevData,
          caseSevenOnDemandPractical,
          caseSevenExamination,
          caseSevenCertificateDelivery,
        ];
      });
    } else {
      setViewRoadMapData((prevData) => {
        return [...prevData, caseSixExamination, caseSixCertificateDelivery];
      });
    }
  }, [courseDetails?.roadmapType]);

  return <RoadMapView viewRoadMapData={viewRoadMapData} />;
}

export default RoadMap;
