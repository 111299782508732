const initialState = {
  OTP1: "",
  OTP2: "",
  OTP3: "",
  OTP4: "",
  OTP5: "",
  OTPErrorMsg: "",
};

const OTPReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UDPATE_OTP_NUMBER":
      if (action.payload.colNumber === 1) {
        return {
          ...state,
          OTP1: action.payload.OTPNumber,
        };
      } else if (action.payload.colNumber === 2) {
        return {
          ...state,
          OTP2: action.payload.OTPNumber,
        };
      } else if (action.payload.colNumber === 3) {
        return {
          ...state,
          OTP3: action.payload.OTPNumber,
        };
      } else if (action.payload.colNumber === 4) {
        return {
          ...state,
          OTP4: action.payload.OTPNumber,
        };
      } else if (action.payload.colNumber === 5) {
        return {
          ...state,
          OTP5: action.payload.OTPNumber,
        };
      }
      return state;
    case "UPDATE_OTP_ERROR_MESSAGE":
      return {
        ...state,
        OTPErrorMsg: action.payload.OTPErrorMsg,
      };
    default:
      return state;
  }
};

export default OTPReducer;
