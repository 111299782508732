import React, { useState } from 'react';
import DisplayNameView from './view';

import { decryptText } from '@library/enc-dec';

import * as LoginServices from '@services/Login';

function DisplayName(props) {
	const [showError, setShowError] = useState('');
	const [displayName, setDisplayName] = useState('');

	const handleOnChange = (event) => {
		try {
			setShowError('');
			setDisplayName(event.target.value);
			let displayNameInput = document.getElementById('displayNameInput');
			if (displayNameInput) {
				displayNameInput.style.borderColor =
					event.target.value.length > 0 ? '#1A73E8' : '#8C8C8C';
			}
		} catch (err) {
			console.log('Error coming from handleOnChange()', err);
		}
	};

	const handleDisplayName = async () => {
		try {
			if (!displayName) {
				setShowError('Input Required');
				return;
			}

			let authToken = decryptText(localStorage.getItem('eMediat'));
			const response = await LoginServices.updateUserInitialDetails(
				{ displayName },
				authToken
			);

			if (response.success) {
				setShowError('');
				props.handleViewAfterLogin('displayName', response.data);
				return;
			}
			setShowError(response.messsage);
			return;
		} catch (err) {
			console.log('Error coming from handleDisplayName()', err);
		}
	};

	return (
		<DisplayNameView
			showError={showError}
			handleOnChange={handleOnChange}
			handleDisplayName={handleDisplayName}
		/>
	);
}

export default DisplayName;
