import React, { useState, useEffect } from 'react';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';

function InputCheckbox(props) {
	return (
		<>
			<div className='inputContainer_x' style={{ marginBottom: '0px' }}>
				<label
					htmlFor={props.inputId}
					className='inputLableCheckBox d-flex'
					style={{alignItems:'flex-start'}}>
					<input
						style={{marginTop:'5px'}}
						checked={props.value}
						onChange={(event) => {
							props.handleInputChange(event, { onChangeAction: props.inputId });
						}}
						type='checkbox'
						id={props.inputId}
					/>
					<span className='inputLableCheckBox_span' style={{marginRight:'7px'}}>
						{props.text}
						{props.isReq === 'true' && <b className='asterisk'>*</b>}
					</span>

					{props.isReq === 'true' &&
						props.showError &&
						props.showError === 'true' &&
						props.iserror &&
						(!props.value || props.value === '' || props.value === null) && (
							<Tags
								span_font_size='10px'
								img_margin='5px'
								bg='#FFE9E9'
								c='#962020'
								bc='#A62323'
								text='Input Required'
								imgs={InputError}
							/>
						)}
				</label>
			</div>
		</>
	);
}

export default InputCheckbox;
