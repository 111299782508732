export const hideShowRWIcons=(value)=>{
    if(document.getElementById('NavRequestCallStickyIcon')){
        let ele = document.getElementById('NavRequestCallStickyIcon')
        if(value === 'hide'){
            ele.style.display = 'none'
        }
        else{
            ele.style.display = 'block'
        }
    }
    if(document.getElementById('NavWhatsAppStickyIcon')){
        let ele = document.getElementById('NavWhatsAppStickyIcon')
        if(value === 'hide'){
            ele.style.display = 'none'
        }
        else{
            ele.style.display = 'block'
        }
    }
}
