import React from 'react';

import './style.css';

export default (props) => (
	<div className='relatedCoursesViewMainContainer'>
		<h4 className='heading'>You may also like:</h4>
		<div className='d-flex overflow-auto realtedProducts'>
			{props.relatedCourses &&
				props.relatedCourses.map((course, index) => (
					<div className='col-7 col-sm-4 col-md-4 col-lg-3 col-xl-3' key={index}>
						<span
							onClick={() => {
								props.handleRedirectProduct(course.seoUrlSlug);
							}}
							className='shineOnCourseCard'>
							<img
								src={course.rectangleThumbnailUrl}
								alt={course.title}
								className='w-100'
								style={{ cursor: 'pointer' }}
							/>
						</span>
						<p className='courseTitle'>{course.title}</p>
					</div>
				))}
		</div>
	</div>
);
