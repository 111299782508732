import DocGif from '@assets/aboutUs/doc.gif';
import TrophyGif from '@assets/aboutUs/trophy.gif';
import FstarsGif from '@assets/aboutUs/Fstars.gif';

export const content = {
    "banner": {
        "title1" : "Medical education made available to your fingertips!",
        "title2" : "Enhance Your Medical Skills without leaving the comfort of your home.",
        "bottomNote": "Last chance to bring home the latest skill with courses as low as 389"
    },
    "section1": {
        "title" : "So many reasons to start",
        "data": [
            {
                "img": DocGif,
                "title": "Expert Faculty",
                "description": "Courses with lectures delivered by highly qualified and experienced faculty in the medical field."
            },
            {
                "img": TrophyGif,
                "title": "Curated Content",
                "description": "Specially curated medical content to offer high-quality and relevant educational resources to learners."
            },
            {
                "img": FstarsGif,
                "title": "Get Certified",
                "description": "By successfully clearing exams, get certified to enhance your professional credentials."
            }
        ]
    },
    "section2": {
        "title": "What is eMediSkill ?",
        "description": "eMediSkill is an online learning platform offering medical courses. It is a comprehensive platform with specially curated structured courses with experienced faculty in their respective fields. Offering flexible learning options, eMediSkill empowers you to advance your medical knowledge and career to a whole new level."
    },
    "section3": {
        "title": "Career Advancement and Specialization:",
        "description": "eMediSkill Services might offer courses that help medical graduates explore new career opportunities, specialize in specific medical fields, or acquire additional skills that complement their existing expertise."
    }
}

export default content;

