import React from 'react';
import { Link } from 'react-router-dom';

import Tags from '@components/Tags';
import InputError from '@assets/userDashboard/Icons/inputError.png';
import TickIcon from '@assets/userDashboard/Icons/verifiedTick.png';
import AlertIcon from '@assets/userDashboard/Icons/alertIcon.png';

function InputText(props) {
	return (
		<>
			{/* <Tags span_font_size="10px"
                img_margin="5px"
                bg="#FFE9E9"
                c="#962020"
                bc="#A62323"
                text="Input Required"
                imgs={InputError} /> */}

			<div
				className={`inputContainer_x ${props.fullWidth && props.fullWidth === 'true'
						? 'col-12'
						: 'col-12 col-sm-6'
					}`}>
				<p className='inputLable d-flex align-items-center'>
					<span style={{ marginRight: '7px' }}>
						{props.text}
						{props.isReq === 'true' && <b className='asterisk'>*</b>}
					</span>


					{props.isReq === 'true' &&
						props.showError &&
						props.showError === 'true' &&
						props.iserror &&
						(!props.value || props.value === '' || props.value === null) && (
							<Tags
								span_font_size='10px'
								img_margin='5px'
								bg='#FFE9E9'
								c='#962020'
								bc='#A62323'
								text='Input Required'
								imgs={InputError}
							/>
						)}

					{props.verified &&
						<Tags span_font_size='13px'
							img_margin='5px'
							bg='#EEFFE4'
							c='#139528'
							bc='#139528'
							text={'Verified'}
							imgs={TickIcon}
						/>
					}
					{props.rejected &&
						<Tags span_font_size='13px'
							img_margin='5px'
							bg='#FFE9E9'
							c='#BA131A'
							bc='#F7A8AD'
							text={'Rejected'}
							imgs={AlertIcon}
						/>
					}
				</p>


				<input
					type='text'
					name=''
					value={props.value}
					onChange={(event) => {
						props.handleInputChange(event, props.onChangeAction);
					}}
				/>


				{props.extraParaText && props.extraParaText.length && (
					<>
						<div className='extraParaText'>
							<span>{props.extraParaText}</span>
							<Link to={props.anchorLink}>{props.anchorText}</Link>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default InputText;
