export const updateEmail = (value) => {
	return {
		type: 'UPDATE_EMAIL',
		payload: {
			email: value,
		},
	};
};

export const updatePassword = (value) => {
	return {
		type: 'UPDATE_PASSWORD',
		payload: {
			password: value,
		},
	};
};

export const updateOTPToken = (value) => {
	return {
		type: 'UPDATE_OTP_TOKEN',
		payload: {
			OTPToken: value,
		},
	};
};

export const updateContactNumber = (value) => {
	return {
		type: 'UPDATE_CONTACT_NUMBER',
		payload: {
			contactNumber: value,
		},
	};
};

export const updateForgotPassToken = (value) => {
	return {
		type: 'UPDATE_FORGOT_PASS_TOKEN',
		payload: {
			forgotPassToken: value,
		},
	};
};

export const updateCurrentLoginStage = (value) => {
	return {
		type: 'UPDATE_CURRENT_LOGIN_STAGE',
		payload: {
			currentLoginStage: value,
		},
	};
};

export const updateLoginSubHeader = (value) => {
	return {
		type: 'UPDATE_LOGIN_SUBHEADER',
		payload: {
			loginSubHeader: value,
		},
	};
};
