import React from 'react';
import ContentLoader from 'react-content-loader';

export default function VideoLoader() {
	return (
		<>
			<div className='d-none d-sm-block mainCourseDetailsPageContainer'>
				<ContentLoader viewBox='0 0 100 120' style={{ marginTop: '20px' }}>
					{/* VideoPlayer */}
					<rect x='0' y='0' rx='1' ry='1' width='100' height='50' />

					{/* START : Video Description head(title, like, dislike,...) */}
					<rect x='0' y='53' rx='1' ry='1' width='28' height='3' />
					<rect x='46' y='53' rx='1' ry='1' width='16' height='3' />
					<rect x='66' y='53' rx='1' ry='1' width='16' height='3' />
					<rect x='84' y='53' rx='1' ry='1' width='16' height='3' />
					<rect x='0' y='60' rx='1' ry='1' width='100' height='5' />
					{/* END : Video Description head(title, like, dislike,...) */}

					{/* START : Comment section */}
					<rect x='0' y='70' rx='1' ry='1' width='30' height='4' />

					{/* START : User Comment */}
					<circle cx='4' cy='80' r='2' />
					<text x='7' y='80.1' fontSize='2'>
						Add a comment
					</text>
					<rect x='7' y='81' rx='1' ry='1' width='93' height='0.5' />
					{/* END : User Comment */}

					{/* START : Other Comments */}
					<circle cx='4' cy='88' r='2' />
					<rect x='7' y='86.5' rx='1' ry='1' width='15' height='1' />
					<rect x='7' y='89' rx='1' ry='1' width='93' height='1' />

					<circle cx='4' cy='96' r='2' />
					<rect x='7' y='94.5' rx='1' ry='1' width='15' height='1' />
					<rect x='7' y='97' rx='1' ry='1' width='93' height='1' />

					<circle cx='4' cy='104' r='2' />
					<rect x='7' y='102.5' rx='1' ry='1' width='15' height='1' />
					<rect x='7' y='105' rx='1' ry='1' width='93' height='1' />
					{/* END : Other Comments */}
				</ContentLoader>
			</div>

			<div className='d-block d-sm-none mainCourseDetailsPageContainer'>
				<ContentLoader viewBox='0 0 100 150' style={{ marginTop: '20px' }}>
					{/* VideoPlayer */}
					<rect x='0' y='0' rx='1' ry='1' width='100' height='50' />

					{/* START : Video Description head(title, like, dislike,...) */}
					<rect x='0' y='53' rx='1' ry='1' width='28' height='3' />
					<rect x='46' y='53' rx='1' ry='1' width='16' height='3' />
					<rect x='66' y='53' rx='1' ry='1' width='16' height='3' />
					<rect x='84' y='53' rx='1' ry='1' width='16' height='3' />
					<rect x='0' y='60' rx='1' ry='1' width='100' height='5' />
					{/* END : Video Description head(title, like, dislike,...) */}

					{/* START : Comment section */}
					<rect x='0' y='70' rx='1' ry='1' width='30' height='4' />

					{/* START : User Comment */}
					<circle cx='5' cy='85' r='4' />
					<text x='12' y='85' fontSize='4'>
						Add a comment
					</text>
					<rect x='10' y='88' rx='1' ry='1' width='88' height='0.5' />
					{/* END : User Comment */}

					{/* START : Other Comments */}
					<circle cx='5' cy='99' r='4' />
					<rect x='11' y='96.5' rx='1' ry='1' width='15' height='1.5' />
					<rect x='11' y='101' rx='1' ry='1' width='88' height='1.5' />

					<circle cx='5' cy='114' r='4' />
					<rect x='11' y='112.5' rx='1' ry='1' width='15' height='1.5' />
					<rect x='11' y='116' rx='1' ry='1' width='88' height='1.5' />

					<circle cx='5' cy='128' r='4' />
					<rect x='11' y='125.5' rx='1' ry='1' width='15' height='1.5' />
					<rect x='11' y='130' rx='1' ry='1' width='88' height='1.5' />

					<circle cx='5' cy='142' r='4' />
					<rect x='11' y='139.5' rx='1' ry='1' width='15' height='1.5' />
					<rect x='11' y='144' rx='1' ry='1' width='88' height='1.5' />

					{/* END : Other Comments */}
				</ContentLoader>
			</div>
		</>
	);
}
