import React, { useEffect, useState } from "react";

import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import { isUserLogedIn } from '@library/auth';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as NavbarActions from '@redux/actions/Navbar';

import AboutUsView from './view'

function AboutUs(props){

	const [isBannerLoaded, setIsBannerLoaded] = useState(false);

    useEffect(()=>{
        window.scroll(0, 0)
        checkLogin()
    }, []);

    const checkLogin= async ()=>{
		const userLogedIn = await isUserLogedIn();
		props.updateLoginState(userLogedIn);
    }

	const handleBannerImageLoading = () => {
		setTimeout(function () {
			setIsBannerLoaded(true);
		}, 300);
	};

    return(
        <>
            <Navbar />
            <AboutUsView 
                isBannerLoaded={isBannerLoaded}
                handleBannerImageLoading={handleBannerImageLoading}
                />
            <Footer />
        </>
    )
}

function mapStateToProps(state) {
	return {
		isLogedIn: state.navbar.loginState,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateLoginState: NavbarActions.updateLoginState,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
