export const updateLoginState = (value) => {
	return {
		type: 'UPDATE_LOGIN_STATE',
		payload: {
			loginState: value,
		},
	};
};

export const updatePurchasesCount = (value) => {
	return {
		type: 'UPDATE_PURCHASES_COUNT',
		payload: {
			purchasesCount: value,
		},
	};
};
