import React from 'react';

import './style.css';
import RightArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';

export default (props) => (
	<div className='userDetailsView'>
		<div className='header'>
			<p>Entry your details</p>
			<button className='closeBtn' data-bs-dismiss='modal'>
				x
			</button>
		</div>

		<div className='content'>
			<p className='note'>
				Please enter your legal name. This name will appear on the invoice.{' '}
				<b>DO NOT</b> add prefixes like <b>Dr, Mr, Mrs, etc.</b> here.
			</p>

			<div className='mb-3 inputCotainer'>
				{props.errorFirstName && <p className='error'>{props.errorFirstName}</p>}
				<input
					ref={props.firstNameInput}
					onChange={(event) => props.handleInputChange('fistName', event)}
					placeholder='First Name*'
					type='text'
					className='form-control'
				/>
			</div>

			<div className='mb-3 inputCotainer'>
				<input
					ref={props.middleNameInput}
					onChange={(event) => props.handleInputChange('middleName', event)}
					placeholder='Middle Name*'
					type='text'
					className='form-control'
				/>
			</div>

			<div className='mb-3 inputCotainer'>
				{props.errorLastName && <p className='error'>{props.errorLastName}</p>}
				<input
					ref={props.lastNameInput}
					onChange={(event) => props.handleInputChange('lastName', event)}
					placeholder='Last Name*'
					type='text'
					className='form-control'
				/>
			</div>
		</div>

		<button onClick={() => props.handleSubmitUserDetails()} className='actionBtn'>
			Next
			<img src={RightArrow} alt='RightArrow' />
		</button>
	</div>
);
