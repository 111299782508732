import React from 'react';

import CallView from './view';

function Call() {
	const handleCall = () => {
		const phoneNumber = '+9198220-8-11-33';
		const telUri = 'tel:' + phoneNumber;
		window.location.href = telUri;
	};
	return <CallView handleCall={handleCall} />;
}

export default Call;
