import React from 'react';

import './style.css';

export default (props) => (
	<>
		<button
			type='button'
			ref={props.openPopupBtnRef}
			id={`ETPopupOpenBtn_${props.type}`}
			className='d-none'
			data-bs-toggle='modal'
			data-bs-target={`#ETPopup_${props.type}`}>
			Exam Test Popup
		</button>

		<div
			className='modal fade etPopup'
			ref={props.modalRef}
			id={`ETPopup_${props.type}`}>
			<div className='modal-dialog modal-dialog-centered'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title'>Notice</h5>
						<button
							type='button'
							className='btn-close'
							onClick={() => {
								props.handleCancel();
							}}
							data-bs-dismiss='modal'></button>
					</div>
					<div className='modal-body'>
						{props.type === "Test" ?
							(
								<>
									<p>Press ok to reattemt</p>
								</>
							) : (
								<>
									{props.data?.examAttempts < 2 ? (
										<>
											<p>
												Before you proceed, please take a moment to read and acknowledge the
												following:
											</p>
											<ol>
												<li>
													You have <b>two free attempts</b> for this exam.
												</li>
												<li>
													Any additional attempts beyond the initial two may incur additional
													charges.
												</li>
											</ol>
											<p>
												By clicking <b>"OK"</b> below, you agree that you are aware of these
												terms and conditions.
											</p>
										</>
									) : (
										<>
											<p>
												You have exusted the reattemt limit and you need to pay ₹500 for
												reatteming the exam
											</p>
										</>
									)}
								</>
							)}
					</div>

					<div className='modal-footer'>
						<button
							className='col-4 cancelBtn'
							ref={props.closeEtPopupBtnRef}
							onClick={() => {
								props.handleCancel();
							}}
							data-bs-dismiss='modal'>
							Cancle
						</button>
						<button
							style={{
								background: props.etPoupOkBtnLoading ? '#676565' : '#252525'
							}}
							disabled={props.etPoupOkBtnLoading}
							onClick={() => props.handleRedirectToEaxm(props.data)}
							className='col-4 okBtn'>
							{props.etPoupOkBtnLoading ? 'Loading...' : 'Ok'}
						</button>
					</div>
				</div>
			</div>
		</div>
	</>
);
