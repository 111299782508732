import React from 'react';
import PreviewVideoView from './view';
import { useSelector } from 'react-redux';

function PreviewVideo() {
	const courseDetails = useSelector((state) => state.course.courseDetails);

	return <PreviewVideoView previewVideoLink={courseDetails?.previewVideoLink} />;
}

export default PreviewVideo;
