import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

import InputError from '@assets/userDashboard/Icons/inputError.png';
import RightWhiteArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';

import Tags from '@components/Tags';
import CountryCodeFlagPhoneNo from '@components/CountryCodeFlagPhoneNo';

function RegistrationView(props) {
	return (
		<>
			<div className='registrationMainContainer'>

				<h5 className='heading'>
					{props.heading ? props.heading : 'Register Your Interest'}
					{props.handleClosePopup &&
						<button onClick={() => props.handleClosePopup()} className='closeBtn' data-bs-dismiss="modal">
							x
						</button>
					}
				</h5>

				{props.fullNameError &&
					<Tags
						span_font_size='9px'
						img_margin='5px'
						bg='#FFE9E9'
						c='#962020'
						bc='#A62323'
						text="Input Required"
						imgs={InputError}
					/>
				}
				{props.fullNameSetter &&
					<input
						value={props.fullName}
						onChange={(event) => props.handleFullName(event)}
						className='inputFullName'
						type='text'
						placeholder='Full Name' />
				}
				<div className='phoneNoContainer'>
					<CountryCodeFlagPhoneNo
						handleIamIntrested={props.handleIamIntrested}
						fullNameSetter={props.fullNameSetter ? props.fullNameSetter : null} // If null it means this component and CountryCodeFlagPhoneNo is used for popup  
						validationError={props.validationError}
						getterSetter={props.getterSetter} />
				</div>


				{props.tnCError &&
					<Tags
						span_font_size='9px'
						img_margin='5px'
						bg='#FFE9E9'
						c='#962020'
						bc='#A62323'
						text="Input Required"
						imgs={InputError}
					/>
				}
				<div className="form-check tncContainer">
					<input
						onChange={(event) => props.handleTnC(event)}
						className="form-check-input"
						type="checkbox"
						checked={true}
						id={`${props.customId ? props.customId : 'coursePageRegistrationTnC'}`} />
					<label htmlFor={`${props.customId ? props.customId : 'coursePageRegistrationTnC'}`} >
						I agree to eMediSkill’s <Link to="/articles">Terms of Service</Link> and <Link to="/articles">Privacy Policy</Link>
					</label>
					{props.imIntrestedIcon &&
						<img
							onClick={() => props.handleIamIntrested()}
							className='imIntrestedIconSendBtn'
							src={props.imIntrestedIcon}
							alt='imIntrestedIcon' />
					}
				</div>

				{!props.imIntrestedIcon && (
					<button
						onClick={() => props.handleIamIntrested()}
						className='imIntrestedBtn'>
						{props.actionBtnText ? props.actionBtnText : 'I’m Interested'}
						<img src={RightWhiteArrow} alt='RightWhiteArrow' />
					</button>
				)}
			</div>
		</>
	);
}

export default RegistrationView;
