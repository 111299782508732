import React from 'react';
import './style.css';

import { Link } from 'react-router-dom';
import RightArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';

function PaymentGatewayView(props) {
	return (
		<div className='paymentGatewayView'>
			<div className='header'>
				<p>Continue to enrollment</p>
				<button className='closeBtn' data-bs-dismiss='modal'>
					x
				</button>
			</div>

			<div
				style={{ maxHeight: window.innerHeight - 200 + 'px' }}
				className='content'>
				<p className='error'>{props.error}</p>
				{props.paymentGateways.map((item, index) => (
					<div
						onClick={() => props.handlePaymentMethodChange(index, item)}
						className={`contentInnerContainer paymentViewContentInnerContainer_${index} ${props.currentGatewayIndex === index && 'selectBlueBorder'
							}`}
						key={index}>
						<p className='heading'>{item.title}</p>
						{item.discount > 0 &&
							<p className='note'>{`Additional ${item.discount}% discount on total`}</p>
						}
						<p className='processedContainer'>
							<span>Processed by</span>
							<img
								style={item.type === 'stripe' ? { width: '40px', height: '16px' } : {}}
								src={item.icon}
								alt="icon"
							/>
						</p>
					</div>
				))}

				<div className='form-check tncContainer'>
					<input
						onChange={props.handleTermsNCondition}
						className='form-check-input'
						type='checkbox'
						checked={true}
						id='paymentGatewayTnC'
					/>
					<label htmlFor='paymentGatewayTnC'>
						I agree to eMediSkill’s <Link to='/articles'>Terms of Service</Link> and{' '}
						<Link to='/articles'>Privacy Policy</Link>
					</label>
				</div>
			</div>

			<button onClick={props.handleSubmitPayment} className='actionBtn'>
				Continue to pay {props.tempCartTotalValue}

				<img src={RightArrow} alt='RightArrow' />
			</button>
		</div>
	);
}

export default PaymentGatewayView;
