import CryptoJS from "crypto-js";

// Encrypting text
export const encryptText = (token) => {
  token = token.toString();
  // Convertong token to cipher text
  const ciphertext = CryptoJS.AES.encrypt(
    token,
    process.env.REACT_APP_ENCRYPTION_SECRET
  ).toString();
  return ciphertext;
};

// Decrypting text
export const decryptText = (ciphertext, jsonCheckDetails = {}) => {
  if (ciphertext === null) {
    return null;
  }
  const bytes = CryptoJS.AES.decrypt(
    ciphertext,
    process.env.REACT_APP_ENCRYPTION_SECRET
  );
  let plaintext = bytes.toString(CryptoJS.enc.Utf8);
  if (Object.entries(jsonCheckDetails).length > 0) {
    plaintext = safePareJson(plaintext, jsonCheckDetails);
  }
  return plaintext;
};

const safePareJson = (plaintext, jsonCheckDetails) => {
  let jsonParseStatus = false;
  try {
    if (plaintext === "") {
      return jsonCheckDetails.returnType;
    }
    let jsonParse = JSON.parse(plaintext);
    jsonParseStatus = true;
  } catch (err) {
    console.log(
      "Issue in json safePareJson coming from handlePaidCourse()",
      err
    );
  }
  if (jsonParseStatus) {
    return plaintext;
  } else {
    return jsonCheckDetails.returnType;
  }
};
