import React from 'react';

import './style.css';

export default (props) => (
	<div className='displayNameMainConatainer'>
		{props.showError !== '' && <p className='error'>{props.showError}</p>}
		<div className='inputConatiner'>
			<input
				onChange={props.handleOnChange}
				type='text'
				className='input'
				id='displayNameInput'
				placeholder=' '
			/>
			<label className='inputLabel'>Name</label>
		</div>

		<button className='nextBtn' onClick={props.handleDisplayName}>
			Next
		</button>
	</div>
);
