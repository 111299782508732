import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';
import QMark from '@assets/timeline/icons/qMark.png';
import RightArrow from '@assets/timeline/icons/rightArrow.png';

function SupportView(props) {
	return (
		<>
			<div className='supportCardView'>
				<p className='topHeading'>Support</p>
				{props.staticData.map((item, index) => (
					<React.Fragment key={index}>
						<Link to={item.link}>
							<div className='row r1'>
								<img src={QMark} alt='QMark' className='qMark col-1' />
								<div className='r2 col-10 col-md-9'>
									<span className='heading'>{item.heading}</span>
									<div className='r2_1'>
										<span>{item.text}</span>
										<img src={RightArrow} alt='RightArrow' />
									</div>
								</div>
							</div>
						</Link>
						{props.staticData.length - 1 > index && <hr />}
					</React.Fragment>
				))}
			</div>
		</>
	);
}

export default SupportView;
