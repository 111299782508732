import React from "react";

import './offerScratchCard.css';
import ScratchCard from 'react-scratchcard';
function OfferScratchCardView(props) {
    return (
        <>
            <center style={{ zIndex: '100', position: 'inherit'}}>

                <div className="offerStrachCardContainer">

                    {props.scratchPercentage < 5 ?
                        (<>
                            <canvas
                                style={{ position: 'absolute', zIndex: 2 }}
                                ref={props.canvasRef} />

                            <div className="secret fill ">
                                <span>Scratch to reveal the offer!</span>
                            </div>
                        </>
                        ) : (
                            <>
                                {!props.isCopied ? (
                                    <>
                                        <div className="secret fill " style={{ fontWeight: '600', cursor: 'pointer' }}>
                                            <span 
                                                title="Click to copy" 
                                                offercode={props.offerCode} 
                                                onClick={(event)=>{props.handleCopy(event)}} >
                                                    {props.offerCode}
                                            </span>
                                        </div>

                                    </>) : (
                                    <>
                                        <div className="secret fill secret-success">
                                            <span>Offer coupon copied!</span>
                                        </div>
                                    </>
                                )}

                            </>
                        )}
                </div>


            </center>
        </>
    )
}

export default OfferScratchCardView;