export const getUser = async (authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/getUser`, {
      method: "GET",
      headers: {
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Userdashboard Services", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateUserDetails = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateUserDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Userdashboard Services", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getPurchases = async (authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getPurchasedCourses`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : getPurchases()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateBasicInfo = async (userDetails, authToken) => {
  try {
    let formData = new FormData();
    for (let key in userDetails) {
      formData.append(key, userDetails[key]);
    }
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateBasicInfo`, {
      method: "POST",
      headers: {
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : updateBasicInfo()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const sendVerificationEmail = async (redirectionUrl, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/sendVerificationEmail`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify({ redirectionUrl: redirectionUrl }),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : sendVerificationEmail()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const verifyEmail = async (emailToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/verifyEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emailToken: emailToken }),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : verifyEmail()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateLegalInfo = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateLegalInfo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : updateLegalInfo()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateDeliveryAddress = async (userDetails, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateDeliveryAddress`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(userDetails),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : updateAddressInfo()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateIdentityInfo = async (userDetails, authToken) => {
  try {
    let formData = new FormData();
    for (let key in userDetails) {
      formData.append(key, userDetails[key]);
    }
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateIdentityInfo`, {
      method: "POST",
      headers: {
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : updateIdentityInfo()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateBssForm = async (userDetails, authToken) => {
  try {
    const formData = new FormData();
    for (let key in userDetails) {
      formData.append(key, userDetails[key]);
    }

    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateBssForm`, {
      method: "POST",
      headers: {
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Error Coming from updateBssForm()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getFavVidoes = async (authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/getFavouriteVideos`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : getFavVideos()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getHistoryVideos = async (authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/getHistoryVideos`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : getHistoryVideos()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const activateCourse = async (courseId, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/activateCourse`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        courseId: courseId,
      }),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : getHistoryVideos()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getPurchasesCount = async (authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/getPurchasesCount`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Userdashboard services : getPurchasesCount()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const activateSeries = async (seriesId, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/activateSeries`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        seriesId: seriesId,
      }),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from user dashboard services : activateSeries()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const checkUserEligibilityToWatchSeries = async (
  seriesId,
  authToken
) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/checkContentEligibility/${seriesId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Error from checkUserEligibilityToWatchSeries()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};



export const getAllQualifications = async (authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getAllQualifications`,
			{
				method: 'GET',
				headers: {
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Userdashboard Services getAllQualifications()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getAllBSSDegrees = async (authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getAllBSSDegrees`,
			{
				method: 'GET',
				headers: {
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Userdashboard Services getAllBSSDegrees()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

