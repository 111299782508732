import React from 'react';

import OTP from '@components/Otp';
import './style.css';

import LeftArrow from '@assets/userDashboard/Icons/leftArrowForVPopup.png';
import RightArrow from '@assets/userDashboard/Icons/rightArrowForVPopup.png';
import Registration from '@pages/Course/Sections/Registration';

function NameAndPhoneNoOtpContentView(props) {
	return (
		<>
			<div className='nameAndPhoneNoOtpView'>

				{props.stage === "otpStage" &&
					<p className="goBackContainer">
						<img
							onClick={() => props.goBack()}
							src={LeftArrow} alt="LeftArrow" />
						Enter Your Details

					</p>
				}

				{props.stage === "init" ?
					(
						<>
							<div className='initContainer'>
								{/* Also used in :components\Popups\CoursePagePopupsContent\WriteToUs\view.js */}
								<Registration
									handleClosePopup={props.handleClosePopup ? props.handleClosePopup : null}
									imIntrestedIcon={props.SendBtn ? props.SendBtn : null}
									heading="Enter Your Details"
									actionBtnText="Next"
									customId="nameAndPhoneNoOtpContentView"
									openOtpPopUp={props.dubLicateOpenOtpPopUp}
								/>
							</div>
						</>
					) : (
						<>
							<div className='otpStageContainer'>
								<p className='verifyNumText'>
									Verify using OTP sent to {props.tempCountryCode} {' '} {props.tempPhoneNo}
								</p>

								<OTP
									nextBtnIcon={RightArrow}
									countryCode={props.tempCountryCode}
									contactNumber={props.tempPhoneNo}
									handleSuccessOTP={props.intermediateHandleSuccessOtp}
								/>
							</div>
						</>
					)}
			</div>
		</>
	);
}

export default NameAndPhoneNoOtpContentView;
