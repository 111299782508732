export const updatePageViewDocId = (value) => {
	return {
		type: 'UPDATE_PAGE_VIEW_DOC_ID',
		payload: value,
	};
};


export const resetPageViewReducers = () => {
	return {
		type: 'RESET_PAGE_VIEW_REDUCER',
	};
};
