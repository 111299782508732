export const getPurchasedCourses = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getPurchasedCourses`, {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Userdashboard Services getPurchasedCourses()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
