import React, { useState } from "react";

import VideoSharePopupView from './view';

function VideoSharePopup() {
    const [copyAlterIsVisible, setCopyAlterIsVisible] = useState(false);


    //START : Fn's of sharing videos functionality


    const handleSocailAppShare = (event, socialMediaPlatform) => {
        let shareUrl = document.getElementById('shareInput').value;
        if (shareUrl != '') {
            if (socialMediaPlatform === 'whatsApp') {
                const whatsappUrl = `https://web.whatsapp.com/send/?text=${shareUrl}`;
                window.open(whatsappUrl, '_blank');
            }

            if (socialMediaPlatform === 'gmail') {
                const email = 'recipient@example.com';
                const subject = 'Check out this link';
                const body = `Hey, check out this link: ${shareUrl}`;
                const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
                    subject
                )}&body=${encodeURIComponent(body)}`;
                window.location.href = mailtoUrl;
            }

            if (socialMediaPlatform == 'facebook') {
                const facebook = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
                window.open(facebook, '_blank');
            }

            if (socialMediaPlatform == 'twitter') {
                const tshareUrl = 'https://twitter.com/intent/tweet?url=<URL>&text=<TEXT>';
                const url = encodeURIComponent(shareUrl);
                const text = encodeURIComponent('Check out eMedi');
                window.open(
                    tshareUrl.replace('<URL>', url).replace('<TEXT>', text),
                    '_blank'
                );
            }
        }
    };


    const handleCopy = () => {
        let copyText = document.getElementById('shareInput').value;
        navigator.clipboard.writeText(copyText);
        setCopyAlterIsVisible(true);
        setTimeout(function () {
            setCopyAlterIsVisible(false);
        }, 2000);
    };

    //END : Fn' of sharing videos functionality

    return (
        <>
            <VideoSharePopupView
                //Usestates and fn's of sharing vidoes functionality
                handleSocailAppShare={handleSocailAppShare}
                copyAlterIsVisible={copyAlterIsVisible}
                handleCopy={handleCopy}
                //Usestates and fn's of sharing vidoes functionality
            />
        </>
    )
}

export default VideoSharePopup;