import React, { useEffect, useRef } from 'react';

import HeadView from './view';

import { handleRichTextEditorCourseDiscription } from '@pages/Course/HelperFunctions';
import { useSelector } from 'react-redux';

function Head() {
	const subPointersRef = useRef(null);
	const courseDetails = useSelector((state) => state.course.courseDetails);

	useEffect(() => {
		updateSubPointers();
	}, [courseDetails]);

	const updateSubPointers = () => {
		if (subPointersRef.current) {
			let html = handleRichTextEditorCourseDiscription(
				courseDetails?.coverPointers?.pointer,
				courseDetails?.coverPointers?.icon,
				''
			);

			subPointersRef.current.innerHTML = html;
		}
	};

	return (
		<HeadView subPointersRef={subPointersRef} courseDetails={courseDetails} />
	);
}

export default Head;
