import React from 'react';
import './style.css';

import RightCarouselArrow from '@assets/course/rightCarouselArrow.png';
import LeftCarouselArrow from '@assets/course/leftCarouselArrow.png';

export default (props) => (
	<>
		<div className='deskTopImageCarouselContainer'>
			<div className='row '>
				{props.imageCarousel &&
					props.imageCarousel.map((item, index) => (
						<div className='col-4 imageCarouselViewContainer' key={index}>
							<img className='imageCarouselImg' src={item} alt='imageCarousel' />
						</div>
					))}
			</div>
		</div>

		<div className='imageCarouselView mobileTabImageCarouselContainer'>
			<div id='courseImageCarousel' className='carousel slide'>
				<div className='carousel-inner'>
					{props.imageCarousel &&
						props.imageCarousel.map((item, index) => (
							<div className={`carousel-item ${index === 0 && 'active'}`} key={index}>
								<img
									src={item}
									className='w-100 imageCarouselImg'
									alt='imageCarousel'
								/>
								{/* <img src="https://img.freepik.com/premium-photo/mockup-green-box-green-background-generative-ai_665163-1768.jpg" className='w-100' alt="imageCarousel" /> */}
							</div>
						))}
				</div>
				<button
					className='carousel-control-prev carouselControlBtn'
					data-bs-target='#courseImageCarousel'
					data-bs-slide='prev'>
					<img src={LeftCarouselArrow} alt='LeftCarouselArrow' />
				</button>
				<button
					className='carousel-control-next carouselControlBtn'
					data-bs-target='#courseImageCarousel'
					data-bs-slide='next'>
					<img src={RightCarouselArrow} alt='RightCarouselArrow' />
				</button>
			</div>
		</div>
	</>
);
