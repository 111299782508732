import React from 'react';
import ContentLoader from 'react-content-loader';

export default function VideoPlayerLoader() {
	return (
		<>
			<div className='d-none d-lg-block mainCourseDetailsPageContainer'>
				<ContentLoader viewBox='0 0 200 80' style={{ marginTop: '20px' }}>
					{/* VideoPlayer */}
					<rect x='0' y='0' rx='1' ry='1' width='132' height='75' />

				</ContentLoader>
			</div>

			<div className='d-block d-lg-none mainCourseDetailsPageContainer'>
				<ContentLoader viewBox='0 0 100 60' style={{ marginTop: '20px' }}>
					{/* VideoPlayer */}
					<rect x='0' y='0' rx='1' ry='1' width='100' height='55' />

				</ContentLoader>
			</div>
		</>
	);
}